import React from 'react';
import { useState } from "react";
import Swal from "sweetalert2";
import {
  Row,
  Col,
  Button,
} from "reactstrap";
import axios from "axios";
import Sidebar from '../../Sidebar';
import { useNavigate } from 'react-router';
import '../../App.css';
import { BiSave } from "react-icons/bi";
import { IoIosEye } from "react-icons/io";
import { TiCancel } from "react-icons/ti";
import { AiOutlineHome } from "react-icons/ai";
import { useCookies } from 'react-cookie';
import TableRows from "../../forms/admin/TableRows";
import { Link } from 'react-router-dom';
import '../../button.css';
import { default as DispatchReport} from './DispatchReport';
import {default as GoodsReceiptNote} from './GoodsReceiptNote';
import {default as SalesPosting} from './SalesPostingReport';
import {default as AgewiseReport} from './AgeWiseReport';
import {default as AgeWiseSummary} from './AgeWiseSummary';
import {default as GSTReport} from './GSTReport';
import {default as CustomerDataReport} from './CustomerDataReport';
import {default as ReceiptReport} from './ReceiptReport';
import {default as AccountReceivableReport} from './AccountReceivableReport';
import {default as ProductionReport} from './ProductionReport';
import {default as EquipmentReport} from './EquipmentReport';
import {default as LedgerAccount} from './LedgerAccount';
import {default as WorkScheduleReport} from './WorkScheduleReport';
import {default as UserReport} from './UserReport';
import {default as DcForTally} from './DcForTally';
function ReportAll() {
  const [cookies] = useCookies(['myToken']);
  const [container, setContainer] = useState('');
  const [inputs, setInputs] = useState({
    report_name:''
});
  const navigate = useNavigate();

  const handleChange2 = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
    setContainer(value);
    console.log(value);
  }

  const handleSubmit=(event)=> {
    event.preventDefault();
  }  
      const Reload = () => {
        window.location.reload();
      }      
      
      const Home = () => {
        navigate('/Home')
      }
  return (
    <>
    <div id="outer-container"  className="App" > 
        <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
        <div id="page-wrap">
            <form className="form" onSubmit={handleSubmit}>
                {/* <div id="header">
                    <h3 className = "text font-weight-bold page-title" >REPORT</h3>
                </div>
          */}
          <div className="container-fluid">
          <Row>
          <Col xl={4} lg={12} md={12}>
                      </Col>
              <Col xl={4} lg={12} md={12}>
                  <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue form-control-panel">
                        <label htmlFor="report_name"className="form-group col-sm-3 text-right ">Report*</label>
                        <select  id="report_name" required name="report_name" onChange={handleChange2} value={inputs.report_name || ""}className="form-control  col-sm-7 browser-default custom-select">
                          <option value=''>Please select Report</option>
                          <option value='dispatch_report'>Dispatch Report</option>
                          <option value='agewise_summary'>AgeWise Summary</option>
                          <option value='gst_report'>GST Report</option>
                          <option value='receipt_report'> Receipt Report</option>
                          <option value='accounts_receivable_report'>AccountReceivable Report</option>
                          <option value='customer_data_report'> CustomerData Report</option>
                          <option value='production_report'> Production Report</option>
                          <option value='equipment_report'>EquipmentReport</option>
                          <option value='goods_receipt_note'>Goods Receipt Note</option>
                          <option value='sales_posting'>Sales Posting Report</option>
                          <option value='agewise_report'>Agewise Report</option>
                          <option value='ledger_account'>Ledger Account</option>
                          <option value='work_schedule_report'>Work Schedule Report</option>
                          <option value='user_report'>User Report</option>
                          <option value='dc_for_tally'>DC for TALLY</option>
                        </select>
                        <Link  to={'/Home'} >
                          <i className=" form-group col-sm-3  fa fa-home fa-2x"aria-hidden="true"></i>
                        </Link>
                  </div>
                  </Col>
          </Row> 
          </div>
              </form> 
              <div id="render-report-all-container"><RenderReportContainer container={container}></RenderReportContainer></div> 
          </div>  </div>
      </>
  );
}
function RenderReportContainer({container}){
  switch(container){
    case 'dispatch_report':
      return <DispatchReport/>;
    case 'goods_receipt_note':
      return <GoodsReceiptNote/>;
    case 'sales_posting':
      return <SalesPosting/>;
    case 'agewise_report':
      return <AgewiseReport/>;
      case 'agewise_summary':
      return <AgeWiseSummary/>;
      case 'customer_data_report':
      return <CustomerDataReport/>;
      case 'gst_report':
      return <GSTReport/>;
      case 'receipt_report':
      return <ReceiptReport/>;
      case 'accounts_receivable_report':
      return <AccountReceivableReport/>;
      case 'production_report':
      return <ProductionReport/>;
      case 'equipment_report':
        return <EquipmentReport/>;
      case 'work_schedule_report':
        return <WorkScheduleReport/>;
    case 'ledger_account':
      return <LedgerAccount/>;
    case 'user_report':
      return <UserReport/>;
    case 'dc_for_tally':
      return <DcForTally/>;
      default: return null;
  }
}
export default ReportAll ;
