function TableRowsPlantConfig({rowsData, deleteTableRows, handletableChange}) {


    return(
        
        rowsData.map((data, index)=>{
            const {header_name,column_number,value}=data
            return(

        <tr  key={index} className="text-center detailclass" id="after-this1">
            <td><button className="btn btn-danger" onClick={()=>(deleteTableRows(index))}>x</button></td>
            <td><input type="text"  name="header_name" value={header_name} onChange={(evnt)=>(handletableChange(index, evnt))} style={{width:"100px"}} className="form-control  mandatory-form-control"  /></td>
            <td><input type="number" min="0" style={{width: "100px"}} className="form-control  mandatory-form-control" value={column_number} onChange={(evnt)=>(handletableChange(index, evnt))}  name="column_number" required /></td>
            {/*  max="20"  */}
            <td><input type="text" style={{width: "100px"}} className="form-control" value={value} onChange={(evnt)=>(handletableChange(index, evnt))}  name="value"/></td>
        </tr>
            )
        })
    )
}
export default TableRowsPlantConfig;