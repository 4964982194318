
import { getApiBaseUrl,getApiClient } from "./serviceconfig";

var apiBaseUrl = getApiBaseUrl();
//var apiBaseUrl = "http://localhost:8001";
export async function getAllCustomerSites(cookies,searchString) {
  let url= `${apiBaseUrl}/consignees/`;
  if (searchString) {
    url = url + `?name=${searchString}`;
  }
    return getApiClient(cookies).get(url)
      .then(response => response.data);
}


export function createCustomerSite(cookies, inputs) {

  return getApiClient(cookies).post(`${apiBaseUrl}/consignees/`, inputs)
}

export function updateCustomerSite(cookies, inputs, siteId) {
return getApiClient(cookies).put(`${apiBaseUrl}/consignees/${siteId}/`,inputs)
}


export async function getCustomerSiteDetails(cookies, siteId) {

  return getApiClient(cookies).get(`${apiBaseUrl}/consignees/${siteId}/`)
      
              .then(response => response.data)
}

export function deleteCustomerSite(cookies, siteId) {

  return getApiClient(cookies).delete(`${apiBaseUrl}/consignees/${siteId}/`)
}
