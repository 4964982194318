function TableRowsIPAddress({rowsData, deleteTableRows, handleChange}) {


    return(
        
        rowsData.map((data, index)=>{
            const { slNo,ip_addr,user_remarks,status}=data
        return(

            <tr  key={index} className="text-center detailclass" style={{textAlign:'center'}} id="after-this1">
            <td><button type="button"className="btn btn-danger" onClick={()=>(deleteTableRows(index))}>x</button></td>
            <td><input type="text"  className="form-control add"  value={slNo} style={{width:"100px",backgroundColor:"white",textAlign:'center'}} readOnly={true}   name="slNo" /></td>
            <td>
                <input type="text"value={ip_addr} onChange={(evnt)=>(handleChange(index, evnt))}   id="ip_addr" className="form-control" style={{width:"120px",textAlign:"centre",backgroundColor:"white"}}   name="ip_addr" />
            </td>
            <td>
                <input type="text"value={user_remarks} onChange={(evnt)=>(handleChange(index, evnt))} className="form-control total" id="user_remarks"  name="user_remarks" style={{textAlign:"right",width:"120px",backgroundColor:"white"}} />
            </td>               
       
         
       
            <td>
                
                
                    <select className="form-control browser-default custom-select"  style={{width: "125px"}}value={status} onChange={(evnt)=>(handleChange(index, evnt))} name="status" required>
                        <option value='1'>Active</option>
                        <option value='0'>Inactive</option>
                      
                    </select>
                
            </td>
        </tr>
        )
        })
   
    )
    
}

export default TableRowsIPAddress;