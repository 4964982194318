import axios from "axios";
import { getApiBaseUrl,getApiClient } from "./serviceconfig";
var apiBaseUrl = getApiBaseUrl();
export async function getAllBank(cookies,searchString) {

  let url= `${apiBaseUrl}/banks/`;
  if (searchString) {
    url = url + `?name=${searchString}`;
  }
 return getApiClient(cookies).get(url)
  .then(response => response.data);
  
              // .then((data) => {
              //   return data;
              // });
}

export function createBank(cookies, inputs) {

  return getApiClient(cookies).post(`${apiBaseUrl}/banks/`, inputs)
}

export function updateBank(cookies, inputs, bankId) {

  return getApiClient(cookies).put(`${apiBaseUrl}/banks/${bankId}/`,inputs)
}

export async function getBankDetails(cookies, bankId) {

  return getApiClient(cookies).get(`${apiBaseUrl}/banks/${bankId}/`)
      
  .then(response => response.data)
}

export function deleteBank(cookies, bankId) {

  return getApiClient(cookies).delete(`${apiBaseUrl}/banks/${bankId}/`)
}