import { getApiBaseUrl, getApiClient } from "./serviceconfig";
//var apiBaseUrl = "http://localhost:8001";
var apiBaseUrl = getApiBaseUrl();

export async function getAllWorkSchedules(cookies,searchString) {

  let url= `${apiBaseUrl}/work_schedules/`;
  if (searchString) {
    url = url + `?name=${searchString}`;
  }
    return getApiClient(cookies).get(url)
      .then(response => response.data);
}

export async function createWorkSchedule(cookies, inputs) {

  return getApiClient(cookies).post(`${apiBaseUrl}/work_schedules/`, inputs)
}

export async function CompleteWorkSchedule(cookies, inputs, wsId) {

  return getApiClient(cookies).post(`${apiBaseUrl}/work_schedules/${wsId}/complete/`, inputs)
}

export function updateWorkSchedule(cookies, inputs, wsId) {
  
  return getApiClient(cookies)
  .put(`${apiBaseUrl}/work_schedules/${wsId}/`, inputs)
}

export async function getWorkScheduleDetails(cookies, wsId) {

  return getApiClient(cookies)
  .get(`${apiBaseUrl}/work_schedules/${wsId}/`)
  .then(response => response.data);
}

export async function getWorkScheduleForJobCompletion(cookies, wsId) {

  return getApiClient(cookies)
  .get(`${apiBaseUrl}/work_schedules/${wsId}/job_completion/`)
  .then(response => response.data);
}

export async function getWorkScheduleForDeliveryChallan(cookies, plantId,dcDate) {

  return getApiClient(cookies)
   .get(`${apiBaseUrl}/work_schedules/delivery_challan/?plant_id=${plantId}&delivery_challan_date=${dcDate}`)
  .then(response => response.data);
}

export async function getWorkSchedulesForDesignMix(cookies, plantId) {

  return getApiClient(cookies)
   .get(`${apiBaseUrl}/work_schedules/design_mix/?plant_id=${plantId}`)
  .then(response => response.data);
}

export async function getAllWorkSchedulesForPumpClearance(cookies, plantId) {

  return getApiClient(cookies)
  .get(`${apiBaseUrl}/work_schedules/pump_clearance/?plant_id=${plantId}`)
  .then(response => response.data);
}


export async function getWorkScheduleJobNumber(cookies, plantId) {

  return getApiClient(cookies)
  .get(`${apiBaseUrl}/work_schedules/schedule_number/?plant_id=${plantId}`)
  .then(response => response.data);
}
// export async function getPlantDetails(cookies, companyId) {

//   return getApiClient(cookies).get(`${apiBaseUrl}/entity_plant/${companyId}/companies`)
//         .then((response) => response.data);
// }
export function deleteWorkSchedule(cookies, wsId) {

  return getApiClient(cookies).delete(`${apiBaseUrl}/work_schedules/${wsId}/`); 
}

