import React from 'react';
import { useState,useEffect } from "react";
import Swal from "sweetalert2";
import {Row,Col,Button} from "reactstrap";
import axios from "axios";
import Sidebar from '../../Sidebar';
import { useNavigate } from 'react-router';
import { BiSave } from "react-icons/bi";
import { IoIosEye } from "react-icons/io";
import { TiCancel } from "react-icons/ti";
import { AiOutlineHome } from "react-icons/ai";
import { Cookies, useCookies } from 'react-cookie';
import { Link,useParams } from 'react-router-dom';
import { checkDuplicateCustomerMain } from './CustomerMainUtils';
import {displayErrorToast,displayError,parseBoolean} from '../../helpers';
import FloatingControls from '../../components/FloatingControls';
import { updateCustomerMain,getCustomerMainDetails } from "../../services/CustomerMainServices";
import ErrorToast from '../../ErrorToast';
import StatusDropDown from '../../components/StatusDropDown';
import { getAllSalesRepresentatives } from '../../services/SalesRepresentativeServices';
import { getAllGroups } from "../../services/GroupFormServices";
import { getAllTax } from "../../services/TaxServices";
import TableRowsCustomer from './TableRowsCustomer';
import { getIndianStates } from '../../utils/PicklistProvider';

function CustomerMainEdit() {
    const [cookies] = useCookies(['myToken']);
    const [inProgress, setInProgress] = useState(false);
    const [category] = useCookies(['myCategory']);
    const [cussite,setCus] = useState([])
    const [superuser] = useCookies(['mySuperuser']);
    const [inputs, setInputs] = useState({

        name: '',
        salesrep: '',
        address_1:'',
        address_2:'',
        address_3:'',
        pincode:'',
        state:'',
        phone_number: '',
        mobile_number:'',
        email_id:'',
        credit_limit:0,
        customer_type:'',
        opening_balance_amt:0,
        opening_balance_date:'',
        customer_type:'',
        nature_of_business: '',
        is_interest_activated: '',
        is_bill_by_bill_maintained:'',
        default_credit:'',
        registration_type:'',
        is_gst_set:'',
        pan_no:'',
        gst_no:'',
        lut_no:'',
        tan_no:'',
        is_tcs_applicable:'',
        // tcs_code:'',
        is_tds_deductible:'',
        contact_person:'',
        contact_designation:'',
        contact_mobile_no: '',
        contact_email_id:'',
        user_remarks:'',
        status: 'true'
       
    });
    const [error, setError] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(true);
    const navigate = useNavigate();
    const [natBusiness, setnatBusiness] = React.useState([]);
    const [groups, setGroups] = React.useState([]);
    const [salesRep, setsalesRep] = React.useState([]);
    const [taxes, setTaxes] = React.useState([]);
    const [CusMain,setCusMain] = React.useState([]);
  //   React.useEffect(() => {
  //   async function getCharacters() {
  //       const promises = [
  //           fetchUrl(`createCustomerMain/`)
  //         ];
  //         const results = Promise.all(promises);
  //   }
  //   getCharacters();
  // }, []);
  //   const fetchUrl = async (name) => {
  //       const baseUrl = 'http://127.0.0.1:8000/';
  //       if(name === 'createCustomerMain/')
  //       {
  //           try {
  //               const res = await fetch(`${baseUrl}` + name,{ method: 'GET',
  //               headers: {
  //                   'Authorization': `Token ${cookies['myToken']}`
  //               }});
  //               const data = await res.json();
    
  //               if(data.msg)
  //               {
  //                 Swal.fire((data.msg), '', 'info')
  //               }
  //               else
  //               {
  //                 setTaxes(data.tax)
  //                 setCusType(data.custype)
  //                 setnatBusiness(data.natbusiness)
  //                 setsalesRep(data.sales)
  //                 setCusMain(data.cus_main)
  //               }
  //           }
  //           catch (err) 
  //           {
  //               console.log('Error fetching data'+err);
  //           }  
  //       }
  //   }
    

  React.useEffect(() => {
    getAllSalesRepresentatives(cookies)
    .then (
        salesRepList => {
            
            const salesrep = salesRepList.salesrep_data.map(
              sales => {
                    return { value: sales.id, label: sales.entity_name }
                }
               
            );
            //console.log(JSON.stringify(salesRepList)+"salesRepList")
        
            setsalesRep(salesrep);
          
        }
    )
}, []);


useEffect( () => {
  getAllTax(cookies)
  .then(response => {

      const tax_value=[]
                      for(var j=0; j<response.tax_list.length; j++) 
                      {
                          if(response.tax_list[j].status='true')
                          {
                              tax_value.push(response.tax_list[j]);
                            
                          }
                      }
                      setTaxes(tax_value);
                      console.log(JSON.stringify(tax_value)+"BBB")
   

  });

}, []);

useEffect( () => {
  getAllGroups(cookies)
  .then(response => {
const group =response.accounts_grouping_list.filter(obj => obj.entity_name == 'CUSTOMER TYPE' )
      const a =[]
                      for(var i=0; i<response.accounts_grouping_list.length; i++) 
                      {
                          if((response.accounts_grouping_list[i].parent_id)=== (group[0].id))
                          {
                              a.push(response.accounts_grouping_list[i]);
                          }
                      }
                    
                      setGroups(a);
   

  });

}, []);

useEffect( () => {
  getAllGroups(cookies)
  .then(response => {
const group =response.accounts_grouping_list.filter(obj => obj.entity_name == 'NATURE OF BUSINESS' )
      const a =[]
                      for(var i=0; i<response.accounts_grouping_list.length; i++) 
                      {
                          if((response.accounts_grouping_list[i].parent_id)=== (group[0].id))
                          {
                              a.push(response.accounts_grouping_list[i]);
                          }
                      }
                    
                      setnatBusiness(a);
   

  });

}, []);
const [rowsData, setRowsData] = useState([]);
 
const addTableRows = () => {
    const count=rowsData.length +1;

    const rowsInput={
      slNo:count,
      invoice_no: "",
      invoice_dt: "",
      invoice_amt: 0,
      payment_terms: 0,
      received_amt: 0,
      balance_amt: 0,
      mode: "debit"
        }
        setRowsData([...rowsData, rowsInput])
}
    
const deleteTableRows = (index)=>{
        const rows = [...rowsData];
        rows.splice(index, 1);
        for(var i=0; i<rows.length; i++) 
        {
            rows[i]['slNo']= i+1
        }
        setRowsData(rows);
}
const handleInvoice = (index, evnt)=>{

  const { name, value } = evnt.target;
        const rowsInput = [...rowsData];
        rowsInput[index][name] = value;
      
       

           if(name === 'invoice_amt')
          {
            console.log(JSON.stringify(name)+"name")
              var amount=value-rowsInput[index]['received_amt']
         
              rowsInput[index]['balance_amt']= amount
              console.log(JSON.stringify(rowsInput)+"rowsInput")
              var grandTotal = 0;
              for(var i=0; i<rowsData.length; i++) 
              {
     
                  if((rowsData[i].balance_amt) == '') 
                  {
                      rowsData[i].balance_amt=0 
                  
                  }
                  grandTotal += parseFloat(rowsData[i].balance_amt)
              }
          
              //setInputs(values => ({...values, [name]: value}))
          }
          else if(name === 'received_amt')
          {
  
              var amount=rowsInput[index]['invoice_amt']-value
        
              rowsInput[index]['balance_amt']= amount
              var grandTotal = 0;
              for(var i=0; i<rowsData.length; i++) 
              {
                 
                  if((rowsData[i].balance_amt) == '') 
                  {
                      rowsData[i].balance_amt=0
                    
                  }
                  grandTotal += parseFloat(rowsData[i].balance_amt)
              }
             
             
          }
          else if(name === 'balance_amt')
          {
             
          }
          setRowsData(rowsInput);
      
        //setInputs(values => ({...values, [name]: value}))
      
 
    }
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        try{
     
        if(name === 'name')   //Customer must not be empty if it is already exist u can create new product name
        {
            if(!value.trim())
            {
                setError(values => ({...values, [name]: "Customer Name must not be empty..."}))
                setIsSubmitting(false); 
            }
            else{
                      
                setError(values => ({...values, [name]: ""}))
                setIsSubmitting(true); 
              }
         
        }

          setInputs(values => ({...values, [name]: event.target.value}))
      
      }
      catch (e)
      {
       displayErrorToast(e);
      }
    }
    // const handleTax = (event) => {
    //   const name = event.target.name;
    //   const value = event.target.value;
    //   try{
     
    //     if(value === "false")
    //     {
            
    //             // const selected_prod = taxes.filter(obj => obj.name == 'NO TAX')
    //             // console.log(JSON.stringify(selected_prod)+"selected prod")
         
    //             // setInputs(values => ({...values, ['tcs_code_id']: ''}))
    //             document.getElementById('tcs_code_id').disabled = true;
    //     }
    //     else{
          
        
    //         //setInputs(values => ({...values, [name]: event.target.value}))
    //        // console.log(inputs+"setInputs")
    //        setInputs(values => ({...values, ['tcs_code_id']:value}))
    //         document.getElementById('tcs_code_id').disabled =false;
    //       }
    //     setInputs(values => ({...values, [name]: event.target.value}))
    
    // }
    // catch (e)
    // {
    //  displayErrorToast(e);
    // }
    // }

    const handleTax = (event) =>{
      const name = event.target.name;
      const value = event.target.value;
      if(value === 'false'){
     
         document.getElementById('tcs_code_id').disabled = true;
         document.getElementById('tcs_code_id').required = false;
       
         document.getElementById('tcs_code_id').className = document.getElementById('tcs_code_id').className.replace('mandatory-form-control',''); 
      }
      else
      {
          document.getElementById('tcs_code_id').disabled = false;
          document.getElementById('tcs_code_id').required = true;
          document.getElementById('tcs_code_id').className += ' mandatory-form-control'; 
      }
      setInputs(values => ({...values, [name]: event.target.value}))
    }
    
    const validate_items = (name,value,regular_expr,error_message) =>
    {
       try{
         
            if(value.trim())
            {
                if(!regular_expr.test(value.trim()))
                {
                    setError(values => ({...values, [name]: error_message}))
                    setIsSubmitting(false); 
                }
                else
                {
                    setError(values => ({...values, [name]: ''}))
                    setIsSubmitting(true);
                }
            }
            else
            {
                // setError(values => ({...values, [name]: 'must not be empty...'}))
                // setIsSubmitting(false);
                setError(values => ({...values, [name]: ''}))
                setIsSubmitting(true);
            }
            setInputs(values => ({...values, [name]: value}))
        }
     
       catch (e)
          {
           displayErrorToast(e);
          }
     }  
     const handleSubmit=(event)=> {
      event.preventDefault();
    
        checkDuplicateCustomerMain(event.target.name.name, event.target.name.value, cookies, id)
      .then(isNameExists => {
        if (isNameExists) {
          setError(values => ({...values, [event.target.name.name]: 'Customer name already exists.'}))
          
        } else {
      
    
          if (Object.values(error).every((x) => !x) && isSubmitting) { 
      //If the fields are correct details it is valid choosing yes or no option,otherwise it is invalid
              Swal.fire({title: 'Do you want to update?',  
              showCancelButton: true,  
              confirmButtonText: `Yes`,  
              cancelButtonText: `No`,
              }).then((result) => {  
                if (result.isConfirmed) { 
           
        
                    console.log(JSON.stringify(inputs)+"inputsubmit")
                    updateCustomerMain(cookies, {
                      id:inputs.id,
                      name:inputs.name,
                      salesrep_id:inputs.salesrep_id,
                      address_1:inputs.address_1,
                      address_2:inputs.address_2,
                      address_3:inputs.address_3,
                      pincode:inputs.pincode,
                      state: inputs.state,
                      phone_number: inputs.phone_number,
                      mobile_number:inputs.mobile_number,
                      email_id:inputs.email_id,
                      credit_limit:parseInt(inputs.credit_limit),
                      opening_balance_amt:parseInt(inputs.opening_balance_amt),    
                      opening_balance_date:inputs.opening_balance_date,
                      customer_type_id:inputs.customer_type_id,
                      nature_of_business_id: inputs.nature_of_business_id,
                      is_interest_activated: inputs.is_interest_activated,
                      is_bill_by_bill_maintained:inputs.is_bill_by_bill_maintained,
                      default_credit:inputs.default_credit,
                      registration_type:inputs.registration_type,
                      is_gst_set:inputs.is_gst_set,
                      pan_no:inputs.pan_no,
                      gst_no:inputs.gst_no,
                      lut_no:inputs.lut_no,
                      tan_no:inputs.tan_no,
                      is_tcs_applicable:parseBoolean(inputs.is_tcs_applicable),
                      // tcs_code_id:inputs.tcs_code_id,
                      is_tds_deductible:parseBoolean(inputs.is_tds_deductible),
                      contact_person:inputs.contact_person,
                      contact_designation: inputs.contact_designation,
                      contact_mobile_no: inputs.contact_mobile_no,
                      contact_email_id:inputs.contact_email_id,
                      user_remarks:inputs.user_remarks,
                      ob_details_list:rowsData.map(cuslist=>({
                        id:cuslist.id,
                        invoice_no:cuslist.invoice_no,
                        invoice_dt:cuslist.invoice_dt,
                        invoice_amt:parseInt(cuslist.invoice_amt),
                        payment_terms:cuslist.payment_terms,
                        received_amt:cuslist.received_amt,
                        balance_amt:cuslist.balance_amt,
                        mode:cuslist.mode


                      })),
                      status:parseBoolean(inputs.status)
           
                   }, id)
                   //console.log(updateAccountMaster)
                  .then(response => {
                      Swal.fire("Updated!", "", "success");
                      Reload();
                  }).catch((error) => {
                      console.log(error.response.data);
                      displayError(error.response.data,"Updated Failed");
                  });
                  
                } 
                else if (result.isDismissed) {
                  Swal.fire("Not Updated", "", "info");
                }
              });
        
        }
  
  }
  });

   } 
   

        const Reload = () => {
          // window.location.reload();
          navigate('/CustomerMainTable')
        }      
        const view = () => {
          navigate('/CustomerMainTable')
        }  
        const Back = () => {
          navigate('/Home')
        }
        const Cancel = () => {
            // setInputs(() => "")
            setInputs({
              name: '',
              salesrep: '',
              address_1:'',
              address_2:'',
              address_3:'',
              pincode:'',
              state:'',
              phone_number: '',
              mobile_number:'',
              email_id:'',
              credit_limit:0,
              opening_balance_amt:0,
              opening_balance_date:'',
              customer_type_id:'',
              nature_of_business_id: '',
              is_interest_activated: '',
              is_bill_by_bill_maintained:'',
              default_credit:'',
              registration_type:'unknown',
              is_gst_set:'',
              pan_no:'',
              gst_no:'',
              lut_no:'',
              tan_no:'',
              is_tcs_applicable:'false',
              // tcs_code_id:'',
              is_tds_deductible:'false',
              contact_person:'',
              contact_designation:'',
              contact_mobile_no: '',
              contact_email_id:'',
              user_remarks:'',
              status: 'true'
            });
        }
  
      const {id}=useParams()
      React.useEffect(() => {
        if (id) {
          setInProgress(true);
          getCustomerMainDetails(cookies, id)
            .then(siteObject => {
              setInProgress(false);
              setInputs({...siteObject,
                salesrep_id:siteObject.salesrep.id,
                customer_type_id:siteObject.customer_type.id,
                is_tcs_applicable:siteObject.is_tcs_applicable,
                //  tcs_code_id:siteObject.tcs_code.id,
                nature_of_business_id:siteObject.nature_of_business.id});
             
                setRowsData(siteObject.ob_details_list.map((cuslist,index)=>({...cuslist,slNo: index+1})));
              setCus(siteObject.consignees)
              console.log(setCus+"setCus")
   
            })
            .catch(error => {
              setInProgress(false);
              //displayError(error.response.data, "Loading Failed");
              console.log(error+"error")
            });
          }
        }, []);
    return (
    <>
     <div id="outer-container"  className="App" > 
        <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
        <ErrorToast/>
        <div id="page-wrap">
        <form onSubmit={handleSubmit}> 
          <div id="header">
              <h3  className = "text font-weight-bold page-title" >CUSTOMER REGISTRATION EDIT FORM </h3>
          </div>
         
          <FloatingControls tableLink="/CustomerMainTable" onCancel={Cancel} enableCancel={true}/>
          <div className="container"> 
                <Row> 
                <Col xl={6} lg={12} md={12}>   
                <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue form-control-panel" >
                <h5 className='col-sm-11'><b>Customer Details</b></h5><br/><br/> 
                                <label htmlFor="name" className="form-group col-sm-4 text-right">Name </label>
                                <input type="text" name="name" id="name" className="form-control col-sm-7 mandatory-form-control" required onChange={handleChange} value={inputs.name || ""} placeholder="Enter fill the name field*"   maxLength="100" /><br/>
                                {error.name && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.name}</strong><br/></>)}

                                <label htmlFor="salesrep_id" className="form-group col-sm-4 text-right">Marketing Name </label>
                                <select id="salesrep_id" name="salesrep_id" className="browser-default custom-select form-control col-sm-7 mandatory-form-control"onChange={handleChange} value={inputs.salesrep_id || ""} required>
                                    <option value="">Select Marketing Name</option>
                                    {salesRep.map((item) => (
                                         <option key={item.value} value={item.value}>
                                         {item.label}
                                       </option>
                                    ))}
                                </select><br/>
                                
                                <label htmlFor="address_1" className="form-group col-sm-4 text-right">Address</label>
                                <input type="text" name="address_1" id="address_1" className="form-control col-sm-7" onChange={handleChange} value={inputs.address_1 || ""}/><br/>
                                
                                <label htmlFor="address_2" className="form-group col-sm-4 text-right"></label>
                                <input type="text" name="address_2" id="address_2" className="form-control col-sm-7" onChange={handleChange} value={inputs.address_2 || ""}/><br/>
                                
                                <label htmlFor="address_3" className="form-group col-sm-4 text-right"></label>
                                <input type="text" name="address_3" id="address_3" className="form-control col-sm-7" onChange={handleChange} value={inputs.address_3 || ""}/><br/>                                       
                                
                                <label htmlFor="pincode"className="form-group col-sm-4 text-right  ">Pin Code</label>
                                <input name="pincode" id="pincode" onChange={ (event) => validate_items(event.target.name,event.target.value,/^[1-9][0-9]{5}$/,"invalid Pincode")} value={inputs.pincode || ""} className="form-control col-sm-7" type = "text" maxLength="6" /><br/>
                                {error.pincode && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.pincode}</strong><br/></>)}

                                {/* <select id="state"name="state" className="browser-default custom-select form-control col-sm-7" onChange={handleChange} value={inputs.state || ""}>
                                    <option value='Select'>Select</option>
                                    <option value='AN Andaman & NicobarIslands'>AN Andaman & Nicobar Islands 35</option>
                                    <option value='Ap AndhraPradesh'>AP Andhra Pradesh 28</option>
                                    <option value='AndhraPradeshNew'>AP Andhra Pradesh(new) 37</option>
                                    <option value='AR ArunachalPradesh'>AR ArunachalPradesh 12</option>
                                    <option value='AS Assam'>AS Assam 18</option>
                                    <option value='BH Bihar'>BH Bihar 10</option>
                                    <option value='CH Chandigarh'>CH Chandigarh 04</option>
                                    <option value='CT Chattisgarh'>CT Chattisgarh 22</option>
                                    <option value='DN Dadra & Nagar Haveli'>DN Dadra & Nagar Haveli 26</option>
                                    <option value='DD Daman & Diu'>DD Daman & Diu 25</option>
                                    <option value='DL Delhi'>DL DElhi 07</option>
                                    <option value='GA Goa'>GD Goa 30</option>
                                    <option value='GA Gujarat'>GA Gujarat 24</option>
                                    <option value='HR Haryana'>HR Haryana 06</option>
                                    <option value='HP HimachalPradesh'>HP HimachalPradesh 02</option>
                                    <option value='JK Jammu&Kashmir'>JK Jammu&Kashmir 01</option>
                                    <option value='JH Jarkhand'>JH Jarkhand 20</option>
                                    <option value='KA Karnataka'>KA Karnataka 29</option>
                                    <option value='KL Kerala'>KL Kerala 32</option>
                                    <option value='LD LakshadweepIslands'>LD LakshadweepIslands 31</option>
                                    <option value='MP MadhyaPradesh'>MP MadhyaPradesh 23</option>
                                    <option value='MH Maharashtra'>MH Maharashtra 27</option>
                                    <option value='MN Manipur'>MN Manipur 14</option>
                                    <option value='ME Meghalaya'>ME Meghalaya 17</option>
                                    <option value='MI Mizoram'>MI Mizoram 15</option>
                                    <option value='NL Nagaland'>NL Nagaland 13</option>
                                    <option value='OR Odisha'>OR Odisha 21</option>
                                    <option value='PY Pondicherry'>PY Pondicherry 34</option>
                                    <option value='PB Punjab'>PB Punjab 03</option>
                                    <option value='RJ RAjasthan'>RJ RAjasthan 08</option>
                                    <option value='SK Sikkim'>SK Sikkim 11</option>
                                    <option value='TN TamilNadu'>TN TamilNadu 33</option>
                                    <option value='TS Telangana'>TS Telangana 36</option>
                                    <option value='TR Tripura'>TR Tripura 16</option>
                                    <option value='UP UttarPradesh'>UP UttarPradesh 09</option>
                                    <option value='UT Uttarakhand'>UT Uttarakhand</option>
                                    <option value='WB WestBengal'>WB WestBengal 19</option>
                                </select> */}
                                <label htmlFor="state"className="form-group col-sm-4 text-right  ">State</label>
                                <select id="state"name="state" className="browser-default custom-select form-control col-sm-7 mandatory-form-control" onChange={handleChange} required value={inputs.state || ""}>
                                <option value="">Select State</option>
                                    {
                                      getIndianStates()
                                      .map(indian_state => <option key={indian_state.value} value={indian_state.value}>{indian_state.name}</option>
                                      )
                                    }
                                  </select>

                                <label htmlFor="phone_number" className="form-group col-sm-4 text-right">Phone No</label>
                                <input type="text" name="phone_number" id="phone_number" className="form-control col-sm-7" onChange={handleChange} value={inputs.phone_number || ""}placeholder="0123456789, 0123456789" /><br/>
                                
                                <label htmlFor="mobile_number" className="form-group col-sm-4 text-right">Mobile No</label>
                                <input type="text" name="mobile_number" id="mobile_number" className="form-control col-sm-7"  onChange={handleChange} value={inputs.mobile_number || ""} placeholder="0123456789, 0123456789"/><br/>
                                
                                <label htmlFor="email_id" className="form-group col-sm-4 text-right">Email</label>
                                <input type="email" name="email_id" id="email_id" className="form-control col-sm-7"  onChange={ (event) => validate_items(event.target.name,event.target.value,/^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,"invalid Emailid")} value={inputs.email_id|| ""} /><br/>
                                {error.email_id && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.cusEmailid}</strong><br/></>)}

                                <label htmlFor="credit_limit" className="form-group col-sm-4 text-right">Credit Limit(in Rs.)</label>
                                <input type="number" name="credit_limit" id="credit_limit" onChange={handleChange} value={inputs.credit_limit || 0} className="form-control col-sm-7"/>

                                <label htmlFor="opening_balance_amt" className="form-group col-sm-4 text-right">Opening Balance Amount</label>
                                <input type="number" name="opening_balance_amt" id="opening_balance_amt" onChange={handleChange} value={inputs.opening_balance_amt || 0} className="form-control col-sm-6"/>
                                <button type="button"  className="btn btn-primary text-blue text-bold " data-toggle="modal" data-target="#exampleModalCenter">Details</button>
                                <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                <div className="modal-dialog modal-lg" style={{height: "500px"}} >
                                  <div className="modal-content">
                                      <div className="modal-body display">
                                        <table id="example mytable" className="table-responsive">
                                            <thead>
                                                <tr>                                                    
                                                <th><button type="button"className="btn btn-success" onClick={addTableRows}>+</button></th>
                            <th colSpan="1">S.No.</th>
                            <th colSpan="1">Invoice No</th>
                            <th colSpan="1" style={{width:"120px"}}>Invoice Date</th>
                            <th colSpan="1">Invoice Amount</th>
                            <th colSpan="1">Payment Terms</th>
                            <th colSpan="1">Received Amount </th>
                            <th colSpan="1">Balance Amount</th>
                            <th colSpan="1">Mode</th>

                                                    </tr>
                                                    </thead>
                                            <tbody id="tbodymodal">
                                         
                                              <TableRowsCustomer rowsData={rowsData} deleteTableRows={deleteTableRows} handleInvoice={handleInvoice} />
                                            </tbody>
                                      
                                        </table>
                                      </div>
                                  </div>
                                </div>
                        </div>


                                <label htmlFor="opening_balance_date"className="form-group col-sm-4 text-right "> Opening  Balance Date </label>
                                <input type="date" id="opening_balance_date" name="opening_balance_date" onChange={handleChange} value={inputs.opening_balance_date || ""}className="form-control col-sm-7 " />
                        </div>
                        
                        <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue form-control-panel" >
                        <h5 className='col-sm-11'><b>Customer Type Details</b></h5><br/><br/> 
                                <label htmlFor="customer_type_id" className="form-group col-sm-4 text-right">Customer Type </label>
                                <select id="customer_type_id" name="customer_type_id" onChange={handleChange} value={inputs.customer_type_id || ""} className="browser-default custom-select form-control col-sm-7 mandatory-form-control" required>
                                    {/* <option value="">Select Cutomer Type</option> */}
                                    {groups.map((item) => (
                                    <option key={item.id} value={item.id}>
                                    {item.entity_name}
                                    </option>
                                ))}
                                </select><br/>
                    
                                <label htmlFor="nature_of_business_id" className="form-group col-sm-4 text-right">Nature of Business </label>
                                <select name="nature_of_business_id" id="nature_of_business_id" onChange={handleChange} value={inputs.nature_of_business_id || ""} className="browser-default custom-select form-control col-sm-7 mandatory-form-control" required>
                                    <option value="">Select Customer's Nature of Business </option>
                                    {natBusiness.map((item) => (
                                    <option key={item.id} value={item.id}>
                                    {item.entity_name}
                                    </option>
                                ))}
                                </select><br/>

                                <label htmlFor="is_interest_activated" className="form-group col-sm-4 text-right">Activate interest</label>
                                <select name="is_interest_activated" id="is_interest_activated" onChange={handleChange} value={inputs.is_interest_activated || ""} className="browser-default custom-select form-control col-sm-7 " required >
  
                                <option value="false">No</option>
                                <option value="true">Yes</option>
                                </select><br/>

                                <label htmlFor="is_bill_by_bill_maintained" className="form-group col-sm-4 text-right">Maintain Bill-by-bill</label>
                                <select name="is_bill_by_bill_maintained" id="is_bill_by_bill_maintained" onChange={handleChange} value={inputs.is_bill_by_bill_maintained} className="browser-default custom-select form-control col-sm-7"  >
  
                                <option value="false">No</option>
                                <option value="true">Yes</option>
                                </select><br/>

                                <label htmlFor="default_credit" className="form-group col-sm-4 text-right">Credit(in Days)</label>
                                <input type="number" name="default_credit" id="default_credit" onChange={handleChange} value={inputs.default_credit || 0} className="form-control col-sm-7"/><br/>
                                
                             
                        </div>
                </Col>
                    <Col xl={6} lg={12} md={12}>   
 
                   
                    <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue form-control-panel" >
                    <h5 className='col-sm-11'><b>Statutory Details</b></h5><br/><br/> 
                    <label htmlFor="registration_type" className="form-group col-sm-4 text-right">GST Type </label>
                                <select name="registration_type" id="registration_type" onChange={handleChange} value={inputs.registration_type || ""} className="browser-default custom-select form-control col-sm-7 mandatory-form-control"  required>
                                <option value="unknown">Unknown</option>
                                <option value="composition">Composition</option>
                                <option value="consumer">Consumer</option>
                                <option value="regular">Regular</option>
                                <option value="unregistered">Unregistered</option>
                                </select><br/>
                    
                                {/* <label htmlFor="tcs_code_id" className="form-group col-sm-4 text-right">Tax Structure</label>
                            <select name="tcs_code_id" id="tcs_code_id" className="browser-default custom-select form-control col-sm-7" onChange={handleChange}  value={inputs.tcs_code_id}   >
                                <option value="">Select Tax</option>
                                {taxes.map((item) => (
                                    <option key={item.id} value={item.id}>
                                    {item.name}
                                    </option>
                                           ))}
                            </select> */}
                                <label htmlFor="is_gst_set" className="form-group col-sm-4 text-right">Set/Alter GST details</label>
                                <select name="is_gst_set" id="is_gst_set" onChange={handleChange} value={inputs.is_gst_set} className="browser-default custom-select form-control col-sm-7 mandatory-form-control"  required>
                                <option value="">Please select</option> 
                                <option value="false">No</option>
                                <option value="true">Yes</option>
                                </select><br/>
                            <label htmlFor="pan_no" className="form-group col-sm-4 text-right">PAN No.</label>
                            <input type="text"name="pan_no" id="pan_no" className="form-control col-sm-7" onChange={ (event) => validate_items(event.target.name,event.target.value,/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,"invalid PANNo")}  value={inputs.pan_no || ""}  placeholder="Please fill the PAN field *"  /><br/>
                            {error.pan_no && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.pan_no}</strong><br/></>)}
                            

                            <label htmlFor="gst_no"className="form-group col-sm-4 text-right  ">GST No </label>
                            <input type="text" id="gst_no" name="gst_no" onChange={ (event) => validate_items(event.target.name,event.target.value,/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,"invalid GSTNo")}  value={inputs.gst_no || ""}className="form-control col-sm-7 "  placeholder="Please fill the Gst field *" /><br/>
                            {error.gst_no && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.gst_no}</strong><br/></>)}

                            <label htmlFor="lut_no" className="form-group col-sm-4 text-right">LUT No</label>
                            <input type="text" name="lut_no" id="lut_no"  onChange={handleChange} 
                            // onChange={ (event) => validate_items(event.target.name,event.target.value,/^[1-9][0-9]{5}$/,"invalid LUTNo")} 
                            value={inputs.lut_no|| ""}className="form-control col-sm-7 " /><br/>
                            {/* {error.lut_no && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.lut_no}</strong><br/></>)} */}
                                
                            
                            <label htmlFor="tan_no" className="form-group col-sm-4 text-right">TAN No.</label>
                            <input type="text" name="tan_no" id="tan_no" onChange={handleChange} 
                            // onChange={ (event) => validate_items(event.target.name,event.target.value,/^[1-9][0-9]{5}$/,"invalid TANNo")} 
                            value={inputs.tan_no || ""}className="form-control col-sm-7 "  /><br/>

                            <label htmlFor="is_tcs_applicable" className="form-group col-sm-4 text-right">Is TCS Applicable </label>
                                <select name="is_tcs_applicable" id="is_tcs_applicable"  onChange={handleChange} value={inputs.is_tcs_applicable } className="browser-default custom-select form-control col-sm-7 mandatory-form-control"  required>
                                {/* onChange={handleTax} */}
                                <option value="false">No</option>
                                <option value="true">Yes</option>
                                </select><br/>

                                
                            <label htmlFor="is_tds_deductible" className="form-group col-sm-4 text-right">Is TDS Deductable </label>
                                <select name="is_tds_deductible" id="is_tds_deductible" onChange={handleChange} value={inputs.is_tds_deductible || ""} className="browser-default custom-select form-control col-sm-7 mandatory-form-control"  required>
                                <option value="false">No</option>
                                <option value="true">Yes</option>
                                </select><br/>
                            
                        </div>
                        
                        <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue form-control-panel" >
                        <h5 className='col-sm-11'><b>Contact Details</b></h5><br/><br/> 
                            <label htmlFor="contact_person" className="form-group col-sm-4 text-right">Contact Person Name</label>
                            <input type="text" name="contact_person" id="contact_person" onChange={handleChange} value={inputs.contact_person || ""} className="form-control col-sm-7"/><br/>
                            
                            <label htmlFor="contact_designation" className="form-group col-sm-4 text-right">Designation</label>
                            <input type="text" name="contact_designation" id="contact_designation" onChange={handleChange} value={inputs.contact_designation || ""} className="form-control col-sm-7"/><br/>
                            
                            <label htmlFor="contact_mobile_no" className="form-group col-sm-4 text-right">Contact Mobile No</label>
                            <input type="text" name="contact_mobile_no" id="contact_mobile_no"  onChange={handleChange} value={inputs.contact_mobile_no || ""} placeholder="0123456789"  className="form-control col-sm-7"/><br/>
                            
                            <label htmlFor="contact_email_id" className="form-group col-sm-4 text-right">Email Id</label>
                            <input type="email" name="contact_email_id" id="contact_email_id" onChange={handleChange} value={inputs.contact_email_id || ""} className="form-control col-sm-7" placeholder="ABC@gmail.com"/><br/>
                            {error.contact_email_id && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.contact_email_id}</strong><br/></>)}
                        </div>

                        <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue form-control-panel" >
                        <h5 className='col-sm-11'><b>Status Details</b></h5><br/><br/> 
                            <label htmlFor="user_remarks" className="form-group col-sm-4 text-right">Remarks</label>
                            <textarea rows="4" cols="50" className="form-control col-sm-7" id="user_remarks" name="user_remarks" onChange={handleChange} value={inputs.user_remarks || ""}></textarea>
                            
                            <StatusDropDown status={inputs.status} onChange={handleChange}/>

                  
                            <br/>
                        </div>
                    </Col>
                </Row>             
            </div>
            <div className="container">
                <Row>
                <Col xl={12} lg={24} md={24}>
             
                  <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue form-control-panel" >
                      <div><b>No.of Site Associated with this Customer:{cussite.length}</b></div>
                      <table border={2} cellPadding={5} cellSpacing={5} width={"100%"} align={"center"}>
                        <thead>
                        <tr>
                          
                          <td style={{fontWeight: "bold"}}>Site Name</td>
                          <td style={{fontWeight: "bold"}}>Site Address1</td>
                          <td style={{fontWeight: "bold"}}>Site Address2</td>
                          <td style={{fontWeight: "bold"}}>Site Address3</td>
                          <td style={{fontWeight: "bold"}}>Project Name</td>
                        </tr>
                        </thead>
                        <tbody>
                          {cussite.map(cus_site => (
                            <tr key={cus_site.id} >
                              
                              <td>{cus_site.name}</td>
                              <td>{cus_site.address_1}</td>
                              <td>{cus_site.address_2}</td>
                              <td>{cus_site.address_3}</td>
                              <td>{cus_site.project_name}</td>
                              
                            </tr>
          
                          ))}


                        </tbody>
                      </table>
                  </div>
                </Col>
                </Row>
              </div>
            <div className="footer text-center">
              
            <Button  type="submit"  style={{width:"80px",fontWeight:"bold",backgroundColor:"RGB(42,245,39,0.8)"}} className="btn btn-twitter">Update</Button> &nbsp;&nbsp;

                  <Button  type="button"style={{width:"80px",fontWeight:"bold",backgroundColor:"RGB(58,29,238,0.8)"}} className="btn btn-twitter" onClick={view}>View</Button>&nbsp;&nbsp;
                  <Button  type="button"style={{width:"80px",fontWeight:"bold",backgroundColor:"RGB(58,29,238,0.8)"}} className="btn btn-twitter" onClick={Back}>Home</Button>
            </div>
        </form>
        </div>
    </div>
         </>
    );
  }
  export default CustomerMainEdit;