
import { getApiBaseUrl, getApiClient } from "./serviceconfig";
// var apiBaseUrl = "http://localhost:8001";
var apiBaseUrl = getApiBaseUrl();

export async function getAllPumpClearance(cookies,searchString) {

  let url= `${apiBaseUrl}/pump_clearances/`;
  if (searchString) {
    url = url + `?name=${searchString}`;
  }
    return getApiClient(cookies).get(url)
      .then(response => response.data);
}


export async function createPumpClearance(cookies, inputs) {
  console.log(JSON.stringify(inputs)+"inputs_services")

  return getApiClient(cookies).put(`${apiBaseUrl}/pump_clearances/${inputs.id}/`,inputs)
  
  
}

export async function updatePumpClearance(cookies, inputs) {

    return getApiClient(cookies)
    .put(`${apiBaseUrl}/pump_clearances/${inputs.id}/`,inputs)
}
  


export async function getPumpClearanceDetails(cookies, PumpClearanceId) {

  return getApiClient(cookies).get(`${apiBaseUrl}/pump_clearances/${PumpClearanceId}/`)
  .then(response => response.data)
}

export async function deletePumpClearance(cookies, PumpClearanceId) {

  return getApiClient(cookies).delete(
    `${apiBaseUrl}/pump_clearances/${PumpClearanceId}/`)
}