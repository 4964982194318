import {getAllPlants} from '../../services/PlantServices'

export function checkForDuplicatePlant(name, value, cookies, existingId) {
    
    
    let nameCheck = new Promise((resolve, reject) => {
      getAllPlants(cookies)
      .then((data) => {

        let isUserExists = false;
        data.plant_list.forEach(plant=>{    //Same Plant Name mentioned it denoted already exist.
        
          if((plant.plant_alias.toUpperCase())=== value.trim().toUpperCase()
            && parseInt(existingId) !== plant.id){   
            
            console.log(`Plant exists with the name - ${value}`) 
            isUserExists = true;
     
          }
        
        resolve(isUserExists);
      });  
    })
    });

    return nameCheck;
  }