import { useEffect, useState, useMemo } from "react";
import ReactTableWrapper from "../../components/ReactTableWrapper";
import { exportAsCSV } from "../../utils/CSVExporter";
import { exportAsPDF } from "../../utils/PDFExporter";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import Sidebar from '../../Sidebar';
import Swal from "sweetalert2";
import { useCookies } from 'react-cookie';
import { Row, Col, Button } from "reactstrap";
import { useLocation, Link } from 'react-router-dom';
import { moment } from 'moment';
import { getAllCubeTestReports, deleteCubeTestReport } from "../../services/CubeTestReportServices";
function CubeTestReportTable() {
    const InitialData = {
        total_pages: 0,
        records: [],
      };
    const [data, setData] = useState(InitialData);
    const navigate = useNavigate(); 
    const [refreshKey, setRefreshKey] = useState(0);
    const [cookies] = useCookies(['myToken']);
    const [isLoading, setIsLoading] = useState(true);  
    const columns = useMemo(
        () => [
            {
                fieldName: 'entity_name', 
                headerName: 'Report No'
            },
           
            {
                fieldName: "address",
                headerName: 'Report Date'
            },
            {
                fieldName: "phone_number",
                headerName: 'Casting Date'
            },
            {
                fieldName: 'entity_name', 
                headerName: 'Customer Name'
            },
            {
                fieldName: 'entity_name', 
                headerName: 'Customer Project/Site'
            },
            {
                fieldName: "mobile_number",
                headerName: 'Grade'
            },
            {
                fieldName: "mobile_number",
                headerName: 'Supplied Qty'
            },
            {
                fieldName: "mobile_number",
                headerName: 'Actual Average 7 days'
             },
             {
                fieldName: "mobile_number",
                headerName: 'Actual Average 28days'
            },

            
            {
                fieldName: "id",
                headerName: "ROW_ACTIONS"
            }
        ],
        [],);

    const onEditCubeTestReport = (cubetestId, event) => {
       
       navigate(`/ CubeTestReportEdit/${cubetestId}/`);
    };

    const onDeleteCubeTestReport = (cubetestId, event) => {

        event.preventDefault();
        Swal.fire({title: 'Are you sure to Delete?',  
        showCancelButton: true,  
        confirmButtonText: `Yes`,  
        cancelButtonText: `No`,
        }).then((result) => {  
          if (result.isConfirmed) { 

            setIsLoading(true);
            deleteCubeTestReport(cookies, cubetestId); 
            console.log(`Cube test with id ${cubetestId} deleted`);
            Swal.fire('Deleted Successfully!', '', 'success');
            setRefreshKey(oldKey => oldKey +1)
          } else if (result.isDismissed) {    
            Swal.fire('Not Deleted', '', 'info')  
        }
        });
        //

    };

    const handleCSVExport = (event) => {
        console.log('CSV Export');
        exportAsCSV(
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(c => c.headerName),
            data.records.map(r => {

                const csvRow = {
                    entity_name: r.entity_name,
                    address: r.address,
                    phone_number:r.phone_number,
                    mobile_number:r.mobile_number,
                    status:r.status
                }
                return csvRow;
                }));
    };

    const handlePDFExport = (event) => {
        console.log('PDF Export');
        exportAsPDF(
            'List of CubeTestReport',
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(col=>({header: col.headerName, dataKey: col.fieldName })),
            data.records.map(r => ({
                entity_name: r.entity_name,
                address: r.address, 
                phone_number:r.phone_number,
                mobile_number:r.mobile_number,
                status:r.status
            })),
            ' CubeTestReport.pdf'
        );
    };

    useEffect(() => {

        getAllCubeTestReports(cookies)
        .then( cubeTestList => {
            const tableData = cubeTestList.salesrep_data
            .map(cubeTestList => ({
                entity_name: cubeTestList.entity_name,
                address: `${cubeTestList.address_1},\n${cubeTestList.address_2},\n${cubeTestList.address_3}`,
                phone_number:cubeTestList.phone_number,
                mobile_number:cubeTestList.mobile_number,
                status:cubeTestList.status,
                id: cubeTestList.id,    
            }));

            setData({
                total: data.total,
                records: tableData
            });
            setIsLoading(false);
        });
        }, [refreshKey]);
    
    return (

        <div id="outer-container"  className="App" > 
            <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
            <div id="page-wrap">
            <h3 style={{color:"rgb(2, 2, 49)",textAlign:"center"}}>List of Cube Test Report</h3>
            <br/>
                <div className="container item-list-table-container">
                <div className="table-responsive">
                <Row>
                    <Col xl={1} lg={12} md={12}>
            </Col>
          
          <Col xl={7} lg={4} md={3}>
            <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-2 bg-blue form-control-panel"style={{alignItems:"baseline",display:"flex"}}>
              <label htmlFor="company" className="form-group col-sm-2 text-right">Plant location *</label>
              <select  id="company" className="browser-default custom-select col-sm-5" onChange={""}  required name="company" >
                  <option value=''>Please select Plant</option>
                  
              </select><br/><br/>
                       

                        <div>    
                            <Link  to={'/Search'} >
                            <i className=" form-group col-sm-2  fa fa-search fa-2x"aria-hidden="true"></i>
                            </Link>
                        </div> 

                       
                    </div>
                    </Col>
              </Row> 
                <ReactTableWrapper
                            title='List of Cube Test Report'
                            columns={columns}
                            data={data.records}
                            onRowEdit={onEditCubeTestReport}
                            onRowDelete={onDeleteCubeTestReport}
                            onCSVExport={handleCSVExport}
                            onPDFExport={handlePDFExport}
                            isLoading={isLoading}
                        />
                </div>
                </div> 


            </div>
            <button style={{position:"relative",right:"40px"}}className="btn btn-fill btn-primary float-end" type="button" onClick={() =>{navigate("/CubeTestReport")}}>Back</button>
        </div>

        
    );

}



export default CubeTestReportTable;