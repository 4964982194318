import {getAllDeliveryChallans} from '../../services/DeliveryChallanServices'
import Swal from "sweetalert2";
import { getHTMLForSummaryPopup } from '../../utils/PopupUtils';
import {parseBooleanToString} from '../../helpers';
export function checkForDuplicateDeliveryChallans(name, value, cookies, existingId) {
    
    
    let nameCheck = new Promise((resolve, reject) => {
      getAllDeliveryChallans(cookies)
      .then((data) => {

        let isUserExists = false;
        for(var i=0; i<data.salesrep_data.length; i++)    //Same Sales Representative Name mentioned it denoted already exist.
        {
          if((data.salesrep_data[i].entity_name) === value.trim().toUpperCase()
            && parseInt(existingId) !== data.salesrep_data[i].id){   
            
            console.log(`User exists with the name - ${value}`) 
            isUserExists = true;
            break;
          }
        }
        resolve(isUserExists);
      });  
    });

    return nameCheck;
  }

  export function showDmixPopup(designmix_list,plantConfig) {
        const plant_configuration = plantConfig.plant_configuration_list[0];
        const design_mix = designmix_list.design_mix_list[0];
        var fine_aggregate = design_mix.agg3 + design_mix.agg4 + design_mix.agg5;
        var coarse_aggregate = design_mix.agg1 + design_mix.agg2;
        console.log(JSON.stringify(design_mix)+"dmix");
    Swal.fire({
      html:getHTMLForSummaryPopup(
        'DESIGN MIX LIST',
          [
            {label: 'Grade', value: designmix_list.design_mix_list[0].work_schedule.sales_order_detail.product.name},
            (plant_configuration.is_silo_1 &&
              {label: plant_configuration.silo_1, value: design_mix.cem1?design_mix.cem1:'0'}),
            (plant_configuration.is_silo_2 &&
              {label: plant_configuration.silo_2, value: design_mix.cem2?design_mix.cem2:'0'}),
            (plant_configuration.is_silo_3 &&
              {label: plant_configuration.silo_3, value: design_mix.cem3?design_mix.cem3:'0'}),
            (plant_configuration.is_silo_4 &&
              {label:plant_configuration.silo_4, value: design_mix.cem4?design_mix.cem4:'0'}),
            (plant_configuration.is_silo_5 &&
              {label:plant_configuration.silo_5, value: design_mix.cem5?design_mix.cem5:'0'}),
            {label: 'Total Cementitious', value: design_mix.total_cem,is_calculated:true},
            
            (plant_configuration.is_bin_1 &&
              {label: plant_configuration.bin_1, value: design_mix.agg1?design_mix.agg1:'0'}),
            (plant_configuration.is_bin_2 &&
              {label:plant_configuration.bin_2, value: design_mix.agg2?design_mix.agg2:'0'}),
            {label: 'Coarse Aggregate', value: coarse_aggregate?coarse_aggregate:'0', is_calculated:true },
            
            (plant_configuration.is_bin_3 &&
              {label: plant_configuration.bin_3, value: design_mix.agg3?design_mix.agg3:'0'}),
            (plant_configuration.is_bin_4 &&
              {label: plant_configuration.bin_4, value: design_mix.agg4?design_mix.agg4:'0'}),
            (plant_configuration.is_bin_5 &&
              {label: plant_configuration.bin_5, value: design_mix.agg5?design_mix.agg5:'0'}),
            {label: 'Fine Aggregate', value: fine_aggregate ? fine_aggregate:'0',is_calculated:true },
            
            (plant_configuration.is_other_1 &&
              {label: plant_configuration.other_1, value: design_mix.water1?design_mix.water1:'0'}),
            (plant_configuration.is_other_2 &&
              {label: plant_configuration.other_2, value: design_mix.water2?design_mix.water2:'0'}),
            (plant_configuration.is_other_3 &&
              {label: plant_configuration.other_3, value: design_mix.adm1?design_mix.adm1:'0'}),
            (plant_configuration.is_other_4 &&
              {label: plant_configuration.other_4, value: design_mix.adm2?design_mix.adm2:'0'}),
            (plant_configuration.is_other_5 &&
              {label: plant_configuration.other_5, value: design_mix.others?design_mix.others:'0'}),
            
            {label: 'Density', value: design_mix.density,is_calculated:true},
            {label: 'WC Ratio', value: design_mix.water_cem,is_calculated:true},
                 ].filter(Boolean))
  }, '', 'info');  
  }
  export function showBillingPop(selectedCustomer){
    Swal.fire({
      html:getHTMLForSummaryPopup(
          'BILLING DETAILS',
          [
            {label: 'BILLING NAME', value: selectedCustomer.name},
          {label: 'ADDRESS', value: selectedCustomer.address_1},
          {value: selectedCustomer.address_2},
          {value: selectedCustomer.address_3},
          {label: 'SALES OFFICER', value: selectedCustomer.salesrep.name},
          
          ])
  }, '', 'info');
  }
  export function showCustomerSitePop(consignee){
    Swal.fire({
      html:getHTMLForSummaryPopup(
          'DELIVERY DETAILS',
          [
            {label: 'DELIVERY NAME', value: consignee.name},
            {label: 'ADDRESS', value: consignee.address_1},
            {value: consignee.address_2},
            {value: consignee.address_3},
          ])
  }, '', 'info');   

  }
  export function showWorkschedulePop(selectedWorkSchedule){
    Swal.fire({
      html:getHTMLForSummaryPopup(
          'WORKSCHEDULE DETAILS',
          [
          
          {label: 'WORK ORDER NO.', value: selectedWorkSchedule.schedule_no},
          {label: 'WORK ORDER DATE', value: selectedWorkSchedule.schedule_date},
          {label: 'MODE OF DELIVERY', value: selectedWorkSchedule.sales_order_detail.delivery_mode},
          {label: 'PUMP NO', value: selectedWorkSchedule.pump.name},
          {label: 'GANG', value: selectedWorkSchedule.gang.name},
          {label: 'IS EXTRA CHUTE REQUIRED?', value: parseBooleanToString(selectedWorkSchedule.is_extra_shut_required)},
          
          ])
  }, '', 'info');
  }
  export function showSalesorderPop(selectedWorkSchedule){
    Swal.fire({
      html:getHTMLForSummaryPopup(
         'SALES ORDER DETAILS',
         [
           
           {label: 'SO DATE', value: selectedWorkSchedule.sales_order_detail.sales_order.order_date},
           {label: 'CUSTOMER P.O NO', value: selectedWorkSchedule.sales_order_detail.sales_order.purchase_order_no},
           {label: 'MODE OF TRANSPORT', value: selectedWorkSchedule.sales_order_detail.sales_order.transport_mode},
           {label: 'SALES ORDER QTY', value: selectedWorkSchedule.sales_order_detail.order_quantity},
           
  
         ])
  }, '', 'info'); 
  }
  export function showProjectrPop(delivery_details){
    Swal.fire({
      html:getHTMLForSummaryPopup(
          'PROJECT/SITE DETAILS',
          [
            {label: 'PROJECT/SITE', value: delivery_details.project_name},
            {label: 'CONTACT NAME', value: delivery_details.contact_person},
            {label: 'MOBILE NUMBER', value: delivery_details.mobile_number},
            {label: 'DESIGNATION', value: delivery_details.contact_designation},
                  ])
  }, '', 'info');   
  }
