function TableRowsCustomer({rowsData, deleteTableRows, handleInvoice}) {


    return(
        
        rowsData.map((data, index)=>{
            const {slNo,invoice_no,invoice_dt,invoice_amt,payment_terms,received_amt,mode,balance_amt}=data
        return(

            <tr  key={index} className="text-center detailclass" id="after-this1">
                <td><button type="button"className="btn btn-danger" onClick={()=>(deleteTableRows(index))}>x</button></td>
                <td><input type="text"  className="form-control add"  value={slNo} style={{width:"100px",backgroundColor:"white", cursor: "not-allowed"}} readOnly={true}   name="slNo" /></td>
                <td><input type="text"  className="form-control add"  value={invoice_no} onChange={(evnt)=>(handleInvoice(index, evnt))} style={{width:"100px",backgroundColor:"white"}}   name="invoice_no" /></td>
                <td><input type="date"  className="form-control add"  value={invoice_dt} onChange={(evnt)=>(handleInvoice(index, evnt))} style={{width:"120px",backgroundColor:"white"}}   name="invoice_dt" /></td>


                <td>
                    <input type="number"value={invoice_amt} onChange={(evnt)=>(handleInvoice(index, evnt))}   id="unitname1" className="form-control" style={{width:"100px",backgroundColor:"white"}}  name="invoice_amt" />
                </td>
                <td>
                    <input type="number" step="any" min="0"style={{width: "100px",textAlign:"right"}} className="form-control qty"  id="qty" value={payment_terms} onChange={(evnt)=>(handleInvoice(index, evnt))} name="payment_terms" required/>
                </td>
                <td>
                    <input type="number" step="any" min="0" style={{width: "100px",textAlign:"right"}} className="form-control price" id="price" value={received_amt} onChange={(evnt)=>(handleInvoice(index, evnt))} name="received_amt"required/>
                </td>
                <td>
                    <input type="number"min="0" value={balance_amt} onChange={(evnt)=>(handleInvoice(index, evnt))} className="form-control total" id="total"  name="balance_amt" style={{textAlign:"right",width:"100px",backgroundColor:"white"}} />
                </td>               

         
                <td>
                    <select className="form-control browser-default custom-select"  style={{width: "125px"}}value={mode} onChange={(evnt)=>(handleInvoice(index, evnt))} name="mode" required>
                
                        <option value="debit">DEBIT</option>
                        <option value="credit">CREDIT</option>
          
                    </select>
                </td>    
                
           

            </tr>
        )
        })
   
    )
    
}

export default TableRowsCustomer;