
import { getApiBaseUrl, getApiClient } from "./serviceconfig";

var apiBaseUrl = getApiBaseUrl();
export async function getAllGangs(cookies,searchString) {

  let url= `${apiBaseUrl}/entity_gang/`;
  if (searchString) {
    url = url + `?name=${searchString}`;
  }
    return getApiClient(cookies).get(url)
      .then(response => response.data);
}

export async function createGang(cookies, inputs) {

  return getApiClient(cookies).post(`${apiBaseUrl}/entity_gang/`,inputs)
  
}

export async function updateGangs(cookies, inputs, gangId) {

    return getApiClient(cookies)
    .put(`${apiBaseUrl}/entity_gang/${gangId}/`,inputs)
}
  


export async function getGangDetails(cookies, gangId) {

  return getApiClient(cookies).get(`${apiBaseUrl}/entity_gang/${gangId}/`)
  .then(response => response.data)
}

export async function deleteGang(cookies, gangId) {

  return getApiClient(cookies).delete(
    `${apiBaseUrl}/entity_gang/${gangId}/`)
   
}