import axios from "axios";
import { getApiBaseUrl, getApiClient } from "./serviceconfig";

var apiBaseUrl = getApiBaseUrl();

export async function getGoodsReceiptnumber(cookies, plant_id, grn_date) {
    let url = `${apiBaseUrl}/goods_receipt_notes/goods_receipt_note_number/?plant_id=${plant_id}&grn_date=${grn_date}`;
  
    return getApiClient(cookies).get(url)
        .then(response => response.data);
} 

  export async function getAllGoodsReceiptNotes(cookies) {
    return getApiClient(cookies).get(`${apiBaseUrl}/goods_receipt_notes/`)
      .then(response => response.data);
  }

  export function createGoodsReceiptNote(cookies, inputs) {
    return getApiClient(cookies).post(`${apiBaseUrl}/goods_receipt_notes/`, inputs);
  }

  export async function deleteGoodsReceiptNote(cookies, grn_id) {
    return getApiClient(cookies).delete(
      `${apiBaseUrl}/goods_receipt_notes/${grn_id}/`)
  }
  
  export async function printGoodsReceiptNote(cookies, grnId) {
    return getApiClient(cookies).get(`${apiBaseUrl}/goods_receipt_notes/${grnId}/print_grn/`)
                .then(response => response.data)
  }
  
  export async function getGoodsReceiptNoteDetails(cookies, grnId) {
    return getApiClient(cookies).get(`${apiBaseUrl}/goods_receipt_notes/${grnId}/`)
                .then(response => response.data)
  }
  
  export function updateGoodsReceiptNote(cookies, inputs, grnId) {
    return getApiClient(cookies).put(`${apiBaseUrl}/goods_receipt_notes/${grnId}/`, inputs)
  }

  export function cancelGoodsReceiptNote(cookies, grnId) {
    return getApiClient(cookies).patch(`${apiBaseUrl}/goods_receipt_notes/${grnId}/`)
  }