import { useEffect, useState, useMemo } from "react";
import ReactTableWrapper from "../../components/ReactTableWrapper";
import { exportAsCSV } from "../../utils/CSVExporter";
import { exportAsPDF } from "../../utils/PDFExporter";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import Sidebar from '../../Sidebar';
import Swal from "sweetalert2";
import { useCookies } from 'react-cookie';
import { getAllUsers, deleteUser } from "../../services/UserServices";
import { useRadioGroup } from "@mui/material";
import ErrorToast from "../../ErrorToast";
import { displayError } from "../../helpers";
function UserTable() {
    const InitialData = {
        total_pages: 0,
        records: [],
      };
    const [data, setData] = useState(InitialData);
    const navigate = useNavigate(); 
    const [refreshKey, setRefreshKey] = useState(0);
    const [cookies] = useCookies(['myToken']);
    const [isLoading, setIsLoading] = useState(true);  
    const columns = useMemo(
        () => [
            {
                fieldName: 'username', 
                headerName: 'User Name'
            },
           
            {
                fieldName: "plant",
                headerName: 'Plant location'
            },
            {
                fieldName: "category",
                headerName: 'Category'
            },
            {
                fieldName: 'is_active',
                headerName: 'Status',
            },
            {
                fieldName: "id",
                headerName: "ROW_ACTIONS"
            }
        ],
        [],);

    const onEditUser = (userId, event) => {
       
       navigate(`/UserEdit/${userId}/`);
    };

    const onDeleteUser = (userId, event) => {

        event.preventDefault();
        Swal.fire({title: 'Are you sure to Delete?',  
        showCancelButton: true,  
        confirmButtonText: `Yes`,  
        cancelButtonText: `No`,
        }).then((result) => {  
          if (result.isConfirmed) { 

            setIsLoading(true);
            deleteUser(cookies, userId)
            .then(response => {
                console.log(`User with id ${userId} deleted`);
                setRefreshKey(oldKey => oldKey +1);
                Swal.fire('Deleted Successfully!', '', 'success');
            })
            .catch(error => {
                setRefreshKey(oldKey => oldKey +1);
                console.log(error.response.data);
                displayError(error.response.data,'Delete Failed');
            }); 
            
            
          } else if (result.isDismissed) {    
            Swal.fire('Not Deleted', '', 'info')  
        }
        });
        //

    };

    const handleCSVExport = (event) => {
        console.log('CSV Export');
        exportAsCSV(
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(c => c.headerName),
            data.records.map(r => {

                const csvRow = {
                    username:r.username,
                    plant:r.plant,
                    category:r.category,
                    status:r.status,
                }
                
                return csvRow;
            }));
    };

    const handlePDFExport = (event) => {
        console.log('PDF Export');
        exportAsPDF(
            'List of Users',
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(col=>({header: col.headerName, dataKey: col.fieldName })),
            data.records.map(r => ({
                username:r.username,
                plant:r.plant,
                category:r.category,
                status:r.status,
                
            })),
            'Users.pdf'
        );
    };

    useEffect(() => {

        getAllUsers(cookies)
        .then( userList => {
            const tableData = userList.user_list
            .map(user => ({
                username:user.username,
                plant:user.is_superuser?'All':user.plant_list.map(p => p.alias).join(),
                category:user.is_superuser?'SUPERUSER':user.group_list.map(g=> g.name).join(),
                // plant:user.plant_list && user.plant_list.length > 0?user.plant_list.map(p => p.alias).join():"All",
                // category:user.group_list && user.group_list.length > 0 ?user.group_list.map(g=> g.name).join():"SUPERUSER",
                is_active:user.is_active? "Active":"Inactive",
                id: user.id,    
            }));

            setData({
                total: data.total,
                records: tableData
            });
            setIsLoading(false);
        });
        }, [refreshKey]);
    
    return (

        <div id="outer-container"  className="App" > 
            <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
            <ErrorToast/>
            <div id="page-wrap">
            <h3 style={{color:"rgb(2, 2, 49)",textAlign:"center"}}>List of Users</h3>
            <br/>
                <div className="container item-list-table-container">
                <div className="table-responsive">
                <ReactTableWrapper
                            title='List of Users'
                            columns={columns}
                            data={data.records}
                            onRowEdit={onEditUser}
                            onRowDelete={onDeleteUser}
                            onCSVExport={handleCSVExport}
                            onPDFExport={handlePDFExport}
                            isLoading={isLoading}
                        />
                </div>
                </div> 


            </div>
            <button style={{position:"relative",right:"40px"}}className="btn btn-fill btn-primary float-end" type="button" onClick={() =>{navigate("/User")}}>Back</button>
        </div>

        
    );

}



export default UserTable;