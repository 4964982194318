import React from "react";
import Swal from "sweetalert2";
import axios from "axios";
import Sidebar from "../../Sidebar";
import TableRows from "../../forms/admin/TableRows";
import ErrorToast from "../../ErrorToast";
import { displayError } from "../../helpers";

import { useState, useEffect, useRef } from "react";
import FloatingControls from '../../components/FloatingControls';
import { Row, Col, Button } from "reactstrap";
import { useNavigate } from "react-router";
import { BiSave } from "react-icons/bi";
import { IoIosEye } from "react-icons/io";
import { TiCancel } from "react-icons/ti";
import { AiOutlineHome } from "react-icons/ai";
import { useCookies } from "react-cookie";
import { Link,useParams } from "react-router-dom";
import { displayErrorToast } from "../../helpers";
import { checkForDuplicateGroup } from './GroupUtils';

import { updateGroup, getGroupDetails, getAllGroups } from "../../services/GroupFormServices";
import "../../App.css";
import "../../button.css";



function GroupFormEdit() {
  const [cookies] = useCookies(["myToken"]);
  const defaultUnderValue = useRef(null);
  const [under, setUnder] = useState([]);
  const [natureGrp, setNatureGrp] = useState({ '': '' });
  const [inProgress, setInProgress] = useState(false);
  const [error, setError] = useState({ entity_name: '' });
  const [isSubmitting, setIsSubmitting] = useState(true);
  const [parent_id, setparent_id] = React.useState([]);

  
  const [inputs, setInputs] = useState({entity_name:'',entity_type:'',parent_id:'',to_backend:''});
  const navigate = useNavigate();
 

  useEffect( () => {
    getAllGroups(cookies)
    .then((response) => {
        console.log(response.accounts_grouping_list);
        setUnder(response.accounts_grouping_list)
    })
  }, []);


  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if(name==="entity_name"){
        if (!value.trim()) {
            // setError((values) => ({
            //   ...values,
            //   [name]: "Group Name must not be empty",
            // }));
            setIsSubmitting(false);  
        }
  
    }
    else if(name === "parent_id") {
      getAllGroups(cookies)
        .then((response) => {
            // console.log(response.data.query_set);
            setUnder(response.accounts_grouping_list)
          });
          if(value != "primary" ){
            const selected_under = under.filter(obj => obj.id == value)
            console.log(selected_under)
            setInputs(values => ({...values, ['entity_type']: selected_under[0].entity_type,['to_backend']:value}))
            document.getElementById("entity_type").disabled = true;
          }
          else if(value === "primary" ){
            setInputs(values => ({...values, ['to_backend']:''}))
            document.getElementById("entity_type").disabled = false;
          }
    }  
    setInputs(values => ({...values,[name]:value}))
  }




  const handleSubmit=(event)=> {
    event.preventDefault();
    try{
    checkForDuplicateGroup(event.target.entity_name.name, event.target.entity_name.value, cookies, id)
    .then(isNameExists => {
      if (isNameExists) {
        setError(values => ({...values, [event.target.entity_name.name]: 'Group name already exists.'}))
        
      } else {
        setError({[event.target.entity_name.name]: ''})
        console.log(error);
        console.log(JSON.stringify(inputs)+"inputs")
  
        if (Object.values(error).every((x) => !x) && isSubmitting) { 
    //If the fields are correct details it is valid choosing yes or no option,otherwise it is invalid
            Swal.fire({title: 'Do you want to update?',  
            showCancelButton: true,  
            confirmButtonText: `Yes`,  
            cancelButtonText: `No`,
            }).then((result) => {  
              if (result.isConfirmed) { 
         
      
                  console.log(JSON.stringify(inputs)+"inputsubmit")
                  updateGroup(cookies, {
                    id:inputs.id,
                    entity_name:(inputs.entity_name).toUpperCase(),
                    entity_type: inputs.entity_type,
                    parent_id:((inputs.parent_id === 'primary')?  "": inputs.parent_id),
                    // parent_name:inputs.parent_name,
                 }, id)
                 //console.log(updateAccountMaster)
                .then(response => {
                    Swal.fire("Updated!", "", "success");
                    view();
                }).catch((error) => {
                    console.log(error.response.data);
                    displayError(error.response.data,"Updated Failed");
                });
                
              } 
              else if (result.isDismissed) {
                Swal.fire("Not Updated", "", "info");
              }
            });
      
      }

}
});
} catch (e) {
  displayErrorToast(e);
} 
 }  

  const [rowsData, setRowsData] = useState([]);
  const Reload = () => {
    window.location.reload();
  };

  const view = () => {
    navigate("/GroupFormTable");
  };

  const Back = () => {
    navigate("/Home");
  };


  const Cancel = () => {
    setInputs(() => "")

   
  };

  const {id}=useParams()
  React.useEffect(() => {
    // getPermission(cookies,id)
    if (id)
   {
  setInProgress(true);
  getGroupDetails(cookies, id)
    
  .then(response =>setInputs(values => (
    {...values,
      ['id']:response.id,
      ['entity_name']: response.entity_name, 
      ['entity_type']: response.entity_type,
      ['parent_id']:response.parent_id,
    

   

     })))

    .catch(error => {
        setInProgress(false);
        displayError(error.response.data, "Loading Failed");
      });
}
  }, []);



  return (
    <>
      <div id="outer-container" className="App">
        <Sidebar
          pageWrapId={"page-wrap"}
          outerContainerId={"outer-container"}
        />
        <ErrorToast />
        <div id="page-wrap">
          <form className="form" onSubmit={handleSubmit}  data-id={inputs.id}>
            <div id="header">
              <h3 className="text font-weight-bold page-title">GROUP EDIT FORM</h3>
            </div>
            <FloatingControls tableLink="/GroupFormTable" enableCancel={false}/>
            <div className="container-fluid">
              <Row>
                <Col xl={4} lg={12} md={12}></Col>
                <Col xl={4} lg={12} md={12}>
                  <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue form-control-panel ">
                  <h5 className='col-sm-11'><b>Group Details</b></h5><br/><br/>
                    <label
                      htmlFor="entity_name"
                      className="form-group col-sm-4 text-right"
                    >
                      {" "}
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control col-sm-7 mandatory-form-control"
                      id="entity_name"
                      required
                      name="entity_name"
                      onChange={handleChange}
                      value={inputs.entity_name || ""}
                      style={{ backgroundColor: "white" }}
                    />
                    {error.entity_name && (<><strong className="form-group col-sm-12 text-center text-danger " >{error.entity_name}</strong><br /></>)}
                    <br />

                    <br></br>

                    <label
                      htmlFor="parent_id"
                      className="form-group col-sm-4 text-right"
                    >
                      Under
                    </label>
                    <select name="parent_id" className="form-control col-sm-7 browser-default custom-select mandatory-form-control" onChange={ handleChange} value={inputs.parent_id || ""}>
                      {/* <option value=''>Please Select Group</option> */}
                      <option value='primary'>Primary</option>
                      {
                        under.map((e) => (
                          <option key={e.id} value={e.id}>{e.entity_name}</option>
                        ))
                      }
                    </select>

                    <br></br>
                    <br></br>

                    <label
                      htmlFor="entity_type"
                      className="form-group col-sm-4 text-right"
                    >
                      Nature Of Group
                    </label>
                    {

                      <select id="entity_type" name="entity_type" className="form-control col-sm-7 browser-default custom-select" onChange={handleChange}  value={inputs.entity_type || ""}>
                             <option value=''>Please Select Type</option>
                      <option value='income'>Income</option>
                      <option value='expenses'>Expenses</option>
                      <option value='assets'>Assets</option>
                      <option value='liabilities'>Liabilities</option>
                      <option value='others'>others</option>
                      </select>
                    }

                  </div>
                </Col>
              </Row>
            </div>
            <div className="footer text-center">
              <br />
              <Button
                type="submit"
                style={{
                  width: "80px",
                  fontWeight: "bold",
                  backgroundColor: "RGB(42,245,39,0.8)",
                }}

                className="btn btn-twitter"
              >
                Update
              </Button>{" "}
              &nbsp;&nbsp;
        
              <Button
                type="button"
                style={{
                  width: "80px",
                  fontWeight: "bold",
                  backgroundColor: "RGB(58,29,238,0.8)",
                }}
                className="btn btn-twitter"
                onClick={view}
              >
                View
              </Button>
              &nbsp;&nbsp;
              <Button
                type="button"
                style={{
                  width: "80px",
                  fontWeight: "bold",
                  backgroundColor: "RGB(58,29,238,0.8)",
                }}
                className="btn btn-twitter"
                onClick={Back}
              >
                Home
              </Button>
            </div>
          </form>
        </div>
        <br />
      </div>
    </>
  );
}

export default GroupFormEdit;