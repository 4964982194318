function TableRowsSalesPosting({rowsData,showDcdetails}) {
// console.log(JSON.stringify(rowsData)+"rowsdata TableRowsSalesPosting")
return(
    rowsData.map((data, index)=>{
        const {id,product,unit,delivery_mode,dc_qty,accepted_qty,tax,basic_rate,taxable_amt,cgst_amt,sgst_amt,igst_amt,gst_amt,gross_amt}=data
    return(
        <tr key={index}>
            <td  style={{display:"None"}}>
                {id}
            </td>
            <td>
                <input type="text" style={{width:"80px",cursor:"pointer"}}  onClick = {(evnt)=>(showDcdetails(index, evnt))} id="product" value={product} className="form-control"  size="10" name="product"/>
            </td> 
            <td>
                {unit}
            </td>        
            <td>
                {delivery_mode}
            </td>
            <td>
                {dc_qty}
            </td>
            <td>
                {accepted_qty}
            </td>
            <td>
                {basic_rate}
            </td>
            <td>
                {taxable_amt}
            </td>
            <td>
                {tax}
            </td>
            <td>
                {cgst_amt}
            </td>
            <td>
                {sgst_amt}
            </td>
            <td>
                {igst_amt}
            </td>
            <td>
                {gst_amt}
            </td>
            <td>
                {gross_amt}
            </td>
        </tr>
        )
    })
)
}
export default TableRowsSalesPosting;