import React from 'react';
import { useState ,useEffect} from "react";
import Swal from "sweetalert2";
import {Row,Col,Button} from "reactstrap";
import axios from "axios";
import Sidebar from '../../Sidebar';
import { useNavigate } from 'react-router';
import { BiSave } from "react-icons/bi";
import { IoIosEye } from "react-icons/io";
import { TiCancel } from "react-icons/ti";
import { AiOutlineHome } from "react-icons/ai";
import { useCookies } from 'react-cookie';
import {useLocation ,Link,useParams} from 'react-router-dom';
import {displayErrorToast,displayError} from '../../helpers';
import ErrorToast from '../../ErrorToast';
import { getAllEquipments} from '../../services/EquipmentServices';
import { getDisplayDate } from '../../utils/DateUtils';
import {getAllPlants } from '../../services/PlantServices';
import {getCustomerMainDetails } from '../../services/CustomerMainServices';
import{getAllWorkSchedulesForPumpClearance,getWorkScheduleDetails,getAllWorkSchedules} from '../../services/WorkScheduleServices';
import {  updatePumpClearance,getPumpClearanceDetails, deletePumpClearance } from '../../services/PumpClearanceServices';
import FloatingControls from '../../components/FloatingControls';
import { getAllCustomerSites,getCustomerSiteDetails } from '../../services/CustomerSiteServices';
import SummaryIcon from '../../components/SummaryIcon';
import { getPlantDetails} from '../../services/PlantServices';
import { getHTMLForSummaryPopup } from '../../utils/PopupUtils';
import { getAllGangs } from "../../services/GangServices";
import LoadingOverlay from '../../components/LoadingOverlay';
function PumpClearanceEdit() {
    const location = useLocation();
    const [cookies] = useCookies(['myToken']);
    const [category] = useCookies(['myCategory']);
    const[selectedCustomer,setSelectedCustomer] = useState({});
    const [superuser] = useCookies(['mySuperuser']);
    const [sites, setSites] = React.useState([]);
    const [customer, setCustomer] = useState([]);
    const [equipment, setEquipment] = useState([]);
    const [refreshKey, setRefreshKey] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [gang, setGang] = useState([]);
    const [isLoading, setIsLoading] = useState(true);  
    const [towingvehicle, setTowingVehicle] = useState([]);
    const [workschedule,setWorkschedule]=React.useState([]);
    const [userId] = useCookies(['myUserId']);
    const [plant, setPlant] = React.useState([]);
    const[selectedSite,setSelectedSite]= React.useState({})
    const[selectedWorkschedule,setSelectedWorkshedule] = useState({}); 
    const [inputs, setInputs] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(true);
    const [error, setError] = useState({});
    const[allWorkshedule,setAllWorkschedule] = useState([]);

    React.useEffect(() => {
        getAllPlants(cookies)
    
        .then (
            plantList => {
                
                const plant = plantList.plant_list.filter(obj => obj.status).map(
                    plant => {
                        return { value: plant.id, label: plant.plant_alias }
                    }
                );
                setPlant(plant);
            }
        )
    }, []);

    useEffect( () => {
        getAllEquipments(cookies)
        .then(response => {
      const equipment =response.equipment_list.filter(obj => ((obj.equip_type.name === 'PUMP')&&
      (obj.equip_name != 'MANUAL')&&( obj.status)))
      setEquipment(equipment);
      console.log(JSON.stringify(equipment)+"equipment")
        });
      
      }, []);


      useEffect( () => {
        getAllGangs(cookies)
        .then(response => {
      const gang =response.gang_list.filter(obj => ((obj.entity_name != 'MANUAL')&&
      ( obj.status)))
      setGang(gang);
      console.log(JSON.stringify(gang)+"gang")
        });
      
      }, []);

      

      useEffect( () => {
        getAllWorkSchedules(cookies)
        .then( workscheduleList => {
            //console.log(JSON.stringify(workscheduleList)+"HC_wrkshedulelist")
        
                const workschedules = workscheduleList.work_schedule_list.map(
                    workschedule => {
                        return { value: workschedule.id,label: workschedule.schedule_no + "-" + workschedule.sales_order_detail.prefix + workschedule.sales_order_detail.order_no + "(" + workschedule.sales_order_detail.product.name + ")"}
                    }
                );
              
                setWorkschedule(workschedules);
                setAllWorkschedule(workscheduleList.work_schedule_list)
        
        })
      
      }, []);

      
      useEffect( () => {
        getAllEquipments(cookies)
        .then(response => {
      const Vehicle =response.equipment_list.filter(obj => ((obj.equip_type.name === 'TOWING VEHICLE')&&
      ( obj.status)))
      setTowingVehicle(Vehicle);
      console.log(JSON.stringify(Vehicle)+"Vehicle")
        });
      
      }, []);
      const navigate = useNavigate();
      const [inProgress, setInProgress] = useState(false);


         
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
       
        try{
            setInputs(values => ({...values, [name]: value}));
            if(name === 'plant_id' && value){
                setIsLoading(true);
                getAllWorkSchedules(cookies,value)
                .then( workscheduleList => {
                    //console.log(JSON.stringify(workscheduleList)+"HC_wrkshedulelist")
                
                        const workschedules = workscheduleList.work_schedule_list.map(
                            workschedule => {
                                return { value: workschedule.id,label: workschedule.schedule_no + "-" + workschedule.sales_order_detail.sales_order.prefix + workschedule.sales_order_detail.sales_order.order_no + "(" + workschedule.sales_order_detail.product.name + ")"}
                            }
                        );
                      
                        setWorkschedule(workschedules);
                        setAllWorkschedule(workscheduleList.work_schedule_list)
                
                })
                .catch((error) =>
                {
                    setIsLoading(false);
                  console.log(error.response.data);
                  displayError(error.response.data," Failed");
                })
            } else if (name === 'plant_id' && !value) {
                setInputs(values => ({...values, ['schedule_no']: ''}));
            }
          }
         catch (e) {
        
          displayErrorToast(e);
        }
      }
      const showCustomerDetails = () => {
        console.log(selectedWorkschedule+"selectedWorksheduleShowCustomerDetails")
       if (selectedWorkschedule.id) {
           getCustomerMainDetails(cookies,selectedWorkschedule.sales_order_detail.sales_order.consignee.customer.id)
           .then(selectedCustomer => {
               console.log(selectedCustomer+"selectedCustomer")
               Swal.fire({
                   html:getHTMLForSummaryPopup(
                       'BILLING DETAILS',
                       [
                       {label: 'BILLING NAME', value: selectedCustomer.name},
                       {label: 'SALES OFFICER', value: selectedCustomer.salesrep.name}
                       ])
               }, '', 'info');
           })
                
       }
       
  }

const handleSubmit = (event) => {
    event.preventDefault();
   
    if ((Object.values(error).every(x => !x)) && isSubmitting) { 
        Swal.fire({title: 'Do you want to Update?',  
          showCancelButton: true,  
          confirmButtonText: `Yes`,  
          cancelButtonText: `No`,
          }).then((result) => {  
            if (result.isConfirmed) 
            { 
               
                updatePumpClearance(cookies, {
         
                                id:parseInt(inputs.id),
                                pump: {
                                    id: parseInt(inputs.pump_id),
                                    starting_hr: parseInt(inputs.pump_starting_hr)
                                  },
                                          
                                            gang_id:parseInt(inputs.gang_id),
                                            towing_vehicle: {
                                                id: parseInt(inputs.towing_vehicle_id),
                                                starting_km: parseFloat(inputs.towing_vehicle_starting_km)
                                              },
                                   
                                            operator_name:inputs.operator_name,
                                            technician_name:inputs.technician_name?inputs.technician_name:'',
                                            supervisor_name:inputs.supervisor_name?inputs.supervisor_name:'',
                                            incharge_name:inputs.incharge_name,
                                            incharge_mobile_number:((inputs.incharge_mobile_number)?  inputs.incharge_mobile_number:"" ),
                                            no_of_bends:parseInt(inputs.no_of_bends),
                                            no_of_pipes:parseInt(inputs.no_of_pipes),
                                            clearance_date:getDisplayDate(inputs.clearance_date),
                                            clearance_time:inputs.clearance_time,
                                            consignee_starting_time:inputs.consignee_starting_time   
                                 
                                }, id)
              .then(function (response) {
                Swal.fire('Updated!', '', 'success') 
                view();
              })
              .catch((error) =>
              {
                console.log(error.response.data);
                displayError(error.response.data,"Update Failed");
              })
              
            } 
            else if (result.isDismissed) 
            {    
              Swal.fire('Not Updated', '', 'info')  
            }
          });
    }
    else
    {
      Swal.fire('Please check * marked fields are filled and details given are valid...', '', 'info')   
    }
}
const handleChangeCustomerDetails = (event) => {
       
    console.log(event.target.value);
    const customerId = event.target.value;
    const propertyName = event.target.name;
    setInputs(values => ({...values, [propertyName]:customerId}));

    //Get the details of the customber using the Id
    getCustomerMainDetails(cookies,customerId)    
    .then(customerObject => {  
        console.log(JSON.stringify(customerObject)+"customerObject")
                    //Set the details of the customer in the form fields
        setSelectedCustomer(customerObject)})  
        getAllCustomerSites(cookies)

        .then (
            siteList => {
                
                const sites = siteList.consignee_list.filter(obj => obj.status && obj.customer.id === parseInt(customerId)).map(
                    site => {
                        return { value: site.id, label: site.name }
                    }
                );
                setSites(sites);
            }
        )   
}


const showWorkscheduleDetails = () => {

    if (selectedWorkschedule.id) {
            Swal.fire({
                html:getHTMLForSummaryPopup(
                    'WORKSCHEDULE DETAILS',
                    [
                    {label: 'SO NUMBER', value: selectedWorkschedule.sales_order_detail.sales_order.order_no},
                    {label: 'SO DATE', value: selectedWorkschedule.sales_order_detail.sales_order.order_date},
                    {label: 'DELIVERY DATE', value: selectedWorkschedule.delivery_date},
                    {label: 'DELIVERY TIME', value: selectedWorkschedule.delivery_time},
                    {label: 'SCHEDULE QTY', value: selectedWorkschedule.scheduled_qty},
                    {label: 'MODE OF DELIVERY', value: selectedWorkschedule.delivery_mode},
                    {label: 'GRADE', value: selectedWorkschedule.sales_order_detail.product.name},
                   
                    ])
            }, '', 'info');   
    }
    
}
const showSiteDetails = () => {

    if (selectedWorkschedule.id) {
        getCustomerSiteDetails(cookies,selectedWorkschedule.sales_order_detail.sales_order.consignee.id)
        .then(consignee => {
            Swal.fire({
                html:getHTMLForSummaryPopup(
                    'SITE DETAILS',
                    [
                    {label: 'SITE NAME', value: consignee.name},
                    {label: 'ADDRESS', value: consignee.address_1},
                    {value: consignee.address_2},
                    {value: consignee.address_3},
                    {label: 'PROJECT/SITE NAME', value: consignee.project_name}
                    ])
            }, '', 'info');   
        })
          
    }
    
}

const handleChangeWorkscheduleDetails = (event) => {
       
    console.log(event.target.value);
    const salesorderId = event.target.value;
    const propertyName = event.target.name;

 
  console.log(salesorderId+"salesorderId")
    if (salesorderId) {
        
        setInputs(values =>({...values,[propertyName]:salesorderId}));
        const selected_wrk = allWorkshedule.filter(obj => obj.id == salesorderId)[0];
        setSelectedWorkshedule(selected_wrk);
        console.log(selected_wrk+"selected_wrk")
        console.log(JSON.stringify(allWorkshedule)+"allWorkshedule")
        getPlantDetails(cookies,selected_wrk.plant.id)  //get plant list
        .then(plant =>{
            console.log(plant+"plants")
                return {value: plant.id,label : plant.name}
               
            })
          
    } 
    else {  
        setSelectedWorkshedule({}); 
    }
    setInputs(values => ({...values, [propertyName]:salesorderId}));
  }



        const view = () => {
          navigate('/PumpClearanceTable')
        }  
        const Back = () => {
          navigate('/Home')
        }
        const Cancel = () => {
            setInputs(() => "")
    
        }
        const onDeletePumpClearance = (event) => {

            event.preventDefault();
            Swal.fire({title: 'Are you sure to Delete?',  
            showCancelButton: true,  
            confirmButtonText: `Yes`,  
            cancelButtonText: `No`,
            }).then((result) => {  
              if (result.isConfirmed) { 
          
                setIsLoading(true);
                deletePumpClearance(cookies, id)
                .then(response => {
                    console.log(`PumpClearance with id ${id} deleted`);
                    setRefreshKey(oldKey => oldKey +1);
                    Swal.fire('Deleted Successfully!', '', 'success');
                    view();
                })
                .catch(error => {
                    setRefreshKey(oldKey => oldKey +1);
                    console.log(error.response.data);
                    displayError(error.response.data,'Delete Failed');
                }); 
                
                
              } else if (result.isDismissed) {    
                Swal.fire('Not Deleted', '', 'info')  
            }
            });
        
          
          };

          const {id}=useParams()
            
        React.useEffect(() => {
          if (id){
        setInProgress(true);
        getPumpClearanceDetails(cookies, id)
          .then(response => {
              console.log(JSON.stringify(response)+"response")
              setInProgress(false);
            //  console.log(typeof(response.quotation_date)+"response.quotation_date")
            setSelectedWorkshedule(response)
              setInputs(values => ({...values,['id']:response.id,

              ['pump_id']: response.pump.id, 
              ['plant_id']:response.plant.id,
              ['schedule_no']:response.schedule_no+response.sales_order_detail.sales_order.prefix+response.sales_order_detail.sales_order.order_no +response.sales_order_detail.sales_order.prefix + response.sales_order_detail.sales_order.order_no+ response.sales_order_detail.product.name ,
              ['gang_id']: response.gang.id,
              ['towing_vehicle_id']:response.towing_vehicle.id,
              ['pump_starting_hr']:response.pump.starting_hr,  
              ['towing_vehicle_starting_km']:response.towing_vehicle.starting_km,
              ['operator_name']: response.operator_name,
              ['technician_name']: response.technician_name, 
              ['supervisor_name']: response.supervisor_name,
              ['incharge_name']:response.incharge_name,
              ['incharge_mobile_number']:response.incharge_mobile_number,  
              ['no_of_bends']:response.no_of_bends,
              ['no_of_pipes']: response.no_of_pipes,
              ['clearance_date']:getDisplayDate(response.clearance_date),
              ['clearance_time']:response.clearance_time,  
              ['consignee_starting_time']:response.consignee_starting_time,
          }));
           
             
           
          getCustomerMainDetails(cookies,response.sales_order_detail.sales_order.consignee.customer.id)    
          .then(customerObject => {              //Set the details of the customer in the form fields
              setSelectedCustomer(customerObject)
              })
              getCustomerSiteDetails(cookies,response.sales_order_detail.sales_order.consignee.id)
              .then(customerObject => {                     //Set the details of the customer in the form fields
    
                
                  setSelectedSite(customerObject);
              });
                      getPlantDetails(cookies,response.work_schedule_list.plant.id)  //get plant list
                      .then(plants =>{
                      
                              return {value: plants.id,label : plants.name}
                         
                          })
          })

          .catch(error => {
              setInProgress(false);
            //   displayError(error.response.data, "Loading Failed");
            });
      }
        }, []);


    return (
    <>
     <div id="outer-container"  className="App" > 
        <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
        <ErrorToast/>
        <LoadingOverlay inProgress={inProgress}/>
        <div id="page-wrap">
        <form onSubmit={handleSubmit} data-id={inputs.id}> 
          <div id="header">
              <h3 className = "text font-weight-bold page-title" >PUMP CLEARANCE EDIT FORM</h3>
          </div>
          <FloatingControls tableLink="/PumpClearanceTable" onCancel={Cancel} enableCancel={false}/>
            <div className="container"> 
                <Row> 
                <Col xl={12} lg={12} md={6} >   
                <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3  form-control-panel " >
                    <h5 className='col-sm-11'><b>Workshedule Details</b></h5><br/><br/>
                    <label htmlFor="plant_id" className="form-group col-sm-2 text-right"> Plant Location </label>
                        <select id="plant_id"name="plant_id" className="form-control col-sm-3  browser-default custom-select  mandatory-form-control"  required onChange={handleChange} value={inputs.plant_id || ""}disabled>
                          {plant.map((item) => (
                                <option key={item.value} value={item.value}>
                                    {item.label}</option>
                            ))}
                        </select>
                        <br></br>      <br></br>
                        <label htmlFor="schedule_no" className="form-group col-sm-2 text-right"> Job No </label>
                     

                         <select id="id" name="id" className="form-control col-sm-3  browser-default custom-select  mandatory-form-control"  required onChange={handleChangeWorkscheduleDetails} value={inputs.id || ""} disabled>
                      
                      <option value="">Select Job No</option>
                      {workschedule.map((item) => (
                              <option key={item.value} value={item.value}>
                              {(item.label)}
                              </option>
                          ))}
                    </select>
                        <SummaryIcon onClickHandler={showWorkscheduleDetails}/> 
                        <br/>                    
                        {(selectedWorkschedule.id ? 
                        <table width="100%" style={{border: "0px solid black"}}>
                        <tr style={{border: "0px solid black"}}>
                        <td className="col-sm-2 text-right" style={{border: "0px solid black"}} onChange={handleChange}>Billing Name:</td>
                        <td style={{border: "0px solid black", fontWeight: "normal"}}> {selectedWorkschedule?.sales_order_detail?.sales_order?.consignee?.customer?.name} 
                        <SummaryIcon onClickHandler={showCustomerDetails}/></td>
                        
                        <td className="col-sm-2 text-right" style={{border: "0px solid black"}}  onChange={handleChange}>Project/Site Name:</td>
                        <td style={{border: "0px solid black", fontWeight: "normal"}}> {selectedWorkschedule?.sales_order_detail?.sales_order?.consignee?.name} 
                        <SummaryIcon onClickHandler={showSiteDetails}/></td>
                        </tr>
                        </table>
                         : null)}
                    </div>


                </Col>

            </Row>
            {/* <div id="header">
                <h4 className = "text font-weight-bold page-title"><b>PUMP BOOKING & SITE DETAILS </b></h4>
            </div> */}
            <Row> 
                <Col xl={6} lg={12} md={12}> 
                   <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3 form-control-panel" >
                   <h5 className='col-sm-11'><b>Site Details</b></h5><br/><br/>
                        <label htmlFor="clearance_date" className="form-group col-sm-4 text-right">Clearance Date </label>
                        <input type="date" required name="clearance_date" id="clearance_date" onChange={handleChange} value={inputs.clearance_date || ""}className="form-control col-sm-7 mandatory-form-control"/>
                        
                        <label htmlFor="clearance_time" className="form-group col-sm-4 text-right">Time </label>
                        <input type="time" id="clearance_time"required name="clearance_time" onChange={handleChange} value={inputs.clearance_time || ""}className="form-control col-sm-7 mandatory-form-control"/>
                        
                        <label htmlFor="incharge_name" className="form-group col-sm-4 text-right">Incharge Name</label>
                        <input type="text"id="incharge_name" name="incharge_name" onChange={handleChange} value={inputs.incharge_name || ""}className="form-control col-sm-7"/>
                        
                        <label htmlFor="incharge_mobile_number" className="form-group col-sm-4 text-right">Mobile No.</label>
                        <input type="text" id="incharge_mobile_number"name="incharge_mobile_number" onChange={handleChange} value={inputs.incharge_mobile_number || ""}className="form-control col-sm-7"/>
                        
                        <label htmlFor="consignee_starting_time" className="form-group col-sm-4 text-right">Site Starting Time </label>
                        <input type="time" id="consignee_starting_time"name="consignee_starting_time"onChange={handleChange}  value={inputs.consignee_starting_time || ""} required className="form-control col-sm-7 mandatory-form-control"/>
                        
                        <label htmlFor="consignee_ending_time" className="form-group col-sm-4 text-right">Site Closing Time</label>
                        <input type="time" name="consignee_ending_time" className="form-control col-sm-7" readOnly={true} style={{backgroundColor:"white", cursor: "not-allowed"}}/>
                    </div>  

                    <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3 form-control-panel">
                    <h5 className='col-sm-11'><b>Pump Operator Details</b></h5><br/><br/>
                        <label htmlFor="operator_name" className="form-group col-sm-4 text-right">Operator Name </label>
                        <input type="text" id="operator_name" onChange={handleChange} value={inputs.operator_name || ""} required name="operator_name" className="form-control col-sm-7 mandatory-form-control"/>
                       
                        <label htmlFor="technician_name" className="form-group col-sm-4 text-right">Technician Name </label>
                        <input type="text"id="technician_name" onChange={handleChange} value={inputs.technician_name || ""}  name="technician_name" className="form-control col-sm-7"/>
                       
                        <label htmlFor="supervisor_name" className="form-group col-sm-4 text-right">Supervisor Name </label>
                        <input type="text"id="supervisor_name"onChange={handleChange}  value={inputs.supervisor_name || ""}  name="supervisor_name" className="form-control col-sm-7"/>
                    </div> 
                </Col>
                <Col xl={6} lg={12} md={12}> 
                <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3 form-control-panel" >
                <h5 className='col-sm-11'><b>Pump Number Details</b></h5><br/><br/>
                        <label htmlFor="pump_id" className="form-group col-sm-4 text-right">Pump No </label>
                        <select required id="pump_id" onChange={handleChange} value={inputs.pump_id || ""}name="pump_id" className="form-control col-sm-7 browser-default custom-select mandatory-form-control" >
                            <option value="">Please select Pump</option>
                            {equipment.map((item) => (
                                <option key={item.id} value={item.id}>{item.equip_name}</option>
                            ))}
                        </select>
                        
                        <label htmlFor="gang_id" className="form-group col-sm-4 text-right">Gang Name </label>
                        <select required id="gang_id" onChange={handleChange}value={inputs.gang_id || ""} name="gang_id" className="form-control col-sm-7 browser-default custom-select mandatory-form-control" >
                            <option value="">Please select Gang</option>
                            {gang.map((item) => (
                                <option key={item.id} value={item.id}>{item.entity_name}</option>
                            ))}
                        </select>
                        
                        <label htmlFor="pipe_length"className="form-group col-sm-4 text-right">Total Pipes/Bends </label>
                        <input required type="number" min="0"value={inputs.no_of_pipes || 0} onChange={handleChange} className="form-control col-sm-4  mandatory-form-control" id="no_of_pipes"name="no_of_pipes"/>
                        <input  required type="number" min="0"value={inputs.no_of_bends || 0} onChange={handleChange} className="form-control col-sm-3  mandatory-form-control" id="no_of_bends" name="no_of_bends"/>
                       
                  
                        <label htmlFor="pump_starting_hr" className="form-group col-sm-4 text-right">Pump Starting Hour </label>
                        <input required type="number"id="pump_starting_hr"onChange={handleChange} value={inputs.pump_starting_hr || ""} name="pump_starting_hr" className="form-control col-sm-7 mandatory-form-control"/>
                        
                        <label htmlFor="pump_ending_hr" className="form-group col-sm-4 text-right">Pump Ending Hour</label>
                        <input  type="number"id="pump_ending_hr" name="pump_ending_hr" className="form-control col-sm-7" readOnly={true} style={{backgroundColor:"white", cursor:"not-allowed"}}/>
                        
                        <label htmlFor="towing_vehicle_id" className="form-group col-sm-4 text-right">Towing Vehicle No </label>
                        <select  id="towing_vehicle_id" name="towing_vehicle_id"onChange={handleChange}  value={inputs.towing_vehicle_id || ""}className="form-control col-sm-7 browser-default custom-select mandatory-form-control" required >
                            <option value="">Please select Towing Vehicle</option>
                            {towingvehicle.map((item) => (
                                <option key={item.id} value={item.id}>{item.equip_name}</option>
                            ))}
                        </select>
                        
                        <label htmlFor="towing_vehicle_starting_km" className="form-group col-sm-4 text-right">Towing Starting Km </label>
                        <input required id="towing_vehicle_starting_km"type="number" name="towing_vehicle_starting_km" value={inputs.towing_vehicle_starting_km || ""}onChange={handleChange}  className="form-control col-sm-7 mandatory-form-control"/>
                        
                        <label htmlFor="towing_vehicle_ending_km" className="form-group col-sm-4 text-right">Towing Closing Km</label>
                        <input  type="number" id="towing_vehicle_ending_km" name="towing_vehicle_ending_km" className="form-control col-sm-7" readOnly={true} style={{backgroundColor:"white", cursor:"not-allowed"}}/>
                </div>

                <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3 form-control-panel" >
                <h5 className='col-sm-11'><b>Customer Feedback Details</b></h5><br/><br/>
                    <label htmlFor="site_feedback" className="form-group col-sm-4 text-right">Customer Feed Back</label>
                    <input type="text" id="site_feedback"name="site_feedback" className="form-control col-sm-7" readOnly ={true} style={{backgroundColor: "white", cursor: "not-allowed"}}/>
                </div>
                </Col>
            </Row>                                
            </div>
                <div className="footer text-center">
                <Button  type="submit"  style={{width:"80px",fontWeight:"bold",backgroundColor:"RGB(58,29,238,0.8)"}} className="btn btn-twitter">Update</Button> &nbsp;&nbsp;               
                    <Button   type="button"style={{width:"80px",fontWeight:"bold",backgroundColor:"RGB(58,29,238,0.8)"}} className="btn btn-twitter"onClick={view}>View</Button>&nbsp;&nbsp;
                    <Button  type="button"style={{width:"80px",fontWeight:"bold",backgroundColor:"RGB(58,29,238,0.8)"}} className="btn btn-twitter"onClick={Back}>Home</Button>&nbsp;&nbsp;
                    <Button className="btn btn-delete"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={onDeletePumpClearance}>Delete</Button>
                </div>
        </form>
        </div>
    </div>
         </>
    );
  }
  export default PumpClearanceEdit;