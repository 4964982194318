import React from 'react';
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { Row, Col, Button } from "reactstrap";
import axios from "axios";
import Sidebar from '../../Sidebar';
import { useNavigate } from 'react-router';
import { BiSave } from "react-icons/bi";
import { IoIosEye } from "react-icons/io";
import { TiCancel } from "react-icons/ti";
import { AiOutlineHome } from "react-icons/ai";
import { useCookies } from 'react-cookie';
import { default as ReactSelect } from "react-select";
import TableRowsPurchaseInvoice from "./TableRowsPurchaseInvoice";
import { components } from "react-select";
import { moment } from 'moment';
import { useLocation, Link } from 'react-router-dom';
import {displayErrorToast} from '../../helpers';
import ErrorToast from '../../ErrorToast';
import FloatingControls from '../../components/FloatingControls';
import LoadingOverlay from '../../components/LoadingOverlay';

function PurchaseInvoice() {
    const [plant, setPlant] = React.useState([]);
    const [concrete, setConcrete] = React.useState([]);
    const [taxes, setTaxes] = React.useState([]);
    const [inProgress, setInProgress] = useState(false);
    const [products, setProducts] = React.useState([]);
    const [category] = useCookies(['myCategory']);
    const [superuser] = useCookies(['mySuperuser']);
    const [cookies] = useCookies(['myToken']);
    const [Pi, setDc] = useState({});
    const [inputs, setInputs] = useState({});
    const [Appr_so_dtl, setAppr_so_dtl] = React.useState([]);
    const [isChecked, setIsChecked] = useState({
        chkbx: false
    })

    const navigate = useNavigate();
    const handleChange4 = (index, evnt) => {
        const { name, value } = evnt.target;
        const rowsInput = [...rowsData];
        rowsInput[index][name] = value;



        setRowsData(rowsInput);
    }


const [rowsData, setRowsData] = useState([]);
const handleSubmit = (event) => {             //If regex is statisfied condition is valid it accept ,otherwise it is Invalid
    event.preventDefault();
        var err = 0;
     try{   
       if (err == 0) {
            Swal.fire({
                title: 'Do you want to save?',
                showCancelButton: true,
                confirmButtonText: `Yes`,
                cancelButtonText: `No`,
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post('http://127.0.0.1:8000/PurchaseInvoice/', {
                        rowsData: rowsData,
                        dcCompCode: inputs.dcCompCode,
                        wsSlNo: inputs.wsSlNo
                    },
                        {
                            headers: {
                                'Authorization': `Token ${cookies['myToken']}`
                            }
                        })
                        .then(function (response) {
                            console.log(JSON.stringify(response) + "response");
                        }).catch(function (error) {
                            console.log(JSON.stringify(error) + "error");
                        })
                    Swal.fire('Saved!', '', 'success')
                    Cancel();
                }
                else if (result.isDismissed) {
                    Swal.fire('Not saved', '', 'info')
                }
            });
        }
        else {
            Swal.fire('Please check the value')
        }
    }
    catch(e)
    {
      displayErrorToast(e);
    }   
}



const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
     try{ 
          if (name === 'dcCompCode') {
            fetch(`http://127.0.0.1:8000/PurchaseInvoice_drp/${value}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Token ${cookies['myToken']}`,
                    'Content-Type': 'application/json'
                }
            })
                .then(response => response.json())
                .then((data) => {
                    console.log(JSON.stringify(data) + "ata_PurchaseOrder_drop");
                    setAppr_so_dtl(data.wrkshedule)
                })
        }
        setInputs(values => ({ ...values, [name]: event.target.value }))
     } 
     catch(e)
     {
       displayErrorToast(e);
     }
    }

    const Cancel = () => {
        setInputs(() => "")
        setRowsData([])
    }
    const [selectedFile, setSelectedFile] = useState({})
    const handleChange3 = (event) => {
        const name = event.target.name;
        const value=event.target.files[0];
        setSelectedFile(values => ({...values, [name]: value}))
    }

    const addTableRows = () => {
        const count=rowsData.length +1;
        // console.log(count+"count_add")
        const rowsInput={
                soDSqlNo:count,
                soDTaxCode:'',
                soDConStruc:'',
                soDDeliveryMode:'',
                soDAmt:'',
                soDRate:'',
                soDOrdQty:'',
                soDUnit:'',
                soDProdCode:'',
                soDRemarks:''
            }
            setRowsData([...rowsData, rowsInput])
    }
    const deleteTableRows = (index)=>{
        const rows = [...rowsData];
        rows.splice(index, 1);
        for(var i=0; i<rows.length; i++) 
        {
            rows[i]['soDSqlNo']= i+1
        }
        setRowsData(rows);
}

const vendordetails = () => {
    const plant = Pi.dcCompCode
   try{
        if (plant) {
        navigate(`/wrkshedule/${plant}`)
        }
        else {
        Swal.fire({html:`<div style='border:1px solid black'><table align='center' width='100%'><tr><td colspan="2"style="background-color: blue"><h6 style="color:white;">VENDOR DETAILS</h6></td></tr><tr><td align='right' style='font-size:14px' width='50%'>ADDRESS :</td><td align='left' style='font-size:14px'>KK NAGAR</td></tr><tr><td align='right' style='font-size:14px' width='50%'>GST NO :</td><td align='left' style='font-size:14px'>AAA</td></tr></table></div>`}, '', 'info'); 
    
        }
    }
    catch(e)
    {
      displayErrorToast(e);
    }
   }

  const purchaseorderdetails = () => {
    const plant = Pi.dcCompCode
  try{  
        if (plant) {
        navigate(`/wrkshedule/${plant}`)
        }
        else {
        Swal.fire({html:`<div style='border:1px solid black'><table align='center' width='100%'><tr><td colspan="2"style="background-color: blue"><h6 style="color:white;">PURCHASE ORDER DETAILS</h6></td></tr><tr><td align='right' style='font-size:14px' width='50%'>PAYMENT TERMS :</td><td align='left' style='font-size:14px'>KK NAGAR</td></tr><tr><td align='right' style='font-size:14px' width='50%'>MODE OF DELIVERY :</td><td align='left' style='font-size:14px'>AAA</td></tr> 
        <tr><td align='right' style='font-size:14px' width='50%'>PURCHASE ORDER DATE :</td><td align='left' style='font-size:14px'>12/02/22</td></tr></table></div>`}, '', 'info'); 
    
        }
    }
    catch(e)
    {
      displayErrorToast(e);
    }
}
    const view = () => {
        navigate('/PurchaseInvoiceTable')
    }

    const Back = () => {
        navigate('/Home')
    }


return (
        <>
            <div id="outer-container" className="App" >
                <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
                <LoadingOverlay inProgress={inProgress}/>
                <div id="page-wrap">
                    <form onSubmit={handleSubmit} >
                        <div id="header">
                            <h3 className = "text font-weight-bold page-title">PURCHASE INVOICE </h3>
                        </div>
                        <FloatingControls tableLink="/PurchaseInvoiceTable" onCancel={Cancel} enableCancel={true}/>
                    <div className="container p-2 ">
                  
                </div>
                    <div className="container">
                        <Row>
                            <Col xl={6} lg={12} md={12}>
                                <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3 form-control-panel   ">

                                    <label htmlFor="soHCompCode" className="form-group col-sm-3 text-right">Plant Location</label>
                                    <select id="soHCompCode" name="soHCompCode" className="form-control col-sm-8  browser-default custom-select" required onChange={handleChange} value={inputs.soHCompCode || ""}>
                                        <option value="">Select Plant</option>
                                        {plant.map((item) => (
                                            <option key={item.id} value={item.id}>{item.MCAlias}</option>
                                        ))}
                                    </select><br />

                                    <label htmlFor="soHCusBCode" className="form-group col-sm-3 text-right">Vendor Name</label>
                                    <select id="soHCusBCode" name="soHCusBCode" className="form-control col-sm-8  browser-default custom-select" required onClick={vendordetails} value={inputs.soHCusBCode || ""}>
                                        <option value="">Select Vendor  Name</option>
                                        {plant.map((item) => (
                                            <option key={item.id} value={item.id}>{item.venName}</option>
                                        ))}
                                    </select><br />

                                    <label htmlFor="puHStatus" className="form-group col-sm-3 text-right">Period From</label>
                                    <input type="text" className="form-control col-sm-8" id="puHStatus" value={inputs.puHStatus || ""} style={{ backgroundColor: "white" , cursor: "not-allowed"}} name="puHStatus" /><br />
                                

                                    <label htmlFor="soHSlDt" className="form-group col-sm-3 text-right">Period To</label>
                                    <input required type="date" min="0" onChange={handleChange} className="form-control col-sm-4" />
                                    <input required type="time" min="0" value={inputs.puHSlTime || 0} onChange={handleChange} className="form-control col-sm-4" />



                                </div>

                                </Col>

                            <Col xl={6} lg={12} md={12}>

                                <div className="form-row table-bordered shadow p-2 my-2  border-secondary p-2 mb-3 form-control-panel " >

                                    


                                    
                                    

                                <label htmlFor="soHSlDt" className="form-group col-sm-4 text-right">Invoice No</label>
                                    <input required type="text" min="0" onChange={handleChange} className="form-control col-sm-3" />
                                    <input required type="date" min="0" value={inputs.puHSlTime || 0} onChange={handleChange} className="form-control col-sm-4" />

                                    <label htmlFor="puHPrefix" className="form-group col-sm-4 text-right">Purchase Order No</label>
                                    <input type="files" className="form-control col-sm-7" id="puHPrefix" onClick={purchaseorderdetails}style={{ backgroundColor: "white" }} /><br />



                                    <label htmlFor="puHPrefix" className="form-group col-sm-4 text-right">Uploading Files</label>
                                    <input type="file" className="col-sm-7" onChange={handleChange3} name="soHPoFile"/>           

                                    <label htmlFor="puHPrefix" className="form-group col-sm-4 text-right">Remarks</label>
                                    <input type="files" className="form-control col-sm-7" id="puHPrefix" style={{ backgroundColor: "white" }} /><br />


                                </div>
                                

                            </Col>


                        </Row>


                <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue container table-responsive">
                <table className="table order-list" id="myTable" style={{textAlign:"center",borderRadius:"10px",backgroundColor:"RGB(188,232,253)"}}>
                    <thead>
                        <tr>
                          
                            <th colSpan="1">S.No.</th>
                            <th colSpan="1">Product Code</th>
                            <th colSpan="1">Materials</th>
                            <th colSpan="1">Unit</th>
                            <th colSpan="1">DC/Invoice Qty.</th>
                            <th colSpan="1">(Received) Qty</th>
                            <th colSpan="1">Basic Rate</th>
                            <th colSpan="1">Taxable Amount</th>
                            <th colSpan="1">GST%</th>
                            <th colSpan="1">GST Amount</th>
                            <th colSpan="2">Gross Amount</th>
    
                        </tr>
                       
<tr >

    <td colspan="10"style={{textAlign:"right"}}><b>Total Taxable Amount </b></td>
    <td colspan="2"><input type="number" ></input></td>
</tr>

<tr >

    <td colspan="10"  style={{textAlign:"right",width:"100px"}}><b>Discount Percentage/Amount</b> </td>
    <td colspan="1" ><input type="number" style={{width:"65px"}}></input></td>
    <td colspan="1" ><input type="number" style={{width:"65px"}}></input></td>
</tr>
<tr >

    <td colspan="10"style={{textAlign:"right"}}></td>
    <td colspan="2"><input type="number" style={{ cursor: "not-allowed" }}></input></td>
</tr>
<tr >

    <td colspan="10"  style={{textAlign:"right",width:"100px"}}><b>TCS Percentage/Amount</b> </td>
    <td colspan="1" ><input type="number" style={{width:"65px"}}></input></td>
    <td colspan="1" ><input type="number" style={{width:"65px"}}></input></td>
</tr>
<tr >

    <td colspan="10"style={{textAlign:"right"}}><b>Frieght Charges </b></td>
    <td colspan="2"><input type="number" style={{ backgroundColor: "white",cursor: "not-allowed" }}></input></td>
</tr>
<tr >

    <td colspan="10"style={{textAlign:"right" }}><b>CGST @ % </b></td>
    <td colspan="2"><input type="text" style={{backgroundColor: "white", cursor: "not-allowed" }}></input></td>
</tr>
<tr >

<td colspan="10"style={{textAlign:"right" }}><b>SGST @ % </b></td>
<td colspan="2"><input type="text" style={{ backgroundColor: "white",cursor: "not-allowed" }}></input></td>
</tr>
<tr >

<td colspan="10"style={{textAlign:"right" }}><b>IGST @ %</b></td>
<td colspan="2"><input type="text" style={{backgroundColor: "white", cursor: "not-allowed" }}></input></td>
</tr>
<tr >

<td colspan="10"style={{textAlign:"right" }}><b>Total</b></td>
<td colspan="2"><input type="text" style={{ backgroundColor: "white",cursor: "not-allowed" }}></input></td>
</tr>
<tr >

<td colspan="10"style={{textAlign:"right", cursor: "not-allowed" }}><b>Rounded Off</b></td>
<td colspan="2"><input type="text" style={{ backgroundColor: "white",cursor: "not-allowed" }}></input ></td>
</tr>
<tr >

<td colspan="10"style={{textAlign:"right"}}><b>Total Invoice value</b></td>
<td colspan="2"><input style={{ backgroundColor: "white",cursor: "not-allowed" }} ></input></td>
</tr>

                    </thead>

      <tbody>
                        <TableRowsPurchaseInvoice rowsData={rowsData} deleteTableRows={deleteTableRows} handleChange4={handleChange4} products={products} taxes={taxes} concrete={concrete}/>
                    </tbody>
                </table>
            </div>  
            <div id="header">
                            <h3 className = "text font-weight-bold page-title" >GOODS RECEIPT NOTE</h3>
                        </div>
            
                        <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue container table-responsive">
                <table className="table order-list" id="myTable" style={{textAlign:"center",borderRadius:"10px",backgroundColor:"RGB(188,232,253)"}}>
                    <thead>
                        <tr>
                          
                            <th colSpan="1">Action	</th>
                            <th colSpan="1">Sl. No</th>
                            <th colSpan="1">GRN No.</th>
                            <th colSpan="1">GRN Date</th>
                            <th colSpan="1">Product Code</th>
                            <th colSpan="1">Materials</th>
                            <th colSpan="1">Unit</th>
                            <th colSpan="1">DC Qty.</th>
                      
                            <th colSpan="1">Received Qty.</th>
                            <th colSpan="1">Rate</th>
                            <th colSpan="1">Amount</th>
                            <th colSpan="1">Vehicle No.</th>
                        </tr>
                    </thead>
               
                </table>
            </div>  
                        </div>
                        <div className="footer text-center">
                        <Button  type="submit" className="btn btn-twitter" style={{width:"80px",fontWeight:"bold"}} >Save</Button> &nbsp;&nbsp;
                                <Button type="reset" className="btn btn-twitter" style={{width:"80px",fontWeight:"bold"}} onClick={Cancel}>Reset</Button> &nbsp;&nbsp;
                                <Button className="btn btn-twitter"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={view}>View</Button>&nbsp;&nbsp;
                                <Button className="btn btn-twitter"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={Back}>Home</Button>
                        </div>

                    </form>

                </div>


            </div>

        </>

    );
}
export default PurchaseInvoice;
