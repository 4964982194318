
import { getApiBaseUrl, getApiClient } from "./serviceconfig";
// var apiBaseUrl = "http://localhost:8001";
var apiBaseUrl = getApiBaseUrl();

export async function getAllDesignMix(cookies,searchString) {

  let url= `${apiBaseUrl}/design_mix/`;
  if (searchString) {
    url = url + `?name=${searchString}`;
  }
    return getApiClient(cookies).get(url)
      .then(response => response.data);
}

export async function getDesignMixForWorkSchedule(cookies,searchString) {

  let url= `${apiBaseUrl}/design_mix/`;
  if (searchString) {
    url = url + `?work_schedule_id=${searchString}`;
  }
    return getApiClient(cookies).get(url)
      .then(response => response.data);
}

export async function getDesignMixForPlantAndProduct(cookies,plantId,productId) {

    return getApiClient(cookies).get(`${apiBaseUrl}/design_mix/?plant_id=${plantId}&product_id=${productId}`)
      .then(response => response.data);
}

export async function createDesignMix(cookies, inputs) {

  return getApiClient(cookies).post(`${apiBaseUrl}/design_mix/`,inputs)
}

export async function updateDesignMix(cookies, inputs, designmixId) {

    return getApiClient(cookies)
    .put(`${apiBaseUrl}/design_mix/${designmixId}/`,inputs)
}
  


export async function getDesignMixDetails(cookies, designmixId) {

  return getApiClient(cookies).get(`${apiBaseUrl}/design_mix/${designmixId}/`)
  .then(response => response.data)
}

export async function deleteDesignMix(cookies, designmixId) {

  return getApiClient(cookies).delete(
    `${apiBaseUrl}/design_mix/${designmixId}/`)
}