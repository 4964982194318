
import { getApiBaseUrl,getApiClient } from "./serviceconfig";

var apiBaseUrl = getApiBaseUrl();
export async function getAllDeliveryChallans(cookies,searchString) {
     let url = `${apiBaseUrl}/delivery_challans/`
     if(searchString) {
      url = url + `?plant_id=${searchString}`;
    }
    return getApiClient(cookies).get(url)
    .then(response => response.data);
}

export async function getAllDeliveryChallanForCheckOut(cookies,plantId) {

  return getApiClient(cookies)
  // .get(`${apiBaseUrl}/delivery_challans/check_out/?plant_id=${plantId}&movement_type=${movement_type}`)
  .get(`${apiBaseUrl}/delivery_challans/check_out/?plant_id=${plantId}`)
  .then(response => response.data);
}

export function createDeliveryChallan(cookies, inputs) {

  return getApiClient(cookies).post(`${apiBaseUrl}/delivery_challans/`, inputs)
  
}

export async function getCustomerVehicles(cookies) {

  let url= `${apiBaseUrl}/delivery_challans/customer_vehicles/`;
     return getApiClient(cookies).get(url)
   .then(response => response.data);
 }

export async function getDeliveryChallanNumber(cookies, plantId) {

 let url= `${apiBaseUrl}/delivery_challans/delivery_challan_number/`;
  if (plantId) {
    url = url +  `?plant_id=${plantId}`;
  }
    return getApiClient(cookies).get(url)
  .then(response => response.data);
}
export async function getAllDeliveryChallanForCheckIn(cookies, plantId) {

  let url= `${apiBaseUrl}/delivery_challans/delivery_challan_number/`;
   if (plantId) {
     url = url +  `?plant_id=${plantId}`;
   }
     return getApiClient(cookies).get(url)
   .then(response => response.data);
 }

 

export async function getCumulativeSerialNumber(cookies, workscheduleId,deliveryDate) {

  return getApiClient(cookies)
  .get(`${apiBaseUrl}/delivery_challans/cumulative_serial_number/?work_schedule_id=${workscheduleId}&delivery_challan_date=${deliveryDate}`)
  .then(response => response.data);
}

export function updateDeliveryChallan(cookies, inputs, deliveryId) {

  return getApiClient(cookies).put(`${apiBaseUrl}/delivery_challans/${deliveryId}/`, inputs)
    
    
}

export async function getDeliveryChallanDetails(cookies, deliveryId) {

  return getApiClient(cookies).get(`${apiBaseUrl}/delivery_challans/${deliveryId}/`)
              .then(response => response.data)
}

export async function getDeliveryChallanEmail(cookies, EmailId) {

  return getApiClient(cookies).get(`${apiBaseUrl}/delivery_challans/${EmailId}/email/`)
              .then(response => response.data)
}
export function deleteDeliveryChallan(cookies, deliveryId) {

  return getApiClient(cookies).delete(`${apiBaseUrl}/delivery_challans/${deliveryId}/`)
}

export function cancelDeliveryChallan(cookies, cancelId) {

  return getApiClient(cookies).patch(`${apiBaseUrl}/delivery_challans/${cancelId}/`)
}

export async function printBatchReport(cookies, deliveryId) {

  return getApiClient(cookies).get(`${apiBaseUrl}/delivery_challans/${deliveryId}/batch_report/`)
              .then(response => response.data)
}

export async function printDeliveryChallan(cookies, deliveryId) {

  return getApiClient(cookies).get(`${apiBaseUrl}/delivery_challans/${deliveryId}/print_dc/`)
              .then(response => response.data)
}

export async function getDeliveryChallansForInvoice(cookies, plantId, salesOrderDtlIds,invoice_date,invoice_id) {
  let url= `${apiBaseUrl}/delivery_challans/invoice/`;
  if (invoice_id) {
    url = url + `?plant_id=${plantId}&sales_order_detail_ids=${salesOrderDtlIds}&invoice_date=${invoice_date}&invoice_id=${invoice_id}`;
  }
  else  {
    url =url + `?plant_id=${plantId}&sales_order_detail_ids=${salesOrderDtlIds}&invoice_date=${invoice_date}`;
  }
  return getApiClient(cookies).get(url)
              .then(response => response.data)
}


