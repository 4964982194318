import { useEffect, useState, useMemo } from "react";
import ReactTableWrapper from "../../components/ReactTableWrapper";
import { exportAsCSV } from "../../utils/CSVExporter";
import { exportAsPDF } from "../../utils/PDFExporter";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import Sidebar from '../../Sidebar';
import Swal from "sweetalert2";
import { useCookies } from 'react-cookie';
import { Link } from "react-router-dom";
import {Row,Col,Button,} from "reactstrap";
import React from 'react';
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import { getAllPlants} from '../../services/PlantServices';
import { getAllProducts } from "../../services/ProductServices";
import { getAllVendors } from "../../services/VendorServices";
import { getGoodsReceiptNote} from "../../services/ReportServices";
import { getAllEquipments } from '../../services/EquipmentServices';
import {  DatePicker } from 'antd';
import moment  from 'moment';
const {RangePicker} = DatePicker;
function GoodsReceiptNoteTable() {
    const InitialData = {
        total_pages: 0,
        records: [],
      };
    const [data, setData] = useState(InitialData);
    const navigate = useNavigate(); 
    const [plants, setPlants] = React.useState([]);
    const [selectedPlant, setSelectedPlant] = useState(null);
    const [refreshKey, setRefreshKey] = useState(0);
    const [cookies] = useCookies(['myToken']);
    const [isLoading, setIsLoading] = useState(true); 
   
    const [vendors, setVendors] = React.useState([]);
    const [selectedVendor, setSelectedVendor] = useState(null);
    const [vehicles, setVehicles] = React.useState([]);
    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [selectedGrade, setSelectedGrade] = useState(null);
    const [grades, setGrades] = useState([]);
    const currentMonthStartDate = moment().startOf('month');
    const currentDate = moment();
    const [dates, setDates] = useState([currentMonthStartDate, currentDate]);


    const Option = (props) => {
        return (
            <>
                <components.Option {...props}>
                    <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}/>{" "}
                <label>{props.label}</label>
                </components.Option>
            </>
        );
    };

    React.useEffect(() => {
        
        //Load data for Plant dropdown
        getAllPlants(cookies)
        .then (
            plantList => {
                
                const plants = plantList.plant_list.filter(obj => obj.status).map(
                    plant => {
                        return { value: plant.id, label: plant.entity_name }
                    }
                );
                setPlants(plants);
            }
        )
    }, []);
    
  React.useEffect(() => {
    getAllVendors(cookies)
    .then (
        vendorList => {
            
            const vendors = vendorList.vendor_list.map(
              vendor => {
                    return { value: vendor.id, label: vendor.entity_name }
                }
            );
            setVendors(vendors);
        }
    )
}, []);
React.useEffect(() => {
    getAllEquipments(cookies)
      .then (
      
         equipmentObject => {
          
              const equipments = equipmentObject.equipment_list.filter(obj => (obj.status) ).map(
                  equipment => {
                      return { value: equipment.id, label: equipment.equip_name}
                  }
              );
              setVehicles(equipments);
          }
      )
  }, []);
  React.useEffect(() => {
    getAllProducts(cookies)
    .then (
        productList => {
            
            const products = productList.product_list.filter(obj => obj.status).map(
                product => {
                    return { value: product.id, label: product.name}
                }
            )
           
            setGrades(products);
        }
    )
}, []);

    const onPlantSelect = (selected) => {
        setSelectedPlant(selected);
    };
    const onVendorSelect = (selected) => {
        setSelectedVendor(selected);
    };
    const onVehicleSelect = (selected) => {
        setSelectedVehicle(selected);
    };
    const onGradeSelect = (selected) => {
        setSelectedGrade(selected);
    };

    const Back = () => {
        navigate('/Home')
      }

    const columns = useMemo(
        () => [
            {
                fieldName: 'entity_name', 
                headerName: 'Date'
            },
           
            {
                fieldName: "entity_name",
                headerName: 'Goods Receipt No'
            },
            {
                fieldName: "entity_type",
                headerName: 'Date'
            },
            {
                fieldName: "entity_type",
                headerName: 'DC Number'
            },
            {
                fieldName: "entity_type",
                headerName: 'Batch Number'
            },
          
          
            {
                fieldName: "entity_type",
                headerName: 'Grade'
            },
            {
                fieldName: "entity_type",
                headerName: 'Time'
            },
       
       
           
          
        ],
        [],);

   
    const handleCSVExport = (event) => {
        console.log('CSV Export');
        exportAsCSV(
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(c => c.headerName),
            data.records.map(r => {

                const csvRow = {
                    entity_name: r.entity_name,
                    address: r.address,
                    phone_number:r.phone_number,
                    mobile_number:r.mobile_number,
                    status:r.status
                }
                return csvRow;
                }));
    };

    const handlePDFExport = (event) => {
        console.log('PDF Export');
        exportAsPDF(
            'List of Goods Receipt Note',
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(col=>({header: col.headerName, dataKey: col.fieldName })),
            data.records.map(r => ({
                entity_name: r.entity_name,
                address: r.address, 
                phone_number:r.phone_number,
                mobile_number:r.mobile_number,
                status:r.status
            })),
            'GoodsReceipt_note.pdf'
        );
    };

    useEffect(() => {

        getGoodsReceiptNote(cookies)
        .then( dispatchreportList => {
            const tableData = dispatchreportList.query_set
            .map(r => ({
                id :r.id,
                entity_name: r.entity_name,
                parent_id: r.parent_id,
                entity_type:r.entity_type,
            }));

            setData({
                total: data.total,
                records: tableData
            });
            setIsLoading(false);
        });
        }, [refreshKey]);
    
    return (

        <div id="outer-container"  className="App" > 
            
            <div id="page-wrap">
            <h3 style={{color:"rgb(2, 2, 49)",textAlign:"center"}}>Goods Receipt Note </h3>
            <br/>
            <form className="form" onSubmit={""}>
        <div className="container">
        <Row>
          <Col xl={1} lg={12} md={12}>
            </Col>
          <Col xl={10} lg={11} md={11}>
          <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3 form-control-panel ">
                      
                    
          <label htmlFor="Checkbox"className="form-group col-sm-0 text-right" > </label>
                                  {/* <div style={{width: '58%'}} > */}
                                  <ReactSelect
                                    options={plants}
                                    isMulti= {true}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{Option}}
                                    isClearable={true}
                                    // defaultValue={selectedOption}
                                    value={selectedPlant}
                                    onChange={onPlantSelect}
                                    className="browser-default custom-select col-sm-3"
                                    placeholder="Please select Plant name"
                                  />
                                  {/* </div> */}
                                <br/><br/>&nbsp;&nbsp;&nbsp;
                             
                                <label htmlFor="Checkbox"className="form-group col-sm-0 text-right" > </label>
                                  {/* <div style={{width: '58%'}} > */}
                                  <ReactSelect
                                    options={vendors}
                                    isMulti= {true}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{Option}}
                                    isClearable={true}
                                    // defaultValue={selectedOption}
                                    value={selectedVendor}
                                    onChange={onVendorSelect}
                                    className="browser-default custom-select col-sm-4"
                                    placeholder="Please select Vendor"
                                  />
                                  {/* </div> */}
                                <br/><br/>&nbsp;&nbsp;&nbsp;
                             
                              
                                <label htmlFor="Checkbox"className="form-group col-sm-0 text-right" > </label>
                                  {/* <div style={{width: '58%'}} > */}
                                  <ReactSelect
                                    options={vehicles}
                                    isMulti= {true}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{Option}}
                                    isClearable={true}
                                    // defaultValue={selectedOption}
                                    value={selectedVehicle}
                                    onChange={onVehicleSelect}
                                    className="browser-default custom-select col-sm-4"
                                    placeholder="Please select Vehicle No"
                                  />
                                     
            
                                     
                          
                                  <label htmlFor="grnHVehNo" className="form-group col-sm-0 text-right"></label>
                                  <select  id="grnHVehNo" className="browser-default custom-select col-sm-3"  required name="grnHVehNo"  >
                                      <option value=''>Please Select Reports</option>
                                      <option value='datewise'>Datewise</option>
                                      <option value='product'>Product</option>
                                      <option value='Supplier'>Supplier</option>
                                      <option value='Vehicle'>Vehicle</option>
                                      </select>
                                      <br></br>
                                      <br></br>   
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                                      <ReactSelect
                                    options={grades}
                                    isMulti= {true}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{Option}}
                                    isClearable={true}
                                    // defaultValue={selectedOption}
                                    value={selectedGrade}
                                    onChange={onGradeSelect}
                                    className="browser-default custom-select col-sm-3"
                                    placeholder="Please Select Grade"
                                  />
                                 
                                <br/><br/>&nbsp;&nbsp;
                             
                        <label htmlFor="grndate" className="form-group col-sm-0 text-left"></label>
                        <RangePicker
                            defaultValue={dates}
                                    format="DD-MM-YYYY"
                                    onChange={(values) => {
                                    if (values.length === 2) {
                                        setDates(values);
                                    }
                                    }}
                                />
                                  
                <div>    
                <Link  to={'/Search'} >
                             <i className=" form-group col-sm-3  fa fa-search fa-2x"aria-hidden="true"></i>
                             </Link>
                             </div> 
            
                             <div>    
                <Link  to={'/Home'} >
                             <i className=" form-group col-sm-3  fa fa-home fa-2x"aria-hidden="true"></i>
                             </Link>
                             </div> 
            </div>
          </Col>
   
          </Row> 
        </div>
    
        </form>
                <div className="container item-list-table-container">
                <div className="table-responsive">
                <ReactTableWrapper
                            title='Goods Receipt Note'
                            columns={columns}
                            data={data.records}
                            onCSVExport={handleCSVExport}
                            onPDFExport={handlePDFExport}
                            isLoading={isLoading}
                        />
                </div>
                </div> 

            </div>
                </div>

        
    );

}

export default GoodsReceiptNoteTable;