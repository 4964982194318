import React from 'react';
import { useState, useEffect,useMemo } from "react";
import Swal from "sweetalert2";
import { Row, Col, Button } from "reactstrap";
import Sidebar from '../../Sidebar';
import { useNavigate } from 'react-router';
import { useCookies } from 'react-cookie';
import TableRowsGRN from './TableRowsGRN';
// import {displayErrorToast} from '../../helpers';
import ErrorToast from '../../ErrorToast';
import { getAllCompanies } from '../../services/CompanyServices';
import { getAllTax } from "../../services/TaxServices";
import { getAllProducts } from '../../services/ProductServices';
import { getUserAllowedPlants, getPlantDetails} from '../../services/PlantServices'
import FloatingControls from '../../components/FloatingControls';
import { getDisplayDate } from '../../utils/DateUtils';
import {displayErrorToast,parseBoolean,displayError} from '../../helpers';
import SummaryIcon from '../../components/SummaryIcon';
import ReactTablePopUpSubmitWrapper from '../../components/ReactTablePopUpSubmitWrapper';
import QuestionIcon from '../../components/QuestionIcon';
import { getHTMLForSummaryPopup } from '../../utils/PopupUtils';
import { getAllVendors,getVendorDetails } from '../../services/VendorServices';
import {getGoodsReceiptnumber,createGoodsReceiptNote } from '../../services/GoodsReceiptNoteServices';
import { getpurchaseorderforgrn } from '../../services/PurchaseOrderServices';
import EditSharpIcon from '@mui/icons-material/EditSharp'
import { getAllEquipments } from '../../services/EquipmentServices';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import "../../button.css";
import { getCurrentTime } from '../../utils/DateUtils';

function GoodsReceiptNote(){
    const [cookies] = useCookies(['myToken']);
    const [category] = useCookies(['myCategory']);
    const [superuser] = useCookies(['mySuperuser']);
    const [inputs, setInputs] = useState({
        vendor_id:'',
        grn_no:'',
        grn_date:'',
        prefix:'',
        order_no:'',
        order_date:'',
        transporter_name:'',
        vehicle_id:'',
        vehicle_others:'',
        transporter_mode:'',
        driver_name:'',
        driver_mobile_no:'',
        in_time:getCurrentTime(),
        out_time:getCurrentTime(),
        weighment_name:'',
        weighment_slip_no:'',
        weighment_slip_date:'',
        weighment_slip_time:getCurrentTime(),
        measurement_taken_by:'',
        authorised_by:'',
        received_by:'',
        lr_no:'',
        lr_dt:'',
        terms_and_condition:'',
        pay_terms:'0',
        is_tax_included:true,
        status:true,
        remarks:'',
    });
    const [company, setcompany] = React.useState([]);
    const [plant, setplant] = React.useState([]);
    const [vendor, setvendor] = React.useState([])
    const [taxes, setTaxes] = React.useState([]);
    const [maxDate, setMaxDate] = React.useState("");
    const [products, setProducts] = React.useState([]);
    const [equipments, setEquipment] = React.useState([]);
    const [selectedVendor,setSelectedVendor] = useState({});
    const [selectedOption, setSelectedOption] = useState(null);
    const [allPurchaseOrders,setAllPurchaseOrders] = useState([]);
    const initial_table_data = {
        total_pages: 0,
        records: [],
      };
    const [data, setData] = useState(initial_table_data);
    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedPurchaseOrder,setSelectedPurchaseOrder] = useState({});
    const [rowsData, setRowsData] = useState([]);
    const [selectedVehicle, setSelectedVehicle] = useState(null);

    React.useEffect(() => {
        getAllCompanies(cookies)
        .then (
            companyList => {
                const company = companyList.company_data.filter(obj => obj.status).map(
                    company => {
                        return { value: company.id, label: company.entity_name }
                    }
                );
                setcompany(company);
            }
        )
    }, []);
    
    React.useEffect(() => {
        getUserAllowedPlants(cookies)
        .then (
            plantList => {
                const plant = plantList.plant_list.filter(obj => obj.status).map(
                    plant => {
                        return { value: plant.id, label: plant.plant_alias }
                    }
                );
                setplant(plant);
            }
        )
    }, []);

    React.useEffect(() => {
        getAllVendors(cookies)
        .then (
            vendorList => {
                const vendor = vendorList.vendor_list.filter(obj => obj.status).map(
                    vendor => {
                        return { value: vendor.id, label: vendor.entity_name }
                    }
                );
                setvendor(vendor);
            }
        )
    }, []);
    
    React.useEffect(() => {
        getAllTax(cookies)
        .then (
            TaxList => {
                const taxes = TaxList.tax_list.filter(obj => obj.status).map(
                    tax => {
                        return { value: tax.id, label: tax.name }
                    }
                );
                setTaxes(taxes);
            }
        )
    }, []);

    React.useEffect(() => {
        var today = new Date().getFullYear()+'-'+("0"+(new Date().getMonth()+1)).slice(-2)+'-'+("0"+new Date().getDate()).slice(-2)
        setInputs(values => ({...values, ['grn_date']: today,['weighment_slip_date']:today,['lr_dt']:today,['dc_dt']:today}))
    }, []);
    
    React.useEffect(() => {
        const today = new Date().toISOString().split("T")[0];
        setMaxDate(today);
    }, []);

    React.useEffect(() => {
        getAllProducts(cookies)
        .then (
            productList => {
                const products = productList.product_list.filter(obj => obj.status);
                setProducts(products.map(product =>{
                    return{ value: product.id, label: product.name}
                }));
            }
        )
    }, []);
    
    React.useEffect(() => {
        getAllEquipments(cookies)
        .then (
            equipmentObject => {
                const equipments = equipmentObject.equipment_list.filter(obj => ((obj.equip_type.name === 'TRANSIT MIXER')&& (obj.status) && (obj.is_equip_ready))).map(
                    equipment => {
                        return { value: equipment.id, label: equipment.equip_name }
                    }
                );
                setEquipment(equipments);
            }
        )
    }, []);

    const handleSubmit=(event)=> {
        event.preventDefault()
        const hasChecked = rowsData.some(row => row.is_checked);
        console.log("Inputs:", JSON.stringify(inputs));
        console.log("Rows Data:", JSON.stringify(rowsData));
        console.log(JSON.stringify(selectedVehicle)+"selectedVehicle.value")
        if (inputs.transporter_name && inputs.transporter_mode && inputs.in_time && inputs.out_time && (inputs.transporter_mode === 'own'?selectedVehicle:inputs.vehicle_others) && hasChecked) { 
            Swal.fire({title: 'Do you want to save?',  
            showCancelButton: true,  
            confirmButtonText: `Yes`,  
            cancelButtonText: `No`,
            }).then((result) => {  
            if (result.isConfirmed) 
            {
                const EMPTY_STRING = '';
                createGoodsReceiptNote(cookies, {
                        "plant_id": parseInt(inputs.plant_id),
                        "goods_receipt_note_no": inputs.goods_receipt_number,
                        "goods_receipt_note_date": inputs.grn_date ? getDisplayDate(inputs.grn_date) : EMPTY_STRING,
                        "goods_receipt_note_time": getCurrentTime(),
                        "prefix": inputs.prefix,
                        "transporter_name": inputs.transporter_name,
                        "transport_mode": inputs.transporter_mode,
                        "vehicle_id": inputs.transporter_mode === 'own'?parseInt(selectedVehicle.value):'',
                        "vehicle_others":inputs.transporter_mode === 'customer'?inputs.vehicle_others:'',
                        "driver_name": inputs. driver_name,
                        "driver_mobile_no":inputs.driver_mobile_no,
                        "dc_no":inputs.dc_no,
                        "dc_dt":inputs.dc_dt? getDisplayDate(inputs.dc_dt) : EMPTY_STRING,
                        "measurement_taken_by": inputs.measurement_taken_by,
                        "authorised_by": inputs.authorised_by,
                        "received_by": inputs.received_by,
                        "out_time": inputs.out_time,
                        "in_time": inputs.in_time,
                        "weighment_name": inputs. weighment_name,
                        "weighment_slip_no": inputs.weighment_slip_no,
                        "weighment_slip_date":inputs.weighment_slip_date? getDisplayDate(inputs.weighment_slip_date) : EMPTY_STRING,
                        "weighment_slip_time": inputs. weighment_slip_time,
                        "user_remarks": inputs.remarks,
                        "lr_no":inputs.lr_no,
                        "lr_dt":inputs.lr_dt? getDisplayDate(inputs.lr_dt) : EMPTY_STRING,
                        "detail_list": rowsData.filter(obj => (obj.is_checked)).map(grn_detail=>({
                                "po_detail_id": parseInt(grn_detail.id),
                                "gross_weight": parseFloat(grn_detail.gross_weight),
                                "tare_weight": parseFloat(grn_detail.tare_weight),
                                "net_weight": parseFloat(grn_detail.net_weight),
                                "dc_qty": parseFloat(grn_detail.dc_qty),
                                "deduction_qty": parseFloat(grn_detail.deduction_qty),
                                "received_qty": parseFloat(grn_detail.received_qty),
                                "accepted_qty":parseFloat(grn_detail.accepted_qty),
                                "user_remarks": grn_detail.user_remarks
                            }))
                })
                .then(response => {
                    Swal.fire("Saved!", "", "success");
                    Reload();
                })
                .catch((error) =>
                {
                    // console.log(error.response.data);
                    displayError(error.response.data,"Save Failed");
                })
              
            }
            else if (result.isDismissed) 
            {    
                Swal.fire('Not saved', '', 'info')  
            }
            });
        }
        else if(!(hasChecked))
        {
        Swal.fire('Please add atleast one product', '', 'info')   
        }
        else 
        {
        Swal.fire('Please check mandatory fields are filled.', '', 'info')   
        }
    }

    const Reload = () => {
        window.location.reload();
    }
   
    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === "plant_id") {
            if (value) {
                var t = inputs.grn_date
                var moment = require('moment');
                var c=moment(t).format("DD-MM-YYYY");
                getGoodsReceiptnumber(cookies,value,c)
                    .then((response) => {
                        setInputs(values => ({
                            ...values,
                            goods_receipt_prefix: response.prefix + response.goods_receipt_note_no,
                            goods_receipt_number: response.goods_receipt_note_no,
                            prefix: response.prefix
                        }));
                    })
                .catch((error) =>
                {
                    displayError(error.response.data,"");
                })
            } 
            else {
                setInputs(values => ({
                    ...values,
                    goods_receipt_prefix: '',
                    goods_receipt_number: '',
                    prefix: ''
                }));
            }
        } 
        else if (name === "grn_date") {
            var t=inputs.plant_id
            var s = value
            var moment = require('moment');
            var d=moment(s).format("DD-MM-YYYY");
            getGoodsReceiptnumber(cookies,t,d)
            .then((response) => {
                setInputs(values => ({
                    ...values,
                    goods_receipt_prefix: response.prefix + response.goods_receipt_note_no,
                    goods_receipt_number: response.goods_receipt_note_no,
                    prefix: response.prefix
                }));
            })
            .catch((error) =>
            {
                displayError(error.response.data,"");
            })
        }
        setInputs(values => ({ ...values, [name]: value }));
    }

    const handleChangeTableDetails = (index, evnt) => {
        const { name, value } = evnt.target;
        const rowsInput = [...rowsData];
        rowsInput[index][name] = value;
        if (name === 'gross_weight' || name === 'tare_weight') {
            const grossWeight = parseFloat(rowsInput[index]['gross_weight'] || 0);
            const tareWeight = parseFloat(rowsInput[index]['tare_weight'] || 0);
            const netWeight = grossWeight - tareWeight;
            rowsInput[index]['net_weight'] = netWeight;
            const deductionQty = parseFloat(rowsInput[index]['deduction_qty'] || 0);
            const receivedQty = netWeight - deductionQty;
            rowsInput[index]['received_qty'] = receivedQty;
            rowsInput[index]['accepted_qty'] = receivedQty;
            rowsInput[index]['taxable_amount'] = receivedQty*(parseFloat(rowsInput[index]['rate']));
            let totalNetWeight = 0;
            for (let i = 0; i < rowsData.length; i++) {
                totalNetWeight += parseFloat(rowsInput[i]['net_weight'] || 0);
            }
            setInputs(values => ({
                ...values,
                ['total_net_weight']: totalNetWeight
            }));
            const dc_qty =  parseFloat(rowsInput[index]['dc_qty'] || 0);;
            const difference_qty = dc_qty - receivedQty;
            rowsInput[index]['difference_qty'] = difference_qty;
        } else if (name === 'deduction_qty') {
            const netWeight = parseFloat(rowsInput[index]['net_weight'] || 0);
            const deductionQty = parseFloat(value || 0);
            const receivedQty = netWeight - deductionQty;
            rowsInput[index]['received_qty'] = receivedQty;
            rowsInput[index]['accepted_qty'] = receivedQty;
            rowsInput[index]['taxable_amount'] = receivedQty*(parseFloat(rowsInput[index]['rate']));
            const acceptedQty = parseFloat(rowsInput[index]['accepted_qty'] || 0);
            const balanceQty = parseFloat(rowsInput[index]['balance_qty']);
            if (acceptedQty > balanceQty) {
                Swal.fire('Accepted quantity cannot be greater than balance quantity!');
            }
            const dc_qty =  parseFloat(rowsInput[index]['dc_qty'] || 0);;
            const difference_qty = dc_qty - receivedQty;
            rowsInput[index]['difference_qty'] = difference_qty;
        }
        else if (name === 'accepted_qty') {
            rowsInput[index]['taxable_amount'] = value*(parseFloat(rowsInput[index]['rate']));
        }
        else if (name === 'dc_qty') {
            const dc_qty =  value;
            const received_qty =  rowsInput[index]['received_qty'];
            // console.log(dc_qty+"dc_qty");
            // console.log(received_qty+"received_qty");
            const difference_qty = dc_qty - received_qty;
            rowsInput[index]['difference_qty'] = difference_qty;
        }
        else if(name=== 'is_checked'){
            const is_checked = evnt.target.checked;
            rowsInput[index]['is_checked'] = is_checked;
        }
        setRowsData(rowsInput);
    }
    
    const handleChangePurchaseOrderDetails = (value, event) => {
        const purchaseOrderId = value;
        const propertyName = 'purchaseorder_id';
        if (purchaseOrderId) {
            const selectedPo = allPurchaseOrders.filter(obj => obj.id == purchaseOrderId)[0];
            setSelectedPurchaseOrder(selectedPo);
            if (selectedPo) { 
                setInputs(values => ({
                    ...values,
                    [propertyName]: purchaseOrderId,
                    ['transporter_mode']: selectedPo.transport_mode,
                    ['order_no']:selectedPo.prefix+selectedPo.order_no,
                    ['order_date']:getDisplayDate(selectedPo.order_date),
                    ['company_id']:selectedPo.company.id,
                    ['vendor_id']:selectedPo.vendor.id,
                }));
                getVendorDetails(cookies, selectedPo.vendor.id)
                .then(vendorObject => {
                    // Set the details of the vendor in the state
                    setSelectedVendor(vendorObject);
                });
            }
            // console.log(JSON.stringify(selectedPo)+"selectedPo")
            setRowsData(selectedPo.order_list);
            setIsLoading(true);
            const a=(selectedPo.order_list.map((quolist,index)=>({...quolist,sl_no: index+1,
                        tax_id:quolist.tax.id,product_id:quolist.product.id,unit:quolist.product.unit.symbol,balance_qty:quolist.balance_qty,accepted_qty:0,taxable_amount:0,id:quolist.id,is_checked:false,gross_weight:0,tare_weight:0,net_weight:0,deduction_qty:0,
                        dc_qty:0,received_qty:0,difference_qty:0})));
            setRowsData(a) 
        } 
        setShowModal(false);
    }

    const ShowOrderDetails = () => {
        getpurchaseorderforgrn(cookies)
        .then (purchaseorderList => {
            setAllPurchaseOrders(purchaseorderList.purchase_order_list.filter(obj => obj.status))
            const tableData = purchaseorderList.purchase_order_list
                .map(grn=> ({
                    id :grn.id,
                    order_no:grn.prefix+grn.order_no,  
                    order_date: grn.order_date,                             
                    vendor_id:grn.vendor.name, 
                    company_id:grn.company.name,
                    prefix:grn.prefix,
                }));
                setData({
                    total: data.total,
                    records: tableData
                });
                setIsLoading(false);
                setShowModal(true)
        })
    }

    const Close = () => {
        setShowModal(false)
    }
    const columns =
        useMemo(
            () => [
                {
                    fieldName: "id",
                    headerName: "ROW_ACTIONS",
                    size:40
                },
                {
                    fieldName: 'order_no',
                    headerName: 'Order No',
                    size:40
                },
                {
                    fieldName: 'order_date',
                    headerName: 'Order Date',
                    size:50
                },
                {
                    fieldName: "company_id",
                    headerName: 'Company',
                    size:180
                },
                {
                    fieldName:"vendor_id",
                    headerName: 'Vendor',
                    size:180
                },
                {
                    fieldName:"prefix",
                    headerName: 'Prefix',
                    size:180
                }
            ],
        [],);

    const showplantdetails=()=>{ 
        if (!inputs.plant_id) {
            // If no plant is selected, do nothing
            return;
        }
        getPlantDetails(cookies,inputs.plant_id)  //get plant list
        .then(plant =>{
            Swal.fire({
                html: getHTMLForSummaryPopup(
                    'PLANT DETAILS',
                    [
                        { label: 'ADDRESS', value: plant.address_1 },
                        { value: plant.address_2 },
                        { value: plant.address_3 },
                        { value: plant.pincode },
                        { label: 'GST', value: plant.gst_no },
                        { label: 'PAN', value: plant.pan_no },
                    ]
                )
            }, '', 'info');  
        })
    }

    const showVendorDetails = () => {
        if (!selectedVendor || !selectedVendor.id) {
            // If no vendor is selected, do nothing
            return;
        }
        Swal.fire({
            html: getHTMLForSummaryPopup(
                'VENDOR DETAILS',
                [
                    { label: 'ADDRESS', value: selectedVendor.address_1 },
                    { value: selectedVendor.address_2 },
                    { value: selectedVendor.address_3 },
                    { label: 'GST', value: selectedVendor.gst_no },
                    { label: 'PAN', value: selectedVendor.pan_no },
                    { label: 'CONTACT PERSON NAME', value: selectedVendor.contact_person },
                    { label: 'DESIGNATION', value: selectedVendor.contact_designation }
                ]
            )
        }, '', 'info');
    }

    const Cancel = () => {
        setInputs({
            company_id:'',
            vendor_id:'',
            transporter_name:'',
            transporter_mode:'',
            vehicle_id:'',
            vehicle_others:'',
            driver_name:'',
            driver_mobile_no:'',
            in_time:'',
            out_time:'',
            weighment_name:'',
            weighment_slip_no:'',
            weighment_slip_date:'',
            weighment_slip_time:'',
            measurement_taken_by:'',
            authorised_by:'',
            received_by:'',
            lr_no:'',
            lr_dt:'',
            dc_no:'',
            dc_dt:'',
            transport_mode:'',
            is_tax_included:'',
            pay_terms:'',
            grn_no:'',
            grn_date:'',
            order_no:'',
            order_date:'',
            prefix:'',
            status:''
        })
        setRowsData([])
    }
    
    const navigate = useNavigate();
    
    const view = () => {
        navigate('/GoodsReceiptNoteTable')
    }

    const Home = () => {
        navigate('/Home')
    }

    const onVehicleSelect = (selected) => {
        setSelectedVehicle(selected);
    };
    
    const handleEditIconClick = () => {
        if (inputs.purchaseorder_id && selectedPurchaseOrder) {
            setShowModal2(true); 
        }
    };

    const handleWeighmentIconClick = () => {
        console.log("Inputs before opening modal:", inputs);
        Swal.fire({
            title: 'Weighment Details',
            html:
            '<label for="weighment_name" class="form-group col-sm-5 text-right">Weighment Name</label>' +
            `<input type="text" id="weighment_name" class="form-group col-sm-5 text-left" value="${inputs.weighment_name || ''}" >` +
            '<label for="weighment_slip_no" class="form-group col-sm-5 text-right">Weighment no</label>' +
            `<input type="text" id="weighment_slip_no" class="form-group col-sm-5 text-left" value="${inputs.weighment_slip_no || ''}" >` +
            '<label for="weighment_slip_date" class="form-group col-sm-5 text-right">Date</label>' +
            `<input type="date" id="weighment_slip_date" class="form-group col-sm-5 text-left"  max="${maxDate}" value="${inputs.weighment_slip_date || ''}" >`+
            '<label for="weighment_slip_time" class="form-group col-sm-5 text-right">Time</label>' +
            `<input type="time" id="weighment_slip_time" class="form-group col-sm-5 text-left" value="${inputs.weighment_slip_time || ''}" >`,
        }).then((result) => {
            if (result.isConfirmed) {
                    const weighmentname = document.getElementById('weighment_name').value;
                    const weighment_slip_no = document.getElementById('weighment_slip_no').value;
                    const weighment_slip_date = document.getElementById('weighment_slip_date').value;
                    const weighment_slip_time = document.getElementById('weighment_slip_time').value;
                    setInputs(values => ({
                        ...values,
                        weighment_name: weighmentname,
                        weighment_slip_no: weighment_slip_no,
                        weighment_slip_date: weighment_slip_date,
                        weighment_slip_time: weighment_slip_time
                    }));
            }
        });
    };

    const handlemeasurementIconClick = () => {
        Swal.fire({
            title: 'Measurement Details',
            html:
            '<label for="measurement_taken_by" class="form-group col-sm-5 text-right">Measurement taken by</label>' +
            `<input type="text" id="measurement_taken_by" class="form-group col-sm-5 text-left" value="${inputs.measurement_taken_by || ''}" >` +
            '<label for="authorised_by" class="form-group col-sm-5 text-right">Authorised by</label>' +
            `<input type="text" id="authorised_by" class="form-group col-sm-5 text-left" value="${inputs.authorised_by || ''}" >`+
            '<label for="received_by" class="form-group col-sm-5 text-right">Received by</label>' +
            `<input type="text" id="received_by" class="form-group col-sm-5 text-left" value="${inputs.received_by || ''}">`,
        }).then((result) => {
            if (result.isConfirmed) {
                const measurement_taken_by = document.getElementById('measurement_taken_by').value;
                const authorised_by = document.getElementById('authorised_by').value;
                const received_by = document.getElementById('received_by').value;
                setInputs(values => ({
                            ...values,
                            measurement_taken_by: measurement_taken_by,
                            authorised_by: authorised_by,
                            received_by:received_by
                        }));
                // console.log("Measurement taken by:", measurement_taken_by);
                // console.log("Authorised by:",authorised_by);
                // console.log("Received by:",received_by);
            }
        });
    };

    const handleChangevehicle = (newValue, actionMeta) => {
        setSelectedOption(newValue);
        console.log(newValue);
        setInputs(values => ({
        ...values,
        vehicle_others: newValue.value}))
    };
        
    return(
        <>
            <div id="outer-container" className="App" >
                <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} /> 
                <ErrorToast/>
                <div id="page-wrap">
                    <form onSubmit={handleSubmit} >
                        <div id="header">
                            <h3 className="text font-weight-bold page-title">GOODS RECEIPT NOTE </h3>
                        </div>
                        
                        <FloatingControls tableLink="/GoodsReceiptNoteTable" onCancel={Cancel} enableCancel={true}/>
                        
                        <div className="container">
                            <Row>
                                <Col xl={6} lg={12} md={12}>
                                    <div className="modal" tabIndex="-1" style={{ display: showModal ? 'block' : 'none' }}>
                                        <div className="modal-dialog modal-xl" style={{height: "500px"}} >
                                            <div className="modal-content">
                                                <div className="modal-body display">
                                                    <div className="container item-list-table-container">
                                                        <ReactTablePopUpSubmitWrapper
                                                            title='List of Group Form'
                                                            columns={columns}
                                                            data={data.records}
                                                            onRowSubmit={handleChangePurchaseOrderDetails}
                                                            isLoading={isLoading}
                                                            onClose = {Close}
                                                        />
                                                    </div> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3 form-control-panel " >
                                        <label htmlFor="company_id" className="form-group col-sm-5 text-right">Company</label>
                                        <select id="company_id" name="company_id" className="form-control col-sm-6"  value={inputs.company_id || ""} disabled={true} style={{cursor: "not-allowed"}}>
                                            <option value="">Select Company</option>
                                            {company.map((item) => (
                                                <option key={item.value}value={item.value}>{item.label} </option>
                                            ))} 
                                        </select><br />

                                        <label htmlFor="plant_id" className="form-group col-sm-5 text-right">Plant</label>
                                        <select id="plant_id" name="plant_id" className="form-control col-sm-6 browser-default custom-select mandatory-form-control" required onChange={handleChange} value={inputs.plant_id || ""}>
                                            <option value="">Select Plant</option>
                                            {plant.map((item) => (
                                                <option key={item.value}value={item.value}>{item.label} </option>
                                            ))} 
                                        </select>
                                        <SummaryIcon onClickHandler={showplantdetails}/><br />

                                        <h4 className='col-sm-11'><b>Vendor Details</b></h4><br/><br/>

                                        <label htmlFor="vendor_id" className="form-group col-sm-5 text-right">Vendor Name</label>
                                        <select id="vendor_id" name="vendor_id" className="form-control col-sm-6 browser-default"  value={inputs.vendor_id || ""} disabled={true} style={{cursor:"not-allowed"}}>
                                            <option value="">Select Vendor  Name</option>
                                            {vendor.map((item) => (
                                                <option key={item.value} value={item.value}>{item.label}</option>
                                            ))}
                                        </select>
                                        <SummaryIcon onClickHandler={showVendorDetails}/><br />

                                        <label htmlFor="transporter" className="form-group col-sm-5 text-right">Transporter</label>
                                        <EditSharpIcon  onClick={handleEditIconClick} 
                                        color="error"
                                        sx={{
                                            cursor: 'pointer'
                                        }} />

                                        <div className="modal" tabIndex="-1" style={{ display: showModal2 ? 'block' : 'none' }}>
                                            <div className="modal-dialog modal-xl" style={{ height: "500px" }}>
                                                <div className="modal-content">
                                                    <div className="modal-header text-center">
                                                        <h5 className="modal-title">Transporter Details</h5>
                                                    </div>
                                                    <div className="modal-body display">
                                                        <div className="form-group row">
                                                            <label htmlFor="transporter_name" className="form-group col-sm-5 text-right">Transporter Name</label>
                                                            <input type="text" id="transporter_name" className="form-group col-sm-5 text-left mandatory-form-control" value={inputs.transporter_name || ''} name = "transporter_name" onChange={handleChange} />
                                                            
                                                            <label htmlFor="transporter_mode" className="form-group col-sm-5 text-right">Transport Mode</label>
                                                            <select id="transporter_mode" className="form-group col-sm-5 text-left mandatory-form-control" value={inputs.transporter_mode || ''} name= "transporter_mode" onChange={handleChange} >
                                                                {selectedPurchaseOrder.transport_mode === 'own' && (
                                                                    <>
                                                                        <option value="own" selected>Own</option>
                                                                    </>
                                                                )}
                                                                {selectedPurchaseOrder.transport_mode === 'customer' && (
                                                                    <>
                                                                        <option value="customer" selected>Vendor</option>
                                                                    </>
                                                                )}
                                                                {selectedPurchaseOrder.transport_mode === 'own/customer' && (
                                                                    <>
                                                                        <option value="" >Please select</option>
                                                                        <option value="own" >Own</option>
                                                                        <option value="customer">Vendor</option>
                                                                    </>
                                                                )}
                                                            </select>

                                                            {inputs.transporter_mode === 'own' && (
                                                                <>
                                                                    <label htmlFor="vehicle_id" className="form-group col-sm-5 text-right">Vehicle Id</label>
                                                                    <Select
                                                                        id="vehicle_id"
                                                                        className="form-group col-sm-5 mandatory-form-control"
                                                                        options={equipments}
                                                                        isSearchable={true}
                                                                        value={selectedVehicle}
                                                                        onChange={onVehicleSelect}
                                                                    />
                                                                </>
                                                            )}

                                                            {inputs.transporter_mode === 'customer' && ( 
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <label htmlFor="vehicle_others" className="form-group col-sm-5 text-right" style={{ marginRight: '10px' }}>Vehicle others</label>
                                                                    <CreatableSelect
                                                                        isClearable
                                                                        onChange={handleChangevehicle}
                                                                        options={[]} 
                                                                        value={selectedOption}
                                                                        className="form-group col-sm-5  mandatory-form-control"
                                                                    />
                                                                </div>
                                                            )}
                                                        
                                                            <label htmlFor="driver_name" className="form-group col-sm-5 text-right">Driver Name</label>
                                                            <input type="text" id="driver_name" className="form-group col-sm-5 text-left" value={inputs.driver_name || ''} onChange={handleChange} name="driver_name"/>
                                                            
                                                            <label htmlFor="driver_mobile_no" className="form-group col-sm-5 text-right">Driver Phone no</label>
                                                            <input type="text" id="driver_mobile_no" className="form-group col-sm-5 text-left" value={inputs.driver_mobile_no || ''} onChange={handleChange} name="driver_mobile_no"/>
                                                            
                                                            <label htmlFor="in_time" className="form-group col-sm-5 text-right">In Time</label>
                                                            <input type="time" id="in_time" className="form-group col-sm-5 text-left mandatory-form-control"  value={inputs.in_time || ''} onChange={handleChange} name="in_time"/>
                                                            
                                                            <label htmlFor="out_time" className="form-group col-sm-5 text-right">Out Time</label>
                                                            <input type="time" id="out_time" className="form-group col-sm-5 text-left mandatory-form-control"  value={inputs.out_time || ''} onChange={handleChange} name="out_time"/>
                                                            
                                                            <label htmlFor="lr_no" className="form-group col-sm-5 text-right">Lr no</label>
                                                            <input type="text" id="lr_no" className="form-group col-sm-5 text-left" value={inputs.lr_no || ''} onChange={handleChange} name="lr_no"/>
                                                            
                                                            <label htmlFor="lr_dt" className="form-group col-sm-5 text-right">Lr dt</label>
                                                            <input type="date" id="lr_dt" className="form-group col-sm-5 text-left" value={inputs.lr_dt || ''} onChange={handleChange} name="lr_dt"/>
                                                        </div>
                                                        <div className="text-center">
                                                            <button type="button" className="btn btn-secondary" onClick={() => setShowModal2(false)}>Close</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                         
                                        <label htmlFor="weighment" className="form-group col-sm-5 text-right">Weighment</label>
                                        <EditSharpIcon color="#1565c0" onClick={handleWeighmentIconClick}  
                                        sx={{
                                            cursor: 'pointer'
                                        }} />

                                        <label htmlFor="measurment" className="form-group col-sm-5 text-right">Measurement</label>
                                        <EditSharpIcon color="#1565c0"
                                        sx={{
                                            cursor: 'pointer'
                                        }} 
                                        onClick={handlemeasurementIconClick}  />
                                    </div>
                                </Col>

                                <Col xl={6} lg={12} md={12}>
                                    <div className="form-row table-bordered shadow p-2 my-2  border-secondary p-2 mb-3 form-control-panel " >
                                        <h4 className='col-sm-11'><b>Order Details</b></h4><br/><br/>
                                        
                                        <label htmlFor="order_no" className="form-group col-sm-4 text-right">Purchase Order No</label>
                                        <input type="text" className="form-control col-sm-7" id="order_no" value={inputs.order_no || ""} disabled={true}  name="order_no" style={{cursor: "not-allowed"}}/><br />
                                        <QuestionIcon onClickHandler={ShowOrderDetails}/><br />

                                        <label htmlFor="order_date" className="form-group col-sm-4 text-right ">Date</label>
                                        <input required type="date" id="order_date" name="order_date"  className="form-control col-sm-7" disabled={true} value={inputs.order_date || ""}  style={{cursor: "not-allowed"}}/>
                       
                                        <label htmlFor="grn_no" className="form-group col-sm-4 text-right">Goods Receipt No</label>
                                        <input type="text" className="form-control col-sm-7" id="grn_no" value={inputs.goods_receipt_prefix || ""} style={{cursor: "not-allowed"}} disabled={true} name="goods_receipt_prefix"  /><br />
                                        
                                        <label htmlFor="grn_date" className="form-group col-sm-4 text-right ">Date</label>
                                        <input required type="date" id="grn_date" name="grn_date" onChange={handleChange} className="form-control col-sm-7 mandatory-form-control" value={inputs.grn_date || ""} max={maxDate} />
                                        
                                        <h4 className='col-sm-11'><b>Supplier Document Details </b></h4><br/><br/>    
                                        
                                        <label htmlFor="dc_no" className="form-group col-sm-4 text-right">DC No</label>
                                        <input type="text" id="dc_no" className="form-control col-sm-7  mandatory-form-control" value={inputs.dc_no || ''} onChange={handleChange} required name="dc_no"/>
                                        
                                        <label htmlFor="dc_dt" className="form-group col-sm-4 text-right">DC Date</label>
                                        <input type="date" id="dc_dt" className="form-control col-sm-7  mandatory-form-control" value={inputs.dc_dt || ''} onChange={handleChange} name="dc_dt" required  max={maxDate}/>
                                    </div>
                                </Col>
                            </Row>

                            <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue container table-responsive">
                                <br/>
                                <table className="table order-list" id="myTable" style={{textAlign:"center",borderRadius:"10px",backgroundColor:"RGB(188,232,253)"}}>
                                    <thead>
                                        <tr>
                                            <th className="form-group col-sm-1 text-left">Action</th>
                                            <th colSpan="1">S.No.</th>
                                            <th colSpan="1">Product</th>
                                            <th colSpan="1">Unit</th>
                                            <th colSpan="1">Order Qty.</th>
                                            <th colSpan="1">Rate / M3 </th>
                                            <th colSpan="1">Amount</th>
                                            <th colSpan="1">Balance Qty</th>
                                            <th colSpan="1">Gross Weight</th>
                                            <th colSpan="1">Tare Weight</th>  
                                            <th colSpan="1">Net Weight</th> 
                                            <th colSpan="1">Deduction Weight</th> 
                                            <th colSpan="1">Accepted Qty</th>  
                                            <th colSpan="1">DC Qty</th> 
                                            <th colSpan="1">Total Received Qty</th>
                                            <th colSpan="1">Difference Qty</th>
                                            <th colSpan="1">Taxable Amount</th>
                                            <th colSpan="1">Tax</th>
                                            <th colSpan="1">Remarks</th> 
                                        </tr>
                                    </thead>  
                                    <tbody >
                                        <TableRowsGRN
                                            rowsData={rowsData}
                                            handleChangeTableDetails={handleChangeTableDetails}
                                            products={products}
                                            taxes={taxes}
                                        />
                                    </tbody>
                                </table> 
                            </div>
                            
                            <div className="form-row table-bordered shadow p-2 my-2  border-secondary p-2 mb-3 form-control-panel ">
                                <label htmlFor="remarks" className="form-group col-sm-2 text-right">Remarks</label>
                                <textarea rows="2" cols="50" className="form-control col-sm-10" id="remarks" name="remarks" onChange={handleChange} value={inputs.remarks || ""}></textarea>
                            </div>
                        </div> 

                        <div className="footer text-center">
                            <Button type="submit" className="btn btn-twitter" style={{ width: "80px", fontWeight: "bold" }} >Save</Button>
                            <Button type="reset" className="btn btn-twitter" style={{ width: "80px", fontWeight: "bold" }} onClick={Cancel}>Cancel</Button>
                            <Button className="btn btn-twitter" type="button" style={{ width: "80px", fontWeight: "bold" }} onClick={view}>View</Button>
                            <Button className="btn btn-twitter" type="button" style={{ width: "80px", fontWeight: "bold" }} onClick={Home}>Home</Button>
                        </div>
                    </form>
                </div>
            </div>
        </>
      )
}
export default GoodsReceiptNote