import React from 'react';
import { useState ,useEffect, useMemo} from "react";
import Swal from "sweetalert2";
import {Row,Col,Button} from "reactstrap";
import Sidebar from '../../Sidebar';
import { useNavigate } from 'react-router';
import { BiSave } from "react-icons/bi";
import { IoIosEye } from "react-icons/io";
import { TiCancel } from "react-icons/ti";
import { AiOutlineHome } from "react-icons/ai";
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';
import {displayErrorToast,displayError} from '../../helpers';
import ErrorToast from '../../ErrorToast';
import { getHTMLForSummaryPopup } from '../../utils/PopupUtils';
import FloatingControls from '../../components/FloatingControls';
import { getCustomerMainDetails } from '../../services/CustomerMainServices';
import LoadingOverlay from '../../components/LoadingOverlay';
import { updateDesignMix,getDesignMixDetails, deleteDesignMix,getDesignMixForPlantAndProduct} from '../../services/DesignMixServices';
import { getCustomerSiteDetails } from '../../services/CustomerSiteServices';
import ViewOnlyTextbox from '../../components/ViewOnlyTextbox';
import { getAllPlantConfigurations } from '../../services/PlantConfigurationServices';
import {getWorkScheduleDetails } from '../../services/WorkScheduleServices';
import ReactTablePopUpSubmitWrapper from "../../components/ReactTablePopUpSubmitWrapper";
import QuestionIcon from '../../components/QuestionIcon';
function DesignMixEdit() {
    const [cookies] = useCookies(['myToken']);
    const [category] = useCookies(['myCategory']);
    const [superuser] = useCookies(['mySuperuser']);
    const [inputs, setInputs] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(true);
    const [selectedCustomer,setSelectedCustomer] = useState({});
    const [inProgress, setInProgress] = useState(false);
    const [error, setError] = useState({});
    const [isLoading, setIsLoading] = useState(true);  
    const [selectedWorkSchedule,setSelectedWorkSchedule] = useState({});
    const [selectedSite,setSelectedSite]= React.useState({});
    const [plantConfig, setPlantConfig] = useState({});
    const [showModal, setShowModal] = useState(false);
    const initial_table_data = {
        total_pages: 0,
        records: [],
      };
    const [data, setData] = useState(initial_table_data);

    React.useEffect(() => {
        const EMPTY_STRING = '';
        if (id)
        {
            setInProgress(true);
            getDesignMixDetails(cookies, id)
            .then(response => {
                console.log(JSON.stringify(response)+"responseUE")
                setInProgress(false);
                setInputs(response);
                // getCustomerMainDetails(cookies,response.work_schedule.sales_order_detail.sales_order.consignee.customer.id)    
                // .then(customerObject => {              //Set the details of the customer in the form fields
                //     setSelectedCustomer(customerObject)
                // })
                // getCustomerSiteDetails(cookies,response.work_schedule.sales_order_detail.sales_order.consignee.id)
                // .then(SiteObject => {                     //Set the details of the site in the form fields
                //     setSelectedSite(SiteObject);
                // });
                getAllPlantConfigurations(cookies,response.work_schedule.plant.id)
                .then(plantConfiguration => {
                    setIsLoading(false);
                    setPlantConfig(plantConfiguration.plant_configuration_list[0] || {});
                })
                .catch((error) =>
                {
                    setIsLoading(false);
                    console.log(error.response.data);
                    displayError(error.response.data," Failed");
                })
                // setSelectedWorkSchedule(response.work_schedule)
                getWorkScheduleDetails(cookies,response.work_schedule.id)
                .then(WorkScheduleObject =>{
                    setSelectedWorkSchedule(WorkScheduleObject)
                })
            })
            .catch(error => {
                setInProgress(false);
                console.log(error.response.data);
                displayError(error.response.data, "Loading Failed");
            });
        }
    }, []);

    const handleChangeFormulaDetails = (event)=>{    
        const name = event.target.name;
        const value = event.target.value;
        try
        {
            setInputs(values => ({...values, [name]: event.target.value}))
            if(name === 'cem1' || name === 'cem2' || name === 'cem3' || name === 'cem4' || name === 'cem5' || name === 'water1' || name === 'water2' )
            {
                let cem = document.getElementsByClassName("val1");
                let cem_total = 0;
                for (let i = 0;  i < cem.length; i++) 
                {
                    if(cem[i].value == '')
                    {
                        cem[i].value = 0
                    }
                    cem_total+=parseFloat(cem[i].value)
                }
                setInputs(values => ({...values, ['total_cem']:cem_total.toFixed(2)}));
                let wat_total =0
                let wat= document.getElementsByClassName("waterval");
                for (let i = 0;  i < wat.length; i++) 
                {
                    if(wat[i].value == '')   //check the water level range upto 0.50
                    {
                        wat[i].value = 0
                    }
                    wat_total+=parseFloat(wat[i].value)
                }
                let waterCementRatio= wat_total / cem_total;
                setInputs(values => ({...values, ['water_cem']:waterCementRatio.toFixed(2)}));
                let elements = document.getElementsByClassName("totalval");
                let sum=0
                for (let i = 0;  i <elements.length; i++) 
                {
                    if(elements[i].value == '')
                    {
                        elements[i].value = 0
                    }
                    sum+=parseFloat(elements[i].value)
                }
                setInputs(values => ({...values, ['density']:sum.toFixed(2)}));
            }
                
            else if(name === 'agg1' || name === 'agg2' || name === 'agg3' || name === 'agg4' || name === 'agg5' || name ==='adm1' || name === 'adm2' || name === 'others')
            {
                let elements = document.getElementsByClassName("totalval");
                let sum=0;
                for (let i = 0;  i <elements.length; i++) 
                {
                    if(elements[i].value == '')
                    {
                        elements[i].value = 0
                    }
                    sum+=parseFloat(elements[i].value)
                }
                setInputs(values => ({...values, ['density']:sum.toFixed(2)}));
            }
        }
        catch (e) {
            displayErrorToast(e);
        }
    }
  
    const navigate = useNavigate();
 
    const handleSubmit=(event)=> {
        event.preventDefault();
        try{
            var err=0;
            if(((inputs.density)< 2200)||((inputs.density)> 3000))    //check the density level upto range 2200-3000
            {
                var err= 1;
            }
            if(((inputs.water_cem)< 0.10) || ((inputs.water_cem)> 1) )
            {
                var err =1;
            }
            if (err == 0) {     //If all the required fields are field u can select the save button then choose the option yes or no
                Swal.fire({title: 'Do you want to update?',  
                showCancelButton: true,  
                confirmButtonText: `Yes`,  
                cancelButtonText: `No`,
                }).then((result) => {  
                if (result.isConfirmed) {
                    updateDesignMix(cookies, {
                        id:parseInt(inputs.id),
                        work_schedule_id:parseInt(inputs.work_schedule_id),
                        cem1:parseInt(inputs.cem1),
                        cem2:parseInt(inputs.cem2),
                        cem3:parseInt(inputs.cem3),
                        cem4:parseInt(inputs.cem4),
                        cem5:parseInt(inputs.cem5),
                        agg1:parseInt(inputs.agg1),
                        agg2:parseInt(inputs.agg2),
                        agg3:parseInt(inputs.agg3),
                        agg4:parseInt(inputs.agg4),
                        agg5:parseInt(inputs.agg5),
                        water1:parseInt(inputs.water1),
                        water2:parseInt(inputs.water2),
                        adm1:parseFloat(inputs.adm1),
                        adm2:parseFloat(inputs.adm2),
                        others:parseInt(inputs.others),
                        density:parseFloat(inputs.density),
                        water_cem:parseFloat(inputs.water_cem),
                        total_cem:parseInt(inputs.total_cem)
                    },id)
                    .then(response => {
                        Swal.fire("Updated!", "", "success");
                        view();
                    }).catch((error) => {
                        //console.log(error.response.data);
                        displayError(error.response.data,"Updated Failed");
                    });
                } 
                else if (result.isDismissed) 
                {    
                    Swal.fire('Not updated', '', 'info')  
                }
                });
            }
            else
            {
            Swal.fire('Density value must be in the range of 2200 - 3000 and WC Ratio must lie between 0.10 and 1.Please check.', '', 'info')   
            }
        }
        catch (e)
        {
        displayErrorToast(e);
        }
    }
 
    const showCustomerDetails = () => {
        if (selectedWorkSchedule.id) {
            getCustomerMainDetails(cookies,selectedWorkSchedule.sales_order_detail.sales_order.consignee.customer.id)
            .then(selectedCustomer => {
                Swal.fire({
                    html:getHTMLForSummaryPopup(
                        'BILLING DETAILS',
                        [
                        {label: 'ADDRESS', value: selectedCustomer.address_1},
                        {value: selectedCustomer.address_2},
                        {value: selectedCustomer.address_3},
                        {label: 'GST', value: selectedCustomer.gst_no},
                        {label: 'PAN', value: selectedCustomer.pan_no},
                        {label: 'SALES OFFICER', value: selectedCustomer.salesrep.name},
                        {label: 'CREDIT LIMIT', value: selectedCustomer.credit_limit}
                        ])
                }, '', 'info');
            })
        }
    }

    const showSiteDetails = () => {
        if (selectedWorkSchedule.id) {
            getCustomerSiteDetails(cookies,selectedWorkSchedule.sales_order_detail.sales_order.consignee.id)
            .then(consignee => {
                Swal.fire({
                    html:getHTMLForSummaryPopup(
                        'SITE DETAILS',
                        [
                        {label: 'ADDRESS', value: consignee.address_1},
                        {value: consignee.address_2},
                        {value: consignee.address_3},
                        {label: 'SITE NAME', value: consignee.project_name},
                        {label: 'CONTACT NAME', value: consignee.contact_person},
                        {label: 'MOBILE NO', value: consignee.contact_mobile_no},
                        {label: 'DESIGNATION', value: consignee.contact_designation}
                        ])
                }, '', 'info');   
            })
        }
    }

    const showWorkScheduleDetails = () => {
        if (selectedWorkSchedule.id) {
            Swal.fire({
                html:getHTMLForSummaryPopup(
                    'WORK SCHEDULE DETAILS',
                    [
                        {label: 'SCHEDULE DATE', value: selectedWorkSchedule.schedule_date},
                        {label: 'DELIVERY DATE', value: selectedWorkSchedule.delivery_date},
                        {label: 'DELIVERY TIME', value: selectedWorkSchedule.delivery_time},
                        {label: 'SCHEDULE QTY', value: selectedWorkSchedule.scheduled_qty}
                    ])
            }, '', 'info');   
        }
    }

    const onDeleteDesignMix = (event) => {
        event.preventDefault();
        Swal.fire({title: 'Are you sure to Delete?',  
        showCancelButton: true,  
        confirmButtonText: `Yes`,  
        cancelButtonText: `No`,
        }).then((result) => {  
            if (result.isConfirmed) { 
                setIsLoading(true);
                deleteDesignMix(cookies, id)
                .then(response => {
                    console.log(`DesignMix with id ${id} deleted`);
                    Swal.fire('Deleted Successfully!', '', 'success');
                    view();
                })
                .catch(error => {
                    console.log(error.response.data);
                    displayError(error.response.data,'Delete Failed');
                }); 
            } 
            else if (result.isDismissed) {    
                Swal.fire('Not Deleted', '', 'info')  
            }
        });
    };
 
    const view = () => {
        navigate('/DesignMixTable')
    }  

    const Back = () => {
        navigate('/Home')
    }

    const Cancel = () => {
        setInputs(() => "")
    }

    const Help = () => {
        if (selectedWorkSchedule.id) {
            getDesignMixForPlantAndProduct(cookies,selectedWorkSchedule.plant.id,selectedWorkSchedule.sales_order_detail.product.id)
            .then(response => {
                const tableData = response.design_mix_list.filter(obj => (obj.id != inputs.id))
            .map(dmix => ({
                id :dmix.id,
                schedule_no:dmix.work_schedule.schedule_no,  
                schedule_date: dmix.work_schedule.schedule_date,                             
                customer_name:dmix.work_schedule.sales_order_detail.sales_order.consignee.customer.name, 
                consignee_name:dmix.work_schedule.sales_order_detail.sales_order.consignee.name,
                silo_1: dmix.cem1,
                silo_2: dmix.cem2,
                silo_3: dmix.cem3,
                silo_4: dmix.cem4,
                silo_5: dmix.cem5,
                bin_1:dmix.agg1,
                bin_2:dmix.agg2,
                bin_3:dmix.agg3,
                bin_4:dmix.agg4,
                bin_5:dmix.agg5,
                other_1:dmix.water1,
                other_2:dmix.water2,
                other_3:dmix.adm1,
                other_4:dmix.adm2,
                other_5:dmix.others,
                density:dmix.density,
                water_cem:dmix.water_cem,
                total_cem:dmix.total_cem
            }));
            // if(tableData.length>0){
                setData({
                    total: data.total,
                    records: tableData
                });
                setIsLoading(false);
                setShowModal(true)
            // }
            // else{
            //     setData(initial_table_data)
            //     setIsLoading(true);
            //     setShowModal(false);
            //     Swal.fire("No records to display!", "", "info");
            // }
            })
        }
        else
        {
            setData(initial_table_data)
            setIsLoading(true);
            setShowModal(false);
        }
    }

    const columns = useMemo(() => {
        const dynamicColumns = [
            {
                fieldName: "id",
                headerName: "ROW_ACTIONS",
                size:40
            },
            {
                fieldName: 'schedule_no',
                headerName: 'Schedule No',
                size:40
            },
            {
                fieldName: 'schedule_date',
                headerName: 'Schedule Date',
                size:50
            },
            {
                fieldName: "customer_name",
                headerName: 'Customer',
                size:180
            },
            {
                fieldName: "consignee_name",
                headerName: 'Site',
                size:180
            }
        ];
    
        // Check for each silo and add it to the columns if it should be displayed
        for (let i = 1; i <= 5; i++) {
            const siloFieldName = `silo_${i}`;
            if (plantConfig[`is_${siloFieldName}`]) {
                dynamicColumns.push({
                    fieldName: siloFieldName,
                    headerName: plantConfig[`${siloFieldName}`],
                    size:50
                });
            }
        }
        // Check for each bin field and add it to the columns if it should be displayed
        for (let i = 1; i <= 5; i++) {
            const binFieldName = `bin_${i}`;
            if (plantConfig[`is_${binFieldName}`]) {
                dynamicColumns.push({
                    fieldName: binFieldName,
                    headerName: plantConfig[`${binFieldName}`],
                    size:50
                });
            }
        }

        // Check for each other field and add it to the columns if it should be displayed
        for (let i = 1; i <= 5; i++) {
            const otherFieldName = `other_${i}`;
            if (plantConfig[`is_${otherFieldName}`]) {
                dynamicColumns.push({
                    fieldName: otherFieldName,
                    headerName: plantConfig[`${otherFieldName}`],
                    size:50
                });
            }
        }
    
        return dynamicColumns;
    }, [plantConfig]);

    const onSubmitDmix = (dmixId, event) => {
        const selected_dmix = data.records.filter(obj => obj.id == dmixId)[0];
        console.log(JSON.stringify(selected_dmix)+"selected_dmix")
        setInputs(values => ({...values,
        ['cem1']:selected_dmix.silo_1,
        ['cem2']:selected_dmix.silo_2,
        ['cem3']:selected_dmix.silo_3,
        ['cem4']:selected_dmix.silo_4,
        ['cem5']:selected_dmix.silo_5,
        ['agg1']:selected_dmix.bin_1,
        ['agg2']:selected_dmix.bin_2,
        ['agg3']:selected_dmix.bin_3,
        ['agg4']:selected_dmix.bin_4,
        ['agg5']:selected_dmix.bin_5,
        ['water1']:selected_dmix.other_1,
        ['water2']:selected_dmix.other_2,
        ['adm1']:selected_dmix.other_3,
        ['adm2']:selected_dmix.other_4,
        ['others']:selected_dmix.other_5,
        ['total_cem']:selected_dmix.total_cem,
        ['water_cem']:selected_dmix.water_cem,
        ['density']:selected_dmix.density
    }));
    setShowModal(false)
    }

    const {id}=useParams()
    const Close = () => {
        setShowModal(false)
      }

return (
    <>
        <div id="outer-container"  className="App" > 
            <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
            <ErrorToast/>
            <LoadingOverlay inProgress={inProgress}/>
            <div id="page-wrap">
                <form onSubmit={handleSubmit} data-id={inputs.id}> 
                    <div id="header">
                        <h3 className = "text font-weight-bold page-title">DESIGN MIX EDIT FORM</h3>
                    </div>
                    <FloatingControls tableLink="/DesignMixTable" onCancel={Cancel} enableCancel={false}/>

                    <div className="container"> 
                        <Row> 
                            <Col xl={12} lg={12} md={6} >   
                                <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3  form-control-panel " >
                                    <h5 className='col-sm-11'><b>Design Mix Details</b></h5><br/>
                                    
                                    <ViewOnlyTextbox 
                                    name="plant_alias" 
                                    value={selectedWorkSchedule?.plant?.alias_name || ""}
                                    labelClassName="form-group col-sm-2 text-right"
                                    label="Plant Location"
                                    inputClassName="form-control col-sm-3"
                                    />
                                    
                                    <ViewOnlyTextbox 
                                    name="work_schedule_no" 
                                    value={
                                        selectedWorkSchedule?.schedule_no
                                        ? `${selectedWorkSchedule.schedule_no}-${selectedWorkSchedule.sales_order_detail?.prefix}${selectedWorkSchedule.sales_order_detail?.order_no}(${selectedWorkSchedule.sales_order_detail?.product?.name})`
                                        : ""
                                    }
                                    // value={selectedWorkSchedule.schedule_no + "-" + selectedWorkSchedule.sales_order_detail.prefix + selectedWorkSchedule.sales_order_detail.order_no + "(" + selectedWorkSchedule.sales_order_detail.product.name + ")"}
                                    labelClassName="form-group col-sm-3 text-right"
                                    label="Work schedule No"
                                    inputClassName="form-control col-sm-3"
                                    showSummaryDetails={showWorkScheduleDetails}
                                    /> 
                                    <br/>

                                    <ViewOnlyTextbox 
                                    name="grade" 
                                    value={selectedWorkSchedule?.sales_order_detail?.product?.name || ""}
                                    labelClassName="form-group col-sm-2 text-right"
                                    label="Grade"
                                    inputClassName="form-control col-sm-3"
                                    />

                                    <ViewOnlyTextbox 
                                    name="billing_name" 
                                    value={selectedWorkSchedule?.sales_order_detail?.sales_order?.consignee?.customer?.name || ""}
                                    labelClassName="form-group col-sm-3 text-right"
                                    label="Billing Name"
                                    inputClassName="form-control col-sm-3"
                                    showSummaryDetails={showCustomerDetails}
                                    /> 
                                    <br/>

                                    <ViewOnlyTextbox 
                                    name="scheduled_qty" 
                                    value={selectedWorkSchedule?.scheduled_qty || ""}
                                    labelClassName="form-group col-sm-2 text-right"
                                    label="Scheduled Qty"
                                    inputClassName="form-control col-sm-3"
                                    />  
                                    
                                    <ViewOnlyTextbox 
                                    name="ship_to" 
                                    value={selectedWorkSchedule?.sales_order_detail?.sales_order?.consignee?.name|| ""}
                                    labelClassName="form-group col-sm-3 text-right"
                                    label="Ship To"
                                    inputClassName="form-control col-sm-3"
                                    showSummaryDetails={showSiteDetails}
                                    />    
                                </div>
                            </Col>
                        </Row>
                        <div className="container  form-control-panel">
                            <h5>
                                <b>Recipe Details</b>
                                &nbsp;&nbsp;
                                {/* <button type="button"  className="btn btn-primary text-blue text-bold rounded-circle rounded-sm"  onClick={Help}>
                                <i className=" fa fa-solid fa-question"></i>
                                </button> */}
                                <QuestionIcon onClickHandler={Help}/>
                            </h5>
                            {/* <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true"> */}
                            <div className="modal" tabIndex="-1" style={{ display: showModal ? 'block' : 'none' }}>
                                <div className="modal-dialog modal-xl" style={{height: "500px"}} >
                                <div className="modal-content">
                                    <div className="modal-body display">
                                    <div className="container item-list-table-container">
                                        {/* <div className="table-responsive"> */}
                                                <ReactTablePopUpSubmitWrapper
                                                    title='List of Group Form'
                                                    columns={columns}
                                                    data={data.records}
                                                    onRowSubmit={onSubmitDmix}
                                                    onClose={Close}
                                                    // onCSVExport={handleCSVExport}
                                                    // onPDFExport={handlePDFExport}
                                                    isLoading={isLoading}
                                                />
                                        {/* </div> */}
                                        </div> 
                                    </div>
                                </div>
                                </div>
                            </div>
                            <Row>
                                <Col xl={4} lg={12} md={12}>   
                                    <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue form-control-panel" >
                                        <h5 className='col-sm-11'><b>SILO</b></h5>

                                        <label htmlFor="silo_1" className="form-group col-sm-3 text-right">Silo 1 </label>
                                        <input type="number" className="form-control totalval val1 wat1 col-sm-5" id="testa" name="cem1" 
                                        onChange={handleChangeFormulaDetails}  
                                        disabled={!plantConfig.is_silo_1} style={{ cursor: plantConfig.is_silo_1 ? 'auto' : 'not-allowed' }}
                                        value={inputs.cem1}  min="0" />
                                        <><strong className="form-group col-sm-4 text-center text-danger" >{plantConfig.is_silo_1 ? plantConfig.silo_1: null}</strong><br/></>

                                        <label htmlFor="silo_2" className="form-group col-sm-3 text-right">Silo 2 </label>
                                        <input type="number"  className="form-control col-sm-5 totalval val1 wat1"  id="testb" 
                                        disabled={!plantConfig.is_silo_2} style={{ cursor: plantConfig.is_silo_2 ? 'auto' : 'not-allowed' }}
                                        name="cem2"onChange={handleChangeFormulaDetails}   value={inputs.cem2} min="0"  />
                                        <><strong className="form-group col-sm-4 text-center text-danger" >{plantConfig.is_silo_2 ? plantConfig.silo_2: null}</strong><br/></>

                                        <label htmlFor="silo_3" className="form-group col-sm-3 text-right">Silo 3 </label>
                                        <input type="number"    className="form-control col-sm-5 totalval val1 wat1" 
                                        disabled={!plantConfig.is_silo_3} style={{ cursor: plantConfig.is_silo_3 ? 'auto' : 'not-allowed' }}
                                        id="testc" name="cem3" onChange={handleChangeFormulaDetails}  value={inputs.cem3} min="0" />
                                        <><strong className="form-group col-sm-4 text-center text-danger" >{plantConfig.is_silo_3 ? plantConfig.silo_3: null}</strong><br/></>

                                        <label htmlFor="silo_4" className="form-group col-sm-3 text-right">Silo 4 </label>
                                        <input type="number"  size="10" className="form-control  col-sm-5 totalval  val1 wat1"
                                        disabled={!plantConfig.is_silo_4} style={{ cursor: plantConfig.is_silo_4 ? 'auto' : 'not-allowed' }}
                                            id="test4"  name="cem4" onChange={handleChangeFormulaDetails}  value={inputs.cem4} min="0" />
                                        <><strong className="form-group col-sm-4 text-center text-danger" >{plantConfig.is_silo_4 ? plantConfig.silo_4: null}</strong><br/></>

                                        <label htmlFor="silo_5" className="form-group col-sm-3 text-right">Silo 5 </label>
                                        <input type="number"  size="10" className="form-control col-sm-5 totalval  val1 wat1" 
                                        disabled={!plantConfig.is_silo_5} style={{ cursor: plantConfig.is_silo_5 ? 'auto' : 'not-allowed' }}id="test5"  name="cem5" 
                                        onChange={handleChangeFormulaDetails}  value={inputs.cem5} min="0" />
                                        <><strong className="form-group col-sm-4 text-center text-danger" >{plantConfig.is_silo_5 ? plantConfig.silo_5: null}</strong><br/></>
                                    </div>
                                </Col>
                                
                                <Col xl={4} lg={12} md={12}>  
                                    <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue form-control-panel">
                                        <h5 className='col-sm-11'><b>AGGREGATE BINS</b></h5>

                                        <label htmlFor="bin_1" className="form-group col-sm-3 text-right">Bin 1 </label>
                                        <input type="number"   id="" size="10" className="form-control col-sm-5 totalval" 
                                        disabled={!plantConfig.is_bin_1} style={{ cursor: plantConfig.is_bin_1 ? 'auto' : 'not-allowed' }}  name="agg1"
                                        onChange={handleChangeFormulaDetails}  value={inputs.agg1} min="0" />
                                        <><strong className="form-group col-sm-4 text-center text-danger" >{plantConfig.is_bin_1 ? plantConfig.bin_1: null}</strong><br/></>  

                                        <label htmlFor="bin_2" className="form-group col-sm-3 text-right">Bin 2 </label>
                                        <input type="number"  id="" size="10" className="form-control col-sm-5 totalval"
                                        disabled={!plantConfig.is_bin_2} style={{ cursor: plantConfig.is_bin_2 ? 'auto' : 'not-allowed' }} 
                                        name="agg2" onChange={handleChangeFormulaDetails}  value={inputs.agg2} min="0" />
                                        <><strong className="form-group col-sm-4 text-center text-danger" >{plantConfig.is_bin_2 ? plantConfig.bin_2: null}</strong><br/></>

                                        <label htmlFor="bin_3" className="form-group col-sm-3 text-right">Bin 3 </label>
                                        <input type="number"  id=""size="10" className="form-control  col-sm-5 totalval"
                                        disabled={!plantConfig.is_bin_3} style={{ cursor: plantConfig.is_bin_3 ? 'auto' : 'not-allowed' }} 
                                        name="agg3" onChange={handleChangeFormulaDetails}  value={inputs.agg3}  min="0" />
                                        <><strong className="form-group col-sm-4 text-center text-danger" >{plantConfig.is_bin_3 ? plantConfig.bin_3: null}</strong><br/></> 

                                        <label htmlFor="bin_4" className="form-group col-sm-3 text-right">Bin 4 </label>
                                        <input type="number"  id="" size="10" className="form-control col-sm-5 totalval"
                                        disabled={!plantConfig.is_bin_4} style={{ cursor: plantConfig.is_bin_4 ? 'auto' : 'not-allowed' }} 
                                        name="agg4" onChange={handleChangeFormulaDetails}  value={inputs.agg4} min="0" />
                                        <><strong className="form-group col-sm-4 text-center text-danger" >{plantConfig.is_bin_4 ? plantConfig.bin_4: null}</strong><br/></>

                                        <label htmlFor="bin_5" className="form-group col-sm-3 text-right">Bin 5 </label>
                                        <input type="number"  id="" size="10" className="form-control col-sm-5 totalval" 
                                        disabled={!plantConfig.is_bin_5} style={{ cursor: plantConfig.is_bin_5 ? 'auto' : 'not-allowed' }} 
                                        name="agg5" onChange={handleChangeFormulaDetails}  value={inputs.agg5} min="0" />
                                        <><strong className="form-group col-sm-4 text-center text-danger" >{plantConfig.is_bin_5 ? plantConfig.bin_5: null}</strong><br/></>
                                    </div>
                                </Col>

                                <Col xl={4} lg={12} md={12}>  
                                    <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue form-control-panel">
                                        <h5 className='col-sm-11'><b>OTHERS</b></h5>

                                        <label htmlFor="other_1" className="form-group col-sm-3 text-right">Other 1 </label>
                                        <input type="number"  id="firstNumber" size="10"  className="form-control  col-sm-5 totalval waterval wat1" 
                                        disabled={!plantConfig.is_other_1} style={{ cursor: plantConfig.is_other_1 ? 'auto' : 'not-allowed' }} 
                                        name="water1" onChange={handleChangeFormulaDetails}  value={inputs.water1} min="0" />
                                        <><strong className="form-group col-sm-4 text-center text-danger" >{plantConfig.is_other_1 ? plantConfig.other_1: null}</strong><br/></>
                                        
                                        <label htmlFor="other_2" className="form-group col-sm-3 text-right">Other 2 </label>
                                        <input type="number"  id="secondNumber" size="10"  className="form-control  col-sm-5 totalval waterval wat1" 
                                        disabled={!plantConfig.is_other_2} style={{ cursor: plantConfig.is_other_2 ? 'auto' : 'not-allowed' }}
                                        name="water2"onChange={handleChangeFormulaDetails}  value={inputs.water2}  min="0" />
                                        <><strong className="form-group col-sm-4 text-center text-danger" >{plantConfig.is_other_2 ? plantConfig.other_2: null}</strong><br/></>
                                            
                                        <label htmlFor="other_3" className="form-group col-sm-3 text-right">Other 3 </label>
                                        <input type="number" step="any"  id="admx1" size="10" className="form-control col-sm-5 totalval admix" 
                                        disabled={!plantConfig.is_other_3} style={{ cursor: plantConfig.is_other_3 ? 'auto' : 'not-allowed' }}
                                        name="adm1" min="0"  max="5" onChange={handleChangeFormulaDetails}  value={inputs.adm1} />
                                        <><strong className="form-group col-sm-4 text-center text-danger" >{plantConfig.is_other_3 ? plantConfig.other_3: null}</strong><br/></>
                                        
                                        <label htmlFor="other_4" className="form-group col-sm-3 text-right">Other 4 </label>
                                        <input type="number" step="any"  id="admx2" size="10" name="adm2" className="form-control col-sm-5 totalval admix"
                                        disabled={!plantConfig.is_other_4} style={{ cursor: plantConfig.is_other_4 ? 'auto' : 'not-allowed' }}
                                        min="0"  max="5" onChange={handleChangeFormulaDetails}  value={inputs.adm2} />
                                        <><strong className="form-group col-sm-4 text-center text-danger" >{plantConfig.is_other_4 ? plantConfig.other_4: null}</strong><br/></>
                                        
                                        <label htmlFor="other_5" className="form-group col-sm-3 text-right">Other 5 </label>
                                        <input type="number" id="others" size="10"name="others" 
                                        disabled={!plantConfig.is_other_5} style={{ cursor: plantConfig.is_other_5 ? 'auto' : 'not-allowed' }}
                                        className="form-control  col-sm-5 totalval" onChange={handleChangeFormulaDetails}  min="0"  value={inputs.others}/>
                                        <><strong className="form-group col-sm-4 text-center text-danger" >{plantConfig.is_other_5 ? plantConfig.other_5: null}</strong><br/></>
                                    </div>
                                </Col>
                            </Row>   
                            <Row>
                                <Col xl={12} lg={12} md={12}> 
                                    <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue form-control-panel">
                                        <label htmlFor="total_cementitious" className="form-group col-sm-2 text-right">Total Cementitious </label>
                                        <input type="number" step="any" size="10"style={{cursor: "not-allowed",textAlign:"right"}} className="form-control col-sm-2 tt TC" value={inputs.total_cem} disabled={true} id="TC" name="total_cem" />
                                        
                                        <label htmlFor="density" className="form-group col-sm-2 text-right">Density </label>
                                        <input type="number" step="any" style={{textAlign:"right", cursor: "not-allowed"}} id="numberBox" size="10" name="density" disabled={true} value={inputs.density} min="2200" max="3000"  className="form-control col-sm-2  alltotal"  />
                                        
                                        <label htmlFor="water_cement_ratio" className="form-group col-sm-2 text-right">Water-Cement ratio </label>
                                        <input type="number" step="any" style={{textAlign:"right", cursor: "not-allowed"}}  id="TOTAL" size="10" className="form-control col-sm-2 TOTAL" disabled={true}  value={inputs.water_cem}  name="water_cem"  min="0.10" max="1" />
                                    </div>
                                </Col>
                            </Row>        
                        </div>  
                    </div>
                    <div className="footer text-center">
                        <Button  type="submit"  style={{width:"80px",fontWeight:"bold",backgroundColor:"RGB(58,29,238,0.8)"}} className="btn btn-twitter">Update</Button> &nbsp;&nbsp;               
                        <Button   type="button"style={{width:"80px",fontWeight:"bold",backgroundColor:"RGB(58,29,238,0.8)"}} className="btn btn-twitter"onClick={view}>View</Button>&nbsp;&nbsp;
                        <Button  type="button"style={{width:"80px",fontWeight:"bold",backgroundColor:"RGB(58,29,238,0.8)"}} className="btn btn-twitter"onClick={Back}>Home</Button>&nbsp;&nbsp;
                        <Button className="btn btn-delete"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={onDeleteDesignMix}>Delete</Button>
                    </div>
                </form>
            </div>
        </div>
    </>
    );
}
export default DesignMixEdit;
