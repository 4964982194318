import { useEffect, useState, useMemo } from "react";
import ReactTableWrapper from "../../components/ReactTableWrapper";
import { exportAsCSV } from "../../utils/CSVExporter";
import { exportAsPDF } from "../../utils/PDFExporter";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import Sidebar from '../../Sidebar';
import Swal from "sweetalert2";
import { useCookies } from 'react-cookie';
import { getAllWorkSchedules, deleteWorkSchedule, } from "../../services/WorkScheduleServices";
import { displayError } from "../../helpers";

function WorkScheduleTable() {
    const InitialData = {
        total_pages: 0,
        records: [],
      };
    const [data, setData] = useState(InitialData);
    const navigate = useNavigate(); 
    const [refreshKey, setRefreshKey] = useState(0);
    const [cookies] = useCookies(['myToken']);
    const [isLoading, setIsLoading] = useState(true); 

    const columns = useMemo(
        () => [
            {
                fieldName: 'plant', 
                headerName: 'Plant'
            },
            {
                fieldName: 'schedule_no', 
                headerName: 'Job No'
            },
           
            {
                fieldName: "schedule_date",
                headerName: 'Job Date'
            },
            {
                fieldName: "order_no",
                headerName: 'SalesOrder No'
            },
            {
                fieldName: "order_date",
                headerName: "SalesOrder Date"
            },
            {
                fieldName: "consignee",
                headerName: "Customer Site"
            },
            {
                fieldName: "order_quantity",
                headerName: "Order Qty"
            },
            {
                fieldName: "scheduled_qty",
                headerName: "Scheduled Qty"
            },
            {
                fieldName: "accepted_qty",
                headerName: "Accepted Qty"
            },
            {
                fieldName: "balance_qty",
                headerName: "Balanced Qty"
            },
            {
                fieldName: "delivery_mode",
                headerName: "Mode Of Delivery"
            },
            {
                fieldName: "status",
                headerName: "Status"
            },
            // {
            //     fieldName: "is_schedule_completed",
            //     headerName: "Is Schedule completed?"
            // },
            {
                fieldName: "id",
                headerName: "ROW_ACTIONS"
            }
       
        ],
        [],);

    const onEditWorkSchedule = (workscheduleId, event) => {
       
       navigate(`/WorkScheduleEdit/${workscheduleId}/`);
    };

    const onDeleteWorkSchedule = (workscheduleId, event) => {

        event.preventDefault();
        Swal.fire({title: 'Are you sure to Delete?',  
        showCancelButton: true,  
        confirmButtonText: `Yes`,  
        cancelButtonText: `No`,
        }).then((result) => {  
          if (result.isConfirmed) { 

            setIsLoading(true);
            deleteWorkSchedule(cookies,workscheduleId)
            .then(response => {
                console.log(`workschedule with id ${workscheduleId} deleted`);
                setRefreshKey(oldKey => oldKey +1);
                Swal.fire('Deleted Successfully!', '', 'success');
            })
            .catch(error => {
                setRefreshKey(oldKey => oldKey +1);
                console.log(error.response.data);
                displayError(error.response.data,'Delete Failed');
            }); 
            
            
          } else if (result.isDismissed) {    
            Swal.fire('Not Deleted', '', 'info')  
        }
        });
    };

    const handleCSVExport = (event) => {
        console.log('CSV Export');
        exportAsCSV(
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(c => c.headerName),
            data.records.map(ws => {

                const csvRow = {
                    plant:ws.plant,
                    schedule_no: ws.schedule_no,
                    schedule_date: ws.schedule_date,
                    scheduled_qty:ws.scheduled_qty,
                    order_quantity:ws.order_quantity,
                    accepted_qty:ws.accepted_qty,
                    balance_qty:ws.balance_qty,
                    delivery_mode:ws.delivery_mode.toUpperCase(),
                    order_no:ws.order_no,
                    order_date:ws.order_date,
                    consignee:ws.consignee,
                    status:ws.status,
                    
                }
                return csvRow;
                }));
    };

    const handlePDFExport = (event) => {
        console.log('PDF Export');
        exportAsPDF(
            'List of WorkShedule Form',
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(col=>({header: col.headerName, dataKey: col.fieldName })),
            data.records.map(ws => ({
                plant:ws.plant,
                schedule_no: ws.schedule_no,
                schedule_date: ws.schedule_date,
                scheduled_qty:ws.scheduled_qty,
                accepted_qty:ws.accepted_qty,
                order_quantity:ws.order_quantity,
                balance_qty:ws.balance_qty,
                delivery_mode:ws.delivery_mode.toUpperCase(),
                order_no:ws.order_no,
                order_date:ws.order_date,
                consignee:ws.consignee,
                status:ws.status,
                
            })),
            'WorkScheduleList.pdf'
        );
    };

    useEffect(() => {

        setIsLoading(true);
        getAllWorkSchedules(cookies)
        .then( workscheduleList => {
            const tableData = workscheduleList.work_schedule_list
            .map(ws => ({
                id :ws.id,
                plant:ws.plant.alias_name,
                schedule_no: ws.schedule_no,
                schedule_date: ws.schedule_date,
                scheduled_qty:ws.scheduled_qty,
                accepted_qty:ws.accepted_qty,
                order_quantity:ws.sales_order_detail.order_quantity,
                balance_qty:ws.balance_qty,
                delivery_mode:ws.delivery_mode.toUpperCase(),
                order_no:ws.sales_order_detail.order_no,
                order_date:ws.sales_order_detail.order_date,
                consignee:ws.sales_order_detail.consignee.name,
                status:ws.status,
                is_schedule_completed:ws.is_schedule_completed?'Yes':'No',
                hide_row_edit:ws.is_schedule_completed?'Yes':'No'
            }));

            setData({
                total: data.total,
                records: tableData
            });
            setIsLoading(false);
        });
        }, [refreshKey]);
    
    return (

        <div id="outer-container"  className="App" > 
            <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
            <div id="page-wrap">
            <h3 style={{color:"rgb(2, 2, 49)",textAlign:"center"}}>List of WorkSchedule Form</h3>
            <br/>
                <div className="container item-list-table-container">
                <div className="table-responsive">
                <ReactTableWrapper
                            title='List of WorkSchedule Form'
                            columns={columns}
                            data={data.records}
                            onRowEdit={onEditWorkSchedule}
                            onRowDelete={onDeleteWorkSchedule}
                            onCSVExport={handleCSVExport}
                            onPDFExport={handlePDFExport}
                            isLoading={isLoading}
                            hideDelete={true}
                        />
                </div>
                </div> 


            </div>
            <button style={{position:"relative",right:"40px"}}className="btn btn-fill btn-primary float-end" type="button" onClick={() =>{navigate("/WorkSchedule")}}>Back</button>
        </div>

        
    );

}



export default WorkScheduleTable;