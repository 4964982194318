import React from 'react';
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { Row, Col, Button } from "reactstrap";
import axios from "axios";
import Sidebar from '../../Sidebar';
import { useNavigate } from 'react-router';
import { BiSave } from "react-icons/bi";
import { IoIosEye } from "react-icons/io";
import { TiCancel } from "react-icons/ti";
import { AiOutlineHome } from "react-icons/ai";
import { useCookies } from 'react-cookie';
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import { moment } from 'moment';
import {displayErrorToast,displayError,parseBoolean,parseBooleanToString} from '../../helpers';
import ErrorToast from '../../ErrorToast';
import FloatingControls from '../../components/FloatingControls';
import LoadingOverlay from '../../components/LoadingOverlay';
import { updateCheckIn,getCheckInNumber,getCheckInDetails,deleteCheckIn } from '../../services/CheckInServices';
import { getAllPlants } from '../../services/PlantServices';
import{getAllDeliveryChallanForCheckIn} from '../../services/DeliveryChallanServices';
import { getCheckOutForCHeckIn } from '../../services/CheckOutServices';
import { getCustomerMainDetails } from '../../services/CustomerMainServices';
import { getCustomerSiteDetails } from '../../services/CustomerSiteServices';
import { getDeliveryChallanDetails } from '../../services/DeliveryChallanServices';
import { showBillingPop, showCustomerSitePop, showDeliveryChallanPop } from './CheckInUtils';
import SummaryIcon from '../../components/SummaryIcon';
import { getDisplayDate } from '../../utils/DateUtils';
import { getCurrentDate, getCurrentTime} from '../../utils/DateUtils';
import NumberSetting from '../../components/NumberSetting';
import { AccessAlarm } from '@mui/icons-material';
import ViewOnlyTextbox from '../../components/ViewOnlyTextbox';
import { useLocation, Link,useParams } from 'react-router-dom';

function CheckInEdit() {
  const [cookies] = useCookies(['myToken']);
    const [category] = useCookies(['myCategory']);
    const [superuser] = useCookies(['mySuperuser']);
    const [inputs, setInputs] = useState({});
    const [inProgress, setInProgress] = useState(false);
    const [plant, setPlant] = React.useState([]);
    const [plants,setPlants] =React.useState([]);
    const [deliverychallans,setDeliverychallans] =React.useState([]);
    const [checkouts,setCheckouts] =React.useState([]);
    const [isSubmitting, setIsSubmitting] = useState(true);
    const [error, setError] = useState({});
    const [allcheckout,setAllCheckOut]=React.useState([]);
    const [alldeliverychallan,setAllDeliveryChallan]=React.useState([]);
    const [deliverychallan,setDeliveryChallan]=React.useState([]);
    const[selectedcheckout,setSelectedDC] = useState({});
    const[Selectedsite,setSelectedSite]= useState({});
    const[Selectedcustomer,setSelectedCustomer]= useState({});
    const [isLoading, setIsLoading] = useState(true); 
    const [selected_checkout,setSelectedCheckout] = useState({});
    const[selectedWorkSchedule,setSelectedWorkSchedule] = useState({});
    const[balancestatus,setBalanceStatus]= useState([
      { value: "full", label: "Full" },
      { value: "returned", label: "Returned" },
      { value: "wastage", label: "Wastage" },
      { value: "transfer", label: "Transfer"},
      { value: "shortage", label: "Shortage"}]);
    const [refreshKey, setRefreshKey] = useState(0);
    const[dcstatus,setDcStatus]= useState([
      { value: "received_with_sign", label: "Received with Sign" },
      { value: "received_without_sign", label: "Received without Sign" },
      { value: "not_received", label: "Not Received" }
    ]);
    // React.useEffect(() => {
    //   setInputs(values => ({...values,
    //       ['checkin_date']: getCurrentDate(),
    //       ['checkin_time']: getCurrentTime(),
    //       ['weighment_slip_date']: getCurrentDate(),
    //       ['weighment_slip_time']: getCurrentTime(),
    //       ['checkout_date']: getCurrentDate()
    //   }))
    //     }, []);

// React.useEffect(() => {
//   getAllPlants(cookies)
//   .then (
//       plantList => {
//         console.log(JSON.stringify(plantList)+"plantList")
//           const plant = plantList.plant_list.filter(obj => obj.status).map(
//               plant => {
//                   return { value: plant.id, label: plant.plant_alias }
//               }
//           );
//           setPlants(plant);
//       }
//   )
// }, []);
      
      const navigate = useNavigate();

    const handleChange = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      if (name === 'tare_weight' || name === 'gross_weight') {
        var tare_weight = document.getElementById('tare_weight').value;
        var gross_weight = document.getElementById('gross_weight').value;     
        if((tare_weight === '') || (tare_weight === NaN) ){
            tare_weight = 0;
        }
        if((gross_weight === '') || (gross_weight === NaN) ){
            gross_weight = 0;
        }
        console.log(tare_weight+"tare_weight")
        console.log(gross_weight+"gross_weight")
        var net_weight = parseFloat(gross_weight) - parseFloat(tare_weight);
        console.log(net_weight+"net_weight")
        setInputs(values => ({...values, ['net_weight']: net_weight})) 
      }
      setInputs(values => ({...values, [name]:value}));
  }
  
  const handleChangeOpeningKmDetails = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if ( name === 'ending_km') {
         var opening_km = document.getElementById('opening_km').value;
         var ending_km = document.getElementById('ending_km').value;
         document.getElementById('ending_km').setAttribute('min',opening_km);      
         if((opening_km === '') || (opening_km === NaN) ){
          opening_km = 0;
         }
         if((ending_km === '') || (ending_km === NaN) ){
          ending_km = 0;
         }
         console.log(opening_km+"opening_km")
         console.log(ending_km+"ending_km")
        var total_km = parseFloat(ending_km) - parseFloat(opening_km);
        console.log(total_km+"total_km")
        setInputs(values => ({...values, ['total_km']: total_km})) 
    }
    setInputs(values => ({...values, [name]: event.target.value}))
  };
  
  const handleChangeOpeningHmDetails = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if ( name === 'ending_hm') {
         var opening_hm = document.getElementById('opening_hm').value;
        var ending_hm = document.getElementById('ending_hm').value;
        document.getElementById('ending_hm').setAttribute('min',opening_hm);     
         if((opening_hm === '') || (opening_hm === NaN) ){
          opening_hm = 0;
         }
         if((ending_hm === '') || (ending_hm === NaN) ){
          ending_hm = 0;
         }
         console.log(opening_hm+"opening_hm")
         console.log(ending_hm+"ending_hm")
        var total_hm = parseFloat(ending_hm) - parseFloat(opening_hm);
        console.log(total_hm+"total_hm")
        setInputs(values => ({...values, ['total_hm']: total_hm})) 
    }
    setInputs(values => ({...values, [name]: event.target.value}))
  };

  // Function to handle changes in the Customer's Status dropdown
  const handleChangeCustomerStatusDetails = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if(value === 'accepted'){
        var dc_qty = document.getElementById('dc_qty').value;
        var client_adjust_qty= inputs.delivery_challan.client_adjust_qty;
        console.log ("client_adjust_qty"+client_adjust_qty)
        var is_client_adj_qty_incld_inv= inputs.delivery_challan.is_client_adj_qty_incld_inv;
        console.log(is_client_adj_qty_incld_inv+"is_client_adj_qty_incld_inv");
        if(is_client_adj_qty_incld_inv)
        {
            var accepted_qty= dc_qty;
        }
        else
        {
          var accepted_qty= dc_qty - client_adjust_qty;
        }
        console.log(dc_qty+"dc_qty")
        console.log(accepted_qty+"accepted_qty")
        document.getElementById('accepted_qty').setAttribute('readOnly','readOnly');
        document.getElementById('accepted_qty').style.cursor = "not allowed";
        var balance_qty = parseFloat(dc_qty) - parseFloat(accepted_qty);
        console.log(balance_qty+"balance_qty")
        setInputs(values => ({...values, ['balance_qty']: balance_qty,['accepted_qty']:accepted_qty})) 
        if(balance_qty === 0){
          var balance_status_List= [{ value: "nill", label: "Nill" }]
          setInputs(values => ({...values, ['balance_status']:"nill"}))
          }
        else{
              var balance_status_List= [
                { value: "", label: "choose balance status" },
              { value: "full", label: "Full" },
              { value: "returned", label: "Returned" },
              { value: "wastage", label: "Wastage" },
              { value: "transfer", label: "Transfer"},
              { value: "shortage", label: "Shortage"}]
        }
        setBalanceStatus(balance_status_List)
        var dc_status_List= [
          { value: "", label: "choose customer dc status" },
        { value: "received_with_sign", label: "Received with Sign" },
        { value: "received_without_sign", label: "Received without Sign" },
        { value: "not_received", label: "Not Received" }
        ]
        setDcStatus(dc_status_List)
    }
       
   if(value === 'cancelled')
    {
      var dc_qty = document.getElementById('dc_qty').value;
      document.getElementById('accepted_qty').setAttribute('readOnly','readOnly');
      document.getElementById('accepted_qty').style.cursor = "not allowed";
      var balance_qty = parseFloat(dc_qty) - 0;
      setInputs(values => ({...values, ['balance_qty']: balance_qty,['accepted_qty']:0})) 
      
        var dc_status = ["Cancelled"];
        var balance_status_List= [
          { value: "", label: "choose balance status" },
            { value: "full", label: "Full" },
            { value: "returned", label: "Returned" },
            { value: "wastage", label: "Wastage" },
            { value: "transfer", label: "Transfer"},
            { value: "shortage", label: "Shortage"}]
        setBalanceStatus(balance_status_List);
        setDcStatus([
          { value: "cancelled", label: "Cancelled" }
         ])
         setInputs(values => ({...values, ['cus_dc_status']:"cancelled"}))
 }
 
    if(value === 'partial_accepted' ){
            var dc_qty = document.getElementById('dc_qty').value;
            var accepted_qty = document.getElementById('accepted_qty');
            document.getElementById('accepted_qty').removeAttribute('readOnly');
            document.getElementById('accepted_qty').style.cursor = "pointer";
            document.getElementById('accepted_qty').setAttribute('max',dc_qty);
            var balance_qty = parseFloat(dc_qty) - parseFloat(accepted_qty);
            console.log(balance_qty+"balance_qty")
            setInputs(values => ({...values, ['balance_qty']: balance_qty})) 
             var balance_status_List= [
              { value: "", label: "choose balance status" },
                { value: "full", label: "Full" },
                { value: "returned", label: "Returned" },
                { value: "wastage", label: "Wastage" },
                { value: "transfer", label: "Transfer"},
                { value: "shortage", label: "Shortage"}]
            setBalanceStatus(balance_status_List)
            var dc_status_List= [
              { value: "", label: "choose customer dc status" },
              { value: "received_with_sign", label: "Received with Sign" },
              { value: "received_without_sign", label: "Received without Sign" },
              { value: "not_received", label: "Not Received" }
             ]
            setDcStatus(dc_status_List)
            }
    setInputs(values => ({...values, [name]: event.target.value}))
  };

  const handleChangeAcceptedDetails = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values,[name]:event.target.value}))
      var dc_qty = document.getElementById('dc_qty').value;
      var accepted_qty = parseFloat(value)
      var balance_qty = parseFloat(dc_qty) - parseFloat(accepted_qty);
      console.log(balance_qty+"balance_qty")
      setInputs(values => ({...values, ['balance_qty']: balance_qty.toFixed(2)})) 
   }
   
  const showCustomerDetails = () => {
    if (inputs.id) {
        getCustomerMainDetails(cookies,inputs.delivery_challan.work_schedule.sales_order_detail.sales_order.consignee.customer.id)
        .then(selectedCustomer => {
            showBillingPop(selectedCustomer)
        })
        }
    }
    
    const showSiteDetails = () => {
      if (inputs.id) {
          getCustomerSiteDetails(cookies,inputs.delivery_challan.work_schedule.sales_order_detail.sales_order.consignee.id)
          .then(consignee => {
            showCustomerSitePop(consignee);
          })
        }
        }
    const showDeliveryChallanDetails = () => {
      if (inputs.id) {
          getDeliveryChallanDetails(cookies,inputs.delivery_challan.id)
          .then(selected_DC => {
            showDeliveryChallanPop(selected_DC);
          })
          }
      }

      const constructFormData = () => {
        const EMPTY_STRING = '';
        return {
          id:inputs.id,
          weighment_slip_no:inputs.weighment_slip_no,
          weighment_slip_date:getDisplayDate(inputs.weighment_slip_date),
          weighment_slip_time:inputs.weighment_slip_time,
          gross_weight:inputs.gross_weight?parseInt(inputs.gross_weight):0,
          tare_weight:inputs.tare_weight?parseInt(inputs.tare_weight):0,
          net_weight:inputs.net_weight?parseInt(inputs.net_weight):0,
          ending_km:inputs.ending_km?parseInt(inputs.ending_km):0,
          total_km:inputs.total_km?parseInt(inputs.total_km):0,
          ending_hm:inputs.ending_hm?parseInt(inputs.ending_hm):0,
          total_hm:inputs.total_hm?parseInt(inputs.total_hm):0,
          customer_status:inputs.customer_status,
          accepted_qty:parseFloat(inputs.accepted_qty),
          balance_qty:parseFloat(inputs.balance_qty),
          balance_status:inputs.balance_status,
          cus_dc_status:inputs.cus_dc_status,
          }
      } 
        
    const handleSubmit = (event) => {
      event.preventDefault();
     
      if ((Object.values(error).every(x => !x)) && isSubmitting) { 
          Swal.fire({title: (inputs.customer_status === 'cancelled'? 'This Will be Deleted if this Cancelled DC is last?. ' : '') + 'Do you want to Update?', 
            showCancelButton: true,  
            confirmButtonText: `Yes`,  
            cancelButtonText: `No`,
            }).then((result) => {  
              if (result.isConfirmed) 
              { 
                updateCheckIn(cookies, constructFormData(),id)
                .then(function (response) {
                  Swal.fire('Updated!', '', 'success') 
                  view();
                })
                .catch((error) =>
                {
                  console.log(error.response.data);
                  displayError(error.response.data,"Update Failed");
                })
              } 
              else if (result.isDismissed) 
              {    
                Swal.fire('Not Updated', '', 'info')  
              }
            });
      }
      else
      {
        Swal.fire('Please check * marked fields are filled and details given are valid...', '', 'info')   
      }
  }
  const {id}=useParams()
  
  React.useEffect(() => {
    if (id) {
      setInProgress(true);
      getCheckInDetails(cookies, id)
        .then(response => {
          setInProgress(false);
          setInputs(response)
          setInputs(values => ({...values,['weighment_slip_date']:getDisplayDate(response.weighment_slip_date),
          ['checkin_date']: getDisplayDate(response.checkin_date),
          ['checkout_date']: getDisplayDate(response.checkout_date),
          ['plant_alias']:response.delivery_challan.work_schedule.plant.alias_name}));
          if(response.customer_status === 'accepted'){
            if(response.balance_qty === 0){
              var balance_status_List= [{ value: "nill", label: "Nill" }]
              setBalanceStatus(balance_status_List)
              document.getElementById('accepted_qty').setAttribute('readOnly','readOnly');
              document.getElementById('accepted_qty').style.cursor = "not allowed";
               }
          }
          else if(response.customer_status === 'cancelled'){
            document.getElementById('accepted_qty').setAttribute('readOnly','readOnly');
            document.getElementById('accepted_qty').style.cursor = "not allowed";
          setDcStatus([
            { value: "cancelled", label: "Cancelled" }
           ])
          }
          else if(response.customer_status === 'partial_accepted' ){
            document.getElementById('accepted_qty').removeAttribute('readOnly');
            document.getElementById('accepted_qty').style.cursor = "pointer";
            document.getElementById('accepted_qty').setAttribute('max',response.delivery_challan.dc_qty);
          }
            
            getCustomerMainDetails(cookies,response.delivery_challan.work_schedule.sales_order_detail.sales_order.consignee.customer.id)    
            .then(customerObject => {              //Set the details of the customer in the form fields
                setSelectedCustomer(customerObject)
                })
  
            getCustomerSiteDetails(cookies,response.delivery_challan.work_schedule.sales_order_detail.sales_order.consignee.id)
                  .then(customerObject => {                     //Set the details of the customer in the form fields
                      setSelectedSite(customerObject);
                  });
            if (response.show_msg){
              Swal.fire(response.remarks, '', 'info') 
            }
        })
        .catch(error => {
          setInProgress(false);
         displayError(error.response.data, "Loading Failed");
        });
      }
    }, []);

const Reload = () => {
window.location.reload();
}      
const Cancel = () => {
setInputs(() => "")
}

const view = () => {
  navigate('/CheckInTable')
} 

const Back = () => {
  navigate('/Home')
}

return (
        <>
 <div id="outer-container" className="App" >
 <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
 <ErrorToast/>
 <LoadingOverlay inProgress={inProgress}/>
 <div id="page-wrap">
 <form onSubmit={handleSubmit} >
 <div id="header">
              <h3 className = "text font-weight-bold page-title" >VEHICLE CHECKIN EDIT FORM </h3>
            </div>
            <FloatingControls tableLink="/CheckInTable" enableCancel={false}/>

            <div className="container p-2 ">
   
            </div>
            <div className="container">
            <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3  form-control-panel">
                    <h5 className='col-sm-11'><b>DeliveryChallan Details</b></h5><br/><br/>
                    
            <Row> 
                    <Col xl={6} lg={6} md={6}> 
                    <div className="form-row p-2 my-2 border-secondary p-2 mb-3 form-control-panel">   
           {/* <label htmlFor="plant_id" className="form-group col-sm-5 text-right"> Plant Location </label>
               <select id="plant_id"  required onChange={handleChangePlant}name="plant_id" className="form-control col-sm-6  browser-default custom-select  mandatory-form-control"   value={inputs.plant_id || ""}>
               <option value="">Select Plant</option>
                           {plants.map((item) => (
                       <option key={item.value} value={item.value}>
                           {item.label}</option>
                   ))}
               </select> */}
               <ViewOnlyTextbox 
                name="plant_alias" 
                value={inputs.plant_alias|| ""}
                labelClassName="form-group col-sm-5 text-right"
                label="Plant Location"
                inputClassName="form-control col-sm-6"
                />
               <br></br> 
               {/* <label htmlFor="checkout_no" className="form-group col-sm-5 text-right">Check Out No.</label>
              <select id="checkout_no" required onChange={handleChangeCheckOutDetails} name="checkout_no"  className="form-control col-sm-6  browser-default custom-select  mandatory-form-control" value={inputs.checkout_no || ""}>
                 <option value="">Select Checkout No</option>
                 {checkouts.map((item) => (
                         <option key={item.value} value={item.value}>
                         {(item.label)}
                         </option>
                     ))}
               </select>  */}
               <ViewOnlyTextbox 
                name="checkout_no" 
                value={inputs.checkout_no|| ""}
                labelClassName="form-group col-sm-5 text-right"
                label="Check Out No."
                inputClassName="form-control col-sm-6"
                />
               {/* <ViewOnlyTextbox 
                name="checkout_date" 
                value={inputs.checkout_date|| ""}
                labelClassName="form-group col-sm-5 text-right"
                label="Check Out Date/Time"
                inputClassName="form-control col-sm-3"
                />  
                 <ViewOnlyTextbox 
                name="checkout_date" 
                value={inputs.checkout_time|| ""}
                labelClassName="form-group col-sm-5 text-right"
                inputClassName="form-control col-sm-3"
                />   */}
                <label htmlFor="checkout_date" className="form-group col-sm-5 text-right">Check Out Date/Time</label>
                <input type="date" className="form-control col-sm-3 text-right" name="checkout_date" value={inputs.checkout_date || ""} id="checkout_date" readOnly={true} style={{cursor: "not-allowed"}} />
                <input type="time" id="checkout_time"  name="checkout_time"  value={inputs.checkout_time || ""} className="form-control col-sm-3" readOnly={true} style={{cursor: "not-allowed"}}/> 

                <ViewOnlyTextbox 
                name="vehicle_no" 
                value={inputs.delivery_challan?.vehicle?.name || ""}
                labelClassName="form-group col-sm-5 text-right"
                label="Vehicle No"
                inputClassName="form-control col-sm-6"
                />  
               
                <br></br>
                <ViewOnlyTextbox 
                name="delivery_challan_no" 
                value={(inputs.id ? '('+inputs?.delivery_challan?.prefix +')'+inputs?.delivery_challan?.delivery_challan_no : "")}
                labelClassName="form-group col-sm-5 text-right"
                label="DC No"
                inputClassName="form-control col-sm-6"
                showSummaryDetails={showDeliveryChallanDetails}
                />    
              
             <label htmlFor="wsSlDt" className="form-group col-sm-5 text-right">Check In Date/Time</label>
              <input type="date" className="form-control col-sm-3" name="checkin_date" value={inputs.checkin_date || ""} id="checkin_date" readOnly={true} style={{ cursor: "not-allowed"}} />
              <input type="time" id="checkin_time" required name="checkin_time"  value={inputs.checkin_time || ""}  readOnly={true}  style={{ cursor: "not-allowed"}} className="form-control col-sm-3"/>
          <br></br>
                           </div>
               </Col>

           <Col xl={6} lg={6} md={6}> 
           <div className="form-row p-2 my-2 border-secondary p-2 mb-3 form-control-panel"> 
           <ViewOnlyTextbox 
                name="company" 
                value={inputs?.delivery_challan?.work_schedule?.sales_order_detail?.sales_order?.company?.name || ""}
                labelClassName="form-group col-sm-5 text-right"
                label="Company"
                inputClassName="form-control col-sm-6"
                />
          
               <ViewOnlyTextbox 
                name="billing_name" 
                value={inputs?.delivery_challan?.work_schedule?.sales_order_detail?.sales_order?.consignee?.customer?.name || ""}
                labelClassName="form-group col-sm-5 text-right"
                label="Billing Name"
                inputClassName="form-control col-sm-6"
                showSummaryDetails={showCustomerDetails}
                />    
             
               <ViewOnlyTextbox 
                name="ship_to" 
                value={inputs?.delivery_challan?.work_schedule?.sales_order_detail?.sales_order?.consignee?.name || ""}
                labelClassName="form-group col-sm-5 text-right"
                label="Ship To"
                inputClassName="form-control col-sm-6"
                showSummaryDetails={showSiteDetails}
                />  
               
                <ViewOnlyTextbox 
                name="project_name" 
                value={inputs?.delivery_challan?.work_schedule?.sales_order_detail?.sales_order?.consignee?.project_name || ""}
                labelClassName="form-group col-sm-5 text-right"
                label="Project/Site Name"
                inputClassName="form-control col-sm-6"
                /> 

               <ViewOnlyTextbox 
                name="grade" 
                value={inputs?.delivery_challan?.work_schedule?.sales_order_detail.product.name || ""}
                labelClassName="form-group col-sm-5 text-right"
                label="Grade"
                inputClassName="form-control col-sm-6"
                />  
             
                <ViewOnlyTextbox 
                name="dc_qty" 
                value={inputs?.delivery_challan?.dc_qty || ""}
                labelClassName="form-group col-sm-5 text-right"
                label="DC Qty"
                inputClassName="form-control col-sm-6"
                />  
               </div>
           </Col>
           </Row>
</div>

<Row>
<Col xl={6} lg={6} md={8}>
<div className="form-row table-bordered shadow p-2 my-2  border-secondary p-2 mb-3 form-control-panel  " >
<h5 className='col-sm-11'><b>Weightment Details</b></h5><br/><br/>
{/* <ViewOnlyTextbox 
                name="weighment_slip_no" 
                value={inputs?.weighment_slip_no|| ""}
                labelClassName="form-group col-sm-4 text-right"
                label="Weightment Slip No."
                inputClassName="form-control col-sm-7"
                />   */}
<label htmlFor="weighment_slip_no" className="form-group col-sm-4 text-right">Weightment Slip No.</label>
<input type="text" id="weighment_slip_no"name="weighment_slip_no" onChange={handleChange} value={inputs.weighment_slip_no || ""} className="form-control col-sm-7"/> 
{/* <ViewOnlyTextbox 
                name="weighment_slip_date" 
                value={inputs?.weighment_slip_date|| ""}
                labelClassName="form-group col-sm-4 text-right"
                label="Weightment Date/Time"
                inputClassName="form-control col-sm-3"
                />  
                 <ViewOnlyTextbox 
                name="weighment_slip_time" 
                value={inputs?.weighment_slip_time||""}
                labelClassName="form-group col-sm-4 text-right"
                inputClassName="form-control col-sm-4"
                />   */}
<label htmlFor="weighment_slip_date" className="form-group col-sm-4 text-right">Weightment Date/Time</label>
<input type="date" className="form-control col-sm-3"name="weighment_slip_date" onChange={handleChange} value={inputs.weighment_slip_date || ""} id="weighment_slip_date"  />
<input type="time" id="weighment_slip_time"required name="weighment_slip_time" onChange={handleChange} value={inputs.weighment_slip_time || ""}  className="form-control col-sm-4"/> 

  {/* <ViewOnlyTextbox 
                  name="gross_weight" 
                  value={selected_checkout?.gross_weight|| ""}
                  labelClassName="form-group col-sm-4 text-right"
                  label="Gross Weight"
                  inputClassName="form-control col-sm-7"
                  />  
<ViewOnlyTextbox 
                  name="tare_weight" 
                  value={selected_checkout?.tare_weight|| ""}
                  labelClassName="form-group col-sm-4 text-right"
                  label="Tare Weight"
                  inputClassName="form-control col-sm-7"
                  />  
<ViewOnlyTextbox 
                  name="net_weight" 
                  value={selected_checkout?.net_weight|| ""}
                  labelClassName="form-group col-sm-4 text-right"
                  label="Net Weight"
                  inputClassName="form-control col-sm-7"
                  />   */}
  <label htmlFor="gross_weight" className="form-group col-sm-4 text-right">Gross Weight</label>
  <input type="number" id="gross_weight" name="gross_weight" onChange={handleChange} value={inputs.gross_weight || 0} className="form-control col-sm-7"/>

  <label htmlFor="tare_weight" className="form-group col-sm-4 text-right">Tare Weight</label>
  <input type="number"  name="tare_weight" id="tare_weight" onChange={handleChange} value={inputs.tare_weight || 0} className="form-control col-sm-7"/>

  <label htmlFor="net_weight" className="form-group col-sm-4 text-right">Net Weight</label>
  <input type="number" id="net_weight" name="net_weight" readOnly={true} style={{cursor: "not-allowed"}}  value={inputs.net_weight || 0}className="form-control col-sm-7"/>

  <label htmlFor="editing_dtls" className="form-group col-sm-4 text-right">Editing Dtls</label>
 <textarea type="text" className="form-control col-sm-7" id="editing_dtls" readOnly={true}  value={inputs.editing_dtls||""} name="editing_dtls" style={{ cursor: "not-allowed" }} />
  
</div>
</Col>
<Col xl={6} lg={12} md={12}>
   <Row>
   <Col xl={6} lg={3} md={3}>
   <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 form-control-panel " >
   {/* <ViewOnlyTextbox 
                  name="opening_km" 
                  value={selected_checkout?.opening_km||""}
                  labelClassName="form-group col-sm-5 text-right"
                  label="Opening KM"
                  inputClassName="form-control col-sm-6"
                  />   */}
              <label htmlFor="opening_km" className="form-group col-sm-5 text-right">Opening KM</label>
             <input type="number" className="form-control col-sm-6" id="opening_km" name="opening_km" value={inputs?.opening_km || 0} readOnly={true} style={{ cursor: "not-allowed" }} /><br />
            
             <label htmlFor="ending_km" className="form-group col-sm-5 text-right">Ending KM</label>
             <input type="number" className="form-control col-sm-6" id="ending_km" name="ending_km" onChange={handleChangeOpeningKmDetails} value={inputs.ending_km || 0} /><br />
             <label htmlFor="total_km" className="form-group col-sm-5 text-right">Total KM</label>
             <input type="number" className="form-control col-sm-6"  readOnly={true} id="total_km" name="total_km"value={inputs.total_km || 0} style={{ cursor: "not-allowed" }} /><br />
           </div>
   </Col>
   <Col xl={6} lg={3} md={3}>
           <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 form-control-panel " >
           {/* <ViewOnlyTextbox 
                  name="opening_hm" 
                  value={selected_checkout?.opening_hm|| ""}
                  labelClassName="form-group col-sm-5 text-right"
                  label="Opening HM"
                  inputClassName="form-control col-sm-6"
                  />   */}
            <label htmlFor="opening_hm" className="form-group col-sm-5 text-right">Opening HM</label>
            <input  type="number" id="opening_hm" name="opening_hm" className="form-control col-sm-6 "  value={inputs?.opening_hm || 0} readOnly={true} style={{cursor:"not-allowed"}}/>
                 
             <label htmlFor="ending_hm" className="form-group col-sm-5 text-right">Ending HM</label>
             <input type="number" className="form-control col-sm-6" name="ending_hm" id="ending_hm" onChange={handleChangeOpeningHmDetails} value={inputs.ending_hm || 0} /><br />
             <label htmlFor="total_hm" className="form-group col-sm-5 text-right">Total HM</label>
             <input type="number" className="form-control col-sm-6" id="total_hm" name="total_hm"  readOnly={true} value={inputs.total_hm || 0} style={{ cursor: "not-allowed" }} /><br />
             </div>
   </Col>
   </Row>
   <Row>
  <Col xl={12} lg={6} md={6}>
    <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 form-control-panel " >
      <h5 className='col-sm-11'><b>CheckIn Status Details</b></h5><br/><br/>
      <label htmlFor="customer_status" className="form-group col-sm-4 text-right">Customer's Status</label>
      <select id="customer_status" name="customer_status" className="form-control col-sm-7  browser-default custom-select mandatory-form-control" required onChange={handleChangeCustomerStatusDetails} value={inputs.customer_status || ""}>
        <option value="">Select</option>
              <option value="accepted">Accepted</option>
              <option value="partial_accepted">Partial Accepted</option>
              <option value="cancelled">Cancelled</option>
      </select><br />

      <label htmlFor="accepted_qty" className="form-group col-sm-4 text-right">Accepted Qty</label>
      <input type="number" step="any" className="form-control col-sm-7 mandatory-form-control" id="accepted_qty" name="accepted_qty" onChange={handleChangeAcceptedDetails} value={inputs.accepted_qty || 0 } readOnly={inputs.mode === "accepted"} style={{ cursor: "not-allowed" }} /><br />
             
      <label htmlFor="balance_qty" className="form-group col-sm-4 text-right">Balance Qty</label>
      <input type="number" className="form-control col-sm-7" id="balance_qty" name="balance_qty" value={inputs.balance_qty || 0 }readOnly={true} style={{ cursor: "not-allowed" }} /><br />

      <label htmlFor="balance_status" className="form-group col-sm-4 text-right">Balance Status</label>
      <select id="balance_status" name="balance_status" className="form-control col-sm-7  browser-default custom-select mandatory-form-control" required onChange={handleChange} value={inputs.balance_status || ""}>
            {balancestatus.map((item) => (
                    <option key={item.value} value={item.value}>
                    {(item.label)}
                    </option>
                ))}
      </select><br />

      <label htmlFor="cus_dc_status" className="form-group col-sm-4 text-right">DC Status</label>
      <select id="cus_dc_status" name="cus_dc_status" className="form-control col-sm-7  browser-default custom-select mandatory-form-control" required onChange={handleChange}  value={inputs.cus_dc_status || ""}>
        {dcstatus.map((item) => (
                  <option key={item.value} value={item.value}>
                  {(item.label)}
                  </option>
              ))}  
      </select><br/>
           </div>
  </Col>
  </Row>
  </Col>
        </Row>
    </div>

    <div className="footer text-center">
      <Button type="submit" className="btn btn-twitter" style={{width:"80px",fontWeight:"bold"}} >Update</Button> &nbsp;&nbsp;
      <Button className="btn btn-twitter"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={view}>View</Button>&nbsp;&nbsp;
      <Button className="btn btn-twitter"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={Back}>Home</Button>
    </div>
  </form>
</div>
</div>
</>
);
}
export default CheckInEdit;