import {getAllUsers} from '../../services/UserServices'

export function checkForDuplicateUsers(userName, userEmail, cookies, existingId) {
    
    
    let nameCheck = new Promise((resolve, reject) => {
      getAllUsers(cookies)
      .then((data) => {

        let isUserExists = false; 
        data.user_list.forEach(user => {
          
          if(
            (user.username.toUpperCase() === userName.trim().toUpperCase()
            || user.email.toUpperCase() === userEmail.trim().toUpperCase)
            && parseInt(existingId) !== user.id){   
            
            console.log(`User exists with the name/email - ${userName}/${userEmail}`) 
            isUserExists = true;
          }
        });
        
        resolve(isUserExists);
      });  
    });

    return nameCheck;
  }

  export function isUserNameValid(userName) {

    let isValid = true;

    if(!/^[a-zA-Z0-9_.+\@]+$/.test(userName.trim())) {
      isValid = false;
    }
    return isValid;
  }

  export function isPasswordValid(value) {

    let capsCount, smallCount, numberCount, symbolCount;
    capsCount = (value.match(/[A-Z]/g) || []).length
    smallCount = (value.match(/[a-z]/g) || []).length
    numberCount = (value.match(/[0-9]/g) || []).length
    symbolCount = (value.match(/\W/g) || []).length
    if (value.length < 4) 
    {
      return "Password must be minimum 4 characters include one UPPERCASE, lowercase, number and special character: @$! % * ? &";
    } else if (capsCount < 1) {
      return "Must contain one UPPERCASE letter";
    } else if (smallCount < 1) {
      return "Must contain one lowercase letter";
    } else if (numberCount < 1) {
      return "Must contain one number";
    } else if (symbolCount < 1) {
      return "Must contain one special character: @$! % * ? &";
    }

    return '';
  }