
import { getApiBaseUrl, getApiClient } from "./serviceconfig";

var apiBaseUrl = getApiBaseUrl();
// var apiBaseUrl ="http://localhost:8001";
export async function getAllCustomerMain(cookies,searchString) {

  let url= `${apiBaseUrl}/customers/`;
  if (searchString) {
    url = url + `?name=${searchString}`;
  }
    return getApiClient(cookies).get(url)
      .then(response => response.data);
}

export async function createCustomerMain(cookies, inputs) {

  return getApiClient(cookies).post(`${apiBaseUrl}/customers/`,inputs)
  
}

export async function updateCustomerMain(cookies, inputs, customerId) {

    return getApiClient(cookies)
    .put(`${apiBaseUrl}/customers/${customerId}/`,inputs)
}
  


export async function getCustomerMainDetails(cookies, customerId) {

  return getApiClient(cookies).get(`${apiBaseUrl}/customers/${customerId}/`)
  .then(response => response.data)
}

export async function deleteCustomerMain(cookies, customerId) {

  return getApiClient(cookies).delete(
    `${apiBaseUrl}/customers/${customerId}/`)
}