import {getAllSalesRepresentatives} from '../../services/SalesRepresentativeServices'

export function checkForDuplicateSalesReps(salesrepName, cookies, existingId) {
    
    
    let nameCheck = new Promise((resolve, reject) => {
      getAllSalesRepresentatives(cookies,salesrepName)
      .then((data) => {

        let isUserExists = false;
        data.salesrep_data.forEach(salesrep =>    //Same Sales Representative Name mentioned it denoted already exist.
        {
          if((salesrep.entity_name) === salesrepName.trim().toUpperCase()
            && parseInt(existingId) !== salesrep.id){   
            
            console.log(`User exists with the name - ${salesrepName}`) 
            isUserExists = true;
            
          }
        });
        resolve(isUserExists);
      });  
    });

    return nameCheck;
  }
