function TableRowsCubeTestReport({rowsData,handleChange4}) {


    return(
        
        rowsData.map((data, index)=>{
            const {id,soDSlNo,
                soDCompCode,soDSqlNo,soDProdCode,soDIsMixAdd,prodname,
                prodcode,cDmxCem1,cDmxCem2,cDmxCem3,cDmxCem4,cDmxAgg1,cDmxAgg2,
                cDmxAgg3,cDmxAgg4,cDmxAgg5,cDmxWat1,cDmxWat2,cDmxAdm1,cDmxAdm2,
                cDmxOth,cDmDensity,cDmxTotal,totalcem,watercem}=data
        return(

            <tr key={index}>
            <td style={{display:"none"}}>
                <input type="text"  style={{width:"80px",backgroundColor:"white", cursor: "not-allowed"}}readOnly={true} size="10" id="id" value={id} className="form-control"  name="id"/>
            </td>
            <td>
                <input type="text"  style={{width:"80px",backgroundColor:"white", cursor: "not-allowed"}}readOnly={true} size="10" id="CDmxSoSqlNo" value={soDSqlNo} className="form-control"  name="CDmxSoSqlNo"/>
            </td>
            <td>
                <input type="text" style={{width:"80px",backgroundColor:"white", cursor: "not-allowed"}}  readOnly={true}id="prodcode" value={prodname} className="form-control"  size="10" name="cDmpdcd"/>
            </td>         
            <td>
                <input type="number" step="any" style={{width:"80px"}} className="form-control totalval val1 wat1" id="testa" name="cDmxCem1"onChange={(evnt)=>(handleChange4(index, evnt))}  value={cDmxCem1} />
            </td>
            <td>
                <input type="number" step="any" style={{width:"80px"}} className="form-control totalval val1 wat1"  id="testb" name="cDmxCem2"onChange={(evnt)=>(handleChange4(index, evnt))} value={cDmxCem2} />
            </td>
            <td>
                <input type="number" step="any" style={{width:"80px"}} size="10"  className="form-control totalval val1 wat1" id="testc" name="cDmxCem3" onChange={(evnt)=>(handleChange4(index, evnt))}  value={cDmxCem3}/>
            </td>
            <td>
                <input type="number" step="any" size="10"style={{width:"80px"}} className="form-control totalval  val1 wat1" id="test4"  name="cDmxCem4" onChange={(evnt)=>(handleChange4(index, evnt))}  value={cDmxCem4}/>
            </td>
            <td>
                <input type="number" step="any" size="10"style={{width:"80px",backgroundColor:"white", cursor: "not-allowed"}} className="form-control tt TC" value={totalcem} readOnly={true} id="TC" name="totalcem" />
            </td>
            <td>
                <input type="number" step="any"  id="" size="10" style={{width:"80px"}}className="form-control  totalval"   name="cDmxAgg1" onChange={(evnt)=>(handleChange4(index, evnt))}  value={cDmxAgg1}/>
            </td>
            <td>
                <input type="number" step="any" id="" size="10"style={{width:"80px"}} className="form-control totalval"name="cDmxAgg2" onChange={(evnt)=>(handleChange4(index, evnt))}  value={cDmxAgg2}/>
            </td>
            <td>
                <input type="number" step="any" id=""size="10"style={{width:"80px"}} className="form-control totalval" name="cDmxAgg3"onChange={(evnt)=>(handleChange4(index, evnt))}  value={cDmxAgg3} />
            </td>
            <td>
                <input type="number" step="any" id="" size="10"style={{width:"80px"}} className="form-control totalval" name="cDmxAgg4" onChange={(evnt)=>(handleChange4(index, evnt))}  value={cDmxAgg4}/>
            </td>
            <td>
                <input type="number" step="any" id="" size="10"style={{width:"80px"}} className="form-control totalval" name="cDmxAgg5"onChange={(evnt)=>(handleChange4(index, evnt))}  value={cDmxAgg5}/>
            </td>
            <td>
                <input type="number" step="any" id="firstNumber" size="10"style={{width:"80px"}} className="form-control totalval waterval wat1" name="cDmxWat1" onChange={(evnt)=>(handleChange4(index, evnt))}  value={cDmxWat1}/>
            </td>
            <td>
                <input type="number" step="any" id="secondNumber" size="10"style={{width:"80px"}}  className="form-control totalval waterval wat1" name="cDmxWat2"onChange={(evnt)=>(handleChange4(index, evnt))}  value={cDmxWat2} />
            </td>
            <td>
                <input type="number" step="any"  id="admx1" size="10"style={{width:"80px"}} className="form-control totalval admix" name="cDmxAdm1" min="0" max="10"onChange={(evnt)=>(handleChange4(index, evnt))}  value={cDmxAdm1} />
            </td>
            <td>
                <input type="number" step="any" style={{width:"80px"}} id="admx2" size="10"name="cDmxAdm2" className="form-control totalval admix" min="0" max="10" onChange={(evnt)=>(handleChange4(index, evnt))}  value={cDmxAdm2}/>
            </td>
            <td>
                <input type="number" step="any" style={{width:"80px"}}  id="others"size="10"name="cDmxOth" className="form-control  totalval" onChange={(evnt)=>(handleChange4(index, evnt))}  value={cDmxOth}/>
            </td>
            <td>
                <input type="number" step="any" style={{width:"80px",backgroundColor:"white", cursor: "not-allowed"}} id="numberBox" size="10" name="cDmxTotal" readOnly={true}value={cDmxTotal} min="2200" max="2600"  className="form-control alltotal"  />
            </td>
            <td>
                <input type="number" step="any" style={{width:"80px",backgroundColor:"white", cursor: "not-allowed"}}  id="TOTAL" size="10" className="form-control TOTAL " readOnly={true}  value={watercem}  name="watercem"  min="0.50" max="0.60" />
            </td>
        </tr>
        )
        })
   
    )
    
}

export default TableRowsCubeTestReport;