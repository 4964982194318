import { useEffect, useState, useMemo } from "react";
import ReactTableWrapper from "../../components/ReactTableWrapper";
import { exportAsCSV } from "../../utils/CSVExporter";
import { exportAsPDF } from "../../utils/PDFExporter";
import { getAllCustomerMain} from '../../services/CustomerMainServices';
import { useNavigate } from "react-router-dom";
import "../../App.css";
import Sidebar from '../../Sidebar';
import Swal from "sweetalert2";
import React from 'react';
import { default as ReactSelect } from "react-select";
import { useCookies } from 'react-cookie';
import { Link } from "react-router-dom";
import {Row,Col,Button,} from "reactstrap";
import { components } from "react-select";
import { getLedgerAccountReport} from "../../services/ReportServices";
import {  DatePicker } from 'antd';
import moment  from 'moment';

function LedgerAccountReportTable() {
    const InitialData = {
        total_pages: 0,
        records: [],
      };
    const [data, setData] = useState(InitialData);
    const navigate = useNavigate(); 
    const [refreshKey, setRefreshKey] = useState(0);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [customers, setCustomers] = useState([]);
    const [cookies] = useCookies(['myToken']);
    const [isLoading, setIsLoading] = useState(true); 
    const {RangePicker} = DatePicker;
    const currentMonthStartDate = moment().startOf('month');
    const currentDate = moment();
  
    const [dates, setDates] = useState([currentMonthStartDate, currentDate]);
  



    const Option = (props) => {
        return (
            <>
                <components.Option {...props}>
                    <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}/>{" "}
                <label>{props.label}</label>
                </components.Option>
            </>
        );
    };
    React.useEffect(() => {
        getAllCustomerMain(cookies)
        .then (
            customerList => {
                
                const customers = customerList.customer_list.filter(obj => obj.status).map(
                    customer => {
                        return { value: customer.id, label: customer.name }
                    }
                );
                setCustomers(customers);
            }
        )
    }, []);
    const onCustomerSelect = (selected) => {
        setSelectedCustomer(selected);
    };
    const columns = useMemo(
        () => [
            {
                fieldName: 'entity_name', 
                headerName: 'Voucher Date'
            },
           
            {
                fieldName: "entity_name",
                headerName: 'Voucher Type'
            },
            {
                fieldName: "entity_type",
                headerName: 'Voucher Date'
            },
            {
                fieldName: "entity_type",
                headerName: 'Voucher Number'
            },
          
          
          
            {
                fieldName: "entity_type",
                headerName: 'Grade'
            },
            {
                fieldName: "entity_type",
                headerName: 'Site/Receipt Name'
            },
            {
                fieldName: "entity_type",
                headerName: 'Mode'
            },
            {
                fieldName: "entity_type",
                headerName: 'Quantity'
            },
       
       
       
           
          
        ],
        [],);

   
    const handleCSVExport = (event) => {
        console.log('CSV Export');
        exportAsCSV(
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(c => c.headerName),
            data.records.map(r => {

                const csvRow = {
                    entity_name: r.entity_name,
                    address: r.address,
                    phone_number:r.phone_number,
                    mobile_number:r.mobile_number,
                    status:r.status
                }
                return csvRow;
                }));
    };

    const handlePDFExport = (event) => {
        console.log('PDF Export');
        exportAsPDF(
            'List of Ledger Account',
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(col=>({header: col.headerName, dataKey: col.fieldName })),
            data.records.map(r => ({
                entity_name: r.entity_name,
                address: r.address, 
                phone_number:r.phone_number,
                mobile_number:r.mobile_number,
                status:r.status
            })),
            'Ledger_Account.pdf'
        );
    };

    useEffect(() => {

        getLedgerAccountReport(cookies)
        .then( ledgeraccountreportList => {
            const tableData = ledgeraccountreportList.query_set
            .map(r => ({
                id :r.id,
                entity_name: r.entity_name,
                parent_id: r.parent_id,
                entity_type:r.entity_type,
            }));

            setData({
                total: data.total,
                records: tableData
            });
            setIsLoading(false);
        });
        }, [refreshKey]);
    
    return (

        <div id="outer-container"  className="App" > 
            
            <div id="page-wrap">
            <h3 style={{color:"rgb(2, 2, 49)",textAlign:"center"}}>Ledger Account Report</h3>
            <br/>
            <form className="form" onSubmit={""}>
        <div className="container">
        <Row>
          <Col xl={1} lg={12} md={12}>
            </Col>
          <Col xl={10} lg={11} md={11}>
          <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3 form-control-panel ">
                      
          &nbsp;&nbsp;&nbsp;   &nbsp;&nbsp;&nbsp; 
          <label htmlFor="Checkbox"className="form-group col-sm-0 text-right" > </label>
                                 
                                 <ReactSelect
                                   options={customers}
                                   isMulti= {true}
                                   closeMenuOnSelect={false}
                                   hideSelectedOptions={false}
                                   components={{Option}}
                                   isClearable={true}
                                   // defaultValue={selectedOption}
                                   value={selectedCustomer}
                                   onChange={onCustomerSelect}
                                   className={"react-select-dropdown col-sm-3"} 
                                   placeholder="Please select Customer"
                                 />
                  
             
                      &nbsp;&nbsp;&nbsp;   &nbsp;&nbsp;&nbsp;    &nbsp;&nbsp;&nbsp;   &nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;   &nbsp;&nbsp;&nbsp; 
                              
                         
                         
            
                      <label htmlFor="grnHCompcode" className="form-group col-sm-0 text-right"></label>
                                  <RangePicker 
                                        defaultValue={dates}
                                        format="DD-MM-YYYY"
                                    onchange={(values)=>{
                                      setDates(values.map(item=>{
                                        return moment(item.format('YYYY-DD-MM'))
                                      }))
                                    }}/>

&nbsp;&nbsp;&nbsp;   &nbsp;&nbsp;&nbsp;     
            
                     <div>    
                <Link  to={'/Search'} >
                             <i className=" form-group col-sm-3  fa fa-search fa-2x"aria-hidden="true"></i>
                             </Link>
                             </div> 
            
                             <div>    
                <Link  to={'/Search'} >
                             <i className=" form-group col-sm-3  fa fa-home fa-2x"aria-hidden="true"></i>
                             </Link>
                             </div> 
             
                            
            
                                  </div>
          </Col>
   
          </Row> 
        </div>
    
        </form>
                <div className="container item-list-table-container">
                <div className="table-responsive">
                <ReactTableWrapper
                            title='Ledger Account Report'
                            columns={columns}
                            data={data.records}
                            onCSVExport={handleCSVExport}
                            onPDFExport={handlePDFExport}
                            isLoading={isLoading}
                        />
                </div>
                </div> 

            </div>
                </div>

        
    );

}

export default LedgerAccountReportTable;