import { useEffect, useState, useMemo } from "react";
import ReactTableWrapper from "../../components/ReactTableWrapper";
import { exportAsCSV } from "../../utils/CSVExporter";
import { exportAsPDF } from "../../utils/PDFExporter";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import Sidebar from '../../Sidebar';
import Swal from "sweetalert2";
import { useCookies } from 'react-cookie';
import { getAllBank, deleteBank } from "../../services/BankService";
function BankTable() {
    const InitialData = {
        total_pages: 0,
        records: [],
      };
    const [data, setData] = useState(InitialData);
    const navigate = useNavigate(); 
    const [refreshKey, setRefreshKey] = useState(0);
    const [cookies] = useCookies(['myToken']);
    const [isLoading, setIsLoading] = useState(true); 

    const columns = useMemo(
        () => [
            {
                fieldName: 'name', 
                headerName: 'Bank Name'
            },
           
            {
                fieldName: "bank_alias",
                headerName: 'Bank Alias Name'
            },
            {
                fieldName: "account_no",
                headerName: 'Account No'
            },
            {
                fieldName: "opening_balance",
                headerName: 'Opening Balance'
            },
            {
                fieldName: "closing_balance",
                headerName: 'Closing Balance'
            },
          
            {
                fieldName: "status",
                headerName: 'Status'
            },
            {
                fieldName: "id",
                headerName: "ROW_ACTIONS"
            }
       
         
        ],
        [],);

    const onEditBank = (bankId, event) => {
       
       navigate(`/BankEdit/${bankId}/`);
    };

    const onDeleteBank = (bankId, event) => {

        event.preventDefault();
        Swal.fire({title: 'Are you sure to Delete?',  
        showCancelButton: true,  
        confirmButtonText: `Yes`,  
        cancelButtonText: `No`,
        }).then((result) => {  
          if (result.isConfirmed) { 
            setIsLoading(true);
            deleteBank(cookies, bankId); 
            console.log(`Bank with id ${bankId} deleted`);
            Swal.fire('Deleted Successfully!', '', 'success');
            setRefreshKey(oldKey => oldKey +1)
          } else if (result.isDismissed) {    
            Swal.fire('Not Deleted', '', 'info')  
        }
        });
        //

    };

    const handleCSVExport = (event) => {
        console.log('CSV Export');
        exportAsCSV(
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(c => c.headerName),
            data.records.map(bank => {

                const csvRow = {
                    name: bank.name,
                    bank_alias: bank.bank_alias,
                    account_no:bank.account_no,
                    opening_balance:bank.opening_balance,
                    closing_balance:bank.closing_balance,
                    status:bank.status,
                }
                return csvRow;
                }));
    };

    const handlePDFExport = (event) => {
        console.log('PDF Export');
        exportAsPDF(
            'List of Bank Form',
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(col=>({header: col.headerName, dataKey: col.fieldName })),
            data.records.map(bank => ({
                   name: bank.name,
                    bank_alias: bank.bank_alias,
                    account_no:bank.account_no,
                    opening_balance:bank.opening_balance,
                    closing_balance:bank.closing_balance,
                    status:bank.status,
            })),
            'Bank_Forms.pdf'
        );
    };

    useEffect(() => {

        getAllBank(cookies)
        .then( bankList => {
            const tableData = bankList.bank_list
            .map(bank => ({
                id :bank.id,
                name: bank.name,
                bank_alias: bank.bank_alias,
                account_no:bank.account_no,
                opening_balance:bank.opening_balance,
                closing_balance:bank.closing_balance,
                status:bank.status? "Active":"Inactive"
            }));

            setData({
                total: data.total,
                records: tableData
            });
            setIsLoading(false);
        });
        }, [refreshKey]);
    
    return (

        <div id="outer-container"  className="App" > 
            <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
            <div id="page-wrap">
            <h3 style={{color:"rgb(2, 2, 49)",textAlign:"center"}}>List of Bank Form</h3>
            <br/>
                <div className="container item-list-table-container">
                <div className="table-responsive">
                <ReactTableWrapper
                            title='List of Bank Form'
                            columns={columns}
                            data={data.records}
                            onRowEdit={onEditBank}
                            onRowDelete={onDeleteBank}
                            onCSVExport={handleCSVExport}
                            onPDFExport={handlePDFExport}
                            isLoading={isLoading}
                        
                        />
                </div>
                </div> 


            </div>
            <button style={{position:"relative",right:"40px"}}className="btn btn-fill btn-primary float-end" type="button" onClick={() =>{navigate("/Bank")}}>Back</button>
        </div>

        
    );

}

export default BankTable;