import { useEffect, useState, useMemo } from "react";
import ReactTableWrapper from "../../components/ReactTableWrapper";
import { exportAsCSV } from "../../utils/CSVExporter";
import { exportAsPDF } from "../../utils/PDFExporter";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import Sidebar from '../../Sidebar';
import Swal from "sweetalert2";
import { useCookies } from 'react-cookie';
import { getAllNumberSetting, deleteNumberSetting } from "../../services/NumberSettingServices";
import {displayError} from '../../helpers';

function NumberSettingTable() {
    const InitialData = {
        total_pages: 0,
        records: [],
      };
    const [data, setData] = useState(InitialData);
    const navigate = useNavigate(); 
    const [refreshKey, setRefreshKey] = useState(0);
    const [cookies] = useCookies(['myToken']);
    const [isLoading, setIsLoading] = useState(true); 

    const columns = useMemo(
        () => [
            {
                fieldName: 'plant', 
                headerName: 'Plant'
            },
            {
                fieldName: "voucher_type",
                headerName: 'Voucher Type'
            },
            {
                fieldName: "starting_number",
                headerName: "Starting Number"
            },
            {
                fieldName: "number_of_digits",
                headerName: "Number Of Digits"
            },
            {
                fieldName: "prefix",
                headerName: "Prefix"
            },
            {
                fieldName: "valid_from_date",
                headerName: "Validity From"
            },
            {
                fieldName: "valid_upto_date",
                headerName: "Validity Upto"
            },
            {
                fieldName: "id",
                headerName: "ROW_ACTIONS"
            }
        ],
        [],);

    const onEditNumberSetting = (numberSettingId, event) => {
       
       navigate(`/NumberSettingEdit/${numberSettingId}/`);
    };

    const handleCSVExport = (event) => {
        console.log('CSV Export');
        exportAsCSV(
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(c => c.headerName),
            data.records.map(r => {

                const csvRow = {
                    id :r.id,
                    plant: r.plant.name,
                    voucher_type: r.voucher_type,
                    starting_number:r.starting_number,
                    prefix: r.prefix,
                    number_of_digits:r.number_of_digits
                    
                }
                return csvRow;
                }));
    };

    const handlePDFExport = (event) => {
        console.log('PDF Export');
        exportAsPDF(
            'List of Group Form',
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(col=>({header: col.headerName, dataKey: col.fieldName })),
            data.records.map(r => ({
                name: r.plant.name,
                voucher_type: r.voucher_type,
                starting_number:r.starting_number,
                prefix: r.prefix,
                number_of_digits:r.number_of_digits
            })),
            'Number_Forms.pdf'
        );
    };

    useEffect(() => {

        getAllNumberSetting(cookies)
        .then( numbersList => {
            const tableData = numbersList.number_settings_list
            .map(r => ({
                id :r.id,
                plant: r.plant.alias?r.plant.alias:"All",
                voucher_type: r.voucher_type.toUpperCase().replace(/_/g, " "),
                starting_number:r.starting_number,
                prefix: r.prefix,
                number_of_digits:r.number_of_digits,
                valid_from_date:r.valid_from_date,
                valid_upto_date:r.valid_upto_date
            }));

            setData({
                total: data.total,
                records: tableData
            });
            setIsLoading(false);
        });
        }, [refreshKey]);
    
    return (

        <div id="outer-container"  className="App" > 
            <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
            <div id="page-wrap">
            <h3 style={{color:"rgb(2, 2, 49)",textAlign:"center"}}>List of NumberSetting Form</h3>
            <br/>
                <div className="container item-list-table-container">
                <div className="table-responsive">
                <ReactTableWrapper
                            title='List of Group Form'
                            columns={columns}
                            data={data.records}
                            onRowEdit={onEditNumberSetting}
                            // onRowDelete={onDeleteNumberSetting}
                            onCSVExport={handleCSVExport}
                            onPDFExport={handlePDFExport}
                            isLoading={isLoading}
                            hideDelete = {true}
                        />
                </div>
                </div> 


            </div>
            <button style={{position:"relative",right:"40px"}}className="btn btn-fill btn-primary float-end" type="button" onClick={() =>{navigate("/NumberSetting")}}>Back</button>
        </div>

        
    );

}



export default NumberSettingTable;