import { useEffect, useState, useMemo } from "react";
import React from 'react';
import ReactTableWrapper from "../../components/ReactTableWrapper";
import { exportAsCSV } from "../../utils/CSVExporter";
import { exportAsPDF } from "../../utils/PDFExporter";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import Sidebar from '../../Sidebar';
import Swal from "sweetalert2";
import { useCookies } from 'react-cookie';
import { Link } from "react-router-dom";
import {Row,Col,Button,} from "reactstrap";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";

import {  DatePicker } from 'antd';
import { getAllPlants} from '../../services/PlantServices';
import { getAllCustomerMain} from '../../services/CustomerMainServices';
import { getAllSalesRepresentatives } from '../../services/SalesRepresentativeServices';
import {getAllCustomerSites} from '../../services/CustomerSiteServices';
import { getAllProducts } from "../../services/ProductServices";
import { getAllEquipments } from '../../services/EquipmentServices';
import {getEquipmentReport} from "../../services/ReportServices";
import moment  from 'moment';
const {RangePicker} = DatePicker;
function EquipmentReportTable() {
    const InitialData = {
        total_pages: 0,
        records: [],
      };
    const [data, setData] = useState(InitialData);
    const navigate = useNavigate(); 
    const [refreshKey, setRefreshKey] = useState(0);
    const [cookies] = useCookies(['myToken']);
    const [isLoading, setIsLoading] = useState(true); 
    const [plants, setPlants] = React.useState([]);
    const [selectedPlant, setSelectedPlant] = useState(null);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [customers, setCustomers] = useState([]);
    const [selectedSalesrep, setSelectedSalesRep] = useState(null);
    const [salesreps, setSalesreps] = useState([]);
    const [selectedSite, setSelectedSite] = useState(null);
    const [sites, setSites] = useState([]);
    const [selectedVehicleno, setSelectedVehicleno] = useState(null);
    const [vehicles, setVehicles] = useState([]);
    const [selectedGrade, setSelectedGrade] = useState(null);
    const [grades, setGrades] = useState([]);
    const currentMonthStartDate = moment().startOf('month');
    const currentDate = moment();
    const [dates, setDates] = useState([currentMonthStartDate, currentDate]);
   

    const Option = (props) => {
        return (
            <>
                <components.Option {...props}>
                    <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}/>{" "}
                <label>{props.label}</label>
                </components.Option>
            </>
        );
    };
    React.useEffect(() => {
        
        //Load data for Plant dropdown
        getAllPlants(cookies)
        .then (
            plantList => {
                
                const plants = plantList.plant_list.filter(obj => obj.status).map(
                    plant => {
                        return { value: plant.id, label: plant.entity_name }
                    }
                );
                setPlants(plants);
            }
        )
    }, []);
    React.useEffect(() => {
        getAllCustomerMain(cookies)
        .then (
            customerList => {
                
                const customers = customerList.customer_list.filter(obj => obj.status).map(
                    customer => {
                        return { value: customer.id, label: customer.name }
                    }
                );
                setCustomers(customers);
            }
        )
    }, []);
    React.useEffect(() => {
    getAllCustomerSites(cookies)
    .then (
        siteList => {
            
            const sites = siteList.consignee_list.filter(obj => obj.status).map(
                site => {
                    return { value: site.id, label: site.name }
                }
            );
            setSites(sites);
        }
    )
}, []);

React.useEffect(() => {
    getAllSalesRepresentatives(cookies)
    .then (
        salesRepList => {
            
            const salesrep = salesRepList.salesrep_data.filter(obj => obj.status).map(
              sales => {
                    return { value: sales.id, label: sales.entity_name }
                }
               
            );
            //console.log(JSON.stringify(salesRepList)+"salesRepList")
        
            setSalesreps(salesrep);
          
        }
    )
}, []);
React.useEffect(() => {
    getAllEquipments(cookies)
      .then (
      
         equipmentObject => {
          
              const equipments = equipmentObject.equipment_list.filter(obj => (obj.status) ).map(
                  equipment => {
                      return { value: equipment.id, label: equipment.equip_name}
                  }
              );
              setVehicles(equipments);
          }
      )
  }, []);

React.useEffect(() => {
    getAllProducts(cookies)
    .then (
        productList => {
            
            const products = productList.product_list.filter(obj => obj.status).map(
                product => {
                    return { value: product.id, label: product.name}
                }
            )
           
            setGrades(products);
        }
    )
}, []);


    const onPlantSelect = (selected) => {
        setSelectedPlant(selected);
    };
    const onCustomerSelect = (selected) => {
        
        setSelectedCustomer(selected);
    };

    const onSiteSelect = (selected) => {
        setSelectedSite(selected);

    };
    const onSalesRepSelect = (selected) => {
        setSelectedSalesRep(selected);
    };


    const onVehiclenoSelect = (selected) => {
        setSelectedVehicleno(selected);
    };
    const onGradeSelect = (selected) => {
        setSelectedGrade(selected);
    };

    const Back = () => {
        navigate('/Home')
      }
    const columns = useMemo(
        () => [
            {
                fieldName: 'entity_name', 
                headerName: 'Plant Name'
            },
            {
                fieldName: 'entity_name', 
                headerName: 'Production Plant'
            },
           
            {
                fieldName: "address",
                headerName: 'Date'
            },
            {
                fieldName: 'entity_name', 
                headerName: 'DC Number'
            },
          
            {
                fieldName: "mobile_number",
                headerName: 'Batch Number'
            },
            {
                fieldName: 'entity_name', 
                headerName: 'Time'
            },
            {
                fieldName: "address",
                headerName: 'SO Number'
            },
            {
                fieldName: "address",
                headerName: 'PO Number'
            },
            {
                fieldName: "address",
                headerName: 'Invoice Number'
            },
            {
                fieldName: 'entity_name', 
                headerName: 'Customer Name'
            },
            {
                fieldName: "address",
                headerName: 'Site/Project Name'
            },
            {
                fieldName: "address",
                headerName: 'Grade'
            },
            {
                fieldName: 'entity_name', 
                headerName: 'Quantity'
            },
            {
                fieldName: 'entity_name', 
                headerName: 'Rate'
            },
            {
                fieldName: 'entity_name', 
                headerName: 'Mode of Delivery'
            },
            {
                fieldName: 'entity_name', 
                headerName: 'Vehicle No'
            },
            
            {
                fieldName: "address",
                headerName: 'Marketting'
            },
            {
                fieldName: "address",
                headerName: 'Driver name'
            },
            {
                fieldName: "address",
                headerName: 'Open KM'
            },
            {
                fieldName: "address",
                headerName: 'End KM'
            },
            {
                fieldName: 'entity_name', 
                headerName: 'DC Grade'
            },
            {
                fieldName: 'entity_name', 
                headerName: 'Total Km'
            },
            {
                fieldName: 'entity_name', 
                headerName: 'Tare weight'
            },
            {
                fieldName: 'entity_name', 
                headerName: 'Gross weight'
            },
            {
                fieldName: 'entity_name', 
                headerName: 'Net weight'
            },
            {
                fieldName: "address",
                headerName: 'Dc Status'
            },
           
        ],
        [],);

   
    const handleCSVExport = (event) => {
        console.log('CSV Export');
        exportAsCSV(
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(c => c.headerName),
            data.records.map(r => {

                const csvRow = {
                    entity_name: r.entity_name,
                    address: r.address,
                    phone_number:r.phone_number,
                    mobile_number:r.mobile_number,
                    status:r.status
                }
                return csvRow;
                }));
    };

    const handlePDFExport = (event) => {
        console.log('PDF Export');
        exportAsPDF(
            'List of Equipment',
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(col=>({header: col.headerName, dataKey: col.fieldName })),
            data.records.map(r => ({
                entity_name: r.entity_name,
                address: r.address, 
                phone_number:r.phone_number,
                mobile_number:r.mobile_number,
                status:r.status
            })),
            'Equipment.pdf'
        );
    };

    useEffect(() => {

        getEquipmentReport(cookies)
        .then( customerList => {
            const tableData = customerList.salesrep_data
            .map(customer => ({
                entity_name: customer.entity_name,
                address: `${customer.address_1},\n${customer.address_2},\n${customer.address_3}`,
                phone_number:customer.phone_number,
                mobile_number:customer.mobile_number,
                status:customer.status,
                id: customer.id,    
            }));

            setData({
                total: data.total,
                records: tableData
            });
            setIsLoading(false);
        });
        }, [refreshKey]);
    
    return (

        <div id="outer-container"  className="App" > 
            
            <div id="page-wrap">
            <h3 style={{color:"rgb(2, 2, 49)",textAlign:"center"}}>Equipment Report</h3>
            <br/>
            <form className="form" onSubmit={""}>
        <div className="container">
        <Row>
          <Col xl={12} lg={12} md={12}>
            </Col>
           
            <Col xl={12} lg={12} md={12}>
            <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3 form-control-panel ">
           
           
                   
                   
            <label htmlFor="Checkbox"className="form-group col-sm-0 text-right" > </label>
                                  {/* <div style={{width: '58%'}} > */}
                                  <ReactSelect
                                    options={plants}
                                    isMulti= {true}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{Option}}
                                    isClearable={true}
                                    // defaultValue={selectedOption}
                                    value={selectedPlant}
                                    onChange={onPlantSelect}
                                    className="browser-default custom-select col-sm-3"
                                    placeholder="Please select Plant name"
                                  />
                                  {/* </div> */}
                                <br/><br/>&nbsp;&nbsp;&nbsp;

                   
             <label htmlFor="Checkbox"className="form-group col-sm-0 text-right" > </label>
                                 
                                  <ReactSelect
                                    options={customers}
                                    isMulti= {true}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{Option}}
                                    isClearable={true}
                                    // defaultValue={selectedOption}
                                    value={selectedCustomer}
                                    onChange={onCustomerSelect}
                                    className="browser-default custom-select col-sm-3"
                                    placeholder="Please select Customer name"
                                  />
                               
                                <br/><br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <label htmlFor="Checkbox"className="form-group col-sm-0 text-right" > </label>
                                 
                                  <ReactSelect
                                    options={sites}
                                    isMulti= {true}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{Option}}
                                    isClearable={true}
                                    // defaultValue={selectedOption}
                                    value={selectedSite}
                                    onChange={onSiteSelect}
                                    className="browser-default custom-select col-sm-3"
                                    placeholder="Please Select Site"
                                  />
                                
                                <br/><br/>&nbsp;&nbsp;&nbsp;
                                <label htmlFor="Checkbox"className="form-group col-sm-0 text-right" > </label>
                                 
                                  <ReactSelect
                                    options={salesreps}
                                    isMulti= {true}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{Option}}
                                    isClearable={true}
                                    // defaultValue={selectedOption}
                                    value={selectedSalesrep}
                                    onChange={onSalesRepSelect}
                                    className="browser-default custom-select col-sm-4"
                                    placeholder="Please Select Sales Representative"
                                  />
                                
                                <br/><br/>&nbsp;&nbsp;&nbsp;
                   
                                <label htmlFor="Checkbox"className="form-group col-sm-0 text-right" > </label>
                                  
                                  <ReactSelect
                                    options={vehicles}
                                    isMulti= {true}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{Option}}
                                    isClearable={true}
                                    // defaultValue={selectedOption}
                                    value={selectedVehicleno}
                                    onChange={onVehiclenoSelect}
                                    className="browser-default custom-select col-sm-3"
                                    placeholder="Please Select VehicleNo"
                                  />
                                
                                <br/><br/>&nbsp;&nbsp;&nbsp;
                                <ReactSelect
                                    options={grades}
                                    isMulti= {true}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{Option}}
                                    isClearable={true}
                                    // defaultValue={selectedOption}
                                    value={selectedGrade}
                                    onChange={onGradeSelect}
                                    className="browser-default custom-select col-sm-3"
                                    placeholder="Please Select Grade"
                                  />
                                 
                                <br/><br/>&nbsp;&nbsp;
                             
                        <label htmlFor="grnHCompcode" className="form-group col-sm-0 text-left"></label>
                        <RangePicker defaultValue={dates}
                            format="DD-MM-YYYY"
                            onChange={(values) => {
                            if (values.length === 2) {
                                setDates(values);
                            }
                            }}
                        />
                    <label  className="form-group col-sm-0 text-right"></label>
                                <select className="browser-default custom-select col-sm-2" id="myData"  onChange={""}   >
                                
                                    <option value="">Please Select Reports</option>
                                    <option value="date">Datewise</option>
                                    <option value="product">Product</option>
                                    <option value="vendor">Supplier</option>
                                    <option value="vehicle">Vehicle</option>
                                </select>
                        
                    <div>    
                        <Link  to={'/Search'} >
                            <i className=" form-group col-sm-3  fa fa-search fa-2x" id="myData" aria-hidden="true"></i>
                         </Link>
                    </div> 

                    <div>    
                        <Link  to={'/HOME'} >
                            <i className=" form-group col-sm-3  fa fa-home fa-2x"id="Back" aria-hidden="true"></i>
                        </Link>
                    </div> 

            </div>
      
        </Col>
     </Row> 
        </div>
    
        </form>
                <div className="container item-list-table-container">
                <div className="table-responsive">
                <ReactTableWrapper
                            title='Equipment Report'
                            columns={columns}
                            data={data.records}
                            onCSVExport={handleCSVExport}
                            onPDFExport={handlePDFExport}
                            isLoading={isLoading}
                        />
                </div>
                </div> 

            </div>
                </div>

        
    );

}

export default EquipmentReportTable;