import { getApiBaseUrl, getApiClient } from "./serviceconfig";

var apiBaseUrl = getApiBaseUrl();

export async function createPlantConfiguration(cookies, inputs) {
    return getApiClient(cookies)
    .post(`${apiBaseUrl}/plant_configurations/`, inputs);
    }

export async function getAllPlantConfigurations(cookies,searchString) {
    let url= `${apiBaseUrl}/plant_configurations/`;
    if (searchString) {
        url = url + `?plant_id=${searchString}`;
      }
        return getApiClient(cookies).get(url)
        .then(response => response.data);
    }
        
export async function getPlantConfigurationDetails(cookies, plantConfigurationId) {
    return getApiClient(cookies).get(`${apiBaseUrl}/plant_configurations/${plantConfigurationId}/`)
    .then(response => response.data)
}

export async function updatePlantConfiguration(cookies, inputs, plantConfigurationId) {
    return getApiClient(cookies)
    .put(`${apiBaseUrl}/plant_configurations/${plantConfigurationId}/`,inputs)
}

export async function deletePlantConfiguration(cookies, plantConfigurationId) {
    return getApiClient(cookies).delete(
      `${apiBaseUrl}/plant_configurations/${plantConfigurationId}/`)
  }