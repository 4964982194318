import { useEffect, useState, useMemo } from "react";
import ReactTableWrapper from "../../components/ReactTableWrapper";
import { exportAsCSV } from "../../utils/CSVExporter";
import { exportAsPDF } from "../../utils/PDFExporter";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import Sidebar from '../../Sidebar';
import Swal from "sweetalert2";
import { useCookies } from 'react-cookie';
import { getAllCheckOut, deleteCheckOut } from "../../services/CheckOutServices";
import { getAllGatePass } from "../../services/VehicleGatePassService";
function VehicleGatePassTable() {
    const InitialData = {
        total_pages: 0,
        records: [],
      };
    const [data, setData] = useState(InitialData);
    const navigate = useNavigate(); 
    const [refreshKey, setRefreshKey] = useState(0);
    const [cookies] = useCookies(['myToken']);
    const [isLoading, setIsLoading] = useState(true);  
    const columns = useMemo(
        () => [
            {
                fieldName: "movement_type",
                headerName: 'MOVEMENT TYPE'
            },
            {
                fieldName: "plant_id",
                headerName: 'PLANT NAME'
            },
            {
                fieldName: 'vehicle_no', 
                headerName: 'VEHICLE NO'
            },
            
            {
                fieldName: "gate_pass_no",
                headerName: 'GATE PASS NO'
            },
            {
                fieldName: "gate_pass_date",
                headerName: 'GATE PASS DATE'
            },
            {
                fieldName: 'gate_pass_time', 
                headerName: 'GATE PASS TIME'
            },
           
           
            {
                fieldName: 'driver_name', 
                headerName: 'Drivers Name'
            },
            {
                fieldName: "status",
                headerName: 'Status'
            },
           
            {
                fieldName: "id",
                headerName: "ROW_ACTIONS"
            }
        ],
        [],);

    const onEditGatePass = (gatepassId, event) => {
       
       navigate(`/VehicleGatePassEdit/${gatepassId}/`);
    };

    const handleCSVExport = (event) => {
        console.log('CSV Export');
        exportAsCSV(
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(c => c.headerName),
            data.records.map(gatepasscsv => {
                
                const csvRow = {
                    movement_type: gatepasscsv.movement_type,
                    plant_id:gatepasscsv.plant_id,
                    vehicle_no:gatepasscsv.vehicle_no,
                    gate_pass_no: gatepasscsv.gate_pass_no,
                    gate_pass_date:gatepasscsv.gate_pass_date,
                    gate_pass_time:gatepasscsv.gate_pass_time,
                    driver_name:gatepasscsv.driver_name,
                    status:gatepasscsv.status?'ACTIVE':'CANCELLED'
        
                }
                return csvRow;
                }));
    };

    const handlePDFExport = (event) => {
        console.log('PDF Export');
        exportAsPDF(
            'List of CheckOut',
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(col=>({header: col.headerName, dataKey: col.fieldName })),
            data.records.map(gatepasspdf => ({
                movement_type: gatepasspdf.movement_type,
                plant_id:gatepasspdf.plant_id,
                vehicle_no:gatepasspdf.vehicle_no,
                gate_pass_no: gatepasspdf.gate_pass_no,
                gate_pass_date:gatepasspdf.gate_pass_date,
                gate_pass_time:gatepasspdf.gate_pass_time,
                driver_name:gatepasspdf.driver_name,
                status:gatepasspdf.status?'ACTIVE':'CANCELLED'
            })),
            'GatePass.pdf'
        );
    };

    useEffect(() => {

        getAllGatePass(cookies)
        .then( gatepassList => {
            const tableData = gatepassList.gate_pass_list
            .map(gatepass => ({
                id :gatepass.id,
                movement_type: gatepass.movement_type,
                plant_id:gatepass.plant.name,
                vehicle_no:gatepass.vehicle.name,
                gate_pass_no: gatepass.gate_pass_no,
                gate_pass_date:gatepass.gate_pass_date,
                gate_pass_time:gatepass.gate_pass_time,
                driver_name:gatepass.driver_name,
                status:gatepass.status?'ACTIVE':'CANCELLED'
              
         
            }));

            setData({
                total: data.total,
                records: tableData
            });
            setIsLoading(false);
        });
        }, [refreshKey]);
    
    return (

        <div id="outer-container"  className="App" > 
            <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
            <div id="page-wrap">
            <h3 style={{color:"rgb(2, 2, 49)",textAlign:"center"}}>List of Vehicle Gate Pass</h3>
            <br/>
                <div className="container item-list-table-container">
                <div className="table-responsive">
                <ReactTableWrapper
                            title='List of CheckOut'
                            columns={columns}
                            data={data.records}
                            onRowEdit={onEditGatePass}
                            hideDelete={true}
                            onCSVExport={handleCSVExport}
                            onPDFExport={handlePDFExport}
                            isLoading={isLoading}
                        />
                </div>
                </div> 


            </div>
            <button style={{position:"relative",right:"40px"}}className="btn btn-fill btn-primary float-end" type="button" onClick={() =>{navigate("/VehicleGatePass")}}>Back</button>
        </div>

        
    );

}



export default VehicleGatePassTable;