import React from 'react';
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { Row, Col, Button } from "reactstrap";
import axios from "axios";
import Sidebar from '../../Sidebar';
import { useNavigate } from 'react-router';
import { BiSave } from "react-icons/bi";
import { IoIosEye } from "react-icons/io";
import { TiCancel } from "react-icons/ti";
import { AiOutlineHome } from "react-icons/ai";
import { useCookies } from 'react-cookie';
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import { moment } from 'moment';
import TableRowsReceiptVoucherEdit from "./TableRowsReceiptVoucherEdit";
import { useLocation, Link } from 'react-router-dom';
import {displayErrorToast} from '../../helpers';
import ErrorToast from '../../ErrorToast';
import FloatingControls from '../../components/FloatingControls';
import LoadingOverlay from '../../components/LoadingOverlay';


function ReceiptVoucher() {
    const location = useLocation(); 
    const [cookies] = useCookies(['myToken']);
    const [category] = useCookies(['myCategory']);
    const [superuser] = useCookies(['mySuperuser']);
    const [inProgress, setInProgress] = useState(false);
    const [inputs, setInputs] = useState({});
    const [plant, setPlant] = React.useState([]);
    const [Appr_so_dtl, setAppr_so_dtl] = React.useState([]);
    const navigate = useNavigate();
    const handleChange = (event) => {
      const name = event.target.name;
      const value = event.target.value;
     try{ 
          if (name === 'dcCompCode') {
            fetch(`http://127.0.0.1:8000/ReceiptVoucher_drp/${value}`, {
              method: 'GET',
              headers: {
                'Authorization': `Token ${cookies['myToken']}`,
                'Content-Type': 'application/json'
              }
            })
              .then(response => response.json())
              .then((data) => {
                console.log(JSON.stringify(data) + "ata_receiptvoucher_drop");
                setAppr_so_dtl(data.wrkshedule)
              })
          }
          setInputs(values => ({ ...values, [name]: event.target.value }))
        }
        catch(e)
        {
          displayErrorToast(e);
        }
    }

    const handleChange4 = (index, evnt)=>{
      const { name, value } = evnt.target;
      const rowsInput = [...rowsData];
      rowsInput[index][name] = value;
 
      setRowsData(rowsInput);
  }
    const [rowsData, setRowsData] = useState([]);
    const handleSubmit = (event) => {
      event.preventDefault();
      var err = 0;
    try{ 
          if (err == 0) {
            Swal.fire({
              title: 'Do you want to save?',
              showCancelButton: true,
              confirmButtonText: `Yes`,
              cancelButtonText: `No`,
            }).then((result) => {
              if (result.isConfirmed) {
                axios.post('http://127.0.0.1:8000/ReceiptVoucher/', {
                  rowsData: rowsData,
                  dcCompCode: inputs.dcCompCode,
                  wsSlNo: inputs.wsSlNo
                },
                  {
                    headers: {
                      'Authorization': `Token ${cookies['myToken']}`
                    }
                  })
                  .then(function (response) {
                    console.log(JSON.stringify(response) + "response");
                  }).catch(function (error) {
                    console.log(JSON.stringify(error) + "error");
                  })
                Swal.fire('Saved!', '', 'success')
                Cancel();
              }
              else if (result.isDismissed) {
                Swal.fire('Not saved', '', 'info')
              }
            });
          }
          else {
            Swal.fire('Please check the value')
          }
        }
        catch(e)
        {
          displayErrorToast(e);
        }
    }

    const Cancel = () => {
        setInputs(() => "")
        setRowsData([])
      }
      const view = () => {
        navigate('/ReceiptVoucherTable')
      } 
    
      const Back = () => {
        navigate('/Home')
      }

return (
    <>
          <div id="outer-container"  className="App" > 
          <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
          <ErrorToast/>
          <LoadingOverlay inProgress={inProgress}/>
        <div id="page-wrap">
        <form onSubmit={handleSubmit} >
            <div id="header">
              <h3 className="text font-weight-bold page-title" >RECEIPT VOUCHER EDIT </h3>
            </div>

            <FloatingControls tableLink="/CompanyTable" enableCancel={false}/>
            <div className="container">
            <Row>
                <Col xl={6} lg={12} md={12}>

                <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3 form-control-panel " >
                    <label htmlFor="Plant_id" className="form-group col-sm-5 text-right">Plant Location</label>
                    <select id="Plant_id" name="Plant_id" className="form-control col-sm-6  browser-default custom-select" required onChange={handleChange} value={inputs.Plant_id || ""}>
                      <option value="">Select Plant</option>
                      {plant.map((item) => (
                        <option key={item.id} value={item.id}>{item.cmpAlias}</option>
                      ))}
                    </select><br />
                    <label htmlFor="Customer_id" className="form-group col-sm-5 text-right">Customer</label>
                    <select id="Customer_id" name="Customer_id" className="form-control col-sm-6  browser-default custom-select" required onChange={handleChange} value={inputs.Customer_id || ""}>
                      <option value="">Select Customer Name</option>
                      {plant.map((item) => (
                        <option key={item.id} value={item.id}>{item.CusName}</option>
                      ))}
                    </select><br />
                    <label htmlFor="show_option" className="form-group col-sm-5 text-right">Show Pending /All Bills</label>
                    <select id="show_option" name="show_option" className="form-control col-sm-2  browser-default custom-select" required onChange={handleChange} value={inputs.show_option || ""}>
                   
                           <option value="">Select</option>
                          <option value="All">All</option>
                           <option value="Pending">Pending</option>
                       </select><br/>

                       <label  htmlFor="check"className="form-group col-sm-3 text-right">Show All Plants</label>
                                <label><input type="checkbox"  id="check" name="check" value="Y" /></label><br/>
                       {/* <label for="check"class="form-group col-sm-3 text-right" > Show All Plants</label> */}
                       {/* <input type="checkbox"style="zoom:2.3;" id="check" class="custchange" name="check" value=""/> */}
                       


                    </div>
                    <div className="form-row table-bordered shadow p-2 my-2  border-secondary p-2 mb-3 form-control-panel ">
                  
                    <label htmlFor="Receipt_Mode" className="form-group col-sm-5 text-right">Mode Of Receipt</label>
                    <select id="Receipt_Mode" name="Receipt_Mode" className="form-control col-sm-6  browser-default custom-select" required onChange={handleChange} value={inputs.Receipt_Mode || ""}>
                   
                           <option value="">Select</option>
                          <option value="All">Cash</option>
                           <option value="Pending">Cheque</option>
                           <option value="Pending">Demand Draft</option>
                           <option value="Pending">NEFT/RTGS</option>
                           <option value="Pending">IMPS</option>
                           <option value="Pending">CARD</option>
                           <option value="Pending">OTHERS</option>
                       </select><br/>
                       <label htmlFor="bank" className="form-group col-sm-5 text-right">Bank/Cash</label>
                    <select id="bank" name="bank" className="form-control col-sm-6  browser-default custom-select" required onChange={handleChange} value={inputs.bank || ""}>
                      <option value="">Select Plant</option>
                      {plant.map((item) => (
                        <option key={item.id} value={item.id}>{item.MCAlias}</option>
                      ))}
                    </select><br />
                    
                    <label htmlFor="Receipt_Amount" className="form-group col-sm-5 text-right">Receipt Amount</label>
                    <input type="text" className="form-control col-sm-6" id="Receipt_Amount"value={inputs.Receipt_Amount || ""}  style={{backgroundColor:"white"}} name="Receipt_Amount" /><br/>

                    <label htmlFor="Instrument_No" className="form-group col-sm-5 text-right">Instrument No.</label>
                    <input type="text" className="form-control col-sm-6" id="Instrument_No"value={inputs.Instrument_No || ""}  style={{backgroundColor:"white"}} name="Instrument_No" /><br/>

                    <label htmlFor="Instrument_Date" className="form-group col-sm-5 text-right">Instrument Date</label>
                    <input type="date" className="form-control col-sm-6" id="Instrument_Date"value={inputs.Instrument_Date || ""}  style={{backgroundColor:"white"}} name="Instrument_Date" /><br/>

                    <label htmlFor="Bank_Name" className="form-group col-sm-5 text-right">Bank Name</label>
                    <input type="text" className="form-control col-sm-6" id="Bank_Name"value={inputs.Bank_Name || ""}  style={{backgroundColor:"white"}} name="Bank_Name" /><br/>

                    <label htmlFor="Bank_Branch" className="form-group col-sm-5 text-right">Branch</label>
                    <input type="text" className="form-control col-sm-6" id="Bank_Branch"value={inputs.Bank_Branch || ""}  style={{backgroundColor:"white"}} name="Bank_Branch" /><br/>


                  </div>
                    </Col>    
                    <Col xl={6} lg={12} md={12}>
                    <div className="form-row table-bordered shadow p-2 my-2  border-secondary p-2 mb-3 form-control-panel">
                    <label htmlFor="wsSlNo" className="form-group col-sm-4 text-right">Receipt No</label>
                    <input type="text" className="form-control col-sm-6" id="wsSlNo"value={inputs.wsSlNo || ""} readOnly={true} style={{backgroundColor:"white", cursor: "not-allowed"}} name="soHSlNo" /><br/>

                    <label htmlFor="wsSlNo" className="form-group col-sm-4 text-right">Receipt Date</label>
                    <input type="date" className="form-control col-sm-6" id="wsSlNo"value={inputs.wsSlNo || ""}  style={{backgroundColor:"white"}} name="soHSlNo" /><br/>

                    <label htmlFor="wsSlNo" className="form-group col-sm-4 text-right">Reconciled Date</label>
                    <input type="date" className="form-control col-sm-6" id="wsSlNo"value={inputs.wsSlNo || ""}  style={{backgroundColor:"white"}} name="soHSlNo" /><br/>


                    </div>
                    <div className="form-row table-bordered shadow p-2 my-2  border-secondary p-2 mb-3 form-control-panel">
                    <label htmlFor="wsSlNo" className="form-group col-sm-4 text-right">Marketing Name</label>
                    <input type="text" className="form-control col-sm-6" id="wsSlNo"value={inputs.wsSlNo || ""} readOnly={true} style={{backgroundColor:"white", cursor: "not-allowed"}} name="soHSlNo" /><br/>

                    <label htmlFor="dcCompCode" className="form-group col-sm-4 text-right">Receipt Against</label>
                    <select id="dcCompCode" name="dcCompCode" className="form-control col-sm-6  browser-default custom-select" required onChange={handleChange} value={inputs.dcCompCode || ""}>
                   
                           <option value="">Select</option>
                          <option value="All">ADVANCE</option>
                           <option value="Pending">BILL</option>
                     
                       </select><br/>

                       <label htmlFor="wsSlNo" className="form-group col-sm-4 text-right">Deposited Branch</label>
                    <input type="text" className="form-control col-sm-6" id="wsSlNo"value={inputs.wsSlNo || ""}  style={{backgroundColor:"white"}} name="soHSlNo" /><br/>

                       <label htmlFor="wsSlNo" className="form-group col-sm-4 text-right">Deposited Date</label>
                    <input type="date" className="form-control col-sm-6" id="wsSlNo"value={inputs.wsSlNo || ""} readOnly={true} style={{backgroundColor:"white", cursor: "not-allowed"}} name="soHSlNo" /><br/>

                    <label htmlFor="wsSlNo" className="form-group col-sm-4 text-right">Total Outstanding</label>
                    <input type="text" className="form-control col-sm-6" id="wsSlNo"value={inputs.wsSlNo || ""} readOnly={true} style={{backgroundColor:"white", cursor: "not-allowed"}} name="soHSlNo" /><br/>

                    <label htmlFor="wsSlNo" className="form-group col-sm-4 text-right">Due Amount</label>
                    <input type="text" className="form-control col-sm-6" id="wsSlNo"value={inputs.wsSlNo || ""} readOnly={true} style={{backgroundColor:"white", cursor: "not-allowed"}} name="soHSlNo" /><br/>

                    <label htmlFor="wsSlNo" className="form-group col-sm-4 text-right">Remarks</label>
                    <input type="text" className="form-control col-sm-6" id="wsSlNo"value={inputs.wsSlNo || ""}  style={{backgroundColor:"white"}} name="soHSlNo" /><br/>

                    </div>
                        </Col>

                    </Row>
                    <div className="form-row  shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue container table-responsive">
                <table className="table-bordered table-responsive searchTable table order-list" id="myTable" style={{textAlign:"center",borderRadius:"10px",backgroundColor:"RGB(188,232,253)"}}>
                    <thead>
                        <tr style={{backgroundColor:"RGB(188,232,253)"}}>
                            <th style={{display:"none"}}>Id</th>
                            <th>SI No</th>
                            <th>Doc Type</th>
                            <th>Plant prefix</th>
                            <th>Plant Id</th>
                            <th>Company Alias Name</th>
                            <th>Project/Site Name</th>
                            <th>Invoice/Debit Note/Number</th>
                            <th>Invoice Date</th>
                            <th>Payment Terms</th>
                            <th>Payment Due Date</th>
                            <th>Invoice Amount</th>
                            <th>Received Amount</th>
                            <th>Balance Amount</th>
                            <th>Receipt Amount</th>
                            <th>Remarks</th>
                        </tr>
                    </thead>
                    <tbody >
                    <TableRowsReceiptVoucherEdit rowsData={rowsData}  handleChange4={handleChange4}   />
                    </tbody>
                </table>
            </div> 
                </div>
                <div className="footer text-center">
                {((superuser['mySuperuser']) || (category['myCategory'][0].Is_receiptvoucher_for_so_edit === true)) &&(
                    <Button  type="submit" className="btn btn-twitter" style={{width:"80px",fontWeight:"bold"}} >Update</Button> 
                )}&nbsp;&nbsp;
                {((superuser['mySuperuser']) || (category['myCategory'][0].Is_receiptvoucher_for_so_view === true)|| (category['myCategory'][0].Is_receiptvoucher_for_so_edit === true) || (category['myCategory'][0].Is_receiptvoucher_for_so_delete === true)) &&(  
                    <Button className="btn btn-twitter" type="button"style={{width:"80px",fontWeight:"bold"}} onClick={view}>View</Button>
                )}&nbsp;&nbsp;
                    <Button className="btn btn-twitter"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={Back}>Home</Button>
                </div>
            </form>
            </div>

          </div>



















</>

);
}
export default ReceiptVoucher;