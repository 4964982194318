
import { getApiBaseUrl, getApiClient } from "./serviceconfig";

var apiBaseUrl = getApiBaseUrl();
//var apiBaseUrl ="http://localhost:8001";
export async function getAllMoisture(cookies,searchString) {

  let url= `${apiBaseUrl}/moisture_correction/`;
  if (searchString) {
    url = url + `?name=${searchString}`;
  }
    return getApiClient(cookies).get(url)
      .then(response => response.data);
}

export async function createMoisture(cookies, inputs) {

  return getApiClient(cookies).post(`${apiBaseUrl}/moisture_correction/`,inputs)
  
}

export async function updateMoisture(cookies, inputs, moisture_id) {

    return getApiClient(cookies)
    .put(`${apiBaseUrl}/moisture_correction/${moisture_id}/`,inputs)
}
  


export async function getMoistureDetails(cookies, moisture_id) {

  return getApiClient(cookies).get(`${apiBaseUrl}/moisture_correction/${moisture_id}/`)
  .then(response => response.data)
}

export async function deleteMoisture(cookies, moisture_id) {

  return getApiClient(cookies).delete(
    `${apiBaseUrl}/moisture_correction/${moisture_id}/`)
   
}