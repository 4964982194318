
import { getApiBaseUrl, getApiClient } from "./serviceconfig";
var apiBaseUrl = getApiBaseUrl();

export async function getAllGatePass(cookies,searchString) {

  let url= `${apiBaseUrl}/gate_pass/`;
  if (searchString) {
    url = url + `?name=${searchString}`;
  }
    return getApiClient(cookies).get(url)
      .then(response => response.data);
}

export async function createGatePass(cookies, inputs) {
  console.log(JSON.stringify(inputs)+"inputs_services")
  return getApiClient(cookies).post(`${apiBaseUrl}/gate_pass/`,inputs) 
}

export async function getAllGatePassForCheckIn(cookies) {

  return getApiClient(cookies)
  .get(`${apiBaseUrl}/gate_pass/check_in/`)
  .then(response => response.data);
}
  export async function getGatePassNumber(cookies, plantId) {
  return getApiClient(cookies)
  .get(`${apiBaseUrl}/gate_pass/gate_pass_number/?plant_id=${plantId}`)
  .then(response => response.data);
}

export async function updateGatePass(cookies, inputs) {

    return getApiClient(cookies)
    .put(`${apiBaseUrl}/gate_pass/${inputs.id}/`,inputs)
}
 
export async function getGatePassDetails(cookies, GatePassId) {

  return getApiClient(cookies).get(`${apiBaseUrl}/gate_pass/${GatePassId}/`)
  .then(response => response.data)
}

export async function deleteGatePass(cookies, GatePassId) {
  return getApiClient(cookies).delete(
    `${apiBaseUrl}/gate_pass/${GatePassId}/`)
}

export async function cancelGatePass(cookies, GatePassId) {
  return getApiClient(cookies).patch(
    `${apiBaseUrl}/gate_pass/${GatePassId}/`)
}