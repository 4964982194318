import { useEffect, useState, useMemo } from "react";
import ReactTableWrapper from "../../components/ReactTableWrapper";
import { exportAsCSV } from "../../utils/CSVExporter";
import { exportAsPDF } from "../../utils/PDFExporter";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import Sidebar from '../../Sidebar';
import Swal from "sweetalert2";
import { useCookies } from 'react-cookie';
import { displayError } from "../../helpers";
import { getAllGoodsReceiptNotes,printGoodsReceiptNote} from "../../services/GoodsReceiptNoteServices";

function GoodsReceiptNoteTable() {
    const InitialData = {
        records: []
    };
    const [data, setData] = useState(InitialData);
    const navigate = useNavigate(); 
    const [refreshKey, setRefreshKey] = useState(0);
    const [cookies] = useCookies(['myToken']);
    const [isLoading, setIsLoading] = useState(true);
    const left_pinned = ["plant","grn_no"]
    const right_pinned = ["id"]  
    const columns = useMemo(
        () => [
            {
                fieldName: "plant",
                headerName: 'Plant'
            },
            {
                fieldName: "grn_no",
                headerName: 'GRN No.'
            },
            {
                fieldName: "goods_receipt_note_date",
                headerName: 'Date'
            },
            {
                fieldName: 'vendor_name', 
                headerName: 'Vendors Name'
            },
            {
                fieldName: "product_name",
                headerName: 'Material'
            },
            {
                fieldName: "unit",
                headerName: 'Unit'
            },
            {
                fieldName: 'dc_qty', 
                headerName: 'Dc/Invoice Qty'
            },
            {
                fieldName: 'dc_no', 
                headerName: 'DC No'
            },
            {
                fieldName: 'dc_dt', 
                headerName: 'Dc Date'
            },
            {
                fieldName: "vehicle",
                headerName: 'Vehicle No'
            },
            {
                fieldName: "invoice_no",
                headerName: 'Invoice No'
            },
            {
                fieldName: "invoice_date",
                headerName: 'Invoice Date'
            },
            {
                fieldName: "gross_weight",
                headerName: 'Gross Weight'
            },
            {
                fieldName: "tare_weight",
                headerName: 'Tare Weight'
            },
            {
                fieldName: 'received_qty', 
                headerName: 'Received Qty'
            },
            {
                fieldName: 'difference_qty', 
                headerName: 'Difference Qty'
            },
            {
                fieldName: "deduction_qty",
                headerName: 'Deduction Qty'
            },
            {
                fieldName: 'weighment_name', 
                headerName: 'Weighment Name'
            },
            {
                fieldName: 'weighment_slip_no', 
                headerName: 'Weighment No'
            },
            {
                fieldName: "status",
                headerName: 'Status'
            },
            {
                fieldName: "id",
                headerName: "ROW_ACTIONS"
            }
        ],
    [],);

    const onEditGoodsReceiptNote = (grnId, event) => {
       navigate(`/GoodsReceiptNoteEdit/${grnId}/`);
    };

    const handleCSVExport = (event) => {
        console.log('CSV Export');
        exportAsCSV(
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(c => c.headerName),
            data.records.map(grn => {
                const csvRow = {
                    plant:grn.plant,
                    grn_no: grn.grn_no,
                    goods_receipt_note_date: grn.goods_receipt_note_date,
                    vendor_name:grn.vendor_name,
                    product_name:grn.product_name,
                    unit:grn.unit,
                    dc_qty: grn.dc_qty,
                    dc_no: grn.dc_no,
                    dc_dt: grn.dc_dt,
                    vehicle:grn.vehicle,
                    invoice_no: grn.invoice_no,
                    invoice_date: grn.invoice_date,
                    gross_weight: grn.gross_weight,
                    tare_weight: grn.tare_weight,
                    received_qty: grn.received_qty,
                    difference_qty:grn.difference_qty,
                    deduction_qty: grn.deduction_qty,
                    weighment_name: grn.weighment_name,
                    weighment_slip_no: grn.weighment_slip_no,
                    status:grn.status
                    // accepted_qty: grn.accepted_qty,
                }
                console.log(JSON.stringify(csvRow)+"csvRow");
                return csvRow;
            }));
    };

    const handlePDFExport = (event) => {
        console.log('PDF Export');
        exportAsPDF(
            'List of Goods Receipt Note',
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(col=>({header: col.headerName, dataKey: col.fieldName })),
            data.records.map(grn => ({
                id: grn.id,  
                grn_no: grn.grn_no,
                goods_receipt_note_date: grn.goods_receipt_note_date,
                gross_weight: grn.gross_weight,
                tare_weight: grn.tare_weight,
                net_weight: grn.net_weight,
                dc_qty: grn.dc_qty,
                deduction_qty: grn.deduction_qty,
                received_qty: grn.received_qty,
                accepted_qty: grn.accepted_qty,
                dc_no: grn.dc_no,
                dc_dt: grn.dc_dt,
                vendor_name:grn.vendor_name,
                product_name:grn.product_name,
                unit:grn.unit,
                vehicle:grn.vehicle,
                invoice_no: grn.invoice_no,
                invoice_date: grn.invoice_date,
                weighment_name: grn.weighment_name,
                weighment_slip_no: grn.weighment_slip_no,
                difference_qty:grn.difference_qty,
                plant:grn.plant,
                status:grn.status
            })),
            'GRN.pdf'
        );
    };

    const onPrintGoodsReceiptNote = (grnId, event) => {
        event.preventDefault();
        setIsLoading(true);
        printGoodsReceiptNote(cookies, grnId)
        .then(response => {
            const file = new Blob([response], { type: "application/pdf" });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            const pdfWindow = window.open();
            pdfWindow.location.href = fileURL;
            setIsLoading(false);
        })
        .catch(error => {
            console.log(error);
            displayError(error,'Print Failed');
            setIsLoading(false);
        });  
    };
    useEffect(() => {
        getAllGoodsReceiptNotes(cookies)
        .then( response => {
            const tableData = response.goods_receipt_note_list
            .map(grn => ({
                id: grn.id,  
                grn_no: grn.prefix+grn.goods_receipt_note_no,
                goods_receipt_note_date: grn.goods_receipt_note_date,
                gross_weight: grn.gross_weight,
                tare_weight: grn.tare_weight,
                net_weight: grn.net_weight,
                dc_qty: grn.dc_qty,
                deduction_qty: grn.deduction_qty,
                received_qty: grn.received_qty,
                accepted_qty: grn.accepted_qty,
                dc_no: grn.dc_no,
                dc_dt: grn.dc_dt,
                vendor_name:grn.vendor.name,
                product_name:grn.product.name,
                unit:grn.product.unit.symbol,
                vehicle:grn.transport_mode === 'own'? grn.vehicle.name:grn.vendor_vehicle_no,
                invoice_no: grn.invoice.invoice_no,
                invoice_date: grn.invoice.invoice_date,
                weighment_name: grn.weighment_name,
                weighment_slip_no: grn.weighment_slip_no,
                difference_qty:grn.difference_qty,
                plant:grn.plant.alias_name,
                status:grn.status? "Active":"Cancelled"
            }));
            setData({
                total: data.total,
                records: tableData
            });
            setIsLoading(false);
        });
    }, [refreshKey]);
    
    return (
        <div id="outer-container"  className="App" > 
            <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
            <div id="page-wrap">
                <h3 style={{color:"rgb(2, 2, 49)",textAlign:"center"}}>List of Goods Receipt Note</h3>
                <br/>
                <div className="container item-list-table-container">
                    <div className="table-responsive">
                        <ReactTableWrapper
                            title='List of Goods Receipt Note'
                            columns={columns}
                            data={data.records}
                            onRowEdit={onEditGoodsReceiptNote}
                            hideDelete={true}
                            onCSVExport={handleCSVExport}
                            onPDFExport={handlePDFExport}
                            isLoading={isLoading}
                            left_pinned={left_pinned}
                            right_pinned={right_pinned}
                            onRowPrint={onPrintGoodsReceiptNote}
                            hidePrint ={false}
                        />
                    </div>
                </div> 
            </div>
            <button style={{position:"relative",right:"40px"}}className="btn btn-fill btn-primary float-end" type="button" onClick={() =>{navigate("/GoodsReceiptNote")}}>Back</button>
        </div>
    );
}
export default GoodsReceiptNoteTable;