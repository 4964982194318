import React from 'react';
import { useState } from "react";
import Swal from "sweetalert2";
import {Row,Col,Button} from "reactstrap";
import axios from "axios";
import Sidebar from '../../Sidebar';
import { useNavigate } from 'react-router';
import { Link, useParams } from 'react-router-dom';
import { BiSave } from "react-icons/bi";
import { IoIosEye } from "react-icons/io";
import { TiCancel } from "react-icons/ti";
import { AiOutlineHome } from "react-icons/ai";
import { useCookies } from 'react-cookie';
import TableRowsCubeTestReport from "./TableRowsCubeTestReport";
import FloatingControls from '../../components/FloatingControls';
import LoadingOverlay from '../../components/LoadingOverlay';

function CubeTestReportEdit() {

    const [cookies] = useCookies(['myToken']);
    const [plant, setPlant] = React.useState([]);
    const [category] = useCookies(['myCategory']);
    const [inputs, setInputs] = useState({});
    const [superuser] = useCookies(['mySuperuser']);
    const [error,setError] = useState({});
    const [inProgress, setInProgress] = useState(false);

    const [isSubmitting, setIsSubmitting] = useState(true);
    const [isChecked,setIsChecked] = useState({
        chkbx:false
      })
       

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        console.log(name+"name")
        console.log(value+"value")    
    }

    const navigate = useNavigate();
    const handleChange4 = (index, evnt)=>{
        const { name, value } = evnt.target;
        const rowsInput = [...rowsData];
        rowsInput[index][name] = value;
    
            
      
        setRowsData(rowsInput);
    }

    
    const [rowsData, setRowsData] = useState([]);
    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(JSON.stringify(rowsData) + "JSON.stringify(rowsdata");
        var err = 0;
        console.log(err + "err")
    
        if (err == 0) {
          Swal.fire({
            title: 'Do you want to save?',
            showCancelButton: true,
            confirmButtonText: `Yes`,
            cancelButtonText: `No`,
          }).then((result) => {
            if (result.isConfirmed) {
              axios.post('http://127.0.0.1:8000/CubeTestReport/', {
                rowsData: rowsData,
                dcCompCode: inputs.dcCompCode,
                wsSlNo: inputs.wsSlNo
              },
                {
                  headers: {
                    'Authorization': `Token ${cookies['myToken']}`
                  }
                })
                .then(function (response) {
                  console.log(JSON.stringify(response) + "response");
                }).catch(function (error) {
                  console.log(JSON.stringify(error) + "error");
                })
              Swal.fire('Saved!', '', 'success')
              Cancel();
            }
            else if (result.isDismissed) {
              Swal.fire('Not saved', '', 'info')
            }
          });
        }
        else {
          Swal.fire('Please check the value...', '', 'info')
        }
      }

    const view = () => {
        navigate('/CubeTestReportTable')
      }  
      const Back = () => {
        navigate('/Home')
      }

      const Cancel = () => {
        navigate('/Cancel')
      }



    return (
        <>
           <div id="outer-container"  className="App" > 
           <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} data-id={inputs.id}/>
           <LoadingOverlay inProgress={inProgress}/>
           <div id="page-wrap">
           <form onSubmit={handleSubmit} data-id={inputs.id}>
           <div id="header">
        <h3 className = "text font-weight-bold page-title">CUBE TEST REPORT EDIT FORM</h3>
        </div>

        <FloatingControls tableLink="/CompanyTable" enableCancel={false}/>
            <div className="container">
            <Row>
            <Col xl={6} lg={12} md={12}>
            <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3 form-control-panel ">

            <label htmlFor="PlantID" className="form-group col-sm-3 text-right">Plant Location</label>
                    <select id="PlantID" name="PlantID" className="form-control col-sm-8  browser-default custom-select" required onChange={handleChange} value={inputs.PlantID || ""}>
                      <option value="">Select Plant</option>
                      {plant.map((item) => (
                        <option key={item.id} value={item.id}>{item.cmpAlias}</option>
                      ))}
                    </select><br />
                    
                    <label htmlFor="CustomerID" className="form-group col-sm-3 text-right">Customer Name</label>
                    <select id="CustomerID" name="CustomerID" className="form-control col-sm-8  browser-default custom-select" required onChange={handleChange} value={inputs.CustomerID || ""}>
                      <option value="">Customer Name</option>
                      {plant.map((item) => (
                        <option key={item.id} value={item.id}>{item.CusName}</option>
                      ))}
                    </select><br />

                    <label htmlFor="ProjectName" className="form-group col-sm-3 text-right">Project Name</label>
                    <input type="text" className="form-control col-sm-8"  id="ProjectName"  style={{ backgroundColor: "white", cursor: "not-allowed" }} /><br />

                    <label htmlFor="Csr_Prefix" className="form-group col-sm-3 text-right">Prefix</label>
                    <input type="text" className="form-control col-sm-8"   id="Csr_Prefix"  style={{ backgroundColor: "white", cursor: "not-allowed" }} /><br />

                    <label htmlFor="CementType" className="form-group col-sm-3 text-right">Cement Type</label>
                    <input type="text" className="form-control col-sm-8"  id="CementType"  style={{ backgroundColor: "white", cursor: "not-allowed" }} /><br />

                    <label htmlFor="CementContent" className="form-group col-sm-3 text-right">Cem Content</label>
                    <input type="text" className="form-control col-sm-8"   id="CementContent"  style={{ backgroundColor: "white", cursor: "not-allowed" }} /><br/><br/>


                    <label htmlFor="dcProdQty" className="form-group col-sm-3 text-right">Age Test|Qty of Moulds</label>
                    <input type="text" className="form-control col-sm-1"  id="dcProdQty"  style={{ backgroundColor: "white", cursor: "not-allowed" }} /><br />
                    <input required type="number" min="0" value={inputs.dcProdQty || 0} onChange={handleChange} className="form-control col-sm-1" />

                            <label><input type="checkbox" id="chkbx"  name="chkbx"  checked={isChecked.chkbx || false} /></label>
                            <br></br>

                            <label htmlFor="dcProdQty" className="form-group col-sm-3 text-right">Age Test|Qty of Moulds</label>
                            <input type="text" className="form-control col-sm-1"  id="dcProdQty"  style={{ backgroundColor: "white", cursor: "not-allowed" }} /><br />
                    <input required type="number" min="0" value={inputs.dcProdQty || 0} onChange={handleChange} className="form-control col-sm-1" />

                            <label><input type="checkbox" id="chkbx"  name="chkbx"  checked={isChecked.chkbx || false} /></label>
                            <br></br>

                            <label htmlFor="dcProdQty" className="form-group col-sm-3 text-right">Age Test|Qty of Moulds</label>
                            <input type="text" className="form-control col-sm-1"  id="dcProdQty"  style={{ backgroundColor: "white", cursor: "not-allowed" }} /><br />
                    <input required type="number" min="0" value={inputs.dcProdQty || 0} onChange={handleChange} className="form-control col-sm-1" />

                            <label><input type="checkbox" id="chkbx"  name="chkbx"  checked={isChecked.chkbx || false} /></label>
                            <br></br>
                            <label htmlFor="dcProdQty" className="form-group col-sm-3 text-right">Age Test|Qty of Moulds</label>
                            <input type="text" className="form-control col-sm-1"  id="dcProdQty"  style={{ backgroundColor: "white", cursor: "not-allowed" }} /><br />
                    <input required type="number" min="0" value={inputs.dcProdQty || 0} onChange={handleChange} className="form-control col-sm-1" />

                            <label><input type="checkbox" id="chkbx"  name="chkbx"  checked={isChecked.chkbx || false} /></label>
                            <br></br>
                            <label htmlFor="dcProdQty" className="form-group col-sm-3 text-right">Age Test|Qty of Moulds</label>
                            <input type="text" className="form-control col-sm-1"  id="dcProdQty"  style={{ backgroundColor: "white", cursor: "not-allowed" }} /><br />
                    <input required type="number" min="0" value={inputs.dcProdQty || 0} onChange={handleChange} className="form-control col-sm-1" />

                            <label><input type="checkbox" id="chkbx"  name="chkbx"  checked={isChecked.chkbx || false} /></label>
                            <br></br>
                            </div>

                </Col>

                <Col xl={6} lg={12} md={12}>

                <div className="form-row table-bordered shadow p-2 my-2  border-secondary p-2 mb-3 form-control-panel " >

<label htmlFor="CastingDate" className="form-group col-sm-4 text-right">Casing Date</label>
        <input type="date" className="form-control col-sm-6" id="CastingDate"value={inputs.CastingDate || ""} style={{backgroundColor:"white"}} name="soHSlNo" /><br/>
        <button type="button"  className="btn btn-primary text-blue text-bold" data-toggle="modal" data-target="#exampleModalCenter">Help</button><br/><br/>
        
    <label htmlFor="SlNumber" className="form-group col-sm-4 text-right">Report No</label>
    <input type="text" className="form-control col-sm-7"  id="SlNumber"  style={{ backgroundColor: "white", cursor: "not-allowed" }} /><br />

    <label htmlFor="SlDate" className="form-group col-sm-4 text-right">Date</label>
    <input type="date" className="form-control col-sm-7"  id="SlDate"  style={{ backgroundColor: "white" }} /><br />

    <label htmlFor="ScheduleNo" className="form-group col-sm-4 text-right">Schedule No.</label>
    <input type="text" className="form-control col-sm-7"  id="ScheduleNo"  style={{ backgroundColor: "white", cursor: "not-allowed" }} /><br />

    <label htmlFor="ScheduleQty" className="form-group col-sm-4 text-right">Schedule Quantity</label>
    <input type="text" className="form-control col-sm-7"  id="ScheduleQty"  style={{ backgroundColor: "white", cursor: "not-allowed" }} /><br />

    <label htmlFor="SrName" className="form-group col-sm-4 text-right">Marketing Name</label>
    <input type="text" className="form-control col-sm-7"  id="SrName"  style={{ backgroundColor: "white", cursor: "not-allowed" }} /><br />

    <label htmlFor="Grade" className="form-group col-sm-4 text-right">Grade</label>
    <input type="text" className="form-control col-sm-7"  id="Grade"  style={{ backgroundColor: "white", cursor: "not-allowed" }} /><br />

    <label htmlFor="SuppliedQty" className="form-group col-sm-4 text-right">Supplied Qty.</label>
    <input type="text" className="form-control col-sm-7"  id="SuppliedQty"  style={{ backgroundColor: "white", cursor: "not-allowed" }} /><br />

    <label htmlFor="NoofMoulds" className="form-group col-sm-4 text-right">No.of Moulds Casted</label>
    <input type="text" className="form-control col-sm-7"  id="NoofMoulds"  style={{ backgroundColor: "white", cursor: "not-allowed" }} /><br />

    <label htmlFor="SampleID" className="form-group col-sm-4 text-right">Sample ID|Reference</label>
    <input type="text" className="form-control col-sm-7"  id="SampleID"  style={{ backgroundColor: "white", cursor: "not-allowed" }} /><br />

    <label htmlFor="TechnicianName" className="form-group col-sm-4 text-right">Technician Name</label>
    <input type="text" className="form-control col-sm-7"  id="TechnicianName"  style={{ backgroundColor: "white", cursor: "not-allowed" }} /><br />

    <label htmlFor="Remarks_hdr" className="form-group col-sm-4 text-right">Remarks</label>
    <input type="text" className="form-control col-sm-7"  id="Remarks_hdr"  style={{ backgroundColor: "white", cursor: "not-allowed" }} /><br />
    </div>
                    </Col>

                </Row>


                <div className="form-row  shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue container table-responsive">
                <table className="table-bordered table-responsive searchTable table order-list" id="myTable" style={{textAlign:"center",borderRadius:"10px",backgroundColor:"RGB(188,232,253)"}}>
                    <thead>
                        <tr style={{backgroundColor:"RGB(188,232,253)"}}>
                            <th style={{display:"none"}}>Id</th>
                            <th>Sq No</th>
                            <th>Cube Id</th>
                            <th>Age</th>
                            <th>Testing Date</th>
                            <th>Age 2</th>
                            <th>Cured Place</th>
                            <th>Weight(gms)</th>
                            <th>Dimension(mm)</th>
                            <th>Area mm2</th>
                            <th>Max Load(kn)</th>
                            <th>Strength(mpa)</th>
                            <th>Average Strength(mpa)</th>
                            <th>Average 2 Strength(mpa)</th>
                            <th>Difference</th>
                            <th>Result(Pass/Fail)</th>
                            <th>Customer Weight(gms)</th>
                            <th>Customer MAx Load(kn)</th>
                            <th>Customer Strength(mpa)</th>
                            <th>Customer Average Strength2(mpa)</th>
                            <th>Customer Difference</th>
                            <th>Customer Result(Pass/Fail)</th>
                            <th>Remarks</th>
                        </tr>
                    </thead>
                    <tbody >
                    <TableRowsCubeTestReport rowsData={rowsData}  handleChange4={handleChange4}   />
                    </tbody>
                </table>
            </div> 
                </div>

                <div className="footer text-center">
                {((superuser['mySuperuser']) || (category['myCategory'][0].Is_cubetestreport_for_so_edit === true)) &&(
                    <Button  type="submit" className="btn btn-twitter" style={{width:"80px",fontWeight:"bold"}} >Update</Button> 
                )}&nbsp;&nbsp;
                {((superuser['mySuperuser']) || (category['myCategory'][0].Is_cubetestreport_for_so_view === true)|| (category['myCategory'][0].Is_cubetestreport_for_so_edit === true) || (category['myCategory'][0].Is_cubetestreport_for_so_delete === true)) &&(  
                    <Button className="btn btn-twitter"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={view}>View</Button>
                )}&nbsp;&nbsp;
                    <Button className="btn btn-twitter"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={Back}>Home</Button>
            </div>



</form>

           </div>

</div>

    </>
    );
  }
  export default CubeTestReportEdit;

