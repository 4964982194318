function TableRowsPurchaseInvoice({rowsData, deleteTableRows, handleChange4,products,concrete}) {


    return(
        
        rowsData.map((data, index)=>{
            const {soDSqlNo,soDTaxCode,soDConStruc,soDDeliveryMode,soDAmt,soDRate,soDOrdQty,soDUnit,soDProdCode,soDRemarks}=data
        return(

            <tr  key={index} className="text-center detailclass" id="after-this1">
                <td><button type="button"className="btn btn-danger" onClick={()=>(deleteTableRows(index))}>x</button></td>
                <td><input type="text"  className="form-control add"  value={soDSqlNo} style={{width:"100px",backgroundColor:"white", cursor: "not-allowed"}} readOnly={true}   name="soDSqlNo" /></td>
                <td>
   
                </td>
                <td>
                   
                </td>
                <td>
                   
                </td>
                <td>

                </td>
                <td>
                   
                </td>               
          
                <tr>
            
                </tr>
       
                <td colSpan="13">
                    <input type="text" style={{width:"350px"}} className="form-control"value={soDRemarks} onChange={(evnt)=>(handleChange4(index, evnt))}  name="soDRemarks"/>
                </td>
        
            </tr>
        )
        })
   
    )
    
}

export default TableRowsPurchaseInvoice;