import React from 'react';
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { Row, Col, Button } from "reactstrap";
import axios from "axios";
import Sidebar from '../../Sidebar';
import { useNavigate } from 'react-router';
import { BiSave } from "react-icons/bi";
import { IoIosEye } from "react-icons/io";
import { TiCancel } from "react-icons/ti";
import { AiOutlineHome } from "react-icons/ai";
import { useCookies } from 'react-cookie';
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import { moment } from 'moment';
import { useLocation, Link } from 'react-router-dom';
import {displayErrorToast} from '../../helpers';
import ErrorToast from '../../ErrorToast';

import FloatingControls from '../../components/FloatingControls';
import LoadingOverlay from '../../components/LoadingOverlay';

function CreditNote() {
    const location = useLocation(); 
    const [cookies] = useCookies(['myToken']);
    const [category] = useCookies(['myCategory']);
    const [inProgress, setInProgress] = useState(false);
    const [superuser] = useCookies(['mySuperuser']);
    const [inputs, setInputs] = useState({});
    const [plant, setPlant] = React.useState([]);
    const [Appr_so_dtl, setAppr_so_dtl] = React.useState([]);
    const navigate = useNavigate();
    const handleChange = (event) => {
      const name = event.target.name;
      const value = event.target.value;
     try{
          if (name === 'dcCompCode') {
            fetch(`http://127.0.0.1:8000/CreditNote_drp/${value}`, {
              method: 'GET',
              headers: {
                'Authorization': `Token ${cookies['myToken']}`,
                'Content-Type': 'application/json'
              }
            })
              .then(response => response.json())
              .then((data) => {
                console.log(JSON.stringify(data) + "ata_creditnote_drop");
                setAppr_so_dtl(data.wrkshedule)
              })
          }
          setInputs(values => ({ ...values, [name]: event.target.value }))
        }
        catch(e)
        {
          displayErrorToast(e);
        }
    }

    const [rowsData, setRowsData] = useState([]);
    const handleSubmit = (event) => {
      event.preventDefault();
      var err = 0;
     try{
          if (err == 0) {
            Swal.fire({
              title: 'Do you want to save?',
              showCancelButton: true,
              confirmButtonText: `Yes`,
              cancelButtonText: `No`,
            }).then((result) => {
              if (result.isConfirmed) {
                axios.post('http://127.0.0.1:8000/CreditNote/', {
                  rowsData: rowsData,
                  dcCompCode: inputs.dcCompCode,
                  wsSlNo: inputs.wsSlNo
                },
                  {
                    headers: {
                      'Authorization': `Token ${cookies['myToken']}`
                    }
                  })
                  .then(function (response) {
                    console.log(JSON.stringify(response) + "response");
                  }).catch(function (error) {
                    console.log(JSON.stringify(error) + "error");
                  })
                Swal.fire('Saved!', '', 'success')
                Cancel();
              }
              else if (result.isDismissed) {
                Swal.fire('Not saved', '', 'info')
              }
            });
          }
          else {
            Swal.fire('Please check the value')
          }
        }
        catch(e)
        {
          displayErrorToast(e);
        }
    }

  const Cancel = () => {
      setInputs(() => "")
      setRowsData([])
    }
    const view = () => {
      navigate('/CreditNoteTable')
    } 
  
    const Back = () => {
      navigate('/Home')
    }

return (
    <>
          <div id="outer-container"  className="App" > 
          <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
          <ErrorToast/>
          <LoadingOverlay inProgress={inProgress}/>
        <div id="page-wrap">
        <form onSubmit={handleSubmit} >
            <div id="header">
              <h3 className="text font-weight-bold" style={{ color: "rgb(12, 22, 105)", paddingTop: "50px", textAlign: "center", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}>CREDIT NOTE </h3>
            </div>
            <FloatingControls tableLink="/BankTable"  enableCancel={false}/>
            <div className="container p-2 ">
       
            </div>
            <div className="container">
            <Row>
                <Col xl={6} lg={12} md={12}>

                <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3  form-control-panel " >
                    <label htmlFor="dcCompCode" className="form-group col-sm-5 text-right">Plant Location</label>
                    <select id="dcCompCode" name="dcCompCode" className="form-control col-sm-6  browser-default custom-select" required onChange={handleChange} value={inputs.dcCompCode || ""}>
                      <option value="">Select Plant</option>
                      {plant.map((item) => (
                        <option key={item.id} value={item.id}>{item.MCAlias}</option>
                      ))}
                    </select><br />
               
                    <label htmlFor="dcCompCode" className="form-group col-sm-5 text-right">Billing Name</label>
                    <select id="dcCompCode" name="dcCompCode" className="form-control col-sm-6  browser-default custom-select" required onChange={handleChange} value={inputs.dcCompCode || ""}>
                      <option value="">Select Billing Name</option>
                      {plant.map((item) => (
                        <option key={item.id} value={item.id}>{item.MCAlias}</option>
                      ))}
                    </select><br />
                    <label htmlFor="dcCompCode" className="form-group col-sm-5 text-right">Site Name</label>
                    <select id="dcCompCode" name="dcCompCode" className="form-control col-sm-6  browser-default custom-select" required onChange={handleChange} value={inputs.dcCompCode || ""}>
                      <option value="">Select Site Name</option>
                      {plant.map((item) => (
                        <option key={item.id} value={item.id}>{item.MCAlias}</option>
                      ))}
                    </select><br />
                    <label htmlFor="dcCompCode" className="form-group col-sm-5 text-right">Invoice No</label>
                    <select id="dcCompCode" name="dcCompCode" className="form-control col-sm-6  browser-default custom-select" required onChange={handleChange} value={inputs.dcCompCode || ""}>
                      <option value="">Select Invoice No</option>
                      {plant.map((item) => (
                        <option key={item.id} value={item.id}>{item.MCAlias}</option>
                      ))}
                    </select><br />
                          
                    <label class="form-group col-sm-5 text-right">Voucher Mode</label>
                        <select  id="CN_Voucher_Mode" name="CNHdr_VoucherMode" class="form-control col-sm-6 " required="">
                            <option value="">Select Voucher Mode</option>
                            <option value="Accounting Invoice">Accounting Invoice</option>
                            <option value="Item Invoice">Item Invoice</option>
                        </select>

    
                    </div>
                   
                    </Col>    
                    <Col xl={6} lg={12} md={12}>
                    <div className="form-row table-bordered shadow p-2 my-2  border-secondary p-2 mb-3 form-control-panel" >
                    <label htmlFor="wsSlNo" className="form-group col-sm-4 text-right">Credit Note No.</label>
                    <input type="text" className="form-control col-sm-6" id="wsSlNo"value={inputs.wsSlNo || ""} readOnly={true} style={{backgroundColor:"white", cursor: "not-allowed"}} name="soHSlNo" /><br/>

                    <label htmlFor="wsSlNo" className="form-group col-sm-4 text-right">Credit Note Date</label>
                    <input type="date" className="form-control col-sm-6" id="wsSlNo"value={inputs.wsSlNo || ""}  style={{backgroundColor:"white"}} name="soHSlNo" /><br/>

                    <label htmlFor="wsSlNo" className="form-group col-sm-4 text-right">Prefix</label>
                    <input type="text" className="form-control col-sm-6" id="wsSlNo"value={inputs.wsSlNo || ""}  style={{backgroundColor:"white"}} name="soHSlNo" /><br/>

                    </div>
                    </Col>
                   
                    </Row>
                    <div className="form-row  shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue container table-responsive">
                    <table className="table-bordered  searchTable table order-list" id="myTable"> 
                <thead>
                <tr style={{backgroundColor:"rgb(56, 108, 187)"}}>
                        <th>Action</th>
                        <th>S.No</th>
                        <th>Description</th>
                        <th>Unit</th>
                        <th>Qty</th>
                        <th>Basic Rate</th>
                        <th>Amount</th>
                        <th>Tax %</th>
                        <th>Tax Amount</th>
                        <th>Total Amount</th>
                        
                    </tr>
                </thead>  
                <tbody>
                   
                </tbody>
            </table> 
            <Col xl={6} lg={12} md={12}>
                    <div className="form-row table-bordered shadow p-2 my-2  border-secondary p-2 mb-3 form-control-panel" >
                    <label htmlFor="wsSlNo" className="form-group col-sm-4 text-right">Total Taxable Amount</label>
                    <input type="text" className="form-control col-sm-6" id="wsSlNo"value={inputs.wsSlNo || ""} readOnly={true} style={{backgroundColor:"white", cursor: "not-allowed"}} name="soHSlNo" /><br/>

                    <label htmlFor="wsSlNo" className="form-group col-sm-4 text-right">Total GST Amount</label>
                    <input type="text" className="form-control col-sm-6" id="wsSlNo"value={inputs.wsSlNo || ""}  style={{backgroundColor:"white"}} name="soHSlNo" /><br/>

                    <label htmlFor="wsSlNo" className="form-group col-sm-4 text-right">Gross Amount</label>
                    <input type="text" className="form-control col-sm-6" id="wsSlNo"value={inputs.wsSlNo || ""}  style={{backgroundColor:"white"}} name="soHSlNo" /><br/>

                    <label htmlFor="wsSlNo" className="form-group col-sm-4 text-right">Round Off</label>
                    <input type="text" className="form-control col-sm-6" id="wsSlNo"value={inputs.wsSlNo || ""}  style={{backgroundColor:"white"}} name="soHSlNo" /><br/>
                    </div>
                    </Col>
                    <Col xl={6} lg={12} md={12}>
                    <div className="form-row table-bordered shadow p-2 my-2  border-secondary p-2 mb-3 form-control-panel " >
                    <label htmlFor="wsSlNo" className="form-group col-sm-4 text-right">CGST Amount</label>
                    <input type="text" className="form-control col-sm-6" id="wsSlNo"value={inputs.wsSlNo || ""} readOnly={true} style={{backgroundColor:"white", cursor: "not-allowed"}} name="soHSlNo" /><br/>

                    <label htmlFor="wsSlNo" className="form-group col-sm-4 text-right">SGST Amount</label>
                    <input type="type" className="form-control col-sm-6" id="wsSlNo"value={inputs.wsSlNo || ""}  style={{backgroundColor:"white"}} name="soHSlNo" /><br/>

                    <label htmlFor="wsSlNo" className="form-group col-sm-4 text-right">IGST Amount</label>
                    <input type="text" className="form-control col-sm-6" id="wsSlNo"value={inputs.wsSlNo || ""}  style={{backgroundColor:"white"}} name="soHSlNo" /><br/>

                    <label htmlFor="wsSlNo" className="form-group col-sm-4 text-right">TCS @%/TCS Amount</label>
                    <input type="text" className="form-control col-sm-6" id="wsSlNo"value={inputs.wsSlNo || ""}  style={{backgroundColor:"white"}} name="soHSlNo" /><br/>

                    <label htmlFor="wsSlNo" className="form-group col-sm-4 text-right">Total Credit Note value</label>
                    <input type="text" className="form-control col-sm-6" id="wsSlNo"value={inputs.wsSlNo || ""}  style={{backgroundColor:"white"}} name="soHSlNo" /><br/>

                    </div>
                    </Col>
             
        </div>
        <div className="form-row  shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue container table-responsive">
                    <table className="table-bordered  searchTable table order-list" id="myTable"> 
                <thead>
                <tr style={{backgroundColor:"rgb(56, 108, 187)"}}>
                        <th>Sl. No.</th>
                        <th>Doc Type</th>
                        <th>Plant Name</th>
                        <th>Project/Site Name</th>
                        <th>Invoice/Debit Note/Number</th>
                        <th>Invoice Date</th>
                        <th>Invoice Amount</th>
                        <th>Received Amount</th>
                        <th>Balance Amount</th>
                        <th>Credit Amount</th>
                        <th>Remarks</th>
                        
                    </tr>
                </thead>  
                <tbody>
                   
                </tbody>
            </table>
            </div>
                </div>
                <div className="footer text-center">
                <Button  type="submit" className="btn btn-twitter" style={{width:"80px",fontWeight:"bold"}} >Save</Button> &nbsp;&nbsp;
                                <Button type="reset" className="btn btn-twitter" style={{width:"80px",fontWeight:"bold"}} onClick={Cancel}>Update</Button> &nbsp;&nbsp;
                                <Button className="btn btn-twitter"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={view}>View</Button>&nbsp;&nbsp;
                                <Button className="btn btn-twitter"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={Back}>Home</Button>
                </div>
            </form>
            </div>

          </div>



















</>

);
}
export default CreditNote;