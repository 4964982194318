import React from 'react';
import { useState ,useEffect} from "react";
import Swal from "sweetalert2";
import {Row,Col,Button} from "reactstrap";
import axios from "axios";
import Sidebar from '../Sidebar';
import { useNavigate } from 'react-router';
import { BiSave } from "react-icons/bi";
import { IoIosEye } from "react-icons/io";
import { TiCancel } from "react-icons/ti";
import { AiOutlineHome } from "react-icons/ai";
import { useCookies } from 'react-cookie';
import { default as ReactSelect } from "react-select";
import TableRowsConsumptionNote from "./TableRowsConsumptionNote";
import { components } from "react-select";
import {moment} from 'moment';

import { Link } from 'react-router-dom';

function ConsumptionNote() {
    const [cookies] = useCookies(['myToken']);
    const [category] = useCookies(['myCategory']);
    const [superuser] = useCookies(['mySuperuser']);
    const [inputs, setInputs] = useState({});
    const [concrete, setConcrete] = React.useState([]);
    const [taxes, setTaxes] = React.useState([]);
    const [products, setProducts] = React.useState([]);
    const [plant, setPlant] = React.useState([]);
    const [Appr_so_dtl, setAppr_so_dtl]= React.useState([]);
    const handleChange4 = (index, evnt)=>{
        const { name, value } = evnt.target;
        const rowsInput = [...rowsData];
        rowsInput[index][name] = value;
        if(name === 'cDmxCem1' || name === 'cDmxCem2' || name === 'cDmxCem3' || name === 'cDmxCem4' || name === 'cDmxWat1' || name === 'cDmxWat2' )
        {
            
            var cem = document.getElementsByClassName("val1");
            var cem_total = 0;
            for (var i = 0;  i < cem.length; i++) 
            {
                if(cem[i].value == '')
                {
                    cem[i].value = 0
                }
                console.log(index+"index")
                var r =cem[i].parentNode.parentNode.rowIndex ;
                console.log(r+"rrrrrrrrrrrr")
                if( (r-1) == index){
                    // console.log(r+"r"+index +"index")
                    cem_total+=parseFloat(cem[i].value)
                }
            }
            rowsInput[index]['totalcem']= cem_total.toFixed(2)
            
            var wat_total =0
            var wat= document.getElementsByClassName("waterval");
            for (var i = 0;  i < wat.length; i++) 
            {
                if(wat[i].value == '')
                {
                    wat[i].value = 0
                }
                var r =wat[i].parentNode.parentNode.rowIndex ;
                console.log(r+"rrrrrrrrrrrr")
                if( (r-1) == index)
                {
                    wat_total+=parseFloat(wat[i].value)
                }
            }
            var WaterCementRatio= wat_total / cem_total;
            rowsInput[index]['watercem']= WaterCementRatio.toFixed(2);
            
            var elements = document.getElementsByClassName("totalval");
            console.log(elements+"elements")
            var sum=0
            for (var i = 0;  i <elements.length; i++) 
            {
                if(elements[i].value == '')
                {
                    elements[i].value = 0
                }
                var r =elements[i].parentNode.parentNode.rowIndex ;
                console.log(r+"rrrrrrrrrrrr")
                if( (r-1) == index)
                {
                   sum+=parseFloat(elements[i].value)
                }
            }
            rowsInput[index]['cDmxTotal']=sum.toFixed(2)
            console.log(sum+"sum")
        }
            
        else if(name === 'cDmxAgg1' || name === 'cDmxAgg2' || name === 'cDmxAgg3' || name === 'cDmxAgg4' || name === 'cDmxAgg5' || name ==='cDmxAdm1' || name === 'cDmxAdm2' || name === 'cDmxOth')
        {
            var elements = document.getElementsByClassName("totalval");
            console.log(elements+"elements");
            var sum=0;
            for (var i = 0;  i <elements.length; i++) 
            {
                if(elements[i].value == '')
                {
                    elements[i].value = 0
                }
                var r =elements[i].parentNode.parentNode.rowIndex ;
                console.log(r+"rrrrrrrrrrrr")
                if( (r-1) == index)
                {
                   sum+=parseFloat(elements[i].value)
                }
            }
            rowsInput[index]['cDmxTotal']=sum.toFixed(2)
            console.log(sum+"sum")
        }
        setRowsData(rowsInput);
    }
    useEffect(() => {
        async function getCharacters() {
          const response = await fetch("http://127.0.0.1:8000/ConsumptionNote/",{ method: 'GET',
          headers: {
              'Authorization': `Token ${cookies['myToken']}`
          }});
          const data = await response.json();
          console.log(JSON.stringify(data)+"data_consumptionNote");
          if(data.msg)
          {
            Swal.fire((data.msg), '', 'info')
          }
          else
          {
          setPlant(data.company)
          }
        }
        getCharacters();
      }, []);
    
      const navigate = useNavigate();
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        console.log(name+"name")
        console.log(value+"value")
        if(name === 'cDmxCompCode')
        {
            fetch(`http://127.0.0.1:8000/ConsumptionNote_drp/${value}`,{
              method: 'GET',
              headers: {
                  'Authorization': `Token ${cookies['myToken']}`,
                  'Content-Type': 'application/json'
              }
          })
            .then(response => response.json())
            .then((data) => {
            console.log(JSON.stringify(data)+"data_consumptionnote_drop");
            setAppr_so_dtl(data.sales)
            })
        }
        setInputs(values => ({...values, [name]: event.target.value}))
    }

    const [rowsData, setRowsData] = useState([]);
 
const handleSubmit=(event)=> {
    event.preventDefault();
    console.log(JSON.stringify(rowsData)+"JSON.stringify(rowsdata");
    var err=0;
    console.log(err+"err")
    for(var i=0; i<rowsData.length; i++) 
    {
        if(((rowsData[i].cDmxTotal)< 2200)||((rowsData[i].cDmxTotal)> 2600))
        {
        var err= 1;
        console.log(err+"err_cDmxTotal") 
        break;
        }
        if(((rowsData[i].watercem)< 0.50) || ((rowsData[i].watercem)> 0.60) )
        {
            var err =1;
            console.log(err+"err_watercem")
        }
    }
    if (err == 0) {
        Swal.fire({title: 'Do you want to save?',  
        showCancelButton: true,  
        confirmButtonText: `Yes`,  
        cancelButtonText: `No`,
        }).then((result) => {  
          if (result.isConfirmed) { 
            axios.post('http://127.0.0.1:8000/ConsumptionNote/', {
                rowsData:rowsData,
                cDmxCompCode:inputs.cDmxCompCode,
                soHSlNo:inputs.soHSlNo
            }, 
            {
              headers: {
                'Authorization': `Token ${cookies['myToken']}`
              }
            })
            .then(function (response) 
            {
              console.log(JSON.stringify(response)+"response");
            }).catch(function (error) 
            {
              console.log(JSON.stringify(error)+"error");
            })
            Swal.fire('Saved!', '', 'success') 
            Cancel();
          } 
          else if (result.isDismissed) 
          {    
            Swal.fire('Not saved', '', 'info')  
          }
        });
}
else
{
  Swal.fire('Please check the Density value.\n It must be in the range of 2200 - 2600 and WC Ratio must lie between 0.50 and 0.60 ...', '', 'info')   
}
  }
  const addTableRows = () => {
    const count=rowsData.length +1;
    // console.log(count+"count_add")
    const rowsInput={
            soDSqlNo:count,
            soDTaxCode:'',
            soDConStruc:'',
            soDDeliveryMode:'',
            soDAmt:'',
            soDRate:'',
            soDOrdQty:'',
            soDUnit:'',
            soDProdCode:'',
            soDRemarks:''
        }
        setRowsData([...rowsData, rowsInput])
    }

    const deleteTableRows = (index)=>{
        const rows = [...rowsData];
        rows.splice(index, 1);
        for(var i=0; i<rows.length; i++) 
        {
            rows[i]['soDSqlNo']= i+1
        }
        setRowsData(rows);
}

  const Dtl_Submit=(event)=> {
    console.log(event.target.value+"value slno")
    const slno=event.target.value;
    const plant=inputs.cDmxCompCode;
    console.log(plant+"plant");
    fetch(`http://127.0.0.1:8000/ConsumptionNote_Dtl/${slno}/${plant}`,{
              method: 'GET',
              headers: {
                  'Authorization': `Token ${cookies['myToken']}`,
                  'Content-Type': 'application/json'
              }
          })
            .then(response => response.json())
            .then((data) => {
            console.log(JSON.stringify(data)+"data_ConsumptionNote_Dtl");
            setInputs(values => ({...values, ['billname']: data.billname,
            ['salesofficer']:data.sales,['billadd1']:data.billadd1,
            ['billadd2']:data.billadd2,['billadd3']:data.billadd3,
            ['SiteCusName']:data.sitename,['siteName']:data.siteproject,
            ['siteadd1']:data.siteadd1,['siteadd2']:data.siteadd2,
            ['siteadd3']:data.siteadd3,['soHSlDt']:data.salesdt,
            ['soHSlNo']:data.soHSlNo,['soHSlTime']:data.salestm,
            ['soHPoNo']:data.salespono,['soHPoDt']:data.salespodt,
            ['soHTransportMode']:data.salesmdt,
            ['soHBReportReq']:data.salesbtchreport,
            ['soHDeliveryDt']:data.salesdeldt,
            ['soHValidity']:data.salesvalidity,
            ['soHRemarks']:data.salesremarks
            }))
            setRowsData(data.salesdtl)
            })
  }
  

        const view = () => {
          navigate('')
        }  
        const Back = () => {
          navigate('/Home')
        }
        const Cancel = () => {
            setInputs(() => "")
            setRowsData([])
        }
    return (
    <>
     <div id="outer-container"  className="App" > 
        <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
        <div id="page-wrap">
        <form onSubmit={handleSubmit} > 
          <div id="header">
              <h3 className = "text font-weight-bold" style={{color:"rgb(12, 22, 105)",paddingTop:"50px",textAlign:"center",fontFamily:"'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif"}}>CONSUMPTION NOTE </h3>
          </div>
         
            <div className="container p-2 "> 
                {/* <div className="footer text-center">
                {((superuser['mySuperuser']) || (category['myCategory'][0].Is_mixdesign_for_so_add === true)) &&(
                  <Button  type="submit"  style={{width:"80px"}} color="primary">{<BiSave size={28}/>}</Button>
                )} &nbsp;&nbsp;
                {((superuser['mySuperuser']) || (category['myCategory'][0].Is_mixdesign_for_so_add === true)) &&(
                  <Button type="reset"color="primary" style={{width:"80px"}} onClick={Cancel}>{ <TiCancel size={28}/>}</Button>
                )} &nbsp;&nbsp;
                {((superuser['mySuperuser']) || (category['myCategory'][0].Is_mixdesign_for_so_view === true)|| (category['myCategory'][0].Is_mixdesign_for_so_edit === true) || (category['myCategory'][0].Is_mixdesign_for_so_delete === true)) &&(  
                  <Button color="primary"  type="button"style={{width:"80px"}} onClick={view}>{ <IoIosEye size={28}/>}</Button>
                )}&nbsp;&nbsp;
                  <Button  color="primary"  type="button"style={{width:"80px"}} onClick={Back}>{ <AiOutlineHome size={28}/>}</Button>
                </div> */}
                   <div className="text-center" style={{paddingLeft:"38%",textAlign:"center"}}>
                <div className="social-btn"  >
				    <ul className="text-center">
                        {((superuser['mySuperuser']) || (category['myCategory'][0].Is_consumptionnote_for_so_add === true)) &&(
                        <li className="text-center">
                            <button type="submit" className="google" style={{borderColor:"transparent"}}>
                                <i className="fa fa-floppy-o"></i><i className="fa fa-floppy-o"></i>
                            </button>
						</li> 
                        )} &nbsp;&nbsp;
						
                        {((superuser['mySuperuser']) || (category['myCategory'][0].Is_consumptionnote_for_so_add === true)) &&(
                        <li className="text-center">
                            <button type="reset" className="google" onClick={Cancel} style={{borderColor:"transparent"}}>
                            {/* <Link to="" onClick={Cancel} className="google"> */}
                                <i className="fa fa-ban"></i><i className="fa fa-ban"></i>
                            {/* </Link> */}
                            </button>
						</li> 
                        )} &nbsp;&nbsp;

                        {((superuser['mySuperuser']) || (category['myCategory'][0].Is_consumptionnote_for_so_view === true)|| (category['myCategory'][0].Is_consumptionnote_for_so_edit === true) || (category['myCategory'][0].Is_consumptionnote_for_so_delete === true)) &&(  
                        <li className="text-center">
                            {/* <Link to={'/DesignMixTable'} className="facebook"> */}
                                <i className="fa fa-eye"></i><i className="fa fa-eye"></i>
                            {/* </Link> */}
                        </li> 
                        )} &nbsp;&nbsp;
								
						<li className="text-center">
                            <Link  to={'/Home'} className="twitter">
                                <i className="fa fa-home"></i><i className="fa fa-home"></i>
                            </Link>
						</li>
					</ul>
                </div>
            </div>
            </div>
            <div className="container"> 
                <Row> 
                <Col xl={6} lg={12} md={12}>   
                    <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3  " style={{backgroundColor:"rgba(161,161,163,0.6)",borderRadius:"10px",fontWeight:"bold"}}>
                    <label htmlFor="billname" className="form-group col-sm-4 text-right">Plant Location</label>
                        <input type="text" className="form-control col-sm-6" id="billname"  style={{backgroundColor:"white"}}/><br/>
                       
               
                    </div>
                        
               

                </Col>

                <Col xl={6} lg={12} md={12}>   
                    <div className="form-row table-bordered shadow p-2 my-2  border-secondary p-2 mb-3 " style={{backgroundColor:"rgba(161,161,163,0.6)",borderRadius:"10px",fontWeight:"bold"}}>
                        <label htmlFor="soHSlNo" className="form-group col-sm-4 text-right">SI No</label>
                        <input type="text" className="form-control col-sm-6" id="soHSlNo"value={inputs.soHSlNo || ""}  style={{backgroundColor:"white"}} name="soHSlNo" /><br/>

                        <label htmlFor="billname" className="form-group col-sm-4 text-right">Date</label>
                        <input type="date" className="form-control col-sm-6" id="billname" value={inputs.billname || ""} style={{backgroundColor:"white"}}/><br/>

                        

                       
                    </div>        
                    
              
                    
                    
                </Col>
            </Row>
            <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue container table-responsive">
                <table className="table order-list" id="myTable" style={{textAlign:"center",borderRadius:"10px",backgroundColor:"rgba(161,161,163,0.5)"}}>
                    <thead>
                        <tr>
                        <th colSpan="6">Source(Consumption)</th>
                            <th colSpan="6">Destination(production)</th>
                        </tr>
                        <tr>
                            <th><button type="button"className="btn btn-success" onClick={addTableRows}>+</button></th>
                            <th colSpan="1">Name of Item</th>
                            <th colSpan="1">Qty.</th>
                            <th colSpan="1">Unit</th>
                            <th colSpan="1">Rate</th>
                            <th colSpan="1">Amount</th>
                            <th colSpan="1">Name of Item</th>
                            <th colSpan="1">Qty.</th>
                            <th colSpan="1">Unit</th>
                            <th colSpan="1">Rate</th>
                            <th colSpan="1">Amount</th>
                        </tr>
                       
                    </thead>
                    <tbody >
                        <TableRowsConsumptionNote rowsData={rowsData} deleteTableRows={deleteTableRows} handleChange4={handleChange4}products={products} taxes={taxes} concrete={concrete}/>
                    </tbody>
                </table>
            </div>  
                        </div>
          




                <div className="footer text-center">
                {((superuser['mySuperuser']) || (category['myCategory'][0].Is_consumptionnote_for_so_add === true)) &&(
                    <Button  type="submit" color="primary" style={{width:"80px",fontWeight:"bold"}} >Save</Button> 
                )}&nbsp;&nbsp;
                {((superuser['mySuperuser']) || (category['myCategory'][0].Is_consumptionnote_for_so_add === true)) &&(
                    <Button type="reset" color="primary" style={{width:"80px",fontWeight:"bold"}} onClick={Cancel}>Cancel</Button>
                )}&nbsp;&nbsp;
                {((superuser['mySuperuser']) || (category['myCategory'][0].Is_consumptionnote_for_so_view === true)|| (category['myCategory'][0].Is_consumptionnote_for_so_edit === true) || (category['myCategory'][0].Is_consumptionnote_for_so_delete === true)) &&(  
                    <Button color="primary"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={view}>View</Button>
                )}&nbsp;&nbsp;
                    <Button color="primary"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={Back}>Home</Button>
                </div>
        </form>
        </div>
    </div>
         </>
    );
  }
  export default ConsumptionNote;