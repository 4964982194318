import {getAllVendors} from '../../services/VendorServices'

export function checkForDuplicateVendors(vendorName, cookies, existingId) {
    
    
    let nameCheck = new Promise((resolve, reject) => {
      getAllVendors(cookies)
      .then((data) => {

        let isUserExists = false;
        data.vendor_list.forEach(vendor =>{
          if((vendor.entity_name) === vendorName.trim().toUpperCase()
          && parseInt(existingId)!== vendor.id){
            console.log(`Vendor exists with the name - ${vendorName}`) 
            isUserExists = true;
            
          }
        });
        resolve(isUserExists);
      });  
    });

    return nameCheck;
  }