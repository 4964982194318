import {
    Row,
    Col,
    Button,
  } from 'reactstrap';
  import { useNavigate } from 'react-router';
  import Sidebar from '../../Sidebar';
  import Swal from "sweetalert2";
  import { useState } from "react";
  import { Link, useParams } from 'react-router-dom';
  import React, { Component, Fragment} from 'react';
  import { BiSave } from "react-icons/bi";
  import { IoIosEye } from "react-icons/io";
  import { AiOutlineHome } from "react-icons/ai";
  import { useCookies } from 'react-cookie';
  import {displayErrorToast,displayError,parseBoolean} from '../../helpers';
  import { updateVendor,getVendorDetails } from '../../services/VendorServices';
import { getAllGroups } from '../../services/GroupFormServices';
import { checkForDuplicateVendors } from './VendorUtils';
import FloatingControls from '../../components/FloatingControls';
import ErrorToast from '../../ErrorToast';
import StatusDropDown from '../../components/StatusDropDown';
import { getIndianStates } from '../../utils/PicklistProvider';


  const axios = require('axios').default;
  function VendorEdit() 
  {
    const [cookies] = useCookies(['myToken']);
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({});
    const [error, setError] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(true);
    const {id}=useParams()
    const [inProgress, setInProgress] = useState(false);
   
    const [groups, setGroups] = React.useState([]);
    React.useEffect(() => {
        if (id) {
          setInProgress(true);
          getVendorDetails(cookies, id)
            .then(response => {
              setInProgress(false);
              setInputs({...response, vendor_type_id:response.vendor_type.id,
                ['status']:String(response.status)});
            })
            .catch(error => {
              setInProgress(false);
              displayError(error.response.data, "Loading Failed");
            });
          }
        }, []);
        

    
    React.useEffect(() => {
      getAllGroups(cookies)
      .then(response => {
   const group =response.accounts_grouping_list.filter(obj => obj.entity_name == 'PRODUCT' )
          const a =[]
                          for(var i=0; i<response.accounts_grouping_list.length; i++) 
                          {
                              if((response.accounts_grouping_list[i].parent_id)=== (group[0].id))
                              {
                                  a.push(response.accounts_grouping_list[i]);
                              }
                          }
                        
                          setGroups(a);
       
  
      });
   
    },  []);

const handleChange = (event) => {
const name = event.target.name;
const value = event.target.value;
try{   
    if(name === 'entity_name')
    {
    if(!value.trim())                //Unit Name must not be empty,otherwise showing error message 
      {
        setError(values => ({...values, [name]: "Vendor Name must not be empty..."}))
        setIsSubmitting(false); 
      } else {
        setError(values => ({...values, [name]: ''}))
        setIsSubmitting(true); 
      }
    }
      setInputs(values => ({...values, [name]: value}))
  }
   catch(e)
   {
    displayErrorToast(e);
   } 
}

const validate_items = (name,value,regular_expr,error_message) =>
{
   try{
     
        if(value.trim())
        {
            if(!regular_expr.test(value.trim()))
            {
                setError(values => ({...values, [name]: error_message}))
                setIsSubmitting(false); 
            }
            else
            {
                setError(values => ({...values, [name]: ''}))
                setIsSubmitting(true);
            }
        }
        else
        {
            setError(values => ({...values, [name]: 'must not be empty...'}))
            setIsSubmitting(false);
        }
        setInputs(values => ({...values, [name]: value}))
    }
 
   catch (e)
      {
       displayErrorToast(e);
      }
 }  

const handleUpdate=()=> {
    

Swal.fire({
    title: "Do you want to Update?",
    showCancelButton: true,
    confirmButtonText: `Yes`,
    cancelButtonText: `No`,
     }).then((result) => {
      if (result.isConfirmed) {
        updateVendor(cookies,{
            entity_name: (inputs.entity_name).toUpperCase(),
            address_1: inputs.address_1,
            address_2:inputs.address_2,
            address_3:inputs.address_3,
            pincode:inputs.pincode,
            state:inputs.state,
            phone_number:inputs.phone_number,
            mobile_number: inputs.mobile_number,
            email_id: inputs.email_id,
            pan_no:inputs.pan_no,
            gst_no:inputs.gst_no,
            vendor_type_id:parseInt(inputs.vendor_type_id),
            contact_person: inputs.contact_person,
            contact_designation: inputs.contact_designation,
            contact_mobile_no:inputs.contact_mobile_no,
            contact_email_id:inputs.contact_email_id,
            ven_bank_name:inputs.ven_bank_name,
            ven_bank_branch: inputs.ven_bank_branch,
            ven_bank_acc_no: inputs.ven_bank_acc_no,
            ven_bank_ifsc:inputs.ven_bank_ifsc,
            user_remarks:inputs.user_remarks,
            status: parseBoolean(inputs.status)
         
                    }
                    ,id)
                    .then(response => {
                        Swal.fire("Updated!", "", "success");
                        Reload();
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        displayError(error.response.data,"Update Failed");
                    });
            
                    
                  } else if (result.isDismissed) {
                    Swal.fire("Not Updated", "", "info");
                  }
                });
              }
const handleSubmit = (event) => {
        event.preventDefault();
        
        try{
            checkForDuplicateVendors(event.target.entity_name.value, cookies, id)
            .then(isNameExists => {
                
            if (isNameExists) {
                setError(values => ({...values, [event.target.entity_name.name]: 'Vendor name already exists.'}));
            } else {
    
                if (Object.values(error).every((x) => !x) && isSubmitting) {        //If the fields are correct details it is valid choosing yes or no option,otherwise it is invalid
                if (id) {
                    handleUpdate();
                } 
                } else {
                Swal.fire("Please check * marked fields are filled and details given are valid...","","info" );
                }
            }
        }); 
        } catch (e) {
            displayErrorToast(e);
        } 
            
        }             
const Reload = () =>{
    navigate('/VendorTable')
} 
                    
const view = () => {
    navigate('/VendorTable')
}

const Back = () => {
    navigate('/Home')
}
             
    return(
      <>
      <div id="outer-container"  className="App" > 
          <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
          <ErrorToast/>
          <div id="page-wrap">
          <form className="form" onSubmit={handleSubmit} data-id={inputs.id}>
                <div id="header"> 
                    <h3 className = "text font-weight-bold page-title" >VENDOR REGISTRATION EDIT FORM</h3>
                </div>
                <FloatingControls tableLink="/VendorTable" enableCancel={false}/>
                <div className="container"> 
                <Row> 
                <Col xl={6} lg={12} md={12}>   
                {/* #99ccff */}
                        <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3  form-control-panel ">
                        <h5 className='col-sm-11'><b>Vendor Details</b></h5><br/><br/>  
                              
                                <label htmlFor="entity_name"className="form-group col-sm-4 text-right  ">Name </label>
                                <input type="text" id="entity_name" name="entity_name" onChange={handleChange} value={inputs.entity_name || ""} className="form-control col-sm-7 mandatory-form-control"  placeholder="Please fill the NAME field *" required /><br/>
                                {error.entity_name && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.entity_name}</strong><br/></>)}
                                
                                <label htmlFor="address_1"className="form-group col-sm-4 text-right  ">Address</label>
                                <input type="address_1" id="venAdd1" name="address_1" onChange={handleChange} value={inputs.address_1 || ""} className="form-control col-sm-7  rounded " /><br/>
                                
                                <label htmlFor="address_2"className="form-group col-sm-4 text-right  "></label>
                                <input type="text" id="address_2"  name="address_2" onChange={handleChange} value={inputs.address_2 || ""} className="form-control col-sm-7 rounded "  /><br/>
                                
                                <label htmlFor="address_3"className="form-group col-sm-4 text-right  "></label>
                                <input type="text" id="address_3" name="address_3" onChange={handleChange} value={inputs.address_3 || ""} className="form-control col-sm-7 rounded "  /><br/>
                                
                                <label htmlFor="pincode" className="form-group col-sm-4 text-right">Pin Code</label>
                                <input name="pincode" id="pincode" onChange={ (event) => validate_items(event.target.name,event.target.value,/^[1-9][0-9]{5}$/,"invalid Pincode")} value={inputs.pincode || ""} className="form-control col-sm-7" type = "number" /><br/>
                                {error.pincode && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.pincode}</strong><br/></>)}
                                
                                <label htmlFor="state"className="form-group col-sm-4 text-right  ">State</label>
                                <select name="state" className="browser-default custom-select form-control col-sm-7" onChange={handleChange} value={inputs.state || ""}>
                                <option value="">Select State</option>
                                      {
                                        getIndianStates()
                                        .map(indian_state => <option value={indian_state.value}>{indian_state.name}</option>
                                        )
                                      }
                                </select>
                                <label htmlFor="email_id" className="form-group col-sm-4 text-right">Email</label>
                                <input type="text" id="email_id"name="email_id" onChange={ (event) => validate_items(event.target.name,event.target.value,/^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,"invalid Emailid")} value={inputs.email_id || ""}className="form-control col-sm-7" placeholder="aaa@gmail.com" />
                                {error.email_id && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.email_id}</strong><br/></>)}

                                <label htmlFor="phone_number"className="form-group col-sm-4 text-right">Phone No</label>
                                <input  type="tel" name="phone_number"onChange={handleChange} value={inputs.phone_number || ""} className="form-control col-sm-7"  /><br/>
                                
                                <label htmlFor="mobile_number" className="form-group col-sm-4 text-right">Mobile No</label>
                                <input type="text" id="mobile_number"name="mobile_number" onChange={handleChange} value={inputs.mobile_number || ""} pattern="[0-9]{10}"  maxLength="10"className="form-control col-sm-7" /><br/>
                                
                                <label htmlFor="pan_no"className="form-group col-sm-4 text-right  ">PAN No </label>
                                <input type="text" id="pan_no"  name="pan_no"  onChange={ (event) => validate_items(event.target.name,event.target.value,/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,"invalid PANNo")} value={inputs.pan_no || ""}className="form-control col-sm-7"  placeholder="Please fill the PAN field *"  /><br/>
                                {error.pan_no && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.pan_no}</strong><br/></>)}
                                
                                <label htmlFor="gst_no"className="form-group col-sm-4 text-right  ">GST No </label>
                                <input type="text" id="gst_no" name="gst_no" onChange={ (event) => validate_items(event.target.name,event.target.value,/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,"invalid GSTNo")}  value={inputs.gst_no || ""}className="form-control col-sm-7 "  placeholder="Please fill the Gst field *" />
                                {error.gst_no && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.gst_no}</strong><br/></>)}
                        </div>
                </Col>
                    <Col xl={6} lg={12} md={12}>   
                        <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3  form-control-panel">
                        <h5 className='col-sm-11'><b>Contact Details</b></h5><br/><br/>  
                                <label htmlFor="vendor_type_id" className="form-group col-sm-4 text-right">Type </label>
                                <select id="vendor_type_id"  name="vendor_type_id" onChange={handleChange} value={inputs.vendor_type_id || ""} className="browser-default custom-select col-sm-7 mandatory-form-control" required>
                                <option value="">Select Type</option>
                                    {
                                    groups.map((item) => (
                                      <option key={item.id} value={item.id}>{item.entity_name}</option>
                                    ))
                                   }
                                </select>            
                    
                                <label htmlFor="contact_person" className="form-group col-sm-4 text-right">Contact Person</label>
                                <input type="text"id="contact_person" name="contact_person"onChange={handleChange} value={inputs.contact_person || ""} className="form-control col-sm-7" />                
                                
                                <label htmlFor="contact_designation" className="form-group col-sm-4 text-right">Designation</label>
                                <input type="text" id="contact_designation"name="contact_designation" onChange={handleChange} value={inputs.contact_designation || ""}className="form-control col-sm-7"/>               
                                       
                                <label htmlFor="contact_mobile_no" className="form-group col-sm-4 text-right">Mobile No</label>
                                <input type="text"id="contact_mobile_no" name="contact_mobile_no" onChange={handleChange} value={inputs.contact_mobile_no || ""}className="form-control col-sm-7" />                                      
                                
                                <label htmlFor="contact_email_id" className="form-group col-sm-4 text-right">Email</label>
                                <input type="text" id="contact_email_id"name="contact_email_id" onChange={ (event) => validate_items(event.target.name,event.target.value,/^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,"invalid ContactEmailid")} value={inputs.contact_email_id || ""}className="form-control col-sm-7" placeholder="aaa@gmail.com" />
                                {error.contact_email_id && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.contact_email_id}</strong><br/></>)}
                        </div>
                        
                        <div className="form-row table-bordered shadow p-2 my-2  border-secondary p-2 mb-3  form-control-panel ">
                        <h5 className='col-sm-11'><b>Bank Details</b></h5><br/><br/>  
                                <label htmlFor="ven_bank_name" className="form-group col-sm-4 text-right">Bank Name</label>
                                <input type="text" id="ven_bank_name"name="ven_bank_name" className="form-control col-sm-7"onChange={handleChange} value={inputs.ven_bank_name || ""} placeholder="Please fill BANK Name" />                 
                                                
                                <label htmlFor="ven_bank_branch" className="form-group col-sm-4 text-right">Branch </label>
                                <input type="text" id="ven_bank_branch"name="ven_bank_branch" className="form-control col-sm-7"onChange={handleChange} value={inputs.ven_bank_branch || ""} placeholder="Please fill  BRANCH Name" />                              
                                
                                <label htmlFor="ven_bank_acc_no" className="form-group col-sm-4 text-right">Account No</label>
                                <input type="text"id="ven_bank_acc_no" name="ven_bank_acc_no" className="form-control col-sm-7"onChange={handleChange} value={inputs.ven_bank_acc_no || ""} placeholder="Enter Account No" />          
                                
                                <label htmlFor="ven_bank_ifsc" className="form-group col-sm-4 text-right">IFSC Code</label>
                                <input type="text"id="ven_bank_ifsc" name="ven_bank_ifsc" className="form-control col-sm-7" onChange={ (event) => validate_items(event.target.name,event.target.value,/^[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$/,"invalid venBankIFSC")} value={inputs.ven_bank_ifsc || ""}placeholder="Enter IFSC Code" />
                                {error.ven_bank_ifsc && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.ven_bank_ifsc}</strong><br/></>)}
                          </div>
                          </Col>
                          <Col xl={6} lg={12} md={12}>   
               
                            <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue form-control-panel">  
                            <h5 className='col-sm-11'><b>Vendor Status</b></h5><br/><br/>  
                                <label htmlFor="user_remarks" className="form-group col-sm-4 text-right">Remarks</label>
                                <input type="text"id="user_remarks" name="user_remarks" className="form-control col-sm-7"onChange={handleChange} value={inputs.user_remarks || ""} placeholder="If any"/>
                                
                                <StatusDropDown status={inputs.status} onChange={handleChange}/>
                        </div>
                    </Col>
                </Row>             
            </div>
                    
                <div className="footer text-center"><br/>
                    <Button  type="submit"  style={{width:"80px",fontWeight:"bold"}} className="btn btn-twitter">Update</Button> &nbsp;&nbsp;
                    <Button className="btn btn-twitter" type="button"style={{width:"80px",fontWeight:"bold"}} onClick={view}>View</Button>&nbsp;&nbsp;
                    <Button className="btn btn-twitter"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={Back}>Home</Button>
                </div>
            </form><br/>
            </div><br/>
            </div> 
            
        </>
    );
  }
  export default VendorEdit;