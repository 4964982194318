import {getAllBank} from '../../services/BankService'

export function checkForDuplicateBank(bankname, cookies, existingId) {
    
    
    let nameCheck = new Promise((resolve, reject) => {
        getAllBank(cookies)
      .then((data) => {

        let isBankExists = false;
        data.bank_list.forEach(bank =>{  //Same Bank Name mentioned it denoted already exist.
        
          if((bank.name.toUpperCase()) === bankname.trim().toUpperCase()
            && parseInt(existingId) !== bank.id){   
            
            console.log(`Bank exists with the name - ${bankname}`) 
            isBankExists = true;
            
          }
      });
        resolve(isBankExists);
      });  
    });

    return nameCheck;
  }