import {getAllCompanies} from '../../services/CompanyServices'

export function checkForDuplicateCompany(companyName,cookies, existingId) {
    
    
    let nameCheck = new Promise((resolve, reject) => {
      getAllCompanies(cookies)
      .then((data) => {

        let isCompanyExists = false;
        data.company_data.forEach(company =>{    //Same Company Name mentioned it denoted already exist.
        
          if(
              company.entity_name.toUpperCase() === companyName.trim().toUpperCase()
              && parseInt(existingId) !== company.id){   
              
              console.log(`Company exists with the name - ${companyName}`) 
              isCompanyExists = true;
            
          }
    });
        resolve(isCompanyExists);
      });  
    });

    return nameCheck;
  }