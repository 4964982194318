function TableRowsConsumptionNote({rowsData, deleteTableRows, handleChange4,products,concrete}) {


    return(
        
        rowsData.map((data, index)=>{
            const {soDSqlNo,soDTaxCode,soDConStruc,soDDeliveryMode,soDAmt,soDRate,soDOrdQty,soDUnit,soDProdCode,soDRemarks}=data
        return(

            <tr  key={index} className="text-center detailclass" id="after-this1">
                <td><button type="button"className="btn btn-danger" onClick={()=>(deleteTableRows(index))}>x</button></td>
                {/* <td><input type="text"  className="form-control add"  value={soDSqlNo} style={{width:"100px",backgroundColor:"white", cursor: "not-allowed"}} readOnly={true}   name="soDSqlNo" /></td> */}
                <td>
                    <select value={soDProdCode} onChange={(evnt)=>(handleChange4(index, evnt))} id="proddescrp1" style={{width: "150px"}}   name="soDProdCode" className="form-control soDProdCode browser-default custom-select" required>
                        <option value="">Select Raw Material</option>
                        {products.map((item) => (
                            <option key={item.id} value={item.id}>{item.prodName}</option>
                        ))}
                    </select>
                </td>
                <td>
                    <input type="text"value={soDUnit} onChange={(evnt)=>(handleChange4(index, evnt))}   id="unitname1" className="form-control" style={{width:"100px",backgroundColor:"white", cursor: "not-allowed"}} readOnly={true}  name="soDUnit" />
                </td>
                <td>
                    <input type="number" step="any" min="0"style={{width: "100px",textAlign:"right"}} className="form-control qty"  id="qty" value={soDOrdQty} onChange={(evnt)=>(handleChange4(index, evnt))} name="soDOrdQty" required/>
                </td>
                <td>
                    <input type="number" step="any" min="0" style={{width: "100px",textAlign:"right"}} className="form-control price" id="price" value={soDRate} onChange={(evnt)=>(handleChange4(index, evnt))} name="soDRate"required/>
                </td>
                <td>
                    <input type="number"min="0" value={soDAmt} onChange={(evnt)=>(handleChange4(index, evnt))} className="form-control total" id="total"  name="soDAmt" style={{textAlign:"right",width:"100px",backgroundColor:"white", cursor: "not-allowed"}} readOnly={true}/>
                </td>     
          
                <td>
                <select id="soDConStruc" style={{width:"150px"}}className="form-control browser-default custom-select" value={soDConStruc} onChange={(evnt)=>(handleChange4(index, evnt))} name="soDConStruc" required>
                        <option value="">Select Finished Goods</option>
                        {concrete.map((item) => (
                            <option key={item.id} value={item.id}>{item.subgrpName}</option>
                        ))}
                    </select>
                </td>
               
       
                <td>
                    <input type="text" style={{width:"150px"}} className="form-control"value={soDRemarks} onChange={(evnt)=>(handleChange4(index, evnt))}  name="soDRemarks"/>
                </td>
                <td>
                    <input type="text" style={{width:"150px"}} className="form-control"value={soDRemarks} onChange={(evnt)=>(handleChange4(index, evnt))}  name="soDRemarks"/>
                </td>
                 <td>
                    <input type="text" style={{width:"150px"}} className="form-control"value={soDRemarks} onChange={(evnt)=>(handleChange4(index, evnt))}  name="soDRemarks"/>
                </td>
            </tr>
        )
        })
   
    )
    
}

export default TableRowsConsumptionNote;