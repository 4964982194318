import { useEffect, useState, useMemo } from "react";
import React from 'react';
import ReactTableWrapper from "../../components/ReactTableWrapper";
import { exportAsCSV } from "../../utils/CSVExporter";
import { exportAsPDF,generatePDFFromHTML } from "../../utils/PDFExporter";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import Swal from "sweetalert2";
import { useCookies } from 'react-cookie';
import {Row,Col,Button,} from "reactstrap";
import {getWorkScheduleReport} from "../../services/ReportServices";
import {  DatePicker } from 'antd';
import { getUserAllowedPlants} from '../../services/PlantServices';
import moment  from 'moment';
import {displayErrorToast,displayError,parseBoolean,parseBooleanToString} from '../../helpers';
const {RangePicker} = DatePicker;

function WorkScheduleReportTable() 
{
    const InitialData = {
        total_pages: 0,
        records: [],
      };
    const [data, setData] = useState(InitialData);
    const navigate = useNavigate(); 
    const [cookies] = useCookies(['myToken']);
    const [isLoading, setIsLoading] = useState(true); 
    const [plants, setPlants] = React.useState([]);
    const [inputs, setInputs] = useState({});

    // Initial state for from_date and to_date
    const initialFromDate = moment().startOf('month');
    const initialToDate =  moment();
    const [fromDate, setFromDate] = useState(initialFromDate);
    const [toDate, setToDate] = useState(initialToDate);
    const handleDateChange = (values) => {
        if ((values) && (values.length === 2)) {
        setFromDate(values[0]);
        setToDate(values[1]);
        }
    };

    React.useEffect(() => {
        //Load data for Plant dropdown
        getUserAllowedPlants(cookies)
        .then (
            plantList => {
                const plants = plantList.plant_list.filter(obj => obj.status).map(
                    plant => {
                        return { value: plant.id, label: plant.plant_alias }
                    }
                );
                setPlants(plants);
            }
        )
    }, []);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        console.log(name+value+"name and value")
        setInputs(values => ({...values, [name]: event.target.value}))
    }

    const columns = useMemo(
        () => [
            {
                fieldName: "schedule_date",
                headerName: 'Date'
            },
            {
                fieldName: 'plant_alias', 
                headerName: 'Plant Alias'
            },
            {
                fieldName: "project_name",
                headerName: 'Site/Project Name'
            },
            {
                fieldName: 'sales_order_no', 
                headerName: 'SO Number'
            },
            {
                fieldName: 'site_address', 
                headerName: 'Location'
            },
            {
                fieldName: 'grade', 
                headerName: 'Grade'
            },
            {
                fieldName: 'scheduled_qty', 
                headerName: 'Qty'
            },
            {
                fieldName: 'delivery_date', 
                headerName: 'Delivery Date'
            },
            {
                fieldName: 'delivery_time', 
                headerName: 'Delivery Time'
            },
            {
                fieldName: "delivery_mode",
                headerName: 'Service'
            },
            {
                fieldName: 'structure', 
                headerName: 'Structure'
            },
            {
                fieldName: "salesrep_name",
                headerName: 'Marketting'
            },
            {
                fieldName:"contact_mobile_no",
                headerName:'Mobile No'
            },
            {
                fieldName:"status",
                headerName:'Status'
            }
        ],
        [],);
   
    const handleCSVExport = (event) => {
        // console.log('CSV Export');
        exportAsCSV(
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            // .filter(col => col.headerName == 'Plant Name')
            .map(c => c.headerName),
            data.records.map(dc => {
                const csvRow = {
                    plant_name:(dc.plant_name != null )? dc.plant_name:'ram',
                    plant_alias: dc.plant_alias?dc.plant_alias:'ram',
                    production_plant_name: dc.production_plant_name?dc.production_plant_name:'ram',
                    delivery_challan_date: dc.delivery_challan_date?dc.delivery_challan_date: 'ram',
                    delivery_challan_no: dc.delivery_challan_no?dc.delivery_challan_no:'ram',
                    batch_no: dc.batch_no?dc.batch_no:'ram',
                    delivery_challan_time: dc.delivery_challan_time?dc.delivery_challan_time:'ram',
                    sales_order_no: dc.sales_order_no?dc.sales_order_no:'ram',
                    purchase_order_no:dc.purchase_order_no?dc.purchase_order_no : 'ram',
                    invoice_number: dc.invoice_number?dc.invoice_number:'ram',
                    customer_name:dc.customer_name ?dc.customer_name: 'ram',
                    project_name:dc.project_name?dc.project_name:'ram',
                    dc_grade:  dc.dc_grade?dc.dc_grade:'ram',
                    dc_qty: dc.dc_qty?dc.dc_qty :0,
                    rate: dc.rate?dc.rate :0,
                    amount:dc.amount?dc.amount:0,
                    delivery_mode: dc.delivery_mode?dc.delivery_mode:'ram',
                    vehicle_no: dc.vehicle_no?dc.vehicle_no: 'ram',
                    salesrep_name: dc.salesrep_name?dc.salesrep_name:'ram',
                    driver_name:  dc.driver_name?dc.driver_name:'ram',
                    accepted_qty:  dc.accepted_qty?dc.accepted_qty:0,
                    customer_dc_status: dc.customer_dc_status?dc.customer_dc_status:'ram',
                    total_km:  dc.total_km?dc.total_km:0,
                    net_weight:  dc.net_weight?dc.net_weight:0,
                    gang:dc.gang?dc.gang:'gang',
                    pump:dc.pump?dc.pump:'pump'
                }
                return csvRow;
                }))
    };

    
    const handlePDFExport = (event) => {
        console.log('PDF Export');
        exportAsPDF(
            'Work Schedule Report',
            columns
            .map(col=>({header: col.headerName, dataKey: col.fieldName })),
            data.records.map(wrkschedule_obj => ({
                schedule_date: wrkschedule_obj.schedule_date,
                    plant_alias: wrkschedule_obj.plant_alias,
                    project_name:wrkschedule_obj.project_name,
                    sales_order_no:  wrkschedule_obj.sales_order_no,
                    site_address:wrkschedule_obj.site_address,
                    grade:wrkschedule_obj.grade,
                    status : wrkschedule_obj.status,
                    scheduled_qty:wrkschedule_obj.scheduled_qty,
                    delivery_mode:wrkschedule_obj.delivery_mode,
                    structure:wrkschedule_obj.structure,
                    salesrep_name:wrkschedule_obj.salesrep_name,
                    delivery_date:wrkschedule_obj.delivery_date,
                    contact_mobile_no:wrkschedule_obj.contact_mobile_no,
                    delivery_time: wrkschedule_obj.delivery_time
            })),
            'WorkScheduleReport.pdf'
        );
        // generatePDFFromHTML('./sample.html','output.pdf')
    };
    
    const constructFormData = () => {
        const EMPTY_STRING = '';
        return {
            plant_id:inputs.plant_id,
            from_date:fromDate.format('DD-MM-YYYY'),
            upto_date:toDate.format('DD-MM-YYYY')
          }
    }

    const handleSubmit=(event)=> 
    {
        event.preventDefault();
        console.log(JSON.stringify(constructFormData())+"submit")
        // const p = constructFormData()
        if ((fromDate) && (toDate))
        {
            getWorkScheduleReport(cookies, constructFormData())
            .then(response => {
                const tableData = response.work_schedule_report_list
                .map(wrkschedule_obj => ({
                    schedule_date: wrkschedule_obj.schedule_date,
                    plant_alias: wrkschedule_obj.plant_alias,
                    project_name:wrkschedule_obj.project_name,
                    sales_order_no:  wrkschedule_obj.sales_order_prefix + wrkschedule_obj.sales_order_no,
                    site_address:wrkschedule_obj.site_address_1 +", "+ wrkschedule_obj.site_address_2 +", "+ wrkschedule_obj.site_address_3,
                    grade:wrkschedule_obj.grade,
                    status : wrkschedule_obj.status,
                    scheduled_qty:wrkschedule_obj.scheduled_qty,
                    delivery_mode:wrkschedule_obj.delivery_mode,
                    structure:wrkschedule_obj.structure,
                    salesrep_name:wrkschedule_obj.salesrep_name,
                    delivery_date:wrkschedule_obj.delivery_date,
                    delivery_time: wrkschedule_obj.delivery_time,
                    contact_mobile_no:wrkschedule_obj.contact_mobile_no
                     }));
                setData({
                    total: data.total,
                    records: tableData
                });
                setIsLoading(false);
            }).catch((error) => {
                console.log(error.response.data);
                displayError(error.response.data);
            });
        }
        else 
        {
            Swal.fire("Plant,From date,Upto date must not be empty.", "", "info");  
        }
    }

    return (
            <>
                <form className="form" onSubmit={handleSubmit}>
                    <div className="container"> 
                       <Row> 
                            <Col xl={3} lg={4} md={4}></Col>
                            <Col xl={6} lg={4} md={4}>
                                <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3 form-control-panel ">
                                    <select className="browser-default custom-select col-sm-5"  onChange={handleChange} value={inputs.plant_id || ""} name ="plant_id" required id="myData">
                                        <option value="">Please Select Plant</option>
                                        {plants.map((item) => (
                                            <option key={item.value} value={item.value}>
                                                {item.label}</option>
                                        ))}
                                    </select>&nbsp;&nbsp;  

                                    <RangePicker
                                        defaultValue={[initialFromDate, initialToDate]}
                                        format="DD-MM-YYYY"
                                        onChange={handleDateChange}
                                        style={{height:"40px",width:"220px"}}
                                    />
                                    &nbsp;&nbsp;

                                    <Button  type="submit"
                                        style={{backgroundColor: "RGB(188,232,253)",fontWeight:"bold",
                                        height:"30px",display: "flex",
                                        alignItems: "center",  
                                        justifyContent: "center"}}>
                                        <i className="fa fa-search fa-2x" id="myData" aria-hidden="true" style={{color:"rgb(93, 156, 233)"}}></i>
                                    </Button> 
                                </div>
                             </Col>
                        </Row> 
                    </div> 
                </form>
                <div className="container item-list-table-container">
                    <div className="table-responsive">
                        <ReactTableWrapper
                            title='CustomerData Report'
                            columns={columns}
                            data={data.records}
                            onCSVExport={handleCSVExport}
                            onPDFExport={handlePDFExport}
                            isLoading={isLoading}
                        />
                    </div>
                </div> 
            </>
);
}

export default WorkScheduleReportTable;