function TableRowsGRNEdit({rowsData,handleChangeTableDetails,products,taxes}) {

    return(
        
        rowsData.map((data, index)=>{
            const {sl_no,id,po_dtl_id,amount,rate,quantity,unit,taxable_amount,product_id,tax_id,balance_qty,gross_weight,tare_weight,net_weight,received_qty,accepted_qty,difference_qty,dc_qty,deduction_qty,user_remarks,is_checked}=data
        return(

            <tr  key={index} className="text-center detailclass" id="after-this1">
                
                <td> 
                    <input type="checkbox" onChange={(evnt)=>(handleChangeTableDetails(index, evnt))} checked={is_checked || false} name="is_checked" />
                </td> 
               
                <td>
                    <input type="text"  className="form-control add"  value={sl_no} style={{width:"80px",cursor: "not-allowed"}}    name="sl_no" disabled={true} />
                </td>
                <td>
                    <input type="text"  className="form-control"  value={id} style={{width:"80px",cursor: "not-allowed"}}    name="id" disabled={true} />
                </td>
                <td>
                    <input type="text"  className="form-control"  value={po_dtl_id} style={{width:"80px",cursor: "not-allowed"}}    name="po_dtl_id" disabled={true} />
                </td>
                <td>
                    <select value={product_id}  id="product_id" style={{width: "150px", cursor: "not-allowed"}}   name="product_id" className="form-control product_id browser-default"  disabled={true}>
                        <option value="">Select Product</option>
                        {products.map((item) => (
                            <option key={item.value} value={item.value}>{item.label}</option>
                        ))}
                    </select>
                </td>
                <td>
                    <input type="text" value={unit}   id="unit" className="form-control" style={{width:"100px",cursor: "not-allowed "}} readOnly={true}  name="unit" disabled={true} />
                </td>
                <td>
                    <input type="number" step="any" min="0"style={{width: "100px",textAlign:"right", cursor: "not-allowed"}} className="form-control qty"  id="quantity" value={quantity}  name="quantity"  disabled={true}/>
                </td>
                <td>
                    <input type="number" step="any" min="0" style={{width: "100px",textAlign:"right", cursor: "not-allowed"}} className="form-control price" id="rate" value={rate}  name="rate" disabled={true}/>
                </td>
                <td>
                    <input type="number" min="0" value={amount}  className="form-control total" id="amount"  name="amount" style={{textAlign:"right",width:"120px", cursor: "not-allowed"}}  disabled={true}/>
                </td> 
                <td>
                    <input type="number" step="any" min="0"style={{width: "100px",textAlign:"right", cursor: "not-allowed"}} className="form-control qty"  id="balance_qty" value={balance_qty}  name="balance_qty" disabled={true} />
                </td> 

                <td>
                    <input type="number" step="any" min="0"style={{width: "100px",textAlign:"right"}} className="form-control qty"  id="gross_weight" value={gross_weight} onChange={(evnt)=>(handleChangeTableDetails(index, evnt))} name="gross_weight" required={is_checked}/>
                </td>  

                <td>
                    <input type="number" step="any" min="0"style={{width: "100px",textAlign:"right"}} className="form-control qty"  id="tare_weight" value={tare_weight} onChange={(evnt)=>(handleChangeTableDetails(index, evnt))} name="tare_weight" required={is_checked}/>
                </td>

                <td>
                    <input type="number" step="any" min="0"style={{width: "100px",textAlign:"right"}} className="form-control qty"  id="net_weight" value={net_weight}  name="net_weight" disabled={true}/>
                </td>

                <td>
                    <input type="number" step="any" min="0"style={{width: "100px",textAlign:"right"}} className="form-control qty"  id="deduction_qty" value={deduction_qty} onChange={(evnt)=>(handleChangeTableDetails(index, evnt))} name="deduction_qty" required={is_checked}/>
                </td> 

                <td>
                    <input type="number" step="any" min="0"style={{width: "100px",textAlign:"right"}} className="form-control qty"  id="accepted_qty" value={accepted_qty} onChange={(evnt)=>(handleChangeTableDetails(index, evnt))} name="accepted_qty" required={is_checked}/>
                </td> 

                <td>
                    <input type="number" step="any" min="0"style={{width: "100px",textAlign:"right"}} className="form-control qty"  id="dc_qty" value={dc_qty} onChange={(evnt)=>(handleChangeTableDetails(index, evnt))} name="dc_qty" required={is_checked}/>
                </td>  

                <td>
                    <input type="number" step="any" min="0"style={{width: "100px",textAlign:"right"}} className="form-control qty"  id="received_qty" value={received_qty}  name="received_qty" disabled={true}/>
                </td>

                <td>
                    <input type="number" step="any" min="0"style={{width: "100px",textAlign:"right"}} className="form-control qty"  id="difference_qty" value={difference_qty} name="difference_qty" disabled={true}/>
                </td>
                <td>
                    <input type="number" min="0" value={taxable_amount}  className="form-control" id="taxable_amount"  name="taxable_amount" style={{textAlign:"right",width:"120px", cursor: "not-allowed"}}  disabled={true}/>
                </td> 
                <td>
                    <select value={tax_id}  id="tax_id" style={{width: "150px", cursor: "not-allowed"}}   name="tax_id" className="form-control  browser-default"  disabled={true}>
                        <option value="">Select Tax</option>
                        {taxes.map((item) => (
                            <option key={item.value} value={item.value}>{item.label}</option>
                        ))}
                    </select>
                </td>
                <td>
                    <input type="text" style={{width:"150px"}} className="form-control"value={user_remarks} onChange={(evnt)=>(handleChangeTableDetails(index, evnt))}  name="user_remarks"/>
                </td>
             
            </tr>
        )
        })
   
    )
    
}

export default TableRowsGRNEdit;


