import axios from "axios";
import { getApiBaseUrl } from "./serviceconfig";

var apiBaseUrl = getApiBaseUrl();
export async function getAllPurchaseInvoice(cookies) {

    // setTimeout(5000, () => {
    //   console.log('Waiting....');
      
    // });
    return fetch(`${apiBaseUrl}/accounting_master/`,{method: 'GET',headers: {
      'Authorization': `Token ${cookies['myToken']}`
    }})
    .then(response => response.json());
    
              // .then((data) => {
              //   return data;
              // });
}

export function createPurchaseInvoice(cookies, inputs) {

  let responseJson;
  axios.post(`${apiBaseUrl}/accounting_master/`, {
    entity_name: (inputs.srName).toUpperCase(),
    address_1: inputs.srAdd1,
    address_2:inputs.srAdd2,
    address_3:inputs.srAdd3,
    pincode:inputs.srPincode,
    phone_number:inputs.srPhoneNo,
    mobile_number: inputs.srMobileNo,
    email_id: inputs.srEmailid,
    pan_no:inputs.srPanNo,
    gst_type:inputs.srgsttype,
    gst_no:inputs.srGstNo,
    contact_person:inputs.srContactPerson,
    contact_mobile_no:inputs.srContactMobileNo,
    contact_designation: inputs.SrContactDesignation,
    contact_email_id: inputs.srContactEmailid,
    credit_limit:inputs.srCreditLimit,
    ref_name:inputs.srRefName,
    ref_mobile_no:inputs.srrefMobileNo,
    user_remarks:inputs.srRemarks,
    status:inputs.srStatus
  }, {
    headers: {
      'Authorization': `Token ${cookies['myToken']}`
    }
  }).then(function (response) {
    console.log(response);
    responseJson = response.json();
  }).catch(function (error) {
    console.log(error);
  })

  return responseJson;
}

export function updatePurchaseInvoice(cookies, inputs, PlantId) {

  fetch(`${apiBaseUrl}/accounting_master/${PlantId}/`,{
    method: 'PUT',
    headers: { 'Authorization': `Token ${cookies['myToken']}`,
              'Content-Type': 'application/json'
              },
    body: JSON.stringify({  
    entity_name: (inputs.srName).toUpperCase(),
  address_1: inputs.srAdd1,
  address_2:inputs.srAdd2,
  address_3:inputs.srAdd3,
  pincode:inputs.srPincode,
  phone_number:inputs.srPhoneNo,
  mobile_number: inputs.srMobileNo,
  email_id: inputs.srEmailid,
  pan_no:inputs.srPanNo,
  gst_type:inputs.srgsttype,
  gst_no:inputs.srGstNo,
  contact_person:inputs.srContactPerson,
  contact_mobile_no:inputs.srContactMobileNo,
  contact_designation: inputs.SrContactDesignation,
  contact_email_id: inputs.srContactEmailid,
  credit_limit:inputs.srCreditLimit,
  ref_name:inputs.srRefName,
  ref_mobile_no:inputs.srrefMobileNo,
  user_remarks:inputs.srRemarks,
  status:inputs.srStatus
    })
  })
  .then(response => response.json())
  .then(data => console.log(data,"data")) // Manipulate the data retrieved back, if we want to do something with it
  .catch(err => console.log(err,"errrrrrrrr")) // Do something with the error
}

export async function getPurchaseInvoiceDetails(cookies, PlantId) {

  return fetch(`${apiBaseUrl}/accounting_master/${PlantId}/`,{method: 'GET',headers: {
            'Authorization': `Token ${cookies['myToken']}`
        }})
              .then(response => response.json())
}

export function deletePurchaseInvoice(cookies, PlantId) {

  fetch(`${apiBaseUrl}/accounting_master/${PlantId}/`, { method: 'PATCH',
            headers: {
              'Authorization': `Token ${cookies['myToken']}`
            } }); 
}