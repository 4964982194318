import React from 'react';
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { Row, Col, Button } from "reactstrap";
import axios from "axios";
import Sidebar from '../../Sidebar';
import { useNavigate } from 'react-router';
import { BiSave } from "react-icons/bi";
import { IoIosEye } from "react-icons/io";
import { TiCancel } from "react-icons/ti";
import { AiOutlineHome } from "react-icons/ai";
import { useCookies } from 'react-cookie';
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import { moment } from 'moment';
import { useLocation, Link } from 'react-router-dom';
import {displayErrorToast,displayError} from '../../helpers';
import ErrorToast from '../../ErrorToast';
import { createMoisture } from "../../services/MoistureCorrectionServices";
import FloatingControls from '../../components/FloatingControls';
import LoadingOverlay from '../../components/LoadingOverlay';

function MoistureCorrection() {
const [category] = useCookies(['myCategory']);
const [error, setError] = useState({});
const [isSubmitting, setIsSubmitting] = useState(true);
const [superuser] = useCookies(['mySuperuser']);
const [cookies] = useCookies(['myToken']);
const [inProgress, setInProgress] = useState(false);
const [inputs, setInputs] = useState({
  "moisture_msand": 0,
  "moisture_csand": 0,
  "moisture_rsand":0,
  "moisture_12mm": 0,
  "moisture_20mm":0,
   "absorption_msand": 0,
  "absorption_csand": 0,
  "absorption_rsand": 0,
  "absorption_12mm": 0,
  "absorption_20mm": 0,
  "correction_factor_msand": 0,
  "correction_factor_csand": 0,
  "correction_factor_rsand": 0,
  "correction_factor_12mm": 0,
  "correction_factor_20mm": 0
});

  const navigate = useNavigate();
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
 try{
    if(name === 'moisture_msand') 
    {
        var cf_msand = ((inputs.absorption_msand) - (value)).toFixed(2);
        console.log(cf_msand+inputs.absorption_msand+value+"cf_msandmois")
        setInputs(values => ({ ...values, ['correction_factor_msand']: cf_msand }))
    } 
    else if(name === 'absorption_msand') 
    {
        var cf_msand = ((value) - (inputs.moisture_msand)).toFixed(2);
        console.log(cf_msand+"cf_msandabbbb")
        setInputs(values => ({ ...values, ['correction_factor_msand']: cf_msand }))
    }  
    else if(name === 'moisture_csand') 
    {
        var cf_csand = ((inputs.absorption_csand) - (value)).toFixed(2);
        setInputs(values => ({ ...values, ['correction_factor_csand']: cf_csand }))
    } 
    else if(name === 'absorption_csand') 
    {
        var cf_csand = ((value) - (inputs.moisture_csand)).toFixed(2);
        setInputs(values => ({ ...values, ['correction_factor_csand']: cf_csand }))
    }  
    else if(name === 'moisture_rsand') 
    {
        var cf_rsand = ((inputs.absorption_rsand) - (value)).toFixed(2);
        setInputs(values => ({ ...values, ['correction_factor_rsand']: cf_rsand }))
    } 
    else if(name === 'absorption_rsand') 
    {
        var cf_rsand = ((value) - (inputs.moisture_rsand)).toFixed(2);
        setInputs(values => ({ ...values, ['correction_factor_rsand']: cf_rsand }))
    }  
    else if(name === 'moisture_12mm') 
    {
        var cf_12mm = ((inputs.absorption_12mm) - (value)).toFixed(2);
        setInputs(values => ({ ...values, ['correction_factor_12mm']: cf_12mm }))
    } 
    else if(name === 'absorption_12mm') 
    {
        var cf_12mm = ((value) - (inputs.moisture_12mm)).toFixed(2);
        setInputs(values => ({ ...values, ['correction_factor_12mm']: cf_12mm }))
    }  
    else if(name === 'moisture_20mm') 
    {
        var cf_20mm = ((inputs.absorption_20mm) - (value)).toFixed(2);
        setInputs(values => ({ ...values, ['correction_factor_20mm']: cf_20mm }))
    } 
    else if(name === 'absorption_20mm') 
    {
        var cf_20mm = ((value) - (inputs.moisture_20mm)).toFixed(2);
        setInputs(values => ({ ...values, ['correction_factor_20mm']: cf_20mm }))
    }  
    setInputs(values => ({ ...values, [name]: event.target.value }))
  }
  catch(e)
  {
    displayErrorToast(e);
  } 
}

const handleSubmit=(event)=> {
  event.preventDefault();
  if ((Object.values(error).every(x => !x)) && isSubmitting) //If all the required fields are field u can select the save button then choose the option yes or no
  {
      Swal.fire({title: 'Do you want to save?',  
        showCancelButton: true,  
        confirmButtonText: `Yes`,  
        cancelButtonText: `No`,
        }).then((result) => {  
          if (result.isConfirmed) { 
              createMoisture(cookies, {
                moisture_msand:inputs.moisture_msand?parseFloat(inputs.moisture_msand):0,
                moisture_csand: inputs.moisture_csand?parseFloat(inputs.moisture_csand):0,
                moisture_rsand:inputs.moisture_rsand?parseFloat(inputs.moisture_rsand):0,
                moisture_12mm:inputs.moisture_12mm?parseFloat(inputs.moisture_12mm):0,
                moisture_20mm:inputs.moisture_20mm?parseFloat(inputs.moisture_20mm):0,
                absorption_msand:inputs.absorption_msand?parseFloat(inputs.absorption_msand):0,
                absorption_csand:inputs.absorption_csand?parseFloat(inputs.absorption_csand):0,
                absorption_rsand:inputs.absorption_rsand?parseFloat(inputs.absorption_rsand):0,
                absorption_12mm:inputs.absorption_12mm?parseFloat(inputs.absorption_12mm):0,
                absorption_20mm:inputs.absorption_20mm?parseFloat(inputs.absorption_20mm):0,
                correction_factor_msand:parseFloat(inputs.correction_factor_msand),
                correction_factor_csand:parseFloat(inputs.correction_factor_csand),
                correction_factor_rsand:parseFloat(inputs.correction_factor_rsand),
                correction_factor_12mm:parseFloat(inputs.correction_factor_12mm),
                correction_factor_20mm:parseFloat(inputs.correction_factor_20mm)
             })
            .then(response => {
                Swal.fire("Saved!", "", "success");
                Reload();
            }).catch((error) => {
                //console.log(error.response.data);
                displayError(error.response.data,"Saved Failed");
            });
          } 
          else if (result.isDismissed) 
          {    
            Swal.fire('Not saved', '', 'info')  
          }
        });
  }
}

  const Cancel = () => {
    setInputs(() => "")
  }

  const Reload = () => {
    window.location.reload();
  } 

  const view = () => {
    navigate('/MoistureCorrectionTable')
  } 

  const Back = () => {
    navigate('/Home')
  }

return(
    <>
      <div id="outer-container" className="App" >
        <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
        <ErrorToast/>
        <LoadingOverlay inProgress={inProgress}/>
        <div id="page-wrap">
          <form onSubmit={handleSubmit} >
            <div id="header">
              <h3 className="text font-weight-bold page-title" >MOISTURE CORRECTION </h3>
            </div>
            <FloatingControls tableLink="/MoistureCorrectionTable" onCancel={Cancel} enableCancel={false}/>
            <div className="container">
              <Row>
                <Col xl={2} lg={12} md={12}>
                </Col>
                     
                <Col xl={8} lg={12} md={12}>
                    <div className="form-row table-bordered shadow p-2 my-1 border-secondary p-3 mb-2 bg-blue form-control-panel" >
                      <h5 className="col-sm-4 text-right"><b>MOISTURE (%) </b></h5>
                      <h5 className="col-sm-4 text-right"><b>ABSORPTION (%)</b></h5>
                      <h5 className="col-sm-4 text-center"><b>CORRECTION FACTOR (%)</b></h5>
                         
                      <label htmlFor="moisture_msand" className="form-group col-sm-1 text-left">MSAND </label>
                      <input type="number" step="any" id="moisture_msand" onChange={handleChange} value={inputs.moisture_msand || 0} name="moisture_msand" max="15" min="0" className="form-control col-sm-3 mandatory-form-control"  pattern="[0-9]*" required/><br/><br/>
                      <label htmlFor="absorption_msand" className="form-group col-sm-1 text-right">&nbsp;  </label>
                      <input type="number"  step="any" id="absorption_msand" onChange={handleChange} value={inputs.absorption_msand || 0} name="absorption_msand" max="5" min="0"  className="form-control col-sm-3 mandatory-form-control"  pattern="[0-9]*" required/><br/><br/>
                      <label htmlFor="correction_factor_msand" className="form-group col-sm-1 text-right">&nbsp;  </label>
                      <input type="number" id="correction_factor_msand"  value={inputs.correction_factor_msand || 0} name="correction_factor_msand" className="form-control col-sm-3" readOnly={true} style={{cursor: "not-allowed"}}   pattern="[0-9]*" required/><br/><br/>

                      <label htmlFor="moisture_csand" className="form-group col-sm-1 text-right">CSAND </label>
                      <input type="number" step="any"  id="moisture_csand" onChange={handleChange} value={inputs.moisture_csand || 0} name="moisture_csand" max="15" min="0"  className="form-control col-sm-3 mandatory-form-control"  pattern="[0-9]*" required/><br/><br/>
                      <label htmlFor="absorption_csand" className="form-group col-sm-1 text-right">&nbsp;  </label>
                      <input type="number" step="any"  id="absorption_csand" onChange={handleChange} value={inputs.absorption_csand || 0} name="absorption_csand" max="5"  min="0" className="form-control col-sm-3 mandatory-form-control"  pattern="[0-9]*" required/><br/><br/>
                      <label htmlFor="correction_factor_csand" className="form-group col-sm-1 text-right">&nbsp;  </label>
                      <input type="number" id="correction_factor_csand"  value={inputs.correction_factor_csand || 0} name="correction_factor_csand" readOnly={true} style={{cursor: "not-allowed"}} className="form-control col-sm-3"  pattern="[0-9]*" required/><br/><br/>

                      <label htmlFor="moisture_rsand" className="form-group col-sm-1 text-right">RSAND </label>
                      <input type="number" step="any"  id="moisture_rsand" onChange={handleChange} value={inputs.moisture_rsand || 0} name="moisture_rsand" max="15"  min="0" className="form-control col-sm-3 mandatory-form-control"  pattern="[0-9]*" required/><br/><br/>
                      <label htmlFor="absorption_rsand" className="form-group col-sm-1 text-right">&nbsp;  </label>
                      <input type="number" step="any"  id="absorption_rsand" onChange={handleChange} value={inputs.absorption_rsand || 0} name="absorption_rsand" max="5"  min="0" className="form-control col-sm-3 mandatory-form-control"  pattern="[0-9]*" required/><br/><br/> 
                      <label htmlFor="correction_factor_rsand" className="form-group col-sm-1 text-right">&nbsp;  </label>
                      <input type="number" id="correction_factor_rsand"  value={inputs.correction_factor_rsand || 0} name="correction_factor_rsand" readOnly={true} style={{cursor: "not-allowed"}} className="form-control col-sm-3"  pattern="[0-9]*" required/><br/><br/>

                      <label htmlFor="moisture_12mm" className="form-group col-sm-1 text-right">12 MM </label>
                      <input type="number" step="any"  id="moisture_12mm" onChange={handleChange} value={inputs.moisture_12mm || 0} name="moisture_12mm" max="15" min="0"  className="form-control col-sm-3 mandatory-form-control"  pattern="[0-9]*" required/><br/><br/> 
                      <label htmlFor="absorption_12mm" className="form-group col-sm-1 text-right">&nbsp;  </label>
                      <input type="number" step="any"  id="absorption_12mm" onChange={handleChange} value={inputs.absorption_12mm || 0} name="absorption_12mm" max="5"  min="0" className="form-control col-sm-3 mandatory-form-control"  pattern="[0-9]*" required/><br/><br/> 
                      <label htmlFor="correction_factor_12mm" className="form-group col-sm-1 text-right">&nbsp;  </label>
                      <input type="number" id="correction_factor_12mm"  value={inputs.correction_factor_12mm || 0} name="correction_factor_12mm" readOnly={true} style={{cursor: "not-allowed"}} className="form-control col-sm-3"  pattern="[0-9]*" required/><br/><br/>

                      <label htmlFor="moisture_20mm" className="form-group col-sm-1 text-right">20 MM </label>
                      <input type="number"  step="any" id="moisture_20mm" onChange={handleChange} value={inputs.moisture_20mm || 0} name="moisture_20mm" max="15"  min="0" className="form-control col-sm-3 mandatory-form-control"  pattern="[0-9]*" required/><br/><br/> 
                      <label htmlFor="absorption_20mm" className="form-group col-sm-1 text-right">&nbsp;  </label>
                      <input type="number"  step="any" id="absorption_20mm" onChange={handleChange} value={inputs.absorption_20mm || 0} name="absorption_20mm" max="5" min="0"  className="form-control col-sm-3 mandatory-form-control"  pattern="[0-9]*" required/><br/><br/>
                      <label htmlFor="correction_factor_20mm" className="form-group col-sm-1 text-right">&nbsp;  </label>
                      <input type="number" id="correction_factor_20mm"  value={inputs.correction_factor_20mm || 0} name="correction_factor_20mm" readOnly={true} style={{cursor: "not-allowed"}} className="form-control col-sm-3"  pattern="[0-9]*" required/><br/><br/>
                    </div>
                </Col>
              </Row>
            </div>

            <div className="footer text-center">
              <Button  type="submit" className="btn btn-twitter" style={{width:"80px",fontWeight:"bold"}} >Save</Button> &nbsp;&nbsp;
              <Button type="reset" className="btn btn-twitter" style={{width:"80px",fontWeight:"bold"}} onClick={Cancel}>Reset</Button> &nbsp;&nbsp;
              <Button className="btn btn-twitter"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={view}>View</Button>&nbsp;&nbsp;
              <Button className="btn btn-twitter"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={Back}>Home</Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
export default MoistureCorrection;