import React from 'react';
import { useState } from "react";
import Swal from "sweetalert2";
import {Row,Col,Button} from "reactstrap";
import axios from "axios";
import Sidebar from '../../Sidebar';
import { useNavigate } from 'react-router';
import { BiSave } from "react-icons/bi";
import { IoIosEye } from "react-icons/io";
import { TiCancel, TiStarOutline } from "react-icons/ti";
import { AiOutlineHome } from "react-icons/ai";
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import {displayErrorToast,displayError,parseBoolean} from '../../helpers';
import ErrorToast from '../../ErrorToast';
import FloatingControls from '../../components/FloatingControls';
import LoadingOverlay from '../../components/LoadingOverlay';
import { getAllGroups } from "../../services/GroupFormServices";
import { getAllVendors } from '../../services/VendorServices';
import { createEquipments } from '../../services/EquipmentServices';
import StatusDropDown from '../../components/StatusDropDown';
function Equipment() {
    const [cookies] = useCookies(['myToken']);
    const [category] = useCookies(['myCategory']);
    const [inProgress, setInProgress] = useState(false);
    const [superuser] = useCookies(['mySuperuser']);
    const [inputs, setInputs] = useState({
      id:'',
      equip_type :'',
      equip_name : '',
      vendor : '',
      mode : 'hire',
      is_tax_applicable : '',
        insurance_date :"",
        permit_date : '',
        fc_date : '',
        capacity :"",
        fuel_type :"petrol",
        meter_status :'true',
        meter_reading :0,
        equip_open_hm :0,
        user_remarks:'',
        status:''
    });

    
    const [error, setError] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(true);
    const navigate = useNavigate();
    const [vendors, setVendors] = React.useState([]);
    const [group, setgroup] = React.useState([]);

    React.useEffect(() => {
      getAllVendors(cookies)
      .then (
          vendorList => {
              
              const vendors = vendorList.vendor_list.filter(obj => obj.status).map(
                vendors => {
                      return { value: vendors.id, label: vendors.entity_name }
                  }
              );
              setVendors(vendors);
          }
      )
  }, []);
  React.useEffect( () => {
    getAllGroups(cookies)
    .then(response => {
  const group =response.accounts_grouping_list.filter(obj => obj.entity_name == 'EQUIPMENT' )
        const a =[]
                        for(var i=0; i<response.accounts_grouping_list.length; i++) 
                        {
                            if((response.accounts_grouping_list[i].parent_id)=== (group[0].id))
                            {
                                a.push(response.accounts_grouping_list[i]);
                            }
                        }
                      
                        setgroup(a);
     
  
    });
  
  }, []);
    
// function showNoTaxOnly(){

//       document.getElementById("insurance_date").disabled = true;
//       //document.getElementById("insurance_date").required = true;

//      document.getElementById("permit_date").disabled = true;
//      //document.getElementById("permit_date").required = true;
//         document.getElementById("fc_date").disabled = true;
//        // document.getElementById("fc_date").required = true;
// }
// function showTaxOnly(){

//   document.getElementById("insurance_date").disabled = false;
//     document.getElementById("permit_date").disabled = false;
//     document.getElementById("fc_date").disabled = false;
// }
// const handleChange = (event) => {
//     const name = event.target.name;
//     const value = event.target.value;
//   try{  
  
        
//       if(name === 'is_tax_applicable'){
//         if (value === 'false') {
//           showNoTaxOnly();
  
      
//       }  else if(value === 'true'){
            
//         showTaxOnly();
           
//             //  setInputs(values => ({...values, ['is_tax_applicable']:value}))
//             //   document.getElementById('is_tax_applicable').disabled =false;
//             }
//             setInputs(values => ({...values, [name]: value}))
//     }
//     setInputs(values => ({...values, [name]: value}))
//         }
//         catch(e)
//         {
//           displayErrorToast(e);
//         }
//       }

const handleChange = (event) => {
  const name = event.target.name;
  const value = event.target.value;
  setInputs(values => ({...values, [name]: event.target.value}))
}
const handleChangeTaxApplicable = (event) =>{
  const name = event.target.name;
  const value = event.target.value;
  if(value === 'false'){
 
     document.getElementById('insurance_date').disabled = true;
     document.getElementById('insurance_date').required = false;
     document.getElementById('permit_date').disabled = true;
     document.getElementById('permit_date').required = false;
     document.getElementById('fc_date').disabled = true;
     document.getElementById('fc_date').required = false;
     document.getElementById('insurance_date').className = document.getElementById('insurance_date').className.replace('mandatory-form-control',''); 
     document.getElementById('permit_date').className = document.getElementById('permit_date').className.replace('mandatory-form-control',''); 
     document.getElementById('fc_date').className = document.getElementById('fc_date').className.replace('mandatory-form-control',''); 

   
  }
  else
  {
      document.getElementById('insurance_date').disabled = false;
      document.getElementById('insurance_date').required = true;
      document.getElementById('permit_date').disabled = false;
      document.getElementById('permit_date').required = true;
      document.getElementById('fc_date').disabled = false;
      document.getElementById('fc_date').required = true;
      document.getElementById('insurance_date').className += ' mandatory-form-control'; 
      document.getElementById('permit_date').className += ' mandatory-form-control'; 
      document.getElementById('fc_date').className += ' mandatory-form-control'; 

  }
  setInputs(values => ({...values, [name]: event.target.value}))
}
 
  const handleSubmit=(event)=> {
    event.preventDefault();

    if ((Object.values(error).every(x => !x)) && isSubmitting) //If all the required fields are field u can select the save button then choose the option yes or no
    {
        Swal.fire({title: 'Do you want to save?',  
          showCancelButton: true,  
          confirmButtonText: `Yes`,  
          cancelButtonText: `No`,
          }).then((result) => {  
            if (result.isConfirmed) { 
                //console.log(JSON.stringify(inputs)+"inputsubmit")
                createEquipments(cookies, {
                  equip_type_id: parseInt(inputs.equip_type_id),
                  vendor_id: parseInt(inputs.vendor_id),
                  equip_name:inputs.equip_name,
                  capacity:parseInt(inputs.capacity),
                  mode:inputs.mode,
                  is_tax_applicable:inputs.is_tax_applicable?parseBoolean(inputs.is_tax_applicable):false,
                  insurance_date: inputs.insurance_date,
                  permit_date:inputs.permit_date,
                  fc_date:inputs.fc_date,
                  meter_status:inputs.meter_status,
                  fuel_type:inputs.fuel_type,
                  meter_reading:parseInt(inputs.meter_reading),
                  equip_open_hm:parseInt(inputs.equip_open_hm),
                  user_remarks:inputs.user_remarks,
                  status:parseBoolean(inputs.status)
               })
           
              .then(response => {
                  Swal.fire("Saved!", "", "success");
                  view();
              }).catch((error) => {
                  //console.log(error.response.data);
                  displayError(error.response.data,"Saved Failed");
              });
              //console.log(JSON.stringify(createEquipments)+"createEquipments")
            } 
            else if (result.isDismissed) 
            {    
              Swal.fire('Not saved', '', 'info')  
            }
          });
    }

  }

        const Reload = () => {
          window.location.reload();
        }      
        const view = () => {
          navigate('/EquipmentTable')
        }  
        const Back = () => {
          navigate('/Home')
        }
        const Cancel = () => {
            setInputs({
              equip_type_id :'',
              equip_name : '',
              vendor_id : '',
              mode : 'hire',
              is_tax_applicable : 'false',
                insurance_date :'',
                permit_date : '',
                fc_date : '',
                capacity :'',
                fuel_type :"petrol",
                meter_status :'',
                meter_reading :0,
                equip_open_hm :0,
                user_remarks:'',
                status:'true'
            });
        }
    return (
    <>
     <div id="outer-container"  className="App" > 
        <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
        <LoadingOverlay inProgress={inProgress}/>
        <div id="page-wrap">
        <form onSubmit={handleSubmit}> 
          <div id="header">
              <h3  className = "text font-weight-bold page-title" >VEHICLE / EQUIPMENT  REGISTRATION FORM </h3>
          </div>
         
            <div className="container p-2 " > 
       
             <FloatingControls tableLink="/EquipmentTable" onCancel={Cancel} enableCancel={true}/>
            </div>
            <div className="container"> 
                <Row> 
                <Col xl={6} lg={12} md={12}>   
                        <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3  form-control-panel " >
                        <h5 className='col-sm-11'><b>Equipment Details</b></h5><br/><br/>
                            <label htmlFor="equip_type_id" className="form-group col-sm-4 text-right">Equipment Type </label>
                            <select  id="equip_type_id" name='equip_type_id'onChange={handleChange} value={inputs.equip_type_id || ""} className="browser-default custom-select form-control col-sm-7 mandatory-form-control" required> 
                                <option value=''>SELECT GROUP </option>
                                {group.map((item) => (
                                    <option key={item.id} value={item.id}>
                                    {item.entity_name}
                                    </option>
                                         ))}
                                         </select><br/>
                            
                           
                            
                            <label htmlFor="equip_name" className="form-group col-sm-4 text-right">Vehicle Reg./Sl.No </label>
                            <input type="text" name="equip_name" id="equip_name" onChange={handleChange} value={inputs.equip_name || ""}placeholder="Vehicle Reg./Sl.No." className="form-control col-sm-7 mandatory-form-control" required /><br/>
                            {error.equip_name && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.equip_name}</strong><br/></>)}
                                
                            <label htmlFor="mode" className="form-group col-sm-4 text-right">Mode </label>
                            <select name="mode"id="mode" onChange={handleChange} value={inputs.mode || ""}className="browser-default custom-select form-control col-sm-7" >
                                <option value="hire" >Hire</option>
                                <option value="own">Own</option>
                            </select><br/>                      
                            
                            <label htmlFor="vendor_id" className="form-group col-sm-4 text-right">Vendor Code </label>
                            <select id="vendor_id" onChange={handleChange} value={inputs.vendor_id || ""} className="browser-default custom-select form-control col-sm-7 mandatory-form-control" name="vendor_id" required>
                                <option value=''>SELECT VENDOR CODE </option>
                                {vendors.map((item) => (
                                          <option key={item.value} value={item.value}>
                                            {item.label}
                                          </option>
                                        ))}
                                    </select><br/><br/>  
                        </div>

                        <div className="form-row table-bordered shadow p-2 my-2  border-secondary p-2 mb-3 form-control-panel " >
                        <h5 className='col-sm-11'><b>Tax Details</b></h5><br/><br/>

                          <label htmlFor="is_tax_applicable" className="form-group col-sm-4 text-right">Is Tax Applicable</label>
                            <select name="is_tax_applicable" id="is_tax_applicable" onChange={handleChangeTaxApplicable} required value={inputs.is_tax_applicable || "false"}className="form-control col-sm-7 browser-default custom-select mandatory-form-control">
                                <option value="true" >Yes</option>
                                <option value="false">No</option>
                            </select><br/>

                            <label htmlFor="insurance_date" className="form-group col-sm-4 text-right">Insurance Due Date </label>
                            <input type="date"id="insurance_date"onChange={handleChange} value={inputs.insurance_date || ""} name="insurance_date" className="form-control col-sm-7 browser-default custom-select"  disabled={true}/><br/><br/>
                            
                            <label htmlFor="permit_date" className="form-group col-sm-4 text-right">Permit Due Date </label>
                            <input type="date" id="permit_date" onChange={handleChange} value={inputs.permit_date || ""} name="permit_date" className="form-control col-sm-7 browser-default custom-select"  disabled={true}/><br/><br/>
                            
                            <label htmlFor="fc_date" className="form-group col-sm-4 text-right">FC Due Date </label>
                            <input type="date" id="fc_date"onChange={handleChange} value={inputs.fc_date || ""} name="fc_date" className="form-control col-sm-7 browser-default custom-select"  disabled={true}/><br/><br/><br/> 
                        </div>
                </Col>
                    <Col xl={6} lg={12} md={12}>   
                   
                        <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3  form-control-panel" >
                        <h5 className='col-sm-11'><b>Fuel Details</b></h5><br/><br/>
                            <label htmlFor="capacity" className="form-group col-sm-4 text-right">Capacity </label>
                            <input type="number" id="capacity" onChange={handleChange} value={inputs.capacity || ""} name="capacity" max="10" className="form-control col-sm-7 mandatory-form-control"  pattern="[0-9]*" required/><br/>
                            
                            <label htmlFor="fuel_type" className="form-group col-sm-4 text-right">Fuel Type </label>
                            <select name="fuel_type" onChange={handleChange} value={inputs.fuel_type || ""}id="fuel_type" className="browser-default custom-select form-control col-sm-7" >
                                <option value="petrol">Petrol</option>
                                <option value="diesel">Diesel</option>
                            </select><br/><br/>                    
                        
                            <label htmlFor="meter_status" className=" form-group col-sm-4 text-right">Meter Status </label>
                            <select name="meter_status" onChange={handleChange} value={inputs.meter_status}id="meter_status" className="browser-default custom-select  form-control col-sm-7" >

                                <option value="true" >Working</option>
                                <option value="false">Not Working</option>
                            </select><br/><br/>                       
                        
                            <label htmlFor="meter_reading" className="form-group col-sm-4 text-right">Opening Meter Reading</label>
                            <input type="number" onChange={handleChange} value={inputs.meter_reading || 0} id="meter_reading"name="meter_reading" className="form-control col-sm-7"  pattern="[0-9]*" /><br/><br/>
                            
                            <label htmlFor="equip_open_hm" className="form-group col-sm-4 text-right">Opening HM </label>
                            <input type="number" onChange={handleChange} value={inputs.equip_open_hm || 0} id="equip_open_hm" name="equip_open_hm" className="form-control col-sm-7"  pattern="[0-9]*"  />                    
                        </div>
                        
                        <div className="form-row table-bordered shadow p-2 my-2  border-secondary p-2 mb-3  form-control-panel" >
                        <h5 className='col-sm-11'><b>Status Details</b></h5><br/><br/>
                            <label htmlFor="user_remarks" className="form-group col-sm-4 text-right">Remarks</label>
                            <input type="text" id="user_remarks"name="user_remarks" onChange={handleChange} value={inputs.user_remarks || ""}placeholder="If Any.." maxLength="1000" className="form-control col-sm-7"  /><br/><br/>
                            
                            <StatusDropDown status={inputs.status} onChange={handleChange}/>
                        </div>
                    </Col>
                </Row>             
            </div>
                              <div className="footer text-center">
                              <Button  type="submit" className="btn btn-twitter" style={{width:"80px",fontWeight:"bold"}} >Save</Button> &nbsp;&nbsp;
                                <Button type="reset" className="btn btn-twitter" style={{width:"80px",fontWeight:"bold"}} onClick={Cancel}>Reset</Button> &nbsp;&nbsp;
                                <Button className="btn btn-twitter"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={view}>View</Button>&nbsp;&nbsp;
                                <Button className="btn btn-twitter"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={Back}>Home</Button>
                              </div>
        </form>
        </div>
    </div>
         </>
    );
  }
  export default Equipment;