import {getAllCheckIn} from '../../services/CheckInServices'
import Swal from "sweetalert2";
import { getHTMLForSummaryPopup } from '../../utils/PopupUtils';

export function checkForDuplicateCheckIn(name, value, cookies, existingId) {
    
    
    let nameCheck = new Promise((resolve, reject) => {
        getAllCheckIn(cookies)
      .then((data) => {

        let isUserExists = false;
        for(var i=0; i<data.salesrep_data.length; i++)    //Same Sales Representative Name mentioned it denoted already exist.
        {
          if((data.salesrep_data[i].entity_name) === value.trim().toUpperCase()
            && parseInt(existingId) !== data.salesrep_data[i].id){   
            
            console.log(`User exists with the name - ${value}`) 
            isUserExists = true;
            break;
          }
        }
        resolve(isUserExists);
      });  
    });

    return nameCheck;
  }

 
  export function showBillingPop(selectedCustomer){
    Swal.fire({
      html:getHTMLForSummaryPopup(
          'BILLING DETAILS',
          [
            {label: 'BILLING NAME', value: selectedCustomer.name},
            {label: 'SALES OFFICER', value: selectedCustomer.salesrep.name},
          
          ])
  }, '', 'info');
  }
  export function showCustomerSitePop(consignee){
    Swal.fire({
      html:getHTMLForSummaryPopup(
          'DELIVERY DETAILS',
          [
            {label: 'SITE NAME', value: consignee.name},
            {label: 'ADDRESS', value: consignee.address_1},
            {value: consignee.address_2},
            {value: consignee.address_3},
            {label: 'PROJECT/SITE', value: consignee.project_name},
          ])
  }, '', 'info');   
  }
  export function showDeliveryChallanPop(selected_DC){
    Swal.fire({
      html:getHTMLForSummaryPopup(
          'DELIVERY CHALLAN DETAILS',
          [
           {label: 'SO NO', value: selected_DC.work_schedule.sales_order_detail.sales_order.order_no},
          {label: 'DELIVERY CHALLAN DATE', value: selected_DC.delivery_challan_date},
           {label: 'DELIVERY DATE', value: selected_DC.work_schedule.sales_order_detail.product.name},
           {label: 'DC QTY', value: selected_DC.dc_qty},
           {label: 'VEHICLE', value: selected_DC.transport_mode === 'own'? selected_DC.vehicle.name : selected_DC.customer_vehicle_no},
           {label: 'TRANSPORT MODE', value: selected_DC.transport_mode}
        
          ])
  }, '', 'info');   

}