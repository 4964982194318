import {getAllCustomerMain} from '../../services/CustomerMainServices'

export function checkDuplicateCustomerMain(name, value, cookies, existingId) {
    
    
    let nameCheck = new Promise((resolve, reject) => {
        getAllCustomerMain(cookies)
      .then((data) => {

        let isUserExists = false;
        data.customer_list.forEach(customer =>{ //Same Customer Name mentioned it denoted already exist.
        
          if((customer.name) === value.trim().toUpperCase()
            && parseInt(existingId) !== customer.id){   
            
            console.log(`Customer exists with the name - ${value}`) 
            isUserExists = true;
       
          }
        
      })
        resolve(isUserExists);
      });  
    });

    return nameCheck;
  }