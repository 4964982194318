import { useEffect, useState, useMemo } from "react";
import ReactTableWrapper from "../../components/ReactTableWrapper";
import { exportAsCSV } from "../../utils/CSVExporter";
import { exportAsPDF } from "../../utils/PDFExporter";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import Sidebar from '../../Sidebar';
import Swal from "sweetalert2";
import { useCookies } from 'react-cookie';
import { Link } from "react-router-dom";
import {Row,Col,Button,} from "reactstrap";

import { getSalesPostingReport} from "../../services/ReportServices";
import {  DatePicker } from 'antd';
import moment  from 'moment';
const {RangePicker} = DatePicker;
function SalesPostingReportTable() {
    const InitialData = {
        total_pages: 0,
        records: [],
      };
    const [data, setData] = useState(InitialData);
    const navigate = useNavigate(); 
    const [refreshKey, setRefreshKey] = useState(0);
    const [cookies] = useCookies(['myToken']);
    const [isLoading, setIsLoading] = useState(true); 
    const [dates,setDates]=useState({}) 
    const columns = useMemo(
        () => [
            {
                fieldName: 'entity_name', 
                headerName: 'STATION'
            },
           
            {
                fieldName: "entity_name",
                headerName: 'DOC Type'
            },
            {
                fieldName: "entity_type",
                headerName: 'No'
            },
            {
                fieldName: "entity_type",
                headerName: 'Date'
            },
            {
                fieldName: "entity_type",
                headerName: 'Delivery Date'
            },
            {
                fieldName: "entity_type",
                headerName: 'Customers Name'
            },
            {
                fieldName: "entity_type",
                headerName: 'Site Name'
            },
          
          
          
            {
                fieldName: "entity_type",
                headerName: 'Grade'
            },
            {
                fieldName: "entity_type",
                headerName: 'Mode'
            },
            {
                fieldName: "entity_type",
                headerName: 'Quantity'
            },
            {
                fieldName: "entity_type",
                headerName: 'Rate'
            },
            {
                fieldName: "entity_type",
                headerName: 'Amount'
            },
            {
                fieldName: "entity_type",
                headerName: 'PUMP Amount'
            },
            {
                fieldName: "entity_type",
                headerName: 'Receivable Amount'
            },
            {
                fieldName: "entity_type",
                headerName: 'Collected Amount'
            },
            {
                fieldName: "entity_name",
                headerName: 'Balance Amount'
            },
            {
                fieldName: "entity_type",
                headerName: 'Marketing'
            },
            {
                fieldName: "entity_type",
                headerName: 'Credit Days'
            },
            {
                fieldName: "entity_type",
                headerName: 'Due Days'
            },
            {
                fieldName: "entity_type",
                headerName: 'Over Due Days'
            },
       
           
          
        ],
        [],);

   
    const handleCSVExport = (event) => {
        console.log('CSV Export');
        exportAsCSV(
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(c => c.headerName),
            data.records.map(r => {

                const csvRow = {
                    entity_name: r.entity_name,
                    address: r.address,
                    phone_number:r.phone_number,
                    mobile_number:r.mobile_number,
                    status:r.status
                }
                return csvRow;
                }));
    };

    const handlePDFExport = (event) => {
        console.log('PDF Export');
        exportAsPDF(
            'List of Sales Posting Report',
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(col=>({header: col.headerName, dataKey: col.fieldName })),
            data.records.map(r => ({
                entity_name: r.entity_name,
                address: r.address, 
                phone_number:r.phone_number,
                mobile_number:r.mobile_number,
                status:r.status
            })),
            'Sales_PostingReport.pdf'
        );
    };

    useEffect(() => {

        getSalesPostingReport(cookies)
        .then( salespostingreportList => {
            const tableData = salespostingreportList.query_set
            .map(r => ({
                id :r.id,
                entity_name: r.entity_name,
                parent_id: r.parent_id,
                entity_type:r.entity_type,
            }));

            setData({
                total: data.total,
                records: tableData
            });
            setIsLoading(false);
        });
        }, [refreshKey]);
    
    return (

        <div id="outer-container"  className="App" > 
            
            <div id="page-wrap">
            <h3 style={{color:"rgb(2, 2, 49)",textAlign:"center"}}>Sales Posting Report</h3>
            <br/>
            <form className="form" onSubmit={""}>
        <div className="container">
        <Row>
          <Col xl={1} lg={12} md={12}>
            </Col>
          <Col xl={10} lg={11} md={11}>
          <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3 form-control-panel ">
                      
                      &nbsp;&nbsp;&nbsp;&nbsp;           
                      <label htmlFor="grnHCompcode" className="form-group col-sm-0 text-right"></label>
                                  <select  id="grnHCompcode" className="browser-default custom-select col-sm-2"   required name="grnHCompcode"   >
                                      <option value=''>Please Select Plant</option>
            
                                     
                                  </select>
                                  <label htmlFor="grnHVehNo" className="form-group col-sm-0 text-right"></label>
                                  <select  id="grnHVehNo" className="browser-default custom-select col-sm-3"   required name="grnHVehNo"   >
                                      <option value=''>Please Select Sales Representative</option>
            
                                     
            
                                     
                                  </select>
                              
                                  <br></br> 
                                  <br></br>     &nbsp;    &nbsp;
                             
                                  <label htmlFor="venName" className="form-group col-sm-0 text-right"></label>
                                  <select  id="venName" className="browser-default custom-select col-sm-3"   required name="venName"   >
                                      <option value=''>Please Select Customer Main</option>
            
            
                                     
                                  </select>
                                  &nbsp;&nbsp;&nbsp;
                                  <label htmlFor="grnHVehNo" className="form-group col-sm-0 text-right"></label>
                                  <select  id="grnHVehNo" className="browser-default custom-select col-sm-3"   required name="grnHVehNo"   >
                                      <option value=''>Please Select Customer Site</option>
            
                                     
            
                                     
                                  </select>
                                  &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;    &nbsp;  
                                  {/* <label htmlFor="grnHVehNo" className="form-group col-sm-0 text-right"></label> */}
                                  <select  id="grnHVehNo" className="browser-default custom-select col-sm-2"   required name="grnHVehNo"   >
                                      <option value=''> Balance Amount</option>
            
                                     
            
                                     
                                  </select>
            
                                  &nbsp;
                                  &nbsp;
                           
            
                                  <label htmlFor="grnHCompcode" className="form-group col-sm-0 text-center"></label>
                                  <RangePicker  style={{centre:"170px",height:"40px"}}
                                    onchange={(values)=>{
                                      setDates(values.map(item=>{
                                        return moment(item.format('YYYY-DD-MM'))
                                      }))
                                    }}/>
                              
                     <div>    
                <Link  to={'/Search'} >
                             <i className=" form-group col-sm-3  fa fa-search fa-2x"aria-hidden="true"></i>
                             </Link>
                             </div> 
            
                             <div>    
                <Link  to={'/Search'} >
                             <i className=" form-group col-sm-3  fa fa-home fa-2x"aria-hidden="true"></i>
                             </Link>
                             </div> 
                            
           
            
                                  </div>
          </Col>
   
          </Row> 
        </div>
    
        </form>
                <div className="container item-list-table-container">
                <div className="table-responsive">
                <ReactTableWrapper
                            title='Salesposting Report'
                            columns={columns}
                            data={data.records}
                            onCSVExport={handleCSVExport}
                            onPDFExport={handlePDFExport}
                            isLoading={isLoading}
                        />
                </div>
                </div> 

            </div>
                </div>

        
    );

}

export default SalesPostingReportTable;