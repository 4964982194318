import { useEffect, useState, useMemo } from "react";
import ReactTableWrapper from "../../components/ReactTableWrapper";
import { exportAsCSV } from "../../utils/CSVExporter";
import { exportAsPDF } from "../../utils/PDFExporter";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import { displayError } from "../../helpers";
import Sidebar from '../../Sidebar';
import Swal from "sweetalert2";
import { useCookies } from 'react-cookie';
import { getAllPlantConfigurations,deletePlantConfiguration } from "../../services/PlantConfigurationServices";

function PlantConfigurationTable() {
    const InitialData = {
        total_pages: 0,
        records: [],
    };
    const [data, setData] = useState(InitialData);
    const navigate = useNavigate(); 
    const [refreshKey, setRefreshKey] = useState(0);
    const [cookies] = useCookies(['myToken']);
    const [isLoading, setIsLoading] = useState(true); 
    const [enableExport,setEnableExport] = useState(false); 
    const columns = useMemo(
        () => [
            {
                fieldName: 'plant_alias', 
                headerName: 'Plant Alias'
            },
            {
                fieldName: "silo_1",
                headerName: 'Silo 1'
            },
            {
                fieldName: "silo_2",
                headerName: 'Silo 2'
            },
            {
                fieldName: "silo_3",
                headerName: 'Silo 3'
            },
            {
                fieldName: "silo_4",
                headerName: 'Silo 4'
            },
            {
                fieldName: "silo_5",
                headerName: 'Silo 5'
            },
            {
                fieldName: "bin_1",
                headerName: 'Bin 1'
            },
            {
                fieldName: "bin_2",
                headerName: 'Bin 2'
            },
            {
                fieldName: "bin_3",
                headerName: 'Bin 3'
            },
            {
                fieldName: "bin_4",
                headerName: 'Bin 4'
            },
            {
                fieldName: "bin_5",
                headerName: 'Bin 5'
            },
            {
                fieldName: "other_1",
                headerName: 'Other 1'
            },
            {
                fieldName: "other_2",
                headerName: 'Other 2'
            },
            {
                fieldName: "other_3",
                headerName: 'Other 3'
            },
            {
                fieldName: "other_4",
                headerName: 'Other 4'
            },
            {
                fieldName: "other_5",
                headerName: 'Other 5'
            },
            {
                fieldName: "id",
                headerName: "ROW_ACTIONS"
            }
        ],
        [],);

    const onEditPlant = (plantConfigurationId, event) => {
       navigate(`/PlantConfigurationEdit/${plantConfigurationId}/`);
    };
    
    const onDeletePlant = (plantConfigurationId, event) => {
        event.preventDefault();
        Swal.fire({title: 'Are you sure to Delete?',  
        showCancelButton: true,  
        confirmButtonText: `Yes`,  
        cancelButtonText: `No`,
        }).then((result) => {  
            if (result.isConfirmed) { 
                setIsLoading(true);
                deletePlantConfiguration(cookies, plantConfigurationId)
                .then(response => {
                    console.log(`Plant configuration with id ${plantConfigurationId} deleted`);
                    Swal.fire('Deleted Successfully!', '', 'success');
                    setRefreshKey(oldKey => oldKey +1);
                })
                .catch(error => {
                    setIsLoading(false);
                    console.log(error.response.data+"error in  delete");
                    displayError(error.response.data,'Delete Failed');
                }); 
            } 
            else if (result.isDismissed) {    
                Swal.fire('Not Deleted', '', 'info')  
            }
        });
    };

    const handleCSVExport = (event) => {
        console.log('CSV Export');
        exportAsCSV(
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(c => c.headerName),
            data.records.map(r => {
                const csvRow = {
                    entity_name: r.entity_name,
                    plant_alias: r.plant_alias,
                    address_1:r.address_1,
                }
                return csvRow;
                }));
    };

    const handlePDFExport = (event) => {
        console.log('PDF Export');
        exportAsPDF(
            'List of Plant Configurations',
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(col=>({header: col.headerName, dataKey: col.fieldName })),
            data.records.map(r => ({
                plant_alias: r.plant_alias,
                silo_1:r.silo_1,
                silo_2:r.silo_2,
                silo_3:r.silo_3,
                silo_4:r.silo_4,
                silo_5:r.silo_5,
                bin_1:r.bin_1,
                bin_2:r.bin_2,
                bin_3:r.bin_3,
                bin_4:r.bin_4,
                bin_5:r.bin_5,
                other_1: r.other_1,
                other_2:r.other_2,
                other_3:r.other_3,
                other_4:r.other_4,
                other_5:r.other_5,
            })),
            'Plant_Configuration.pdf'
        );
    };

    useEffect(() => {
        getAllPlantConfigurations(cookies)
        .then( response => {
            const tableData = response.plant_configuration_list
            .map(r => ({
                id :r.id,
                plant_alias: r.plant.alias_name,
                silo_1:r.silo_1,
                silo_2:r.silo_2,
                silo_3:r.silo_3,
                silo_4:r.silo_4,
                silo_5:r.silo_5,
                bin_1:r.bin_1,
                bin_2:r.bin_2,
                bin_3:r.bin_3,
                bin_4:r.bin_4,
                bin_5:r.bin_5,
                other_1: r.other_1,
                other_2:r.other_2,
                other_3:r.other_3,
                other_4:r.other_4,
                other_5:r.other_5,
            }));
            setData({
                total: data.total,
                records: tableData
            });
            setIsLoading(false);
        });
        }, [refreshKey]);
    
    return (
        <div id="outer-container"  className="App" > 
            <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
            <div id="page-wrap">
                <h3 style={{color:"rgb(2, 2, 49)",textAlign:"center"}}>List of Plant Configurations</h3>
                <br/>
                    <div className="container item-list-table-container">
                        <div className="table-responsive">
                            <ReactTableWrapper
                                title='List of Group Form'
                                columns={columns}
                                data={data.records}
                                onRowEdit={onEditPlant}
                                onRowDelete={onDeletePlant}
                                // enableExportToolbar = {enableExport}
                                onCSVExport={handleCSVExport}
                                onPDFExport={handlePDFExport}
                                isLoading={isLoading}
                            />
                        </div>
                    </div> 
            </div>
            <button style={{position:"relative",right:"40px"}}className="btn btn-fill btn-primary float-end" type="button" onClick={() =>{navigate("/PlantConfiguration")}}>Back</button>
        </div>
    );
}
export default PlantConfigurationTable;