import { useEffect, useState, useMemo } from "react";
import ReactTableWrapper from "../../components/ReactTableWrapper";
import { exportAsCSV } from "../../utils/CSVExporter";
import { exportAsPDF } from "../../utils/PDFExporter";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import Sidebar from '../../Sidebar';
import Swal from "sweetalert2";
import { useCookies } from 'react-cookie';
import { displayError } from "../../helpers";
import { getAllCheckOut, deleteCheckOut,printDcCheckout,printBatchReport } from "../../services/CheckOutServices";
function CheckOutTable() {
    const InitialData = {
        total_pages: 0,
        records: [],
      };
    const [data, setData] = useState(InitialData);
    const navigate = useNavigate(); 
    const [refreshKey, setRefreshKey] = useState(0);
    const [cookies] = useCookies(['myToken']);
    const [isLoading, setIsLoading] = useState(true);  
    const right_pinned =["id"]
    const columns = useMemo(
        () => [
            {
                fieldName: "plant",
                headerName: 'Plant'
            },
            {
                fieldName: "checkout_no",
                headerName: 'CheckOut No.'
            },
            {
                fieldName: 'checkout_date', 
                headerName: 'CheckOut Date'
            },
            
            {
                fieldName: "delivery_challan_no",
                headerName: 'DC Nos'
            },
            {
                fieldName: "sales_order_no",
                headerName: 'SO Nos'
            },
            {
                fieldName: 'billing_name', 
                headerName: 'Billing Name'
            },
           
            {
                fieldName: "site_name",
                headerName: 'Project/Site Name'
            },
            {
                fieldName: "grade",
                headerName: 'Grade'
            },
            {
                fieldName: "dc_qty",
                headerName: 'Qty'
            },
            {
                fieldName: "transport_mode",
                headerName: 'Mode'
            },
            {
                fieldName: "customer_vehicle_no",
                headerName: 'Vehicle No'
            },
            {
                fieldName: 'driver_name', 
                headerName: 'Drivers Name& Mobile No.'
            },
           
            {
                fieldName: "id",
                headerName: "ROW_ACTIONS"
            }
        ],
        [],);

    const onEditCheckOut = (checkoutId, event) => {
       
       navigate(`/CheckOutEdit/${checkoutId}/`);
    };

    const onDeleteCheckOut= (checkoutId, event) => {

        event.preventDefault();
        Swal.fire({title: 'Are you sure to Delete?',  
        showCancelButton: true,  
        confirmButtonText: `Yes`,  
        cancelButtonText: `No`,
        }).then((result) => {  
          if (result.isConfirmed) { 

            setIsLoading(true);
            deleteCheckOut(cookies, checkoutId); 
            console.log(`Checkout with id ${checkoutId} deleted`);
            Swal.fire('Deleted Successfully!', '', 'success');
            setRefreshKey(oldKey => oldKey +1)
          } else if (result.isDismissed) {    
            Swal.fire('Not Deleted', '', 'info')  
        }
        });
        //

    };

    const handleCSVExport = (event) => {
        console.log('CSV Export');
        exportAsCSV(
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(c => c.headerName),
            data.records.map(checkoutcsv => {
                
                const csvRow = {
                    plant:checkoutcsv.plant,
                    checkout_no: checkoutcsv.checkout_no,
                    checkout_date:checkoutcsv.checkout_date,
                    checkout_time:checkoutcsv.checkout_time,
                    delivery_challan_no:checkoutcsv.delivery_challan_no,
                     sales_order_no:checkoutcsv.sales_order_no,
                    billing_name:checkoutcsv.billing_name,
                    site_name:checkoutcsv.site_name,
                    grade:checkoutcsv.grade,
                    dc_qty:checkoutcsv.dc_qty,
                    transport_mode:checkoutcsv.transport_mode,
                    vehicle_no:checkoutcsv.customer_vehicle_no,
                    driver_name:checkoutcsv.driver_name
        
                }
                return csvRow;
                }));
    };

    const onPrintDeliveryChallan = (checkoutId, event) => {
        event.preventDefault();
        setIsLoading(true);
        printDcCheckout(cookies, checkoutId)
        .then(response => {
            const file = new Blob([response], { type: "application/pdf" });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            const pdfWindow = window.open();
            pdfWindow.location.href = fileURL;
            setIsLoading(false);
        })
        .catch(error => {
            // setRefreshKey(oldKey => oldKey +1);
            console.log(error);
            displayError(error,'Print Failed');
            setIsLoading(false);
        });  
    };

    const onPrintBr = (checkoutId, event) => {
        event.preventDefault();
        setIsLoading(true);
        printBatchReport(cookies, checkoutId)
        .then(response => {
            const file = new Blob([response], { type: "application/pdf" });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            const pdfWindow = window.open();
            pdfWindow.location.href = fileURL;
            setIsLoading(false);
        })
        .catch(error => {
            // setRefreshKey(oldKey => oldKey +1);
            console.log(error);
            displayError(error,'BR print Failed');
            setIsLoading(false);
        });  
    };
    

    const handlePDFExport = (event) => {
        console.log('PDF Export');
        exportAsPDF(
            'List of CheckOut',
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(col=>({header: col.headerName, dataKey: col.fieldName })),
            data.records.map(checkoutpdf => ({
                plant:checkoutpdf.plant,
                checkout_no: checkoutpdf.checkout_no,
                checkout_date:checkoutpdf.checkout_date,
                checkout_time:checkoutpdf.checkout_time,
                delivery_challan_no:checkoutpdf.delivery_challan_no,
                 sales_order_no:checkoutpdf.sales_order_no,
                billing_name:checkoutpdf.billing_name,
                site_name:checkoutpdf.site_name,
                grade:checkoutpdf.grade,
                dc_qty:checkoutpdf.dc_qty,
                transport_mode:checkoutpdf.transport_mode,
                vehicle_no:checkoutpdf.customer_vehicle_no,
                driver_name:checkoutpdf.driver_name
            })),
            'CheckOutList.pdf'
        );
    };

    useEffect(() => {

        getAllCheckOut(cookies)
        .then( checkoutList => {
            const tableData = checkoutList.checkout_list
            .map(checkout => ({
                id :checkout.id,
                checkout_no: checkout.checkout_no,
                checkout_date:checkout.checkout_date,
                checkout_time:checkout.checkout_time,
                delivery_challan_no:checkout.delivery_challan.delivery_challan_no,
                sales_order_no:checkout.delivery_challan.work_schedule.sales_order_detail.sales_order.order_no,
                billing_name:checkout.delivery_challan.work_schedule.sales_order_detail.sales_order.consignee.name,
                site_name:checkout.delivery_challan.work_schedule.sales_order_detail.sales_order.consignee.project_name,
                grade:checkout.delivery_challan.work_schedule.sales_order_detail.product.name,
                dc_qty:checkout.delivery_challan.dc_qty,
                transport_mode:checkout.delivery_challan.transport_mode,
                customer_vehicle_no:checkout.delivery_challan.vehicle.name?checkout.delivery_challan.vehicle.name:checkout.delivery_challan.customer_vehicle_no,
                driver_name:checkout.driver_name,
                plant:checkout.delivery_challan.work_schedule.plant.alias_name,
                is_batch_report_needed:checkout.delivery_challan.work_schedule.sales_order_detail.sales_order.company.is_batch_report_needed?"Yes":"No",
                hide_row_print:"No"
            }));

            setData({
                total: data.total,
                records: tableData
            });
            setIsLoading(false);
        });
        }, [refreshKey]);
    
    return (

        <div id="outer-container"  className="App" > 
            <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
            <div id="page-wrap">
            <h3 style={{color:"rgb(2, 2, 49)",textAlign:"center"}}>List of CheckOut</h3>
            <br/>
                <div className="container item-list-table-container">
                <div className="table-responsive">
                <ReactTableWrapper
                            title='List of CheckOut'
                            columns={columns}
                            data={data.records}
                            onRowEdit={onEditCheckOut}
                            onRowDelete={onDeleteCheckOut}
                            onCSVExport={handleCSVExport}
                            onPDFExport={handlePDFExport}
                            onRowPrint={onPrintDeliveryChallan}
                            onRowBrPrint={onPrintBr}
                            hidePrint ={false}
                            isLoading={isLoading}
                            right_pinned={right_pinned}
                        />
                </div>
                </div> 


            </div>
            <button style={{position:"relative",right:"40px"}}className="btn btn-fill btn-primary float-end" type="button" onClick={() =>{navigate("/CheckOut")}}>Back</button>
        </div>

        
    );

}



export default CheckOutTable;