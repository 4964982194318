import { useEffect, useState, useMemo } from "react";
import ReactTableWrapper from "../../components/ReactTableWrapper";
import { exportAsCSV } from "../../utils/CSVExporter";
import { exportAsPDF } from "../../utils/PDFExporter";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import Sidebar from '../../Sidebar';
import Swal from "sweetalert2";
import { useCookies } from 'react-cookie';
import { getAllApprovalSetting, deleteApprovalSetting } from "../../services/ApprovalSettingService";
import {parseBooleanToString} from "../../helpers";
import { displayError } from "../../helpers";
function ApprovalSettingTable() {
    const InitialData = {
        total_pages: 0,
        records: [],
      };
    const [data, setData] = useState(InitialData);
    const navigate = useNavigate(); 
    const [refreshKey, setRefreshKey] = useState(0);
    const [cookies] = useCookies(['myToken']);
    const [isLoading, setIsLoading] = useState(true);  
    const columns = useMemo(
        () => [
            {
                fieldName: 'name', 
                headerName: 'Company'
            },
           
            {
                fieldName: "voucher_type",
                headerName: 'Voucher Type'
            },
            {
                fieldName: "is_appr_needed",
                headerName: 'Is Approval Needed?'
            },
            {
                fieldName: "is_so_wait_on_save",
                headerName: 'Wait for SO Approval OnSave?'
            },
            {
                fieldName: "conditions_for_so_waiting",
                headerName: 'Condition for SO waiting?'
            },
            {
                fieldName: "is_mail_needed",
                headerName: 'Is mail Needed?'
            },
            {
                fieldName: "mail_ids",
                headerName: 'E-Mail Ids'
            },
          
            {
                fieldName: "id",
                headerName: "ROW_ACTIONS"
            }
        ],
        [],);

    const OnEditApprovalSetting = (settingId, event) => {
       
       navigate(`/ApprovalSettingEdit/${settingId}/`);
    };

    const OnDeleteApprovalSetting = (settingId, event) => {
        event.preventDefault();
        Swal.fire({title: 'Are you sure to Delete?',  
        showCancelButton: true,  
        confirmButtonText: `Yes`,  
        cancelButtonText: `No`,
        }).then((result) => {  
          if (result.isConfirmed) { 

            setIsLoading(true);
            deleteApprovalSetting(cookies, settingId)
            .then(response => {
                console.log(`ApprovalSetting with id ${settingId} deleted`);
                Swal.fire('Deleted Successfully!', '', 'success');
                setRefreshKey(oldKey => oldKey +1)
            })
            .catch(error => {
                setRefreshKey(oldKey => oldKey +1);
                console.log(error.response.data);
                displayError(error.response.data,'Delete Failed');
            });  
           
          } else if (result.isDismissed) {    
            Swal.fire('Not Deleted', '', 'info')  
        }
        });
        //

    };
       
    const handleCSVExport = (event) => {
        console.log('CSV Export');
        exportAsCSV(
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(c => c.headerName),
            data.records.map(approval => {

                const csvRow = {
                    name: approval.name,
                    voucher_type: approval.voucher_type,
                    is_appr_needed:parseBooleanToString(approval.is_appr_needed),
                    is_so_wait_on_save:parseBooleanToString(approval.is_so_wait_on_save),
                    conditions_for_so_waiting:approval.conditions_for_so_waiting,
                    is_mail_needed:parseBooleanToString(approval.is_mail_needed),
                    mail_ids:approval.mail_ids,
                    id: approval.id,    
                }
                return csvRow;
                }));
    };

    const handlePDFExport = (event) => {
        console.log('PDF Export');
        exportAsPDF(
            'List of Approval Setting',
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(col=>({header: col.headerName, dataKey: col.fieldName })),
            data.records.map(approval => ({
                name: approval.name,
                voucher_type: approval.voucher_type,
                is_appr_needed:parseBooleanToString(approval.is_appr_needed),
                is_so_wait_on_save:parseBooleanToString(approval.is_so_wait_on_save),
                conditions_for_so_waiting:approval.conditions_for_so_waiting,
                is_mail_needed:parseBooleanToString(approval.is_mail_needed),
                mail_ids:approval.mail_ids,
                id: approval.id,    
            })),
            'Approval_setting.pdf'
        );
    };

    useEffect(() => {

        getAllApprovalSetting(cookies)
        .then( approvalList => {
            const tableData = approvalList.approval_mail_settings_list
            .map(approval => ({
                name: approval.company.name,
                voucher_type: approval.voucher_type.toUpperCase().replace(/_/g, " "),
                is_appr_needed:parseBooleanToString(approval.is_appr_needed),
                is_so_wait_on_save:parseBooleanToString(approval.is_so_wait_on_save),
                conditions_for_so_waiting:approval.conditions_for_so_waiting.join(',').toUpperCase().replace(/_/g, " "),
                is_mail_needed:parseBooleanToString(approval.is_mail_needed),
                mail_ids:approval.mail_ids.join(','),
                id: approval.id,    
            }));

            setData({
                total: data.total,
                records: tableData
            });
            setIsLoading(false);
        });
        }, [refreshKey]);
    
    return (

        <div id="outer-container"  className="App" > 
            <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
            <div id="page-wrap">
            <h3 style={{color:"rgb(2, 2, 49)",textAlign:"center"}}>List of Approval Setting</h3>
            <br/>
                <div className="container item-list-table-container">
                <div className="table-responsive">
                <ReactTableWrapper
                            title='List of Approval Setting'
                            columns={columns}
                            data={data.records}
                            onRowEdit={OnEditApprovalSetting}
                            onRowDelete={OnDeleteApprovalSetting}
                            onCSVExport={handleCSVExport}
                            onPDFExport={handlePDFExport}
                            isLoading={isLoading}
                        />
                </div>
                </div> 


            </div>
            <button style={{position:"relative",right:"40px"}}className="btn btn-fill btn-primary float-end" type="button" onClick={() =>{navigate("/ApprovalSetting")}}>Back</button>
        </div>

        
    );

}



export default ApprovalSettingTable;