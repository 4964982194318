import { useEffect, useState, useMemo } from "react";
import ReactTableWrapper from "../../components/ReactTableWrapper";
import { exportAsCSV } from "../../utils/CSVExporter";
import { exportAsPDF } from "../../utils/PDFExporter";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import Sidebar from '../../Sidebar';
import Swal from "sweetalert2";
import { useCookies } from 'react-cookie';
import { getAllCreditNote, deleteCreditNote } from "../../services/CreditNoteServices";
import {  DatePicker } from 'antd';
import moment  from 'moment';
import 'antd/dist/antd.css';
import { Route,Switch } from 'react-router-dom';
import {
    Row,
    Col,
    Button,
  } from "reactstrap";
const {RangePicker} = DatePicker;
function CreditNoteTable() {
    const InitialData = {
        total_pages: 0,
        records: [],
      };
    const [data, setData] = useState(InitialData);
    const [dates,setDates]=useState({})
    const navigate = useNavigate(); 
    const [refreshKey, setRefreshKey] = useState(0);
    const [cookies] = useCookies(['myToken']);
    const [isLoading, setIsLoading] = useState(true); 

    const columns = useMemo(
        () => [
            {
                fieldName: 'entity_name', 
                headerName: 'Plant Location'
            },
           
            {
                fieldName: "entity_name",
                headerName: 'Credit Note No'
            },
            {
                fieldName: "entity_type",
                headerName: 'Credit Note Date'
            },
            {
                fieldName: "entity_type",
                headerName: 'Customer Name'
            },
            {
                fieldName: "entity_type",
                headerName: 'Site Name'
            },
            {
                fieldName: "entity_type",
                headerName: 'Voucher Type'
            },
            {
                fieldName: "entity_type",
                headerName: 'Amount'
            },
          
      
            {
                fieldName: "id",
                headerName: "ROW_ACTIONS"
            }
       
         
        ],
        [],);

    const onEditCreditNote = (creditnoteId, event) => {
       
       navigate(`/CreditNoteEdit/${creditnoteId}/`);
    };

    const onDeleteCreditNote = (creditnoteId, event) => {

        event.preventDefault();
        Swal.fire({title: 'Are you sure to Delete?',  
        showCancelButton: true,  
        confirmButtonText: `Yes`,  
        cancelButtonText: `No`,
        }).then((result) => {  
          if (result.isConfirmed) { 
            setIsLoading(true);
            deleteCreditNote(cookies, creditnoteId); 
            console.log(`Bank with id ${creditnoteId} deleted`);
            Swal.fire('Deleted Successfully!', '', 'success');
            setRefreshKey(oldKey => oldKey +1)
          } else if (result.isDismissed) {    
            Swal.fire('Not Deleted', '', 'info')  
        }
        });
        //

    };

    const handleCSVExport = (event) => {
        console.log('CSV Export');
        exportAsCSV(
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(c => c.headerName),
            data.records.map(r => {

                const csvRow = {
                    entity_name: r.entity_name,
                    parent_id: r.parent_id,
                    entity_type:r.entity_type
                    
                }
                return csvRow;
                }));
    };

    const handlePDFExport = (event) => {
        console.log('PDF Export');
        exportAsPDF(
            'List of Credit Note',
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(col=>({header: col.headerName, dataKey: col.fieldName })),
            data.records.map(r => ({
                entity_name: r.entity_name,
                parent_id: r.parent_id,
                entity_type:r.entity_type
            })),
            'Bank_Forms.pdf'
        );
    };

    useEffect(() => {

        getAllCreditNote(cookies)
        .then( bankList => {
            const tableData = bankList.query_set
            .map(r => ({
                id :r.id,
                entity_name: r.entity_name,
                parent_id: r.parent_id,
                entity_type:r.entity_type,
            }));

            setData({
                total: data.total,
                records: tableData
            });
            setIsLoading(false);
        });
        }, [refreshKey]);
    
    return (

        <div id="outer-container"  className="App" > 
            <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
            <div id="page-wrap">
            <h3 style={{color:"rgb(2, 2, 49)",textAlign:"center"}}>List of Credit Note</h3>
            <br/>
            <form className="form" >
        <div className="container">
          <Row>
            <Col xl={1} lg={12} md={12}>
            </Col>
          <Col xl={10} lg={12} md={12}>
            <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue form-control-panel"style={{alignItems:"baseline",display:"flex"}}>
              <label htmlFor="company" className="form-group col-sm-1 text-right">Plant location *</label>
              <select  id="company" className="browser-default custom-select col-sm-3"  required name="company"   >
                  <option value=''>Please select</option>
                
              </select>
              <label htmlFor="company" className="form-group col-sm-1 text-right">Customer</label>
              <select  id="company" className="browser-default custom-select col-sm-3"  required name="company"   >
                  <option value=''>Please select</option>
                
              </select>

              <label htmlFor="grnHCompcode" className="form-group col-sm-0 text-right"></label>
                      <RangePicker  style={{centre:"170px"}}
                        onchange={(values)=>{
                          setDates(values.map(item=>{
                            return moment(item.format('YYYY-DD-MM'))
                          }))
                        }}/>
            
            </div>

          </Col>
          </Row> 
        </div>

        </form>
                <div className="container item-list-table-container">
                <div className="table-responsive">
                <ReactTableWrapper
                            title='List of Credit Note'
                            columns={columns}
                            data={data.records}
                            onRowEdit={onEditCreditNote}
                            onRowDelete={onDeleteCreditNote}
                            onCSVExport={handleCSVExport}
                            onPDFExport={handlePDFExport}
                            isLoading={isLoading}
                        
                        />
                </div>
                </div> 


            </div>
            <button style={{position:"relative",right:"40px"}}className="btn btn-fill btn-primary float-end" type="button" onClick={() =>{navigate("/Credit Note")}}>Back</button>
        </div>

        
    );

}



export default CreditNoteTable;