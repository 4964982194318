import { useEffect, useState, useMemo } from "react";
import ReactTableWrapper from "../../components/ReactTableWrapper";
import { exportAsCSV } from "../../utils/CSVExporter";
import { exportAsPDF } from "../../utils/PDFExporter";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import React from 'react';
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import Sidebar from '../../Sidebar';
import Swal from "sweetalert2";
import { useCookies } from 'react-cookie';
import { Link } from "react-router-dom";
import {Row,Col,Button,} from "reactstrap";
import { getAllCustomerMain} from '../../services/CustomerMainServices';
import { getAllSalesRepresentatives } from '../../services/SalesRepresentativeServices';
import { getCustomerDataReport} from "../../services/ReportServices";
import {  DatePicker } from 'antd';
import moment  from 'moment';
const {RangePicker} = DatePicker;
function CustomerDataReportTable() {
    const InitialData = {
        total_pages: 0,
        records: [],
      };
    const [data, setData] = useState(InitialData);
    const navigate = useNavigate(); 
    const [refreshKey, setRefreshKey] = useState(0);
    const [cookies] = useCookies(['myToken']);
    const [isLoading, setIsLoading] = useState(true); 
    const currentMonthStartDate = moment().startOf('month');
    const currentDate = moment();
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [customers, setCustomers] = useState([]);
    const [selectedSalesrep, setSelectedSalesRep] = useState(null);
    const [salesreps, setSalesreps] = useState([]);
    const [dates, setDates] = useState([currentMonthStartDate, currentDate]);
    const Option = (props) => {
        return (
            <>
                <components.Option {...props}>
                    <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}/>{" "}
                <label>{props.label}</label>
                </components.Option>
            </>
        );
    };
    React.useEffect(() => {
        getAllSalesRepresentatives(cookies)
        .then (
            salesRepList => {
                
                const salesrep = salesRepList.salesrep_data.filter(obj => obj.status).map(
                  sales => {
                        return { value: sales.id, label: sales.entity_name }
                    }
                   
                );
                //console.log(JSON.stringify(salesRepList)+"salesRepList")
            
                setSalesreps(salesrep);
              
            }
        )
    }, []);
    React.useEffect(() => {
        getAllCustomerMain(cookies)
        .then (
            customerList => {
                
                const customers = customerList.customer_list.filter(obj => obj.status).map(
                    customer => {
                        return { value: customer.id, label: customer.name }
                    }
                );
                setCustomers(customers);
            }
        )
    }, []);
    const onSalesRepSelect = (selected) => {
        setSelectedSalesRep(selected);
    };
    const onCustomerSelect = (selected) => {
        
        setSelectedCustomer(selected);
    };

    const columns = useMemo(
        () => [
            {
                fieldName: 'entity_name', 
                headerName: 'Customer Name'
            },
            {
                fieldName: 'entity_name', 
                headerName: 'Project Name'
            },
           
            {
                fieldName: "address",
                headerName: ' Site Address'
            },
            {
                fieldName: 'entity_name', 
                headerName: 'Contact person'
            },
          
            {
                fieldName: "mobile_number",
                headerName: 'Mobile Nos'
            },
            {
                fieldName: 'entity_name', 
                headerName: 'Billing Name'
            },
            {
                fieldName: "address",
                headerName: 'Address'
            },
            {
                fieldName: "address",
                headerName: ' State'
            },
            {
                fieldName: "address",
                headerName: 'PAN No'
            },
            {
                fieldName: "address",
                headerName: ' GST No'
            },
            {
                fieldName: "address",
                headerName: 'Contact Details'
            },
            {
                fieldName: 'entity_name', 
                headerName: 'Email'
            },
            {
                fieldName: 'entity_name', 
                headerName: 'Credit Limit'
            },
            {
                fieldName: 'entity_name', 
                headerName: 'Type of Customer'
            },
            {
                fieldName: 'entity_name', 
                headerName: 'Nature of Business'
            },
            {
                fieldName: 'entity_name', 
                headerName: 'Marketing Name'
            },
           
          
        ],
        [],);

   
    const handleCSVExport = (event) => {
        console.log('CSV Export');
        exportAsCSV(
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(c => c.headerName),
            data.records.map(r => {

                const csvRow = {
                    entity_name: r.entity_name,
                    address: r.address,
                    phone_number:r.phone_number,
                    mobile_number:r.mobile_number,
                    status:r.status
                }
                return csvRow;
                }));
    };

    const handlePDFExport = (event) => {
        console.log('PDF Export');
        exportAsPDF(
            'List of CustomerData Report',
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(col=>({header: col.headerName, dataKey: col.fieldName })),
            data.records.map(r => ({
                entity_name: r.entity_name,
                address: r.address, 
                phone_number:r.phone_number,
                mobile_number:r.mobile_number,
                status:r.status
            })),
            'Customer.pdf'
        );
    };

    useEffect(() => {

        getCustomerDataReport(cookies)
        .then( customerList => {
            const tableData = customerList.salesrep_data
            .map(customer => ({
                entity_name: customer.entity_name,
                address: `${customer.address_1},\n${customer.address_2},\n${customer.address_3}`,
                phone_number:customer.phone_number,
                mobile_number:customer.mobile_number,
                status:customer.status,
                id: customer.id,    
            }));

            setData({
                total: data.total,
                records: tableData
            });
            setIsLoading(false);
        });
        }, [refreshKey]);
    
    return (

        <div id="outer-container"  className="App" > 
            
            <div id="page-wrap">
            <h3 style={{color:"rgb(2, 2, 49)",textAlign:"center"}}>Customer Report</h3>
            <br/>
            <form className="form" onSubmit={""}>
        <div className="container">
        <Row>
          <Col xl={1} lg={12} md={12}>
            </Col>
          <Col xl={10} lg={11} md={11}>
          <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-2 form-control-panel ">
                      
          <label htmlFor="Checkbox"className="form-group col-sm-0 text-right" > </label>
                                 
                                     
          <label htmlFor="Checkbox"className="form-group col-sm-0 text-right" > </label>
                                 
                                  <ReactSelect
                                    options={salesreps}
                                    isMulti= {true}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    components={{Option}}
                                    isClearable={true}
                                    // defaultValue={selectedOption}
                                    value={selectedSalesrep}
                                    onChange={onSalesRepSelect}
                                    className="browser-default custom-select col-sm-4"
                                    placeholder="Please Select Sales Representative"
                                  />
                                
                                <br/><br/>&nbsp;&nbsp;&nbsp;
                                <ReactSelect
                                   options={customers}
                                   isMulti= {true}
                                   closeMenuOnSelect={false}
                                   hideSelectedOptions={false}
                                   components={{Option}}
                                   isClearable={true}
                                   // defaultValue={selectedOption}
                                   value={selectedCustomer}
                                   onChange={onCustomerSelect}
                                   className="browser-default custom-select col-sm-4"
                                   placeholder="Please select Customer name"
                                 />
                              
                               <br/><br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                               <label htmlFor="grnHCompcode" className="form-group col-sm-0 text-left"></label>
                                <RangePicker defaultValue={dates}
                                    format="DD-MM-YYYY"
                                    onChange={(values) => {
                                    if (values.length === 2) {
                                        setDates(values);
                                    }
                                    }}
                        />    
                <div>  
                          
                
                    <Link  to={'/Search'} >
                                <i className=" form-group col-sm-3  fa fa-search fa-2x"aria-hidden="true"></i>
                                </Link>
                                </div> 

                                <div>    
                    <Link  to={'/Search'} >
                                <i className=" form-group col-sm-3  fa fa-home fa-2x"aria-hidden="true"></i>
                                </Link>
                 </div> 
                
      

                      </div>
          </Col>
   
          </Row> 
        </div>
    
        </form>
                <div className="container item-list-table-container">
                <div className="table-responsive">
                <ReactTableWrapper
                            title='CustomerData Report'
                            columns={columns}
                            data={data.records}
                            onCSVExport={handleCSVExport}
                            onPDFExport={handlePDFExport}
                            isLoading={isLoading}
                        />
                </div>
                </div> 

            </div>
                </div>

        
    );

}

export default CustomerDataReportTable;